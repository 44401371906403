import {useCallback, useMemo} from 'react';

import Checkbox from '@mui/material/Checkbox';

import {type VantageTableConfig} from '../hooks/useVantageTableConfig';
import {type VantageTableActions} from '../VantageTable';

interface TableActionSelectAllProps<TableData extends object> {
  loading?: boolean;
  data: TableData[];
  config: VantageTableConfig<TableData>;
  selection: VantageTableActions<TableData>['selection'];
}

export function TableActionSelectAll<TableData extends object>({
  loading,
  data,
  config,
  selection,
}: TableActionSelectAllProps<TableData>) {
  const filteredListBySelectableRows = useMemo(
    () =>
      data.filter((row) =>
        config.selection?.getSelectableRow == null
          ? true
          : config.selection.getSelectableRow(row),
      ),
    [config, data],
  );

  const handleSelectAll = useCallback(() => {
    if (selection != null) {
      selection.onSelectionChange(
        selection.selection.length > 0 &&
          selection.selection.length === filteredListBySelectableRows.length
          ? []
          : filteredListBySelectableRows,
      );
    }
  }, [filteredListBySelectableRows, selection]);

  const isSelectDisabled = useMemo(
    () => data.length === 0 || filteredListBySelectableRows.length === 0,
    [filteredListBySelectableRows.length, data.length],
  );
  const isAllSelected = useMemo(
    () =>
      selection?.selection != null &&
      selection.selection.length > 0 &&
      selection.selection.length === filteredListBySelectableRows.length,
    [filteredListBySelectableRows.length, selection],
  );

  const isIndeterminate = useMemo(
    () =>
      selection?.selection != null &&
      selection.selection.length > 0 &&
      selection.selection.length < filteredListBySelectableRows.length,
    [filteredListBySelectableRows.length, selection],
  );

  // enableSelectAll is true by default
  if (selection == null || config.selection?.enableSelectAll === false) {
    return null;
  }

  return (
    <Checkbox
      onChange={handleSelectAll}
      disabled={isSelectDisabled || loading === true}
      checked={isAllSelected}
      indeterminate={isIndeterminate}
    />
  );
}
