import {type ReactNode} from 'react';

import Button, {type ButtonProps} from '@mui/material/Button';
import {useFormikContext} from 'formik';

import {type Permission} from '../../../../permissions';
import {Loader} from '../loader/Loader';

interface SubmitProps extends Omit<ButtonProps, 'title'> {
  label?: ReactNode;
  disableIsDirty?: boolean;
  loading?: boolean;
  loadingLabel?: ReactNode;
  permission?: Permission;
  enabled?: boolean;
}

export function VantageFormSubmit({
  label = 'Submit',
  loadingLabel = 'Submitting',
  disableIsDirty = false,
  loading = false,
  disabled = false,
  startIcon,
  ...props
}: SubmitProps) {
  const {dirty, isSubmitting, status} = useFormikContext();
  const isLoading = loading || isSubmitting;
  return (
    <Button
      variant="contained"
      data-cy="submit"
      fullWidth={false}
      aria-label="Submit form button"
      {...props}
      disabled={
        status === 'unauthorized' ||
        status === 'disabled' ||
        (!disableIsDirty && !dirty) ||
        disabled ||
        isLoading
      }
      startIcon={isLoading ? <Loader size={25} /> : startIcon}
      type="submit"
    >
      {isLoading ? loadingLabel : label}
    </Button>
  );
}
