import {lazy, Suspense} from 'react';
import {useAuth} from 'react-oidc-context';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import {PermissionsLoader} from '../../common/components/loader/PermissionsLoader';
import {useAuthStore} from '../../common/stores/authStore';
import {HomeMenuItemCards} from '../components/HomeMenuItemCards';

const LazyAppShellAnimatedBackground = lazy(
  async () =>
    import('../../common/components/appShell/AppShellAnimatedBackground'),
);

export default function Home() {
  const authStatus = useAuthStore((state) => state.authStatus);
  const {isLoading} = useAuth();

  if (authStatus === 'authenticating' || isLoading) {
    return <PermissionsLoader />;
  }

  return (
    <>
      <Suspense fallback={null}>
        <LazyAppShellAnimatedBackground />
      </Suspense>
      <Container maxWidth="xl" style={{flex: 1, display: 'flex'}}>
        <Stack py={2} flex={1} justifyContent="center" alignItems="center">
          <HomeMenuItemCards />
        </Stack>
      </Container>
    </>
  );
}
