import {useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {DateTimeFormatService} from '../../../services/DateTimeFormatService';
import {VantageDateTimePickerField} from '../../form/VantageDateTimePickerField';
import {ValueEditorStringFilter} from '../components/ValueEditorStringFilter';
import {checkIsFieldDisabled} from '../helpers/checkIsFieldDisabled';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';

export interface DateTimeTypeProviderEditorProps<TableData extends object> {
  rowId: keyof TableData;
  config: VantageTableConfig<TableData>;
}

export function DateTimeTypeProviderEditor<TableData extends object>({
  rowId,
  config,
  ...props
}: DataTypeProvider.ValueEditorProps &
  DateTimeTypeProviderEditorProps<TableData>) {
  const {row, column, value, onValueChange} = props;
  const columnConfig = useMemo(
    () => config.columns.find((c) => c.name === column.name),
    [column.name, config.columns],
  );

  if (columnConfig == null) {
    throw new Error('Column config not found');
  }

  if (row == null) {
    return <ValueEditorStringFilter columnConfig={columnConfig} {...props} />;
  }

  const disabled =
    checkIsFieldDisabled(row, rowId, columnConfig) || props.disabled;

  if (disabled) {
    let date: unknown;
    if (columnConfig?.getValue == null) {
      date = value;
    } else {
      date = columnConfig.getValue(row as TableData);
    }

    if (date == null || !(typeof date === 'string' || date instanceof Date)) {
      return <>-</>;
    }

    return <>{new DateTimeFormatService().formatDateTime(date)}</>;
  }
  return (
    <VantageDateTimePickerField
      name={`${column.name}-${rowId.toString()}`}
      size="small"
      defaultValue={value}
      onChange={(newDate) => {
        onValueChange(newDate);
      }}
    />
  );
}
