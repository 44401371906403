import {useCallback, useMemo, useRef} from 'react';

import SyncIcon from '@mui/icons-material/SyncRounded';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import {type Driver} from '@onroadvantage/onroadvantage-api';
import {type InferType, object, string} from 'yup';

import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {useGenerateAudio} from '../hooks/useGenerateAudio';
import {useSendVantageMobileBroadcast} from '../hooks/useSendVantageMobileBroadcast';

const broadcastVantageMobileSchema = object({
  heading: string().required('Required'),
  message: string().required('Required'),
});
export type BroadcastVantageMobileValues = InferType<
  typeof broadcastVantageMobileSchema
>;

interface BroadcastVantageMobileFormProps {
  selectedDrivers: Driver[];
  onPreviousStep: () => void;
  onResetStepper: () => void;
}

export function BroadcastVantageMobileForm({
  selectedDrivers,
  onPreviousStep,
  onResetStepper,
}: BroadcastVantageMobileFormProps) {
  const messageInputRef = useRef<HTMLInputElement>(null);
  const {generatedDetails, generatedUrlStatus, generateAudioFile} =
    useGenerateAudio(messageInputRef);
  const {onSubmit, submitStatus} = useSendVantageMobileBroadcast(
    generatedDetails,
    selectedDrivers,
    onResetStepper,
  );

  let submitLabel = 'Send broadcast';

  if (submitStatus === 'success') {
    submitLabel = 'Sent broadcast';
  }
  if (submitStatus === 'error' || submitStatus === 'warning') {
    submitLabel = "Didn't broadcast";
  }
  const renderPlayerContent = useMemo(() => {
    switch (generatedUrlStatus) {
      case 'generating':
        return (
          <Skeleton
            height={54}
            width={300}
            variant="rectangular"
            style={{borderRadius: '8rem'}}
          />
        );
      case 'success':
        if (generatedDetails?.url == null) {
          return <Typography>Failed to generate message</Typography>;
        }
        return (
          <audio controls>
            <source src={generatedDetails.url ?? ''} type="audio/mp3" />
            <track kind="captions" />
          </audio>
        );
      default:
        return null;
    }
  }, [generatedUrlStatus, generatedDetails?.url]);

  const handleGenerateAudioFile = useCallback(async () => {
    await generateAudioFile();
  }, [generateAudioFile]);

  return (
    <VantageForm
      initialValues={{message: '', heading: ''}}
      onSubmit={onSubmit}
      validationSchema={broadcastVantageMobileSchema}
    >
      <VantageFormContent>
        <VantageTextField name="heading" label="Heading" formik />
        <VantageTextField
          name="message"
          label="Message"
          inputRef={messageInputRef}
          multiline
          formik
        />
        <Collapse in={generatedUrlStatus !== 'idle'} mountOnEnter unmountOnExit>
          {renderPlayerContent}
        </Collapse>
      </VantageFormContent>
      <VantageFormActions bgcolor="background.default">
        <Button variant="contained" color="inherit" onClick={onPreviousStep}>
          Back
        </Button>
        <VantageFormSubmit
          label={submitLabel}
          loadingLabel="Sending broadcast..."
        />
        <Button
          startIcon={<SyncIcon />}
          color="inherit"
          variant="outlined"
          onClick={handleGenerateAudioFile}
        >
          Generate message
        </Button>
      </VantageFormActions>
    </VantageForm>
  );
}
