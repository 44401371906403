import {type ReportParameter} from '@onroadvantage/onroadvantage-api';

import {ReportParameterAutocomplete} from './ReportParameterAutocomplete';
import {ReportParameterAutocompleteWithListing} from './ReportParameterAutocompleteWithListing';
import {ReportParameterDateRange} from './ReportParameterDateRange';
import {VantageDatePickerField} from '../../../common/components/form/VantageDatePickerField';
import {VantageDateTimePickerField} from '../../../common/components/form/VantageDateTimePickerField';
import {VantageTextField} from '../../../common/components/form/VantageTextField';
import {capitalizeFirstLetter} from '../../../common/helpers/capitalizeFirstLetter';
import {ReportParameterParameterType} from '../../enums/ReportParameterParameterType';

interface ReportParameterProps {
  parameter: ReportParameter;
  index: number;
}

export function ReportParameterField({parameter, index}: ReportParameterProps) {
  if (parameter.name == null) {
    return null;
  }
  const name = `parameters[${index}].value`;

  if (
    parameter.type === ReportParameterParameterType.arrayInt ||
    parameter.type === ReportParameterParameterType.requiredArrayInt
  ) {
    return (
      <ReportParameterAutocompleteWithListing
        models={
          parameter.listName?.split('_').map(capitalizeFirstLetter).join('') ??
          ''
        }
        name={name}
        label={`${parameter.description} (Required)`}
      />
    );
  }

  if (
    parameter.type === ReportParameterParameterType.customList ||
    parameter.type === ReportParameterParameterType.requiredCustomList
  ) {
    return (
      <ReportParameterAutocomplete
        listValues={parameter.listValues ?? []}
        options={[]}
        name={name}
        label={parameter.description}
      />
    );
  }

  if (parameter.type === ReportParameterParameterType.date) {
    return (
      <VantageDatePickerField
        name={name}
        label={parameter.description}
        formik
      />
    );
  }

  if (parameter.type === ReportParameterParameterType.dateTime) {
    return (
      <VantageDateTimePickerField
        name={name}
        label={parameter.description}
        formik
      />
    );
  }

  if (parameter.type === ReportParameterParameterType.dateRange) {
    return (
      <ReportParameterDateRange name={name} label={parameter.description} />
    );
  }

  return <VantageTextField name={name} label={parameter.description} formik />;
}
