import {type ReportParameter} from '@onroadvantage/onroadvantage-api';

import {ReportParameterParameterType} from '../enums/ReportParameterParameterType';

export const getReportParameter = (parameter: ReportParameter) => {
  let defaultValue;
  let type = parameter.type ?? 'string';
  if (
    parameter.type === ReportParameterParameterType.arrayInt ||
    parameter.type === ReportParameterParameterType.customList
  ) {
    defaultValue = [];
  } else if (
    parameter.type === ReportParameterParameterType.date ||
    parameter.type === ReportParameterParameterType.dateTime
  ) {
    defaultValue = new Date();
  } else if (parameter.type === ReportParameterParameterType.dateRange) {
    defaultValue = 'Today';
  }

  if (
    type === ReportParameterParameterType.arrayInt &&
    parameter.required === true
  ) {
    type = ReportParameterParameterType.requiredArrayInt;
  }
  if (
    type === ReportParameterParameterType.customList &&
    parameter.required === true
  ) {
    type = ReportParameterParameterType.requiredCustomList;
  }

  return {
    name: parameter.name,
    description: parameter.description ?? 'Parameter',
    reportParameterId: parameter.id,
    type,
    required: parameter.required ?? false,
    value: defaultValue,
    listName: parameter.listName,
    listValues: parameter.listValues,
  };
};
