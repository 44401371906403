import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';

import {type NodeType} from '@onroadvantage/onroadvantage-api';

import {nodeTypeApi} from '../../../api';
import {type DeleteStatus} from '../../common/types/DeleteStatus';

interface UseDeleteSiteTypeParams {
  reloadData: () => Promise<void>;
}

export const useDeleteSiteType = ({reloadData}: UseDeleteSiteTypeParams) => {
  const [deleteStatus, setDeleteStatus] = useState<DeleteStatus>('idle');

  const onDelete = useCallback(
    async (nodeType: NodeType) => {
      if (nodeType.id == null) {
        toast('NodeType ID not found', {type: 'warning'});
        return;
      }
      setDeleteStatus('deleting');
      try {
        await nodeTypeApi.apiNodeTypeNodeTypeIdDelete({
          nodeTypeId: nodeType.id,
        });
        setDeleteStatus('success');
        await reloadData();
      } catch {
        toast('Failed to delete site type', {type: 'error'});
        setDeleteStatus('error');
      }
    },
    [reloadData],
  );

  return {
    deleteNodeType: onDelete,
    deleteStatus,
  };
};
