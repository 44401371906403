import {type Filter} from '@devexpress/dx-react-grid';
import Description from '@mui/icons-material/DescriptionRounded';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {TripCard} from './tripCard/TripCard';
import {TripCardAvatarBox} from './tripCard/TripCardAvatarBox';
import {config} from '../../../config';
import {type AutocompleteOption} from '../../common/components/form/VantageAutocompleteField';
import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {useUserStore} from '../../common/stores/userStore';

interface TripsContractFilterFieldProps {
  onFiltersChange: (filter: Filter[]) => void;
}

export function TripsContractFilterField({
  onFiltersChange,
}: TripsContractFilterFieldProps) {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  return (
    <Stack
      direction="row"
      spacing={2}
      px={config.theme.gutterSpacing}
      pt={config.theme.gutterSpacing}
    >
      <TripCard>
        <Stack p={1}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="nowrap"
            mb={1}
          >
            <TripCardAvatarBox>
              <Description fontSize="medium" />
            </TripCardAvatarBox>
            <Typography whiteSpace="nowrap">
              <strong>Filter by contract</strong>{' '}
            </Typography>
          </Stack>
          <VantageAutocompleteFieldWithListing
            size="small"
            name="contract"
            models="Contract"
            queryOptions={{metaData: 'code'}}
            label={
              hasPermissions('contracts:viewAll')
                ? 'All Contracts'
                : 'Assigned Contracts'
            }
            onChange={(_, contract) => {
              onFiltersChange([
                {
                  columnName: 'contractCode',
                  value:
                    (contract as AutocompleteOption)?.metaData ??
                    (contract as AutocompleteOption)?.label?.split(' - ')[0],
                },
              ]);
            }}
          />
        </Stack>
      </TripCard>
    </Stack>
  );
}
