import {useFormikContext} from 'formik';

export const useFieldDisabled = (disabled: boolean): boolean => {
  const {isSubmitting, status} = useFormikContext();

  return (
    disabled ||
    isSubmitting ||
    status === 'disabled' ||
    status === 'unauthorized'
  );
};
