import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import Dashboards from './pages/Dashboards';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyDashboardAdd = lazy(async () => import('./pages/DashboardAdd'));
const LazyDashboard = lazy(async () => import('./pages/Dashboard'));

export const dashboardControllerRoutes: RouteObject[] = [
  {
    path: '',
    loader: () => redirect(routes.dashboard.list),
  },
  {
    path: routes.getRelativePath(routes.dashboard.list, routes.dashboard.base),
    element: <Dashboards />,
    loader: () => {
      document.title = 'Dashboards';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Dashboards'}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.dashboard.add, routes.dashboard.base),
    element: <LazyDashboardAdd />,
    loader: () => {
      document.title = 'Add Dashboard';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Dashboards', path: routes.dashboard.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.dashboard.dashboardId,
      routes.dashboard.base,
    ),
    element: <LazyDashboard />,
    loader: () => {
      document.title = 'View Dashboard';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Dashboards', path: routes.dashboard.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
