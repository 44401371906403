import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const broadcastMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Single Message Broadcast',
    pathname: routes.broadcast.singleMessage,
    permission: 'broadcast:view',
  },
  {
    level: 2,
    label: 'Vantage Mobile Broadcast',
    pathname: routes.broadcast.vantageMobile,
    permission: 'broadcast:view',
  },
  {
    level: 2,
    label: 'Broadcasts',
    pathname: routes.broadcast.list,
    permission: 'broadcast:list',
  },
];
