import {useCallback, useEffect, useState} from 'react';

import type {UpdatePagination} from './usePagination';
import {catchHandler} from '../helpers/catchHandler';
import {isObjectWithKeys} from '../helpers/unknownValueTypeChecks';
import type {LoadStatus} from '../types/LoadStatus';

export const useLoadList = <T>(
  loadHandler: () => Promise<{
    items?: T[] | undefined;
    pages?: number | undefined;
    total?: number | undefined;
  }>,
  updatePagination: UpdatePagination | undefined = undefined,
  deps: unknown[] = [],
) => {
  const [loadDataStatus, setLoadDataStatus] = useState<LoadStatus>('idle');
  const [data, setData] = useState<T[]>([]);

  const loadData = useCallback(
    async (reason: 'initial' | 'reload') => {
      setLoadDataStatus(reason === 'initial' ? 'loading' : 'reloading');
      try {
        const response = await loadHandler();
        setData(response.items ?? []);
        setLoadDataStatus('success');
        if (updatePagination != null) {
          updatePagination({
            pages: response.pages,
            total: response.total,
          });
        }
      } catch (error) {
        const {type} = catchHandler(error, 'Failed to load data');

        setLoadDataStatus(type);
      }
    },
    [loadHandler, updatePagination],
  );

  useEffect(() => {
    void loadData('initial');
    return () => {
      setData([]);
      setLoadDataStatus('idle');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    loadData,
    loadDataStatus,
    data,
    setData,
    reloadData: async () => {
      await loadData('reload');
    },
    removeItem: (id: number | string, key?: string) => {
      setData((prevData) =>
        prevData.filter((item) => {
          if (key != null && isObjectWithKeys(item, key)) {
            return item[key] !== id;
          }
          if (isObjectWithKeys(item, 'id')) {
            return item.id !== id;
          }
          return true;
        }),
      );
    },
  };
};
