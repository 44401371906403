import {toast} from 'react-toastify';

import {type Report} from '@onroadvantage/onroadvantage-api';

import {reportApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteReport = ({removeItem}: DeleteParams) => {
  return useDelete<Report>(async ({id}) => {
    if (id == null) {
      toast('Report ID not found', {type: 'warning'});
      return;
    }
    await reportApi.apiReportReportIdDelete({
      reportId: id,
    });
    removeItem(id);
  });
};
