import {lazy} from 'react';
import {Outlet, redirect, type RouteObject} from 'react-router-dom';

import VehicleTypes from './pages/VehicleTypes';
import {vehicleTypeCapacityRoutes} from './vehicleTypeCapacity/vehicleTypeCapacityRoutes';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyVehicleTypeAdd = lazy(async () => import('./pages/VehicleTypeAdd'));
const LazyVehicleTypeOutlet = lazy(
  async () => import('./pages/VehicleTypeOutlet'),
);
const LazyVehicleType = lazy(async () => import('./pages/VehicleType'));

export const vehicleTypeRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.vehicleType.list)},
  {
    path: routes.getRelativePath(
      routes.vehicleType.list,
      routes.vehicleType.base,
    ),
    element: <VehicleTypes />,
    loader: () => {
      document.title = 'Vehicle Types';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Vehicle Types'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.vehicleType.add,
      routes.vehicleType.base,
    ),
    element: <LazyVehicleTypeAdd />,
    loader: () => {
      document.title = 'Add Vehicle Type';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Vehicle Types', path: routes.vehicleType.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.vehicleType.vehicleTypeId,
      routes.vehicleType.base,
    ),
    element: <LazyVehicleTypeOutlet />,
    children: [
      {
        path: '',
        element: <LazyVehicleType />,
        loader: () => {
          document.title = 'View Vehicle Type';
          useBreadcrumbStore.setState({
            breadcrumbs: [
              {label: 'Vehicle Types', path: routes.vehicleType.list},
              {label: 'View'},
            ],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.vehicleType.capacity.base,
          routes.vehicleType.vehicleTypeId,
        ),
        element: <Outlet />,
        children: vehicleTypeCapacityRoutes,
      },
    ],
  },
];
