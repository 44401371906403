import {PagingPanel} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

export const VantageTablePagingPanelContainer = styled(PagingPanel.Container)(
  ({theme}) => ({
    padding: '0 !important',
    height: 'fit-content',
    backgroundColor: theme.palette.background.default,
    '& .Pagination-text': {
      color: theme.palette.text.primary,
    },
  }),
);
