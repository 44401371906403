import {useState} from 'react';
import {useAuth} from 'react-oidc-context';
import {useNavigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {object, string} from 'yup';

import {LoginAcknowledgement} from './LoginAcknowledgement';
import {routes} from '../../../routes';
import {email} from '../../common/components/form/validationSchemas';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {catchHandler} from '../../common/helpers/catchHandler';
import {type LoadStatus} from '../../common/types/LoadStatus';
import {useLogin} from '../hooks/useLogin';

const validationSchema = object({
  email: email.required('Required'),
  password: string().required('Required'),
});

export interface LoginFormValues {
  email: string;
  password: string;
}

export function LoginForm() {
  const {signinRedirect} = useAuth();
  const navigate = useNavigate();
  const {onSubmit} = useLogin();
  const [loginWithSsoStatus, setLoginWithSsoStatus] =
    useState<LoadStatus>('idle');

  const handleSsoClick = async () => {
    setLoginWithSsoStatus('loading');
    try {
      await signinRedirect();
      setLoginWithSsoStatus('success');
    } catch (e) {
      catchHandler(e);
      setLoginWithSsoStatus('error');
    }
  };

  const handleForgotPasswordClick = () => {
    navigate(routes.auth.forgotPassword);
  };

  let loginWithSsoColor: 'inherit' | 'success' | 'error' = 'inherit';
  let loginWithSsoLabel = 'Login with SSO';
  if (loginWithSsoStatus === 'success') {
    loginWithSsoColor = 'success';
    loginWithSsoLabel = 'Logged in';
  } else if (loginWithSsoStatus === 'error') {
    loginWithSsoColor = 'error';
    loginWithSsoLabel = 'Failed to login';
  } else if (loginWithSsoStatus === 'loading') {
    loginWithSsoLabel = 'Logging in...';
  }

  return (
    <VantageForm
      initialValues={{email: '', password: ''}}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <VantageFormContent px={0}>
        <VantageTextField
          name="email"
          label="Email"
          placeholder="Enter your user email address"
          formik
        />
        <VantageTextField
          name="password"
          label="Password"
          type="password"
          placeholder="Enter your user password"
          formik
        />
        <Typography
          color="text.secondary"
          variant="caption"
          onClick={handleForgotPasswordClick}
          style={{cursor: 'pointer'}}
        >
          Forgot Password?
        </Typography>
      </VantageFormContent>
      <VantageFormActions bgcolor={undefined} px={0}>
        <VantageFormSubmit name="login" label="Login" />
        <Button
          disabled={loginWithSsoStatus === 'loading'}
          variant="outlined"
          color={loginWithSsoColor}
          onClick={handleSsoClick}
          startIcon={
            loginWithSsoStatus === 'loading' ? (
              <CircularProgress color="primary" size={22} />
            ) : null
          }
        >
          {loginWithSsoLabel}
        </Button>
      </VantageFormActions>
      <LoginAcknowledgement />
    </VantageForm>
  );
}
