import {useCallback} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import DeleteIcon from '@mui/icons-material/DeleteRounded';

import {VantageDialogButton} from '../../dialog/VantageDialogButton';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';
import {type VantageTableRowActions} from '../VantageTable';

interface TableActionButtonDeleteProps<TableData extends object> {
  loading?: boolean;
  config: VantageTableConfig<TableData>;
  onDelete: VantageTableRowActions<TableData>['onDelete'];
}

export function TableActionButtonDelete<TableData extends object>({
  row,
  onDelete,
  loading,
  config,
}: DataTypeProvider.ValueFormatterProps &
  TableActionButtonDeleteProps<TableData>) {
  const handleDelete = useCallback(() => {
    if (onDelete != null) {
      onDelete(row as TableData);
    }
  }, [row, onDelete]);

  if (onDelete == null) {
    return null;
  }

  return (
    <VantageDialogButton
      title={config.deleteIcon ?? <DeleteIcon />}
      dialogTitle={config.deleteTitle ?? 'Are you sure you want to delete?'}
      dataCy="TableActionsDelete"
      onAccept={handleDelete}
      maxWidth="xs"
      disabled={loading}
      acceptTitle={config.deleteConfirmationLabel ?? 'Delete'}
      acceptProps={{color: 'error'}}
      IconButtonProps={{
        size: 'small',
      }}
      declineTitle="Cancel"
      iconButton
      fullWidth
      enableDividers
    >
      {config.deleteMessage != null
        ? config.deleteMessage(row as TableData)
        : null}
    </VantageDialogButton>
  );
}
