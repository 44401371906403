import {type InferType, object, date, string} from 'yup';

import {
  listing,
  requiredListing,
} from '../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageDateTimePickerField} from '../../common/components/form/VantageDateTimePickerField';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageSelectField} from '../../common/components/form/VantageSelectField';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {type FormProps} from '../../common/types/FormProps';

const orderFormSchema = object({
  orderNumber: string(),
  transporter: listing,
  customer: requiredListing.required('Required'),
  primeMover: string(),
  upliftPoint: requiredListing.required('Required'),
  offloadPoint: requiredListing.required('Required'),
  status: string(),
  depotDeparture: date().required('Required'),
  actualDispatch: date(),
  deliverByDateTime: date(),
  tripDate: date(),
  contract: requiredListing.required('Required'),
  deliveryNoteNumber: string(),
  customerReferenceNumber: string(),
});

export type OrderFormValues = InferType<typeof orderFormSchema>;

const statuses = ['Planned', 'Loaded', 'Offloaded', 'Completed', 'Cancelled'];

export function OrderForm(props: FormProps<OrderFormValues>) {
  return (
    <VantageForm {...props} validationSchema={orderFormSchema}>
      <VantageFormContent>
        <VantageTextField
          name="orderNumber"
          label="Order Number"
          placeholder="Enter the order number"
          formik
        />
        <VantageFormFieldsRow>
          <VantageDateTimePickerField
            name="depotDeparture"
            label="Planned Depot Departure DateTime"
            formik
          />
          <VantageDateTimePickerField
            name="actualDispatch"
            label="Actual Dispatch DateTime"
            formik
          />
          <VantageDateTimePickerField
            name="deliverByDateTime"
            label="Deliver By DateTime"
            formik
          />
        </VantageFormFieldsRow>
        <VantageAutocompleteFieldWithListing
          name="customer"
          models="Customer"
          label="Customer"
          placeholder="Enter and select the customer"
          formik
        />
        <VantageTextField
          name="deliveryNoteNumber"
          label="Delivery Note Number"
          placeholder="Enter the delivery note number"
          formik
        />
        <VantageTextField
          name="customerReferenceNumber"
          label="Customer Reference Number"
          placeholder="Enter the customer reference number"
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="transporter"
          label="Transporter"
          models="Transporter"
          placeholder="Enter the transporter"
          formik
        />
        <VantageTextField
          name="primeMover"
          placeholder="Enter the vehicle registration number"
          label="Prime Mover"
          formik
        />
        <VantageFormFieldsRow>
          <VantageAutocompleteFieldWithListing
            name="upliftPoint"
            label="Loading Point"
            models="Node"
            formik
          />
          <VantageAutocompleteFieldWithListing
            name="offloadPoint"
            label="Offload Point"
            models="Node"
            formik
          />
          <VantageSelectField
            name="status"
            label="Status"
            options={statuses}
            placeholder="Select Status"
            formik
          />
        </VantageFormFieldsRow>
        <VantageAutocompleteFieldWithListing
          name="contract"
          label="Contract"
          models="Contract"
          formik
        />
      </VantageFormContent>
      <VantageFormActions sticky>
        <VantageFormSubmit />
      </VantageFormActions>
    </VantageForm>
  );
}
