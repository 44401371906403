import {type RefObject, useState} from 'react';
import {toast} from 'react-toastify';

import {generateUid} from '../../common/helpers/generateUid';
import {
  isObjectWithKeys,
  isString,
} from '../../common/helpers/unknownValueTypeChecks';
import VoiceUtil from '../../common/utils/VoiceUtil';

type GenerateAudioStatus = 'idle' | 'generating' | 'success' | 'error';
export interface GeneratedDetails {
  identifier: string;
  url: string;
  message: string;
}

export const useGenerateAudio = (
  messageRef: RefObject<HTMLInputElement> | null,
  message?: string | null,
) => {
  const [generatedDetailsStatus, setGeneratedDetailsStatus] =
    useState<GenerateAudioStatus>('idle');
  const [generatedDetails, setGeneratedDetails] =
    useState<GeneratedDetails | null>(null);

  const generateAudioFile = async (params?: {message?: string}) => {
    if (
      (messageRef?.current?.value == null ||
        messageRef?.current?.value === '') &&
      (message == null || message === '') &&
      (params?.message == null || params?.message === '')
    ) {
      toast.warning('Message is empty. Please enter a message.');
      return null;
    }
    setGeneratedDetailsStatus('generating');
    const res = await toast.promise(
      async () => {
        const identifier = `${generateUid()}.mp3`;
        const data = await VoiceUtil.generateVoiceMessage({
          identifier,
          message:
            params?.message ?? messageRef?.current?.value ?? message ?? '',
        });
        if (isObjectWithKeys(data, 'data') && isString(data.data)) {
          setGeneratedDetails({
            identifier,
            url: data.data,
            message:
              params?.message ?? messageRef?.current?.value ?? message ?? '',
          });
        }
        return {
          identifier,
          url: data.data,
          message:
            params?.message ?? messageRef?.current?.value ?? message ?? '',
        };
      },
      {
        pending: 'Generating message...',
        success: 'Message generated successfully.',
        error: 'Failed to generate message.',
      },
    );
    setGeneratedDetailsStatus('success');
    return res;
  };
  return {
    generatedUrlStatus: generatedDetailsStatus,
    generatedDetails,
    generateAudioFile,
    onResetGeneratedUrl: () => {
      setGeneratedDetails(null);
      setGeneratedDetailsStatus('idle');
    },
  };
};
