import {
  closestCorners,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {TripAddStopsOverviewDraggableStop} from './TripAddStopsOverviewDraggableStop';
import {TripAddStopsTripDetails} from './TripAddStopsTripDetails';
import {VantageFormActions} from '../../../common/components/form/VantageFormActions';
import {OverlayLoader} from '../../../common/components/loader/OverlayLoader';
import {useTripAddDetailsContext} from '../../contexts/TripAddDetailsContext';
import {useAddTrip} from '../../hooks/useAddTrip';
import {type TripAddStopsStop} from '../../hooks/useAddTripStops';

interface TripAddStopsSummaryProps {
  stops: TripAddStopsStop[];
  onDragEnd: (event: DragEndEvent) => void;
  onRemoveStop: (id: UniqueIdentifier) => void;
}

export function TripAddStopsOverview({
  stops,
  onDragEnd,
  onRemoveStop,
}: TripAddStopsSummaryProps) {
  const {tripDetails, onPreviousStep} = useTripAddDetailsContext();
  const {addTrip, addStatus} = useAddTrip(tripDetails, stops);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <>
      <OverlayLoader
        loading={addStatus === 'adding'}
        label="Creating trip..."
      />
      <DndContext
        sensors={sensors}
        onDragEnd={onDragEnd}
        collisionDetection={closestCorners}
      >
        <Stack flex={1} alignItems="flex-start" direction="row">
          {stops.length === 0 ? (
            <Typography
              flex={1}
              variant="h5"
              textAlign="center"
              alignSelf="center"
              fontWeight={600}
            >
              No stops
            </Typography>
          ) : (
            <Stack spacing={1} p={1} flex={1}>
              <SortableContext
                items={stops.map((stop) => ({...stop, id: stop.sequence}))}
                strategy={verticalListSortingStrategy}
              >
                {stops.map((stop) => (
                  <TripAddStopsOverviewDraggableStop
                    key={stop.sequence}
                    id={stop.sequence}
                    onRemoveStop={onRemoveStop}
                    stop={stop}
                  />
                ))}
              </SortableContext>
            </Stack>
          )}
          <TripAddStopsTripDetails />
        </Stack>
        <VantageFormActions sticky>
          <Button color="inherit" variant="outlined" onClick={onPreviousStep}>
            Back
          </Button>
          <Button
            disabled={stops.length === 0}
            variant="contained"
            onClick={addTrip}
          >
            Create trip
          </Button>
        </VantageFormActions>
      </DndContext>
    </>
  );
}
