import {StrictMode, useEffect} from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import {App} from './App';
import {config} from './config';
import {ErrorBoundaryFallback} from './router/ErrorBoundaryFallback';

Sentry.init({
  dsn: config.sentry.dsn,
  environment: import.meta.env.MODE,
  enabled: window.location.hostname !== 'localhost',
  integrations: [
    Sentry.browserTracingIntegration(),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  tracePropagationTargets: [
    config.serverUri.replace('https://', ''),
    /\/api\//,
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>,
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/serviceWorker.js')
      .then(() => {
        // eslint-disable-next-line
        console.log('ServiceWorker registration successful');
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('ServiceWorker registration failed:', error);
      });
  });
}
