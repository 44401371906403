import {nodeApi} from '../../../api';
import {useTableDownload} from '../../common/hooks/useTableDownload';

export const useDownloadSites = () => {
  return useTableDownload('Sites', async () => {
    const {items} = await nodeApi.apiNodeGet({
      perPage: 10000,
    });
    return (
      items?.map((item) => ({
        name: item.name ?? '-',
        externalReference: item.externalReference ?? '-',
        type: item.nodeType?.name ?? '-',
        latitude: item.latitude ?? '-',
        longitude: item.longitude ?? '-',
        loadingTimeInMinutes: item.loadingTimeInMinutes ?? '-',
        unloadingTimeInMinutes: item.unloadingTimeInMinutes ?? '-',
        processingTimeInMinutes: item.processingTimeInMinutes ?? '-',
      })) ?? []
    );
  });
};
