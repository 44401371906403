import {Link} from 'react-router-dom';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const linkRegex = /^(ftp|http|https):\/\/[^ "]+$/;

export function LinkTypeProviderFormatter({
  row,
  value,
}: DataTypeProvider.ValueFormatterProps) {
  const {link, action, label} = value;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (!linkRegex.test(link ?? '')) {
    return null;
  }

  const handleClick = () => {
    action?.(value, row);
  };

  return (
    <Link
      to={{pathname: link}}
      target="_blank"
      rel="external noreferrer"
      onClick={handleClick}
    >
      <Tooltip title={label} arrow>
        <IconButton
          aria-label={label}
          style={{
            marginTop: -12,
            marginBottom: -12,
            paddingTop: 12,
            paddingBottom: 12,
          }}
          size="large"
        >
          <OpenInNewOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Link>
  );
}
