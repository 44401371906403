import {styled} from '@mui/material/styles';
import Typography, {type TypographyProps} from '@mui/material/Typography';

import {bounceAnimation} from '../../keyframes/bounceKeyframes';

export function LoadingText({
  loading = true,
  children,
  ...props
}: TypographyProps & {loading?: boolean}) {
  return (
    <LoadingTypography {...props}>
      {children}
      {loading && (
        <>
          <span />
          <span />
          <span />
        </>
      )}
    </LoadingTypography>
  );
}

const getWidthAndHeight = (variant: string | undefined) => {
  switch (variant) {
    case 'h4':
      return {width: '8px', height: '8px'};
    case 'h5':
      return {width: '5px', height: '5px'};
    case 'h6':
    default:
      return {width: '4px', height: '4px'};
  }
};

const LoadingTypography = styled(Typography)(({variant, theme, color}) => ({
  '& > span': {
    ...getWidthAndHeight(variant),
    display: 'inline-block',
    animation: `${bounceAnimation} 1s infinite`,
    background: color ?? theme.palette.text.primary,
    borderRadius: '50%',
    marginInline: '2px',

    '&:nth-of-type(1)': {
      animationDelay: '0.1s',
      marginLeft: '6px',
    },

    '&:nth-of-type(2)': {
      animationDelay: '0.2s',
    },

    '&:nth-of-type(3)': {
      animationDelay: '0.3s',
    },
  },
}));
