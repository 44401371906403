import {
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
  useMemo,
} from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {TableActionButtonBase} from './TableActionButtonBase';
import {isObjectWithKeys} from '../../../helpers/unknownValueTypeChecks';

interface TableActionNavigateProps<TableData extends object> {
  expandedRowIds: Array<string | number>;
  setExpandedRowIds: Dispatch<SetStateAction<Array<string | number>>>;
  rowId: keyof TableData;
  row: TableData;
}

export function TableActionExpand<TableData extends object>({
  expandedRowIds,
  setExpandedRowIds,
  rowId,
  row,
}: TableActionNavigateProps<TableData>) {
  const expanded = useMemo(
    () => expandedRowIds?.includes(row[rowId] as string | number),
    [expandedRowIds, row, rowId],
  );

  const handleExpand = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (
      isObjectWithKeys(row, rowId) &&
      (typeof row[rowId] === 'number' || typeof row[rowId] === 'string')
    ) {
      setExpandedRowIds((prev) =>
        prev.includes(row[rowId] as number | string)
          ? prev.filter((item) => item !== row[rowId])
          : [...prev, row[rowId] as string | number],
      );
    }
  };

  return (
    <TableActionButtonBase data-cy="TableActionsExpand" onClick={handleExpand}>
      <ChevronRightIcon
        style={{transform: expanded ? 'rotate(90deg)' : 'none'}}
      />
    </TableActionButtonBase>
  );
}
