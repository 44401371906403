import {memo} from 'react';

import Collapse from '@mui/material/Collapse';
import {styled} from '@mui/material/styles';

import {
  EventsPaneAdvancedSearchForm,
  type EventsPaneAdvancedSearchFormProps,
} from './EventsPaneAdvancedSearchForm';
import {
  EventsPaneSearchForm,
  type EventsPaneSearchFormProps,
} from './EventsPaneSearchForm';
import {type ExpandedSearchForm} from '../../hooks/useSearchEvents';

interface EventsPaneSearchProps
  extends EventsPaneAdvancedSearchFormProps,
    EventsPaneSearchFormProps {
  expandedSearchForm: ExpandedSearchForm;
}

export const EventsPaneSearch = memo(function EventsPaneSearch({
  expandedSearchForm,
  onClearSearch,
  advancedFormInnerRef,
  onAdvancedSearch,
  onSearch,
  searchFormInnerRef,
}: EventsPaneSearchProps) {
  return (
    <>
      <Collapse in={expandedSearchForm === 'advanced'}>
        <ScrollContainer>
          <EventsPaneAdvancedSearchForm
            onClearSearch={onClearSearch}
            advancedFormInnerRef={advancedFormInnerRef}
            onAdvancedSearch={onAdvancedSearch}
          />
        </ScrollContainer>
      </Collapse>
      <Collapse in={expandedSearchForm === 'basic'}>
        <ScrollContainer>
          <EventsPaneSearchForm
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            searchFormInnerRef={searchFormInnerRef}
          />
        </ScrollContainer>
      </Collapse>
    </>
  );
});

const ScrollContainer = styled('div')(({theme}) => ({
  background: theme.palette.background.default,
  display: 'flex',
  maxHeight: '600px',
  flexDirection: 'column',
  overflowY: 'auto',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderTopRightRadius: theme.shape.borderRadius,
  borderTopLeftRadius: theme.shape.borderRadius,
  scrollbarWidth: 'none',
}));
