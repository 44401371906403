import {memo} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  type WebEvent,
  type WebEventVehicle,
} from '@onroadvantage/onroadvantage-api';

import {EventsPaneCard} from './EventsPaneCard';
import {EventsPaneSkeleton} from './EventsPaneSkeleton';
import {type EventsPaneTab} from './EventsPaneTabs';
import {type UpdateEventState} from '../../hooks/useSearchEvents';

interface EventsPaneEventsProps {
  isLoading: boolean;
  reloadEvents: () => Promise<void>;
  activeTab: EventsPaneTab;
  events: WebEvent[];
  updateEventState: UpdateEventState;
  onSearchVehicleHistory: (vehicle: WebEventVehicle) => Promise<void>;
}
export const EventsPaneEvents = memo(function EventsPaneEvents({
  activeTab,
  events,
  updateEventState,
  isLoading,
  onSearchVehicleHistory,
  reloadEvents,
}: EventsPaneEventsProps) {
  if (isLoading) {
    return <EventsPaneSkeleton />;
  }

  if (events.length === 0 && activeTab === 'Inbox') {
    return (
      <Stack flex={1} p={1}>
        <Stack
          p={2}
          bgcolor="background.paper"
          borderRadius="16px"
          textAlign="center"
        >
          <Typography
            variant="h5"
            lineHeight={1.5}
            fontWeight={600}
            color="text.secondary"
          >
            No Open Events
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack flex={1} p={1} spacing={1} overflow="auto" minHeight={0}>
      {events.map((event) => (
        <EventsPaneCard
          key={event.id}
          event={event}
          updateEventState={updateEventState}
          onSearchVehicleHistory={onSearchVehicleHistory}
          reloadEvents={reloadEvents}
        />
      ))}
    </Stack>
  );
});
