import {VirtualTable} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

export const VantageTableCellComponent = styled(VirtualTable.Cell)(
  ({theme}) => ({
    cursor: 'default',
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`,
    '&:first-of-type': {
      paddingLeft: `${theme.spacing(1)} !important`,
    },
  }),
);
