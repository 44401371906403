import {keyframes} from '@mui/material/styles';

export const bounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-4px);
  }
`;
