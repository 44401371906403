import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyWebhookAuths = lazy(async () => import('./pages/WebhookAuths'));
const LazyWebhookAuthAdd = lazy(async () => import('./pages/WebhookAuthAdd'));
const LazyWebhookAuth = lazy(async () => import('./pages/WebhookAuth'));

export const webhookAuthRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.webhookAuth.list)},
  {
    path: routes.getRelativePath(
      routes.webhookAuth.list,
      routes.webhookAuth.base,
    ),
    element: <LazyWebhookAuths />,
    loader: () => {
      document.title = 'Webhook Auth';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Webhook Auth'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.webhookAuth.add,
      routes.webhookAuth.base,
    ),
    element: <LazyWebhookAuthAdd />,
    loader: () => {
      document.title = 'Add Webhook Auth';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Webhook Auth', path: routes.webhookAuth.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.webhookAuth.webhookAuthId,
      routes.webhookAuth.base,
    ),
    element: <LazyWebhookAuth />,
    loader: () => {
      document.title = 'View Webhook Auth';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Webhook Auth', path: routes.webhookAuth.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
