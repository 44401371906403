import {purchaseOrderApi} from '../../../api';
import {useLoad} from '../../common/hooks/useLoad';

export const useLoadPurchaseOrder = (id: number) => {
  const {item, updateState, loadStatus, reload} = useLoad(async () =>
    purchaseOrderApi.apiPurchaseOrderPurchaseOrderIdGet({
      purchaseOrderId: id,
    }),
  );
  return {item, id, loadStatus, updateState, reload};
};
