import {dashboardApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadDashboards = ({
  getFilter,
  loadRequestPagination,
  updatePagination,
}: Pick<
  UseLoadParams,
  'getFilter' | 'updatePagination' | 'loadRequestPagination'
>) => {
  return useLoadList(
    async () =>
      dashboardApi.apiDashboardGet({
        ...loadRequestPagination,
        name: getFilter('name'),
      }),
    updatePagination,
    [getFilter, loadRequestPagination],
  );
};
