import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import ReportScheduleAdd from './pages/ReportScheduleAdd';
import {routes} from '../../../routes';
import {useBreadcrumbStore} from '../../common/stores/breadcrumbsStore';

const LazyReportSchedule = lazy(async () => import('./pages/ReportSchedule'));

export const reportScheduleRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.report.schedule.add)},
  {
    path: routes.getRelativePath(
      routes.report.schedule.add,
      routes.report.schedule.base,
    ),
    element: <ReportScheduleAdd />,
    loader: ({params}) => {
      document.title = 'Add Report Schedule';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Reports', path: routes.report.list},
          {
            label: 'View',
            path:
              params.reportId != null
                ? routes.addQueryParams(
                    routes.replaceParams(routes.report.reportId, {
                      reportId: params.reportId,
                    }),
                    {tab: 'Schedules'},
                  )
                : undefined,
          },
          {label: 'Add Schedule'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.report.schedule.reportScheduleId,
      routes.report.schedule.base,
    ),
    element: <LazyReportSchedule />,
    loader: ({params}) => {
      document.title = 'View Report Schedule';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Reports', path: routes.report.list},
          {
            label: 'View',
            path:
              params.reportId != null
                ? routes.addQueryParams(
                    routes.replaceParams(routes.report.reportId, {
                      reportId: params.reportId,
                    }),
                    {tab: 'Schedules'},
                  )
                : undefined,
          },
          {label: 'Add Schedule'},
        ],
      });
      return null;
    },
  },
];
