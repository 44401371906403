import {useCallback, useEffect, useState} from 'react';

import {type ApiReasonCodeGetRequest} from '@onroadvantage/onroadvantage-api';

import {reasonCodeApi} from '../../../../api';
import {type AutocompleteOption} from '../../../common/components/form/VantageAutocompleteField';
import {catchHandler} from '../../../common/helpers/catchHandler';
import {type LoadStatus} from '../../../common/types/LoadStatus';

export const useLoadReasonCodes = (
  requestParameters: ApiReasonCodeGetRequest,
) => {
  const [loadStatus, setLoadStatus] = useState<LoadStatus>('idle');
  const [reasonCodeOptions, setReasonCodeOptions] = useState<
    AutocompleteOption[]
  >([]);
  const onLoadReasonCodes = useCallback(async () => {
    setLoadStatus('loading');
    try {
      const res = await reasonCodeApi.apiReasonCodeGet(requestParameters);
      setReasonCodeOptions(
        res.items?.map((item) => ({
          value: item.id,
          label:
            item.description != null
              ? `${item.name} - ${item.description}`
              : (item.name ?? '-'),
        })) ?? [],
      );
      setLoadStatus('success');
    } catch (e) {
      catchHandler(e);
      setLoadStatus('error');
    }
  }, [requestParameters]);

  useEffect(() => {
    void onLoadReasonCodes();
    return () => {
      setLoadStatus('idle');
      setReasonCodeOptions([]);
    };
  }, [onLoadReasonCodes]);

  return {loadStatus, reasonCodeOptions};
};
