import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyWebhooks = lazy(async () => import('./pages/Webhooks'));
const LazyWebhookAdd = lazy(async () => import('./pages/WebhookAdd'));
const LazyWebhook = lazy(async () => import('./pages/Webhook'));

export const webhookRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.webhook.list)},
  {
    path: routes.getRelativePath(routes.webhook.list, routes.webhook.base),
    element: <LazyWebhooks />,
    loader: () => {
      document.title = 'Webhooks';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Webhooks'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.webhook.add, routes.webhook.base),
    element: <LazyWebhookAdd />,
    loader: () => {
      document.title = 'Add Webhook';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Webhooks', path: routes.webhook.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.webhook.webhookId, routes.webhook.base),
    element: <LazyWebhook />,
    loader: () => {
      document.title = 'View Webhook';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Webhooks', path: routes.webhook.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
