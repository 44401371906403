import {nodeApi} from '../../../api';
import {useLoad} from '../../common/hooks/useLoad';

export const useLoadSite = (id: number) => {
  const {item, updateState, loadStatus, load} = useLoad(async () =>
    nodeApi.apiNodeNodeIdGet({
      nodeId: id,
    }),
  );

  return {
    item,
    id,
    loadStatus,
    updateState,
    reload: async () => {
      await load('reload');
    },
  };
};
