import type {MouseEvent} from 'react';
import {useNavigate} from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded';

import {TableActionButtonBase} from './TableActionButtonBase';

interface TableActionNavigateProps<TableData extends object> {
  onAuxNavigate: (row: TableData) => string | null | undefined;
  row: TableData;
}

export function TableActionAuxNavigate<TableData extends object>({
  onAuxNavigate,
  row,
}: TableActionNavigateProps<TableData>) {
  const navigate = useNavigate();

  const handleAuxClick = (event: MouseEvent<HTMLButtonElement>) => {
    const link = onAuxNavigate(row);
    if (link == null) {
      return;
    }
    event.preventDefault();
    window.open(link, '_blank', 'noreferrer');
  };

  const handleClickNavigate = (event: MouseEvent<HTMLButtonElement>) => {
    const link = onAuxNavigate(row);
    if (link == null) {
      return;
    }
    if (event.metaKey || event.ctrlKey || event.shiftKey) {
      window.open(link, '_blank', 'noreferrer');
    } else {
      navigate(link);
    }
  };

  return (
    <TableActionButtonBase
      data-cy="TableActionsNavigate"
      onAuxClick={handleAuxClick}
      onClick={handleClickNavigate}
    >
      <OpenInNewIcon fontSize="small" />
    </TableActionButtonBase>
  );
}
