import {useMemo} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type Order, type OrderLine} from '@onroadvantage/onroadvantage-api';

import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {
  type VantageTableConfig,
  type VantageTableDeleteMessage,
} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useUserStore} from '../../common/stores/userStore';
import {type EditProps} from '../../common/types/EditProps';
import {useEditOrderLines} from '../hooks/useEditOrderLines';

const config = (
  deleteMessage: VantageTableDeleteMessage<OrderLine>,
): VantageTableConfig<OrderLine> => ({
  columns: [
    {
      name: 'product',
      label: 'Product Name',
      type: 'autocomplete',
      models: 'Product',
      enableFilter: true,
      enableEdit: true,
      enableAdd: true,
      enableSort: true,
      getValue: (row) => ({value: row.product?.id, label: row.product?.name}),
      getDisplayValue: (row) => row.product?.name,
    },
    {
      name: 'quantity',
      label: 'Planned Quantity',
      type: 'number',
      enableEdit: true,
      enableAdd: true,
      enableFilter: false,
    },
    {
      name: 'actualLoadedQuantity',
      label: 'Actual Loaded Quantity',
      type: 'number',
      enableEdit: true,
      enableFilter: false,
      enableAdd: true,
    },
    {
      name: 'actualQuantity',
      label: 'Actual Offloaded Quantity',
      type: 'number',
      enableEdit: true,
      enableFilter: false,
      enableAdd: true,
    },
  ],
  fitHeight: true,
  deleteMessage,
});

export function OrderLines({item, ...editParams}: EditProps<Order, 'reload'>) {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const {
    addStatus,
    deleteStatus,
    updateStatus,
    onDelete,
    onInlineAdd,
    onInlineEdit,
  } = useEditOrderLines(editParams);

  const memoizedConfig = useMemo(
    () =>
      config((row) => (
        <Typography>
          Remove the order line <strong>{row.product?.name}</strong> from the
          order <strong>{item?.orderNumber}</strong>
        </Typography>
      )),
    [item?.orderNumber],
  );

  return (
    <Stack position="relative">
      <OverlayLoader
        loading={addStatus === 'adding'}
        label="Adding order line..."
      />
      <OverlayLoader
        loading={deleteStatus === 'deleting'}
        label="Deleting order line..."
      />
      <OverlayLoader
        loading={updateStatus === 'submitting'}
        label="Updating order line..."
      />
      <VantageTable
        config={memoizedConfig}
        data={item?.lines ?? []}
        rowId="id"
        actions={hasPermissions('orderLine:edit') ? {onInlineAdd} : undefined}
        rowActions={
          hasPermissions('orderLine:edit')
            ? {onInlineEdit, onDelete}
            : undefined
        }
      />
    </Stack>
  );
}
