import {memo} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';

import {VantageAutocompleteField} from '../../form/VantageAutocompleteField';
import {onDateTypeProviderKeyDown} from '../helpers/onDateTypeProviderKeyDown';
import {type VantageTableColumn} from '../hooks/useVantageTableConfig';

interface ValueEditorAutocompleteWithOptionsProps<TableData extends object>
  extends Pick<
    DataTypeProvider.ValueEditorProps,
    | 'value'
    | 'onValueChange'
    | 'onBlur'
    | 'onKeyDown'
    | 'onFocus'
    | 'disabled'
    | 'autoFocus'
  > {
  columnConfig: VantageTableColumn<TableData>;
  id: string;
  name: string;
  multiple?: boolean;
}

function ValueEditorAutocompleteWithOptionsComponent<TableData extends object>({
  columnConfig,
  value,
  onValueChange,
  onKeyDown,
  ...props
}: ValueEditorAutocompleteWithOptionsProps<TableData>) {
  if (
    columnConfig == null ||
    (columnConfig?.type !== 'autocompleteMulti' &&
      columnConfig?.type !== 'autocomplete') ||
    !('options' in columnConfig)
  ) {
    throw new Error(
      `ValueEditorAutocompleteWithOptions can only be used with columns of type 'autocompleteMulti' and with 'options' provided`,
    );
  }

  return (
    <VantageAutocompleteField
      {...props}
      limitTags={columnConfig.limitTags ?? 4}
      options={columnConfig.options}
      ChipProps={{size: 'small'}}
      size="small"
      sx={{my: -0.5}}
      value={value}
      onChange={(_event, newValue) => {
        onValueChange(newValue);
      }}
      onInputChange={columnConfig.onInputChange}
      onKeyDown={onDateTypeProviderKeyDown(onKeyDown)}
    />
  );
}

export const ValueEditorAutocompleteWithOptions = memo(
  ValueEditorAutocompleteWithOptionsComponent,
) as typeof ValueEditorAutocompleteWithOptionsComponent;
