import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {Loader} from './Loader';
import {getGlassBackground} from '../../../../theme/getGlassBackground';

export function PermissionsLoader() {
  return (
    <PermissionsLoaderContainer>
      <Loader size={69} label="Validating..." />
    </PermissionsLoaderContainer>
  );
}

const PermissionsLoaderContainer = styled(Stack)(({theme}) => ({
  flex: 1,
  borderRadius: theme.shape.borderRadius,
  ...getGlassBackground(theme),
}));
