import {contactApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadAudiences = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  return useLoadList(
    async () => {
      return contactApi.apiWebContactGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        name: getFilter('name'),
        description: getFilter('description'),
        email: getFilter('email'),
        cellphoneNumber: getFilter('cellphoneNumber'),
        contactGroup: getFilter('contactGroup'),
        type: getFilter('type'),
        externalId: getFilter('externalId'),
        emailBan: getFilter('emailBan'),
        smsBan: getFilter('smsBan'),
        callBan: getFilter('callBan'),
        whatsappBan: getFilter('whatsappBan'),
      });
    },
    updatePagination,
    [getFilter, loadRequestSorting, loadRequestPagination],
  );
};
