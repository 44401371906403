import {type GeneratedDetails} from './useGenerateAudio';
import {twilioApi} from '../../../api';
import {Warning} from '../../common/classes/Warning';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type BroadcastSingleMessageValues} from '../pages/BroadcastSingleMessage';

export const useSendSingleMessageBroadcast = (
  generatedDetails: GeneratedDetails | null,
) => {
  return useSubmitForm<BroadcastSingleMessageValues>(
    async (values) => {
      if (generatedDetails?.identifier == null) {
        throw new Warning('Generate message before sending.');
      }
      if (generatedDetails.message !== values.message) {
        throw new Warning(
          'Message changed. Generate new message before sending.',
        );
      }

      await twilioApi.apiVoiceTwilioDirectPost({
        body: {...values, identifier: generatedDetails.identifier},
      });
    },
    [generatedDetails],
    {resetStatus: true},
  );
};
