import {Toolbar} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

export const VantageTableToolbarRootComponent = styled(Toolbar.Root)(
  ({theme}) => ({
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(0.5, 1)} !important`,
    minHeight: 'fit-content !important',
    alignItems: 'center',
  }),
);
