import {toast} from 'react-toastify';

import {type WebMasterRouteOptimised} from '@onroadvantage/onroadvantage-api';

import {masterRouteApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteMasterRoute = ({removeItem}: DeleteParams) => {
  return useDelete<WebMasterRouteOptimised>(async ({id}) => {
    if (id == null) {
      toast('MasterRoute ID not found', {type: 'warning'});
      return;
    }
    await masterRouteApi.apiMasterRouteMasterRouteIdDelete({
      masterRouteId: id,
    });
    removeItem(id as string | number);
  });
};
