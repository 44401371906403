import {useCallback} from 'react';

import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {
  DateTimeTypeProviderEditor,
  type DateTimeTypeProviderEditorProps,
} from './DateTimeTypeProviderEditor';
import {DateTimeFormatService} from '../../../services/DateTimeFormatService';

export default function DateTimeTypeProvider<TableData extends object>({
  config,
  rowId,
  ...props
}: DataTypeProviderProps & DateTimeTypeProviderEditorProps<TableData>) {
  const renderEditorComponent = useCallback(
    (editorProps: DataTypeProvider.ValueEditorProps) => (
      <DateTimeTypeProviderEditor
        {...editorProps}
        config={config}
        rowId={rowId}
      />
    ),
    [config, rowId],
  );

  const renderFormatterComponent = useCallback(
    ({value, row, column}: DataTypeProvider.ValueFormatterProps) => {
      const columnConfig = config.columns.find((c) => c.name === column.name);
      let date: unknown;
      if (columnConfig?.getValue == null) {
        date = value;
      } else {
        date = columnConfig.getValue(row as TableData);
      }

      if (date == null || !(typeof date === 'string' || date instanceof Date)) {
        return <>-</>;
      }

      return <>{new DateTimeFormatService().formatDateTime(date)}</>;
    },
    [config],
  );

  return (
    <DataTypeProvider
      formatterComponent={renderFormatterComponent}
      editorComponent={renderEditorComponent}
      {...props}
    />
  );
}
