import Stack, {type StackProps} from '@mui/material/Stack';

export function VantageFormFieldsRow({children, ...props}: StackProps) {
  return (
    <Stack
      direction={{xs: 'column', md: 'row'}}
      spacing={1.5}
      alignSelf="stretch"
      alignItems="flex-start"
      {...props}
    >
      {children}
    </Stack>
  );
}
