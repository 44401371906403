import {useCallback, useState} from 'react';

import {type VantageTableOnInlineAdd} from '../components/table/VantageTable';
import {catchHandler} from '../helpers/catchHandler';
import type {AddStatus} from '../types/AddStatus';

export const useInlineAdd = (
  updateHandler: (values: Record<string, unknown> | null) => Promise<void>,
  deps: unknown[] = [],
) => {
  const [addStatus, setAddStatus] = useState<AddStatus>('idle');

  const onInlineAdd = useCallback<VantageTableOnInlineAdd>(
    async (values) => {
      setAddStatus('adding');
      try {
        await updateHandler(values);
        setAddStatus('success');
        return 'success';
      } catch (error) {
        const {type} = catchHandler(error, 'Failed to add');

        setAddStatus(type);

        return 'catch';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateHandler, ...deps],
  );

  return {addStatus, onInlineAdd};
};
