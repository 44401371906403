import {type WebEvent} from '@onroadvantage/onroadvantage-api';

import {webEventApi} from '../../../api';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {eventModels} from '../../common/types/EventModel';
import {type EventBulClosingEventsCommentValues} from '../components/EventBulkClosingEvents';

export const useBulkCloseEvents = (
  selectedWebEvents: WebEvent[],
  onResetStepper: () => void,
) => {
  return useSubmitForm<EventBulClosingEventsCommentValues>(
    async ({comment}) => {
      const criticalEventTypeIds = new Set<number>();
      const operationalEventTypeIds = new Set<number>();

      selectedWebEvents?.forEach(({metaData, id}) => {
        if (typeof id !== 'number') {
          return;
        }
        if (metaData?.model === eventModels.OperationalEvent) {
          operationalEventTypeIds.add(id);
        } else {
          criticalEventTypeIds.add(id);
        }
      });

      await webEventApi.apiWebEventBulkClosePost({
        body: {
          comment,
          criticalEventIds:
            criticalEventTypeIds.size > 0
              ? Array.from(criticalEventTypeIds)
              : undefined,
          operationalEventIds:
            operationalEventTypeIds.size > 0
              ? Array.from(operationalEventTypeIds)
              : undefined,
        },
      });
      onResetStepper();
    },
    [selectedWebEvents],
    {resetFormBehaviour: 'always', resetStatus: true},
  );
};
