import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyPermissions = lazy(async () => import('./pages/Permissions'));
const LazyPermissionAdd = lazy(async () => import('./pages/PermissionAdd'));
const LazyPermission = lazy(async () => import('./pages/Permission'));

export const permissionRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.permission.list)},
  {
    path: routes.getRelativePath(
      routes.permission.list,
      routes.permission.base,
    ),
    element: <LazyPermissions />,
    loader: () => {
      document.title = 'Permissions';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Permissions'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.permission.add, routes.permission.base),
    element: <LazyPermissionAdd />,
    loader: () => {
      document.title = 'Add Permission';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Permissions', path: routes.permission.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.permission.permissionId,
      routes.permission.base,
    ),
    element: <LazyPermission />,
    loader: () => {
      document.title = 'View Permission';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Permissions', path: routes.permission.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
