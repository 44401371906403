import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface UserPreferencesStoreState {
  tableHiddenColumns: Record<
    string,
    string[]
  > /** {identifier: [...hiddenColumns]} */;
  tableGroupedColumns: Record<
    string,
    string[]
  > /** {identifier: [...groupedColumns]} */;
}

interface UserPreferencesStoreActions {
  setTableHiddenColumns: (
    tableIdentifier: string,
    hiddenColumns: string[],
  ) => void;
  setTableGroupedColumns: (
    tableIdentifier: string,
    groupedColumns: string[],
  ) => void;
}

const userPreferencesStoreInitialState: UserPreferencesStoreState = {
  tableHiddenColumns: {},
  tableGroupedColumns: {},
};

export const useUserPreferencesStore = create<
  UserPreferencesStoreState & UserPreferencesStoreActions
>()(
  persist(
    (setState) => ({
      ...userPreferencesStoreInitialState,
      setTableHiddenColumns: (tableIdentifier, hiddenColumns) => {
        setState((currentState) => ({
          tableHiddenColumns: {
            ...currentState.tableHiddenColumns,
            [tableIdentifier]: hiddenColumns,
          },
        }));
      },
      setTableGroupedColumns: (tableIdentifier, groupedColumns) => {
        setState((currentState) => ({
          tableGroupedColumns: {
            ...currentState.tableGroupedColumns,
            [tableIdentifier]: groupedColumns,
          },
        }));
      },
    }),
    {
      name: 'userPreferences',
      partialize: (state) => ({
        tableHiddenColumns: state.tableHiddenColumns,
        tableGroupedColumns: state.tableGroupedColumns,
      }),
    },
  ),
);
