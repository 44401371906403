import {useState} from 'react';
import {type DateRangeFocus, DateRangePickerCalendar} from 'react-nice-dates';

import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {enGB} from 'date-fns/locale';
import {useFormikContext} from 'formik';

import 'react-nice-dates/build/style.css';

export function ReportScheduleFormScheduleDateRange() {
  const {getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value: startDateValue, error: startDateError} =
    getFieldMeta('startDate');
  const {setValue: setStartDate} = getFieldHelpers('startDate');
  const {value: endDateValue, error: endDateError} = getFieldMeta('endDate');
  const {setValue: setEndDate} = getFieldHelpers('endDate');

  const [focus, setFocus] = useState<DateRangeFocus>('startDate');
  const handleFocusChange = (newFocus: DateRangeFocus) => {
    setFocus(newFocus ?? 'startDate');
  };

  let startDate = new Date();
  let endDate = new Date();

  if (typeof startDateValue === 'string') {
    startDate = new Date(startDateValue);
  } else if (startDateValue instanceof Date) {
    startDate = startDateValue;
  }

  if (typeof endDateValue === 'string') {
    endDate = new Date(endDateValue);
  } else if (endDateValue instanceof Date) {
    endDate = endDateValue;
  }

  let error: string | undefined;
  if (startDateError != null) {
    error = `Start date: ${startDateError}`;
  }

  if (endDateError != null) {
    error =
      error == null
        ? `End date: ${endDateError}`
        : `${error} | End date: ${endDateError}`;
  }

  return (
    <Stack spacing={1} flex={1}>
      <DateRangeContainer>
        <DateRangePickerCalendar
          startDate={startDate}
          endDate={endDate}
          focus={focus}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          onFocusChange={handleFocusChange}
          locale={enGB}
        />
      </DateRangeContainer>
      {error != null && <Typography color="error">{error}</Typography>}
    </Stack>
  );
}

const DateRangeContainer = styled(Stack)(({theme}) => ({
  border: `1px solid ${theme.palette.divider} !important`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  flex: 1,
  '.nice-dates-navigation': {
    padding: theme.spacing(1, 0),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& .nice-dates-navigation_previous, .nice-dates-navigation_next': {
      paddingBottom: 0,
      '&::before': {width: '10px', height: '10px'},
    },
  },
  '.nice-dates-day_month': {
    top: 0,
  },
  '.nice-dates-day_date': {
    color: theme.palette.text.secondary,
  },
  '.nice-dates-grid': {
    height: '240px !important',
    color: theme.palette.text.primary,
  },
  '.nice-dates-day': {
    height: '40px !important',
  },
}));
