import {type ReactNode} from 'react';
import {useAuth} from 'react-oidc-context';

import {AuthStateMessage} from './AuthStateMessage';
import {config} from '../../../config';
import {useAuthStore} from '../../common/stores/authStore';

interface AuthStateHandlerProps {
  children?: ReactNode;
}

export function AuthStateHandler({children}: AuthStateHandlerProps) {
  const {isLoading, isAuthenticated, activeNavigator} = useAuth();

  const authStatus = useAuthStore((state) => state.authStatus);

  if (
    activeNavigator != null &&
    config.sso.ssoLogoutNavigators.includes(activeNavigator)
  ) {
    return <AuthStateMessage message="Logging you out" status="loading" />;
  }

  if (
    authStatus === 'authenticating' ||
    isLoading ||
    (activeNavigator != null &&
      config.sso.ssoLoginNavigators.includes(activeNavigator))
  ) {
    return <AuthStateMessage message="Logging you in" status="loading" />;
  }

  if (authStatus === 'authenticated' || isAuthenticated) {
    return (
      <AuthStateMessage message="Successfully logged in!" status="success" />
    );
  }

  return (
    children ?? (
      <AuthStateMessage message="Failed to log you in" status="error" />
    )
  );
}
