import {type ReactNode, useCallback} from 'react';

import {styled} from '@mui/material/styles';
import Tabs, {type TabsProps} from '@mui/material/Tabs';

interface VantageTabsProps<TabValue = string> extends TabsProps {
  activeTab: TabValue;
  onTabChange: (newTab: TabValue) => void;
  children: ReactNode;
}

export function VantageTabs<TabValue = string>({
  activeTab,
  onTabChange,
  children,
  ...tabsProps
}: VantageTabsProps<TabValue>) {
  const handleTabChange = useCallback(
    (_event: unknown, value: TabValue) => {
      onTabChange(value);
    },
    [onTabChange],
  );

  return (
    <StyledTabs
      value={activeTab}
      onChange={handleTabChange}
      indicatorColor="primary"
      {...tabsProps}
    >
      {children}
    </StyledTabs>
  );
}

const StyledTabs = styled(Tabs)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: 'auto',
  position: 'sticky',
  overflow: 'visible',
  top: 0,
  zIndex: 2,
}));
