import type {KeyboardEvent} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';

export const onDateTypeProviderKeyDown =
  (onKeyDown: DataTypeProvider.ValueEditorProps['onKeyDown']) =>
  (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      onKeyDown({key: event.key});
    }
  };
