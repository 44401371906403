import {useCallback, useState} from 'react';

import {catchHandler} from '../helpers/catchHandler';
import type {DeleteStatus} from '../types/DeleteStatus';

export const useDelete = <T>(deleteHandler: (item: T) => Promise<void>) => {
  const [deleteStatus, setDeleteStatus] = useState<DeleteStatus>('idle');

  const onDelete = useCallback(
    async (item: T) => {
      setDeleteStatus('deleting');
      try {
        await deleteHandler(item);
        setDeleteStatus('success');
      } catch (error) {
        const {type} = catchHandler(error, 'Failed to delete item.');

        setDeleteStatus(type);
      }
    },
    [deleteHandler],
  );

  return {onDelete, deleteStatus};
};
