export const getMetaTagContent = (
  tagName: string,
  fallbackEnv: unknown,
  defaultValue = '-',
): string => {
  if (import.meta.env.MODE === 'development') {
    if (typeof fallbackEnv === 'string') {
      return fallbackEnv;
    }
    return defaultValue;
  }

  const metas = document.getElementsByTagName('meta');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < metas.length; i++) {
    const meta = metas[i];
    if (meta.getAttribute('property') === tagName) {
      const content = meta.getAttribute('content');
      if (content != null && !content.startsWith('__APP')) {
        return content;
      }
    }
  }

  if (typeof fallbackEnv === 'string') {
    return fallbackEnv;
  }

  return defaultValue;
};
