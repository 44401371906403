import {type InferType, object, date, string} from 'yup';

import {
  requiredListing,
  multipleListing,
  number,
} from '../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageDateTimePickerField} from '../../common/components/form/VantageDateTimePickerField';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';

const planningOrderAddFormSchema = object({
  shipmentNumber: string().required(),
  upliftPointDuration: number.required(),
  upliftPointTimeWindows: string(),
  offloadPointDuration: number.required(),
  offloadPointTimeWindows: string(),
  skill: multipleListing.required('Required'),
  priority: number,
  capacityDimension1: number.required(),
  capacityDimension1Unit: string().required(),
  capacityDimension2: number,
  capacityDimension2Unit: string(),
  capacityDimension3: number,
  capacityDimension3Unit: string(),
  capacityDimension4: number,
  capacityDimension4Unit: string(),
  collectionDurationInMinutes: number.required(),
  collectionExternalReference: requiredListing.required('Required'),
  contractCode: requiredListing.required('Required'),
  deliverByDatetime: date().required(),
  deliveryDurationInMinutes: number.required(),
  deliveryExternalReference: requiredListing.required('Required'),
});

export type PlanningOrderAddFormValues = InferType<
  typeof planningOrderAddFormSchema
>;

const initialValues: PlanningOrderAddFormValues = {
  shipmentNumber: '',
  upliftPointDuration: 0,
  upliftPointTimeWindows: '',
  offloadPointDuration: 0,
  offloadPointTimeWindows: '',
  skill: [],
  priority: 0,
  capacityDimension1: 0,
  capacityDimension1Unit: '',
  collectionDurationInMinutes: 0,
  collectionExternalReference: {value: 0, label: ''},
  contractCode: {value: 0, label: ''},
  deliverByDatetime: new Date(),
  deliveryDurationInMinutes: 0,
  deliveryExternalReference: {value: 0, label: ''},
};

export interface PlanningOrderAddFormProps {
  onPlanningOrderSubmit: (values: PlanningOrderAddFormValues) => void;
}

export function PlanningOrderAddForm({
  onPlanningOrderSubmit,
}: PlanningOrderAddFormProps) {
  return (
    <VantageForm
      FormProps={{style: {minHeight: 0}}}
      permission="planningOrder:add"
      initialValues={initialValues}
      onSubmit={onPlanningOrderSubmit}
      validationSchema={planningOrderAddFormSchema}
    >
      <VantageFormContent overflow="auto">
        <VantageTextField
          name="shipmentNumber"
          label="Order Number"
          placeholder="Enter the shipment number"
          formik
        />
        <VantageFormFieldsRow>
          <VantageTextField
            name="upliftPointDuration"
            label="Loading Point Duration"
            placeholder="Enter the uplift point duration"
            formik
          />
          <VantageTextField
            name="upliftPointTimeWindows"
            label="Loading Point Time Windows"
            placeholder="Enter the uplift point time windows"
            formik
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageTextField
            name="offloadPointDuration"
            label="Offload Point Duration"
            placeholder="Enter the offload point duration"
            formik
          />
          <VantageTextField
            name="offloadPointTimeWindows"
            label="Offload Point Time Windows"
            placeholder="Enter the offload point time windows"
            formik
          />
        </VantageFormFieldsRow>
        <VantageAutocompleteFieldWithListing
          name="skill"
          label="Skill"
          placeholder="Enter skill"
          models="PlanningSkill"
          formik
          multiple
        />
        <VantageTextField
          name="priority"
          label="Priority"
          placeholder="Enter the priority"
          formik
        />
        <VantageFormFieldsRow>
          <VantageTextField
            name="capacityDimension1"
            label="Capacity Dimension 1"
            placeholder="Enter capacity dimension 1"
            formik
          />
          <VantageTextField
            name="capacityDimension1Unit"
            label="Capacity Dimension 1 Unit"
            placeholder="Enter capacity dimension 1 unit"
            formik
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageTextField
            name="capacityDimension2"
            label="Capacity Dimension 2"
            placeholder="Enter capacity dimension 2"
            formik
          />
          <VantageTextField
            name="capacityDimension2Unit"
            label="Capacity Dimension 2 Unit"
            placeholder="Enter capacity dimension 2 unit"
            formik
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageTextField
            name="capacityDimension3"
            label="Capacity Dimension 3"
            placeholder="Enter capacity dimension 3"
            formik
          />
          <VantageTextField
            name="capacityDimension3Unit"
            label="Capacity Dimension 3 Unit"
            placeholder="Enter capacity dimension 3 unit"
            formik
          />
        </VantageFormFieldsRow>

        <VantageFormFieldsRow>
          <VantageTextField
            name="capacityDimension4"
            label="Capacity Dimension 4"
            placeholder="Enter capacity dimension 4"
            formik
          />
          <VantageTextField
            name="capacityDimension4Unit"
            label="Capacity Dimension 4 Unit"
            placeholder="Enter capacity dimension 4 unit"
            formik
          />
        </VantageFormFieldsRow>

        {/* additional fields */}
        <VantageTextField
          name="collectionDurationInMinutes"
          label="Collection Duration (Minutes)"
          placeholder="Enter collection duration in minutes"
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="collectionExternalReference"
          label="Collection External Reference"
          placeholder="Enter collection external reference"
          models="Node"
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="contractCode"
          label="Contract Code"
          placeholder="Enter contract code"
          models="Contract"
          formik
        />
        <VantageDateTimePickerField
          name="deliverByDateTime"
          label="Deliver By DateTime"
          formik
        />
        <VantageTextField
          name="deliveryDurationInMinutes"
          label="Delivery Duration (Minutes)"
          placeholder="Enter delivery duration in minutes"
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="deliveryExternalReference"
          label="Delivery External Reference"
          placeholder="Enter delivery external reference"
          models="Node"
          formik
        />
      </VantageFormContent>
      <VantageFormActions zIndex={500} bgcolor="background.paper">
        <VantageFormSubmit label="Next" />
      </VantageFormActions>
    </VantageForm>
  );
}
