import {array, date, mixed, object, string} from 'yup';

import {number} from '../../common/components/form/validationSchemas';

export const reportParameterValueSchema = mixed()
  .nullable()
  .when('type', {
    is: (type: unknown) => type === 'int' || type === 'integer',
    then: () => number,
  })
  .when('type', {
    is: (type: unknown) => type === 'numeric' || type === 'decimal',
    then: () =>
      string().matches(
        /^-?([1-9]\d*|\d[.]\d*|[1-9]\d*\.\d*)$/,
        'Value must be an decimal e.g. 0.123',
      ),
  })
  .when('type', {
    is: (type: unknown) => type === 'date' || type === 'datetime',
    then: () => date(),
  })
  .when('type', {
    is: (type: unknown) => type === 'arrint' || type === 'customlist',
    then: () =>
      array(
        object({
          value: string().required('Required.'),
          label: string(),
        }),
      ),
  })
  .when('type', {
    is: (type: unknown) =>
      type === 'arrint_required' || type === 'customlist_required',
    then: () =>
      array(
        object({
          value: string().required('Required.'),
          label: string(),
        }),
      ).min(1, 'Select at least one'),
  })
  .when('required', {
    is: true,
    then: (schema) => schema.required('Required.'),
  });
