import {memo} from 'react';

import Stack from '@mui/material/Stack';

import {NavigationMenuItem} from './NavigationMenuItem';
import {useExpandedMenuItem} from '../../hooks/useExpandedMenuItem';
import {useFilteredMenuItems} from '../../hooks/useFilteredMenuItems';

interface NavigationMenuItemsProps {
  filter: string | null;
}

export const NavigationMenuItems = memo(function NavigationMenuItems({
  filter,
}: NavigationMenuItemsProps) {
  const {expandedGroups, setExpandedGroups} = useExpandedMenuItem();
  const filteredMenuItems = useFilteredMenuItems(filter);

  return (
    <Stack height="100%" flex={1} sx={{overflowX: 'hidden', overflowY: 'auto'}}>
      {filteredMenuItems.map((item) => (
        <NavigationMenuItem
          key={item.label}
          item={item}
          expandedGroups={expandedGroups}
          setExpandedGroups={setExpandedGroups}
          parentItem={null}
          filter={filter}
        />
      ))}
    </Stack>
  );
});
