import {useCallback} from 'react';

import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {
  StringTypeProviderEditor,
  type StringTypeProviderEditorProps,
} from './StringTypeProviderEditor';

export default function StringTypeProvider<TableData extends object>({
  config,
  loading,
  rowId,
  ...props
}: DataTypeProviderProps & StringTypeProviderEditorProps<TableData>) {
  const renderEditor = useCallback(
    (editorProps: DataTypeProvider.ValueEditorProps) => (
      <StringTypeProviderEditor
        loading={loading}
        config={config}
        rowId={rowId}
        {...editorProps}
      />
    ),
    [config, loading, rowId],
  );

  const renderFormatter = useCallback(
    ({column, value, row}: DataTypeProvider.ValueFormatterProps) => {
      const columnConfig = config.columns.find((c) => c.name === column.name);
      if (columnConfig?.getValue == null) {
        return value;
      }
      return columnConfig.getValue(row as TableData) as
        | string
        | null
        | undefined;
    },
    [config.columns],
  );

  return (
    <DataTypeProvider
      formatterComponent={renderFormatter}
      editorComponent={renderEditor}
      {...props}
    />
  );
}
