import {type MutableRefObject, useCallback, useRef, useState} from 'react';
import {toast} from 'react-toastify';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {type Broadcast} from '@onroadvantage/onroadvantage-api';

import {useGenerateAudio} from '../hooks/useGenerateAudio';

export function BroadcastsPlay({
  row,
  audioRef,
}: {
  row: Broadcast;
  audioRef: MutableRefObject<HTMLAudioElement | undefined>;
}) {
  const {generateAudioFile, generatedUrlStatus} = useGenerateAudio(
    null,
    row.message,
  );
  const localAudioRef = useRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = useCallback(async () => {
    if (audioRef.current == null) {
      // eslint-disable-next-line no-param-reassign
      audioRef.current = new Audio();
      // void preloadAudio.play();
      const res = await generateAudioFile();
      if (res?.url != null) {
        const newAudioRef = new Audio(res.url as string);
        // eslint-disable-next-line no-param-reassign
        audioRef.current = newAudioRef;
        await newAudioRef.play();
        setIsPlaying(true);
        newAudioRef.onpause = () => {
          setIsPlaying(false);
          // eslint-disable-next-line no-param-reassign
          audioRef.current = undefined;
        };
        localAudioRef.current = newAudioRef;
      }
    } else if (!toast.isActive('audioPlaying')) {
      toast.info('Audio is already playing', {toastId: 'audioPlaying'});
    }
  }, [audioRef, generateAudioFile]);

  let startIcon = <PlayArrowIcon />;
  let message = 'Play message';
  if (isPlaying) {
    startIcon = <PauseIcon />;
    message = 'Playing message...';
  }
  if (generatedUrlStatus === 'generating') {
    startIcon = <CircularProgress size={16} />;
    message = 'Preparing message...';
  }

  return (
    <Button
      disabled={generatedUrlStatus === 'generating'}
      startIcon={startIcon}
      onClick={handleClick}
      color="inherit"
    >
      {message}
    </Button>
  );
}
