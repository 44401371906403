import {type WebNodeListDump} from '@onroadvantage/onroadvantage-api';

import {nodeApi} from '../../../api';
import {Warning} from '../../common/classes/Warning';
import {useDelete} from '../../common/hooks/useDelete';
import type {DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteSite = ({removeItem}: DeleteParams) => {
  return useDelete<WebNodeListDump>(async ({id}) => {
    if (id == null) {
      throw new Warning('Id not found');
    }
    await nodeApi.apiNodeNodeIdDelete({
      nodeId: id,
    });
    removeItem(id);
  });
};
