import {Fragment, memo} from 'react';

import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';

import {VantageTooltip} from '../VantageTooltip';

interface LabelProps {
  label: string;
  filter: string | null;
}

export const NavigationMenuItemLabel = memo(function Label({
  label,
  filter,
}: LabelProps) {
  if (filter == null) {
    if (label.length > 23) {
      return (
        <VantageTooltip title={label} placement="right">
          <Typography
            fontWeight="bold"
            color="action"
            flex={1}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {label.substring(0, 22)}...
          </Typography>
        </VantageTooltip>
      );
    }
    return (
      <Typography
        fontWeight="bold"
        color="action"
        flex={1}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {label === "What's New?" || label === 'Documentation' ? (
          <Badge color="primary" variant="dot">
            {label}
          </Badge>
        ) : (
          label
        )}
      </Typography>
    );
  }

  return (
    <Typography fontWeight="bold" color="action" flex={1} whiteSpace="nowrap">
      {label?.split(new RegExp(`(${filter})`, 'gi')).map((part, index) => {
        const id = `${label}|${part}|${index}`;
        if (part.toLowerCase() === filter.toLowerCase()) {
          return (
            <span
              key={id}
              style={{textDecoration: 'underline', fontWeight: 500}}
            >
              {part}
            </span>
          );
        }
        return <Fragment key={id}>{part}</Fragment>;
      })}
    </Typography>
  );
});
