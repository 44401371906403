import {type ElementType, useEffect} from 'react';

import type {ChipTypeMap} from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import {
  VantageAutocompleteField,
  type VantageAutocompleteFieldProps,
} from './VantageAutocompleteField';
import {splitByCapitals} from '../../helpers/splitByCapitals';
import {
  useLoadListing,
  type UseLoadListingQueryOptions,
} from '../../hooks/useLoadListing';

export type VantageAutocompleteFieldWithListingProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  VantageAutocompleteFieldProps<
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >,
  'options' | 'onInputChange' | 'inputValue' | 'loading' | 'loadingText'
> & {
  models: Set<string> | string;
  queryOptions?: UseLoadListingQueryOptions;
};

export function VantageAutocompleteFieldWithListing<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  models,
  queryOptions,
  disabled,
  ...props
}: VantageAutocompleteFieldWithListingProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  const {listing, loadListing, loadListingStatus, onDebounceSearch} =
    useLoadListing(models, queryOptions);

  useEffect(() => {
    if (disabled !== true) {
      void loadListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <VantageAutocompleteField
      options={listing}
      onInputChange={onDebounceSearch}
      disabled={disabled}
      limitTags={10}
      {...props}
      loading={loadListingStatus === 'loading'}
      loadingText={
        <Typography color="text.secondary">
          Loading{' '}
          <strong>
            {models instanceof Set
              ? Array.from(models)
                  .map((model) => splitByCapitals(model))
                  .join(', ')
              : splitByCapitals(models)}
          </strong>{' '}
          listing...
        </Typography>
      }
    />
  );
}
