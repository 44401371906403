import {useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {ValueEditorBooleanFilter} from '../components/ValueEditorBooleanFilter';
import {ValueEditorCheckbox} from '../components/ValueEditorCheckbox';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';

interface BooleanTypeProviderEditorProps<TableData extends object> {
  config: VantageTableConfig<TableData>;
  rowId: keyof TableData;
}

export function BooleanTypeProviderEditor<TableData extends object>({
  rowId,
  config,
  ...props
}: DataTypeProvider.ValueEditorProps &
  BooleanTypeProviderEditorProps<TableData>) {
  const {row, column} = props;
  const columnConfig = useMemo(
    () => config.columns.find((c) => c.name === column.name),
    [column.name, config.columns],
  );
  if (columnConfig == null) {
    throw new Error('Column config not found');
  }

  if (row == null) {
    return <ValueEditorBooleanFilter columnConfig={columnConfig} {...props} />;
  }

  return (
    <ValueEditorCheckbox columnConfig={columnConfig} rowId={rowId} {...props} />
  );
}
