import {type Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const TableActionSelectStyles = (theme: Theme) =>
  createStyles({
    disabledIcon: {
      borderRadius: 3,
      margin: 2.5,
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      boxShadow: 'none',
      background: 'rgba(194,194,194,0.45)',
    },
  });

export const useTableActionSelectStyles = makeStyles(TableActionSelectStyles, {
  name: 'TableActionSelectStyles',
});
