import {lazy, Suspense, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import {keyframes, styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import onroadVantageLogo from '../../../assets/onroadVantageLight.json';
import {getGlassBackground} from '../../../theme/getGlassBackground';
import {LottieAnimation} from '../../common/components/loader/LottieAnimation';

const LazyAppShellAnimatedBackground = lazy(
  async () =>
    import('../../common/components/appShell/AppShellAnimatedBackground'),
);

export default function NewVantageScreen() {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setTimeout(() => {
      setSearchParams((prev) => {
        prev.delete('reason');
        return prev;
      });
    }, 7000);
  }, [setSearchParams]);

  return (
    <Stack flex={1}>
      <Suspense fallback={null}>
        <LazyAppShellAnimatedBackground />
      </Suspense>
      <StyledShell>
        <StyleTypography variant="h1">Welcome to the new</StyleTypography>
        <StyleLottieAnimation
          name="Onroad Vantage Loader"
          animationData={onroadVantageLogo}
          height={150}
        />
      </StyledShell>
    </Stack>
  );
}

const StyledShell = styled(Stack)(({theme}) => ({
  ...getGlassBackground(theme),
  position: 'relative',
  zIndex: 1,
  flex: 1,
  padding: theme.spacing(2),
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

const typographyAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px) scale(0.6);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const StyleTypography = styled(Typography)(() => ({
  animation: `${typographyAnimation} 1s ease-in-out`,
  fontWeight: 600,
  color: 'rgb(48,58,56)',
}));

const StyleLottieAnimation = styled(LottieAnimation)(() => ({
  animation: `${typographyAnimation} 1.8s ease-in-out`,
  fontWeight: 600,
}));
