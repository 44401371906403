import {webMasterTripDebriefApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {useDateRangeStore} from '../../common/stores/dateRangeStore';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadTripDebriefs = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  const startDate = useDateRangeStore((state) => state.startDate);
  const endDate = useDateRangeStore((state) => state.endDate);
  const isActive = useDateRangeStore((state) => state.isActive);

  return useLoadList(
    async () =>
      webMasterTripDebriefApi.apiWebMasterTripDebriefGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        ...(isActive
          ? {
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            }
          : {}),
        status: getFilter('status'),
        masterTripDebriefId: getFilter('masterTripDebriefId'),
        upliftNodeName: getFilter('upliftNodeName'),
        driverName: getFilter('driverName'),
        tripNumber: getFilter('tripNumber'),
        contractId: getFilter('contractId'),
        vehicleRegistrationNumber: getFilter('vehicleRegistrationNumber'),
        contractCode: getFilter('contractCode'),
        masterTripId: getFilter('masterTripId'),
        debriefed: getFilter('debriefed'),
        offloadNodeName: getFilter('offloadNodeName'),
        vehicleFleetNumber: getFilter('vehicleFleetNumber'),
        masterTripDebriefApprovedForBilling: getFilter(
          'masterTripDebriefApprovedForBilling',
        ),
        orderNumber: getFilter('orderNumber'),
      }),
    updatePagination,
    [
      getFilter,
      loadRequestPagination,
      loadRequestSorting,
      startDate,
      endDate,
      isActive,
    ],
  );
};
