import {type ChangeEvent, useState} from 'react';

import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {OrderAddForm, type OrderAddFormProps} from './OrderAddForm';
import {
  PlanningOrderAddForm,
  type PlanningOrderAddFormProps,
} from './PlanningOrderAddForm';

export function OrdersStep({
  onOrderSubmit,
  onPlanningOrderSubmit,
}: PlanningOrderAddFormProps & OrderAddFormProps) {
  const [createPlanningOrder, setCreatePlanningOrder] = useState(true);

  const handlePlanningOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCreatePlanningOrder(event.target.value === 'create');
  };

  return (
    <>
      <RadioGroup
        aria-label="createPlanningOrder"
        name="createPlanningOrder"
        value={createPlanningOrder ? 'create' : 'noCreate'}
        onChange={handlePlanningOrderChange}
      >
        <FormControlLabel
          value="create"
          control={<Radio />}
          sx={{marginLeft: '20px'}}
          label="Create Planning Order"
        />
        <FormControlLabel
          value="noCreate"
          control={<Radio />}
          sx={{marginLeft: '20px'}}
          label="Create Order"
        />
      </RadioGroup>
      <Collapse in={createPlanningOrder}>
        <PlanningOrderAddForm onPlanningOrderSubmit={onPlanningOrderSubmit} />
      </Collapse>
      <Collapse in={!createPlanningOrder}>
        <OrderAddForm onOrderSubmit={onOrderSubmit} />
      </Collapse>
    </>
  );
}
