import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyContractGroups = lazy(async () => import('./pages/ContractGroups'));
const LazyContractGroupAdd = lazy(
  async () => import('./pages/ContractGroupAdd'),
);
const LazyContractGroup = lazy(async () => import('./pages/ContractGroup'));

export const contractGroupRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.contractGroup.list)},
  {
    path: routes.getRelativePath(
      routes.contractGroup.list,
      routes.contractGroup.base,
    ),
    element: <LazyContractGroups />,
    loader: () => {
      document.title = 'Contract Groups';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Contract Groups'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.contractGroup.add,
      routes.contractGroup.base,
    ),
    element: <LazyContractGroupAdd />,
    loader: () => {
      document.title = 'Add Contract Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Contract Groups', path: routes.contractGroup.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.contractGroup.contractGroupId,
      routes.contractGroup.base,
    ),
    element: <LazyContractGroup />,
    loader: () => {
      document.title = 'View Contract Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Contract Groups', path: routes.contractGroup.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
