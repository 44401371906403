import {reportApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadReports = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  return useLoadList(
    async () =>
      reportApi.apiReportGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        name: getFilter('name'),
        description: getFilter('description'),
        dbFunctionName: getFilter('dbFunctionName'),
        reportCategories: getFilter('reportCategories'),
        version: getFilter('version'),
      }),
    updatePagination,
    [getFilter, loadRequestPagination, loadRequestSorting],
  );
};
