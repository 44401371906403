import {lazy} from 'react';
import {type RouteObject} from 'react-router-dom';

import {routes} from '../../../routes';
import {useBreadcrumbStore} from '../../common/stores/breadcrumbsStore';

const LazyVehicleTypeCapacityAdd = lazy(
  async () => import('./pages/VehicleTypeCapacityAdd'),
);
const LazyVehicleTypeCapacity = lazy(
  async () => import('./pages/VehicleTypeCapacity'),
);

export const vehicleTypeCapacityRoutes: RouteObject[] = [
  {
    path: routes.getRelativePath(
      routes.vehicleType.capacity.add,
      routes.vehicleType.capacity.base,
    ),
    loader: (args) => {
      document.title = 'Add Vehicle Type Capacity';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Vehicle Types', path: routes.vehicleType.list},
          {
            label: 'View',
            path:
              args.params.vehicleTypeId != null
                ? routes.replaceParams(routes.vehicleType.vehicleTypeId, {
                    vehicleTypeId: args.params.vehicleTypeId,
                  })
                : undefined,
          },
          {label: 'Add Capacity'},
        ],
      });
      return null;
    },
    element: <LazyVehicleTypeCapacityAdd />,
  },
  {
    path: routes.getRelativePath(
      routes.vehicleType.capacity.capacityId,
      routes.vehicleType.capacity.base,
    ),
    loader: (args) => {
      document.title = 'View Vehicle Type Capacity';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Vehicle Types', path: routes.vehicleType.list},
          {
            label: 'View',
            path:
              args.params.vehicleTypeId != null
                ? routes.replaceParams(routes.vehicleType.vehicleTypeId, {
                    vehicleTypeId: args.params.vehicleTypeId,
                  })
                : undefined,
          },
          {label: 'View Capacity'},
        ],
      });
      return null;
    },
    element: <LazyVehicleTypeCapacity />,
  },
];
