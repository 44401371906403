import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';

import {useDateRangeStore} from '../../../stores/dateRangeStore';
import {VantageBooleanField} from '../../form/VantageBooleanField';

export function TableToolbarDaterangeFilterButton() {
  const isActive = useDateRangeStore((state) => state.isActive);
  const setIsActive = useDateRangeStore((state) => state.setIsActive);
  return (
    <Plugin
      dependencies={[{name: 'Toolbar'}]}
      name="ToolbarDaterangeFilterButton"
    >
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <VantageBooleanField
          name="dateRangeFilter"
          checked={isActive}
          label="Date Range"
          onChange={(_event, checked) => {
            setIsActive(checked);
          }}
        />
      </Template>
    </Plugin>
  );
}
