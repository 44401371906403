import {create} from 'zustand';

interface NavigationStoreState {
  navigationDrawerOpen: boolean;
}

interface NavigationStoreActions {
  toggleNavigationsDrawer: (newState?: boolean) => void;
}

const dateRangeStoreInitialState: NavigationStoreState = {
  navigationDrawerOpen: true,
};

export const useNavigationStore = create<
  NavigationStoreState & NavigationStoreActions
>()((setState) => ({
  ...dateRangeStoreInitialState,
  toggleNavigationsDrawer: (newState) => {
    if (newState !== undefined) {
      setState({navigationDrawerOpen: newState});
    } else {
      setState((state) => ({
        navigationDrawerOpen: !state.navigationDrawerOpen,
      }));
    }
  },
}));
