import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageBooleanField} from '../../common/components/form/VantageBooleanField';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../common/components/form/VantageFormFieldsRow';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {VantageTimePickerField} from '../../common/components/form/VantageTimePickerField';

export function SiteDetailsFields() {
  return (
    <VantageFormContent>
      <VantageFormFieldsRow>
        <VantageTextField
          name="name"
          placeholder="Enter the site name"
          label="Name"
          formik
        />
        <VantageTextField
          name="externalReference"
          placeholder="Enter the external reference"
          label="External Reference"
          formik
        />
      </VantageFormFieldsRow>
      <VantageFormFieldsRow>
        <VantageTextField
          name="latitude"
          placeholder="Enter the site latitude coordinate"
          label="Latitude"
          formik
        />
        <VantageTextField
          name="longitude"
          placeholder="Enter the site longitude coordinate"
          label="Longitude"
          formik
        />
      </VantageFormFieldsRow>
      <VantageTextField
        name="address"
        placeholder="e.g. 1 Newtown Drive, Newlands, Cape Town"
        label="Physical Address"
        InputLabelProps={{shrink: true}}
        formik
      />
      <VantageAutocompleteFieldWithListing
        name="nodeType"
        models="NodeType"
        label="Site Type"
        placeholder="Enter and select the site type"
        formik
      />
      <VantageFormFieldsRow>
        <VantageTimePickerField name="openTime" label="Open Time" formik />
        <VantageTimePickerField name="closeTime" label="Close Time" formik />
      </VantageFormFieldsRow>
      <VantageFormFieldsRow>
        <VantageTextField
          name="loadingTimeInMinutes"
          placeholder="e.g. 30"
          label="Loading Time"
          formik
        />
        <VantageTextField
          name="unloadingTimeInMinutes"
          placeholder="e.g. 30"
          label="Unloading Time"
          formik
        />
        <VantageTextField
          name="processingTimeInMinutes"
          placeholder="e.g. 15"
          label="Processing Time"
          formik
        />
      </VantageFormFieldsRow>
      <VantageAutocompleteFieldWithListing
        name="planningSkills"
        placeholder="Enter and select the site skills"
        label="Planning Skills"
        models="PlanningSkill"
        multiple
        formik
      />
      <VantageBooleanField
        name="prohibitAfterHoursProcessing"
        label="Prohibit After Hours Processing"
      />
    </VantageFormContent>
  );
}
