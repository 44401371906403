import {
  type ReactNode,
  type RefObject,
  useCallback,
  useLayoutEffect,
} from 'react';

import {type StackProps} from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {
  Form,
  Formik,
  type FormikFormProps,
  type FormikHelpers,
  type FormikProps,
  useFormikContext,
} from 'formik';
import {type Schema} from 'yup';

import {type Permission} from '../../../../permissions';
import {useUserStore} from '../../stores/userStore';

interface VantageFormProps<Values extends object>
  extends Partial<FormikProps<Values>> {
  initialValues: Values;
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => void | Promise<void>;
  validationSchema?: Schema<Partial<Values>>;
  children: ReactNode;
  permission?: Permission;
  disabled?: boolean;
  FormProps?: FormikFormProps;
  StackProps?: StackProps;
  formInnerRef?: RefObject<FormikProps<Values>>;
}
export type OnFormSubmit<Values extends object> = (
  values: Values,
  formikHelpers: FormikHelpers<Values>,
) => void | Promise<void>;

export function VantageForm<Values extends object>({
  initialValues,
  onSubmit,
  validationSchema,
  children,
  FormProps,
  permission,
  disabled,
  StackProps,
  formInnerRef,
  ...props
}: VantageFormProps<Values>) {
  const handleSubmit = useCallback<OnFormSubmit<Values>>(
    async (value, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      try {
        await onSubmit(value, formikHelpers);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [onSubmit],
  );

  return (
    <Formik<Values>
      {...props}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formInnerRef}
      enableReinitialize
    >
      <StyledForm {...FormProps}>
        <FormPermissionHandler permission={permission} />
        <FormDisableHandler disabled={disabled} />
        {children}
      </StyledForm>
    </Formik>
  );
}

const StyledForm = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

interface FormPermissionHandlerProps {
  permission?: Permission;
}

function FormPermissionHandler({permission}: FormPermissionHandlerProps) {
  const {setStatus} = useFormikContext();
  const hasPermissions = useUserStore((state) => state.hasPermissions);

  useLayoutEffect(() => {
    if (permission != null && !hasPermissions(permission)) {
      setStatus('unauthorized');
    } else {
      setStatus('authorized');
    }
  }, [permission, hasPermissions, setStatus]);

  return null;
}
interface FormDisableHandlerProps {
  disabled?: boolean;
}

function FormDisableHandler({disabled = false}: FormDisableHandlerProps) {
  const {setStatus} = useFormikContext();

  useLayoutEffect(() => {
    setStatus(disabled ? 'disabled' : 'enabled');
  }, [disabled, setStatus]);

  return null;
}
