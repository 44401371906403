import {toast} from 'react-toastify';

import {type Order} from '@onroadvantage/onroadvantage-api';

import {orderApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteOrder = ({removeItem}: DeleteParams) => {
  return useDelete<Order>(async ({id}) => {
    if (id == null) {
      toast('Order ID not found', {type: 'warning'});
      return;
    }
    await orderApi.apiOrderOrderIdDelete({
      orderId: id,
    });
    removeItem(id);
  });
};
