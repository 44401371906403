import {toast} from 'react-toastify';

import {captureException} from '@sentry/react';

import {isObjectWithKeys} from './unknownValueTypeChecks';
import {Warning} from '../classes/Warning';

export const catchHandler = (
  error: unknown,
  fallbackMessage: string = 'An error occurred',
) => {
  let message: string = fallbackMessage;
  let type: 'warning' | 'error' = 'error';

  if (error instanceof Warning) {
    message = error.message;
    type = 'warning';
  } else if (error instanceof Error && error.message != null) {
    captureException(error);
    try {
      const errorJson = JSON.parse(error.message);

      if (
        isObjectWithKeys(errorJson, 'description') &&
        typeof errorJson.description === 'string'
      ) {
        message = errorJson.description;
      } else if (
        isObjectWithKeys(errorJson, 'message') &&
        typeof errorJson.message === 'string' &&
        !errorJson.message.includes('Unexpected token')
      ) {
        message = errorJson.message;
      } else if (
        isObjectWithKeys(errorJson, 'message') &&
        isObjectWithKeys(errorJson.message, 'description') &&
        typeof errorJson.message.description === 'string'
      ) {
        message = errorJson.message.description;
      } else if (!error.message.includes('Unexpected token')) {
        message = error.message;
      }
    } catch {
      if (!error.message.includes('Unexpected token')) {
        message = error.message;
      }
    }
    type = 'error';
  }

  if (message.includes('The request was well-formed')) {
    message = fallbackMessage;
  }
  const toastId = `${message}|${type}`;

  if (!toast.isActive(toastId)) {
    toast(message, {type, toastId});
  }

  return {message, type};
};
