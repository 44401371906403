import {checkIsNewRow} from './checkIsNewRow';
import {type VantageTableColumn} from '../hooks/useVantageTableConfig';

export const checkIsFieldDisabled = <TableData extends object>(
  row: unknown,
  rowId: keyof TableData,
  columnConfig: VantageTableColumn<TableData>,
) => {
  const isNewRow = checkIsNewRow(row, rowId);
  if (isNewRow) {
    if (typeof columnConfig.enableAdd === 'function') {
      return !columnConfig.enableAdd(row as TableData);
    }
    return columnConfig.enableAdd !== true;
  }
  if (typeof columnConfig.enableEdit === 'function') {
    return !columnConfig.enableEdit(row as TableData);
  }
  return columnConfig.enableEdit !== true;
};
