import {useCallback, useMemo, useState} from 'react';

import {type VantageTablePagination} from '../components/table/VantageTable';

interface UpdatePaginationParams {
  pages?: number;
  total?: number;
}

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemTotal, setItemTotal] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageTotal, setPageTotal] = useState(0);

  return {
    setCurrentPage,
    tablePagination: useMemo(
      () =>
        ({
          currentPage,
          itemTotal,
          pageTotal,
          pageSize,
          onPageSizeCountsChange: (newPageSize: number) => {
            setCurrentPage(1);
            setPageSize(newPageSize);
          },
          onCurrentPageChange: (newCurrentPage: number) => {
            if (newCurrentPage < 0) {
              setCurrentPage(0);
            } else if (newCurrentPage > pageTotal) {
              setCurrentPage(pageTotal);
            } else {
              setCurrentPage(newCurrentPage + 1);
            }
          },
        }) satisfies VantageTablePagination,
      [currentPage, itemTotal, pageSize, pageTotal],
    ),
    loadRequestPagination: useMemo(
      () => ({page: currentPage, perPage: pageSize}),
      [currentPage, pageSize],
    ),
    updatePagination: useCallback((params: UpdatePaginationParams) => {
      setPageTotal(params.pages ?? 0);
      setItemTotal(params.total ?? 0);
    }, []),
  };
};

export type TablePagination = ReturnType<
  typeof usePagination
>['tablePagination'];

export type LoadRequestPagination = ReturnType<
  typeof usePagination
>['loadRequestPagination'];

export type UpdatePagination = ReturnType<
  typeof usePagination
>['updatePagination'];
