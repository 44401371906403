import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {type WebEvent} from '@onroadvantage/onroadvantage-api';
import {type InferType, object, string} from 'yup';

import {
  type OnFormSubmit,
  VantageForm,
} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTable} from '../../common/components/table/VantageTable';
import {splitByCapitals} from '../../common/helpers/splitByCapitals';

const tableConfig: VantageTableConfig<WebEvent> = {
  columns: [
    {
      name: 'metaData',
      label: 'Model',
      type: 'string',
      getValue: ({metaData}) => splitByCapitals(metaData?.model ?? ''),
    },
    {
      name: 'eventType',
      label: 'Event Type',
      type: 'string',
      getValue: (row) => row.eventType?.name,
    },
    {name: 'eventDate', label: 'Event Date', type: 'datetime'},
    {
      name: 'vehicle',
      label: 'Vehicle',
      type: 'string',
      getValue: (row) => row.vehicle?.registrationNumber, // ask travis reg or fleet
    },
    {
      name: 'Driver',
      label: 'Driver',
      type: 'string',
      getValue: (row) => row.driver?.firstname,
    },
    {
      name: 'contract',
      label: 'Contract',
      type: 'string',
      getValue: (row) => row.vehicle?.contractCode,
    },
    {
      name: 'tripNumber',
      label: 'Trip Number',
      type: 'string',
      getValue: (row) => row.masterTrip?.tripNumber,
    },
  ],
};

const eventBulClosingEventsCommentValidationSchema = object({
  comment: string().required('Required'),
});
export type EventBulClosingEventsCommentValues = InferType<
  typeof eventBulClosingEventsCommentValidationSchema
>;

interface EventBulkClosingEventsProps {
  webEvents: WebEvent[];
  selectedWebEvents: WebEvent[];
  onSelectedWebEventsChange: (change: WebEvent[]) => void;
  onCloseEvents: OnFormSubmit<EventBulClosingEventsCommentValues>;
  onPreviousStep: () => void;
}

export function EventBulkClosingEvents({
  webEvents,
  selectedWebEvents,
  onSelectedWebEventsChange,
  onPreviousStep,
  onCloseEvents,
}: EventBulkClosingEventsProps) {
  return (
    <Stack flex={1}>
      <VantageTable
        config={tableConfig}
        data={webEvents}
        rowId="id"
        rowActions={{
          selection: {
            selection: selectedWebEvents,
            onSelectionChange: onSelectedWebEventsChange,
          },
        }}
      />
      <Divider />

      <VantageForm
        FormProps={{
          style: {flex: 1, justifyContent: 'flex-end'},
        }}
        initialValues={{comment: ''}}
        onSubmit={onCloseEvents}
      >
        <VantageFormContent>
          <VantageTextField
            name="comment"
            label="Comment"
            placeholder="Enter a comment to close the events"
            formik
          />
        </VantageFormContent>
        <VantageFormActions>
          <Button variant="contained" color="inherit" onClick={onPreviousStep}>
            Back
          </Button>
          <VantageFormSubmit
            variant="contained"
            color="primary"
            disabled={selectedWebEvents.length === 0}
            label={
              selectedWebEvents.length === 0
                ? 'No events selected'
                : `Close ${selectedWebEvents.length} events`
            }
          />
        </VantageFormActions>
      </VantageForm>
    </Stack>
  );
}
