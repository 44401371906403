import {useCallback} from 'react';

import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {
  AutocompleteProviderEditor,
  type StringTypeProviderEditorProps,
} from './AutocompleteProviderEditor';

export default function AutocompleteTypeProvider<TableData extends object>({
  config,
  loading,
  rowId,
  ...props
}: DataTypeProviderProps & StringTypeProviderEditorProps<TableData>) {
  const renderEditor = useCallback(
    (editorProps: DataTypeProvider.ValueEditorProps) => {
      return (
        <AutocompleteProviderEditor
          loading={loading}
          config={config}
          rowId={rowId}
          {...editorProps}
        />
      );
    },
    [config, loading, rowId],
  );

  const renderFormatter = useCallback(
    ({column, value, row}: DataTypeProvider.ValueFormatterProps) => {
      const columnConfig = config.columns.find((c) => c.name === column.name);
      if (columnConfig?.getDisplayValue == null) {
        return value;
      }

      return columnConfig.getDisplayValue(row as TableData);
    },
    [config.columns],
  );

  return (
    <DataTypeProvider
      formatterComponent={renderFormatter}
      editorComponent={renderEditor}
      {...props}
    />
  );
}
