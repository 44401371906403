import {type ChangeEvent, useState} from 'react';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {object, string, type InferType} from 'yup';

import {listing} from '../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {
  useAddPurchaseOrder,
  type UseAddPurchaseOrderParams,
} from '../hooks/useAddPurchaseOrder';

const purchaseOrderAddFormSchema = object({
  purchaseOrder: string().nullable(),
  existingPurchaseOrders: listing.nullable(),
});

export type PurchaseOrderAddFormValues = InferType<
  typeof purchaseOrderAddFormSchema
>;

const initialValues: PurchaseOrderAddFormValues = {
  purchaseOrder: null,
  existingPurchaseOrders: null,
};

interface PurchaseOrderAddFormProps extends UseAddPurchaseOrderParams {
  onPreviousStep: () => void;
}

export function PurchaseOrderAddForm({
  onPreviousStep,
  ...addParams
}: PurchaseOrderAddFormProps) {
  const {onSubmit, submitStatus} = useAddPurchaseOrder(addParams);
  const [linkPurchaseOrder, setLinkPurchaseOrder] = useState(true);

  const handlePurchaseOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLinkPurchaseOrder(event.target.value === 'specify');
  };

  let submitLabel = 'Submit';

  if (submitStatus === 'success') {
    submitLabel = 'Submitted Orders';
  }
  if (submitStatus === 'error' || submitStatus === 'warning') {
    submitLabel = "Didn't Submit Orders";
  }

  return (
    <VantageForm
      permission="planningOrder:add"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={purchaseOrderAddFormSchema}
    >
      <VantageFormContent>
        <RadioGroup
          aria-label="SpecifyPurchaseOrder"
          name="specifyPurchaseOrder"
          value={linkPurchaseOrder ? 'specify' : 'link'}
          onChange={handlePurchaseOrderChange}
        >
          <FormControlLabel
            value="specify"
            control={<Radio />}
            label="Specify Purchase Order"
          />
          <FormControlLabel
            value="link"
            control={<Radio />}
            label="Link Existing Purchase Order"
          />
        </RadioGroup>

        {linkPurchaseOrder && (
          <VantageTextField
            name="purchaseOrder"
            label="Purchase Order"
            placeholder="Enter the purchase Order"
            formik
          />
        )}
        {!linkPurchaseOrder && (
          <VantageAutocompleteFieldWithListing
            name="existingPurchaseOrders"
            label="Existing Purchase Order"
            models="PurchaseOrder"
            formik
          />
        )}
      </VantageFormContent>
      <VantageFormActions>
        <Button variant="contained" color="inherit" onClick={onPreviousStep}>
          Back
        </Button>
        <VantageFormSubmit label={submitLabel} loadingLabel="Submitting..." />
      </VantageFormActions>
    </VantageForm>
  );
}
