import {isObjectWithKeys} from '../../../helpers/unknownValueTypeChecks';

export const checkIsNewRow = (
  row: unknown,
  rowId: number | symbol | string,
) => {
  return !(
    isObjectWithKeys(row, rowId) &&
    (typeof row[rowId] === 'string' ||
      (typeof row[rowId] === 'number' && row[rowId] > 0))
  );
};
