import {useMemo} from 'react';

import {
  type AutocompleteOption,
  VantageAutocompleteField,
  type VantageAutocompleteFieldFormikProps,
} from '../../../common/components/form/VantageAutocompleteField';

interface ReportParameterAutocompleteProps {
  listValues: string[];
}
export function ReportParameterAutocomplete({
  listValues,
  label,
  ...props
}: ReportParameterAutocompleteProps &
  VantageAutocompleteFieldFormikProps<true, undefined, undefined>) {
  const options = useMemo<AutocompleteOption[]>(
    () => [
      {
        value: 0,
        label:
          typeof label === 'string'
            ? `All ${label === 'node' ? 'Site' : label}s`
            : 'All items',
      },
      ...(listValues?.map((item) => ({
        label: item,
        value: item,
      })) ?? []),
    ],
    [listValues, label],
  );
  return (
    // @ts-expect-error TODO Fix typing
    <VantageAutocompleteField
      {...props}
      label={label}
      options={options}
      multiple
      formik
    />
  );
}
