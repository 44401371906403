import {type ReactNode} from 'react';

import {analysisHomeMenuItems} from './analysisHomeMenuItems';
import {broadcastingHomeMenuItems} from './broadcastingHomeMenuItems';
import {debriefingHomeMenuItems} from './debriefingHomeMenuItems';
import {documentationHomeMenuItems} from './documentationHomeMenuItems';
import {executionHomeMenuItems} from './executionHomeMenuItems';
import {masterDataHomeMenuItems} from './masterDataHomeMenuItems';
import {planningHomeMenuItems} from './planningHomeMenuItems';
import {settingsHomeMenuItems} from './settingsHomeMenuItems';
import {vantageLabsHomeMenuItems} from './vantageLabsHomeMenuItems';
import type {Permission} from '../../../permissions';

export interface HomeMenuItem {
  label: string;
  path: string;
  permissions?: Permission[];
  permissionCheck?: 'one-of' | 'all' | 'superadmin';
}

export interface HomeMenuItemCard {
  title: string;
  icon: ReactNode;
  menuItems: HomeMenuItem[];
}

export const homeMenuItemCards: HomeMenuItemCard[] = [
  planningHomeMenuItems,
  executionHomeMenuItems,
  debriefingHomeMenuItems,
  analysisHomeMenuItems,
  broadcastingHomeMenuItems,
  masterDataHomeMenuItems,
  vantageLabsHomeMenuItems,
  settingsHomeMenuItems,
  documentationHomeMenuItems,
];
