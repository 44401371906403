import {useCallback, useId, useState} from 'react';
import {DateRangePicker} from 'react-nice-dates';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIosRounded';
import Today from '@mui/icons-material/TodayRounded';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {enGB} from 'date-fns/locale';

import {useDateRangeStore} from '../../stores/dateRangeStore';

import 'react-nice-dates/build/style.css';

export function TopBarDateRange() {
  const id = useId();
  const startDate = useDateRangeStore((store) => store.startDate);
  const endDate = useDateRangeStore((store) => store.endDate);
  const setStartDate = useDateRangeStore((store) => store.setStartDate);
  const setEndDate = useDateRangeStore((store) => store.setEndDate);
  const setToToday = useDateRangeStore((store) => store.setToToday);
  const [toggleSetToday, setToggleSetToday] = useState(false);

  const handleSetToToday = useCallback(() => {
    setToggleSetToday((state) => !state);
    setToToday();
  }, [setToToday]);

  return (
    <DateRangeContainer>
      <IconButton onClick={handleSetToToday}>
        <Today />
      </IconButton>
      <DateRangePicker
        key={`${id}${toggleSetToday}`} // TODO Find better solution. This is a temp fix for the bug where the calendar view does not update when you set the range to today
        startDate={new Date(startDate)}
        endDate={new Date(endDate)}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        format="dd MMM yyyy"
        locale={enGB}
      >
        {({startDateInputProps, endDateInputProps, focus}) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.8}
            divider={<ArrowForwardIos fontSize="inherit" />}
          >
            <TextField
              size="small"
              sx={{maxWidth: '120px'}}
              inputProps={{
                sx: {padding: '5px 12px', cursor: 'pointer'},
              }}
              focused={focus === 'startDate'}
              {...startDateInputProps}
              placeholder="Start date"
            />
            <TextField
              size="small"
              sx={{maxWidth: '120px'}}
              focused={focus === 'endDate'}
              inputProps={{
                sx: {padding: '5px 12px', cursor: 'pointer'},
              }}
              {...endDateInputProps}
              placeholder="End date"
            />
          </Stack>
        )}
      </DateRangePicker>
    </DateRangeContainer>
  );
}

const DateRangeContainer = styled(Stack)(({theme}) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.8),
  '.nice-dates-navigation': {
    padding: theme.spacing(1, 0),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    '& .nice-dates-navigation_previous, .nice-dates-navigation_next': {
      paddingBottom: 0,
      '&::before': {width: '10px', height: '10px'},
    },
  },
  '.nice-dates-popover': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: '300px !important',
  },
  '.nice-dates-day_date': {
    color: theme.palette.text.secondary,
  },
  '.nice-dates-day_month': {
    top: 0,
  },
  '.nice-dates-grid': {
    height: '240px !important',
    color: theme.palette.text.primary,
  },

  '.nice-dates-day': {
    height: '40px !important',
  },
}));
