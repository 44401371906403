import {type ReactNode} from 'react';

import {styled} from '@mui/material/styles';
import Tab, {type TabProps} from '@mui/material/Tab';

import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';

interface VantageTabProps<TabValue = string>
  extends Omit<TabProps, 'value' | 'label'> {
  value: TabValue;
  label: ReactNode;
  size?: 'small' | 'default';
}

export function VantageTab({
  value,
  label,
  size = 'small',
  ...tabProps
}: VantageTabProps) {
  return <StyledTab value={value} label={label} $size={size} {...tabProps} />;
}

const StyledTab = styled(Tab, {
  shouldForwardProp: addShouldNotForwardProps('$size'),
})<{$size?: 'small' | 'default'}>(({theme, $size}) => ({
  textTransform: 'none',
  minWidth: $size === 'small' ? '90px' : '110px',
  minHeight: 'auto',
  padding: $size === 'small' ? theme.spacing(1, 2) : theme.spacing(1.5, 2),
  '& .MuiTab-iconWrapper': {
    width: '0.9em',
    height: '0.9em',
  },
}));
