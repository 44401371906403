import {useCallback, useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';

import {useTableActionSelectStyles} from './TableActionSelect.style';
import {isObjectWithKeys} from '../../../helpers/unknownValueTypeChecks';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';
import {type VantageTableRowActions} from '../VantageTable';

interface TableActionSelectProps<TableData extends object> {
  config: VantageTableConfig<TableData>;
  rowId: keyof TableData;
  selection: VantageTableRowActions<TableData>['selection'];
}

export function TableActionSelect<TableData extends object>({
  row,
  rowId,
  config,
  selection,
}: DataTypeProvider.ValueFormatterProps & TableActionSelectProps<TableData>) {
  const classes = useTableActionSelectStyles();
  const isSelected = useMemo(
    () =>
      selection?.selection?.find(
        (item) => isObjectWithKeys(item, 'id') && item.id === row.id,
      ) != null,
    [row.id, selection],
  );
  const isDisabled = useMemo(
    () =>
      config.selection?.getSelectableRow != null &&
      !config.selection.getSelectableRow(row as TableData),
    [config.selection, row],
  );

  const handleSelect = useCallback(() => {
    if (selection?.onSelectionChange != null && selection.selection != null) {
      selection.onSelectionChange(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        selection.selection.some((item) => item[rowId] === row[rowId])
          ? selection.selection.filter((item) => item[rowId] !== row[rowId])
          : [...selection.selection, row],
      );
    }
  }, [row, rowId, selection]);

  if (config.selection?.selectionType === 'radio') {
    return (
      <Radio
        checked={isSelected}
        onChange={handleSelect}
        disabled={isDisabled}
      />
    );
  }

  return (
    <Checkbox
      size="small"
      icon={isDisabled ? <span className={classes.disabledIcon} /> : undefined}
      checked={isSelected}
      onChange={handleSelect}
      disabled={isDisabled}
    />
  );
}
