import {broadcastApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadBroadcasts = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  return useLoadList(
    async () =>
      broadcastApi.apiBroadcastGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        heading: getFilter('heading'),
        message: getFilter('message'),
        status: getFilter('status'),
      }),
    updatePagination,
    [getFilter, loadRequestPagination, loadRequestSorting],
  );
};
