import {type Dispatch, type SetStateAction, useCallback, useState} from 'react';
import {toast} from 'react-toastify';

import {type WebMasterTripDebriefListMasterTrip} from '@onroadvantage/onroadvantage-api';

import {masterTripDebriefApi} from '../../../api';
import {config} from '../../../config';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';

type ApproveStatus = 'idle' | 'approving' | 'success' | 'error' | 'warning';

interface UseApproveTripDebriefsParams {
  selectedTrips: WebMasterTripDebriefListMasterTrip[];
  setSelectedTrips: Dispatch<
    SetStateAction<WebMasterTripDebriefListMasterTrip[]>
  >;
  reloadData: () => Promise<void>;
}
export const useApproveTripDebriefs = ({
  selectedTrips,
  setSelectedTrips,
  reloadData,
}: UseApproveTripDebriefsParams) => {
  const [approveStatus, setApproveStatus] = useState<ApproveStatus>('idle');

  const onApprove = useCallback(async () => {
    setApproveStatus('approving');
    try {
      await Promise.all(
        selectedTrips
          .filter(
            ({masterTripId, masterTripDebriefRevision}) =>
              masterTripId != null && masterTripDebriefRevision != null,
          )
          .map(
            async ({tripNumber, masterTripId, masterTripDebriefRevision}) => {
              try {
                await masterTripDebriefApi.apiMasterTripMasterTripIdDebriefApprovePatch(
                  {
                    masterTripId: masterTripId ?? 0,
                    body: {revision: masterTripDebriefRevision ?? 0},
                  },
                );
              } catch (e) {
                if (isObjectWithKeys(e, 'status') && e.status === 422) {
                  if (
                    !toast.isActive(`trip-approval-warning-${masterTripId}`)
                  ) {
                    toast(
                      `Unable to approve trip ${tripNumber}. Confirm trip is ready for approval and that this is the trip's latest revision.`,
                      {
                        style: {borderRadius: '8px'},
                        type: 'warning',
                        toastId: `trip-approval-warning-${masterTripId}`,
                        autoClose: config.toastyAutoHideDuration * 2,
                      },
                    );
                  }
                } else if (
                  !toast.isActive(`trip-approval-error-${masterTripId}`)
                ) {
                  toast(`Unable to approve trip ${tripNumber}.`, {
                    type: 'error',
                    toastId: `trip-approval-error-${masterTripId}`,
                  });
                }
              }
            },
          ),
      );
      void reloadData();
      setApproveStatus('success');
      setSelectedTrips([]);
    } catch (e) {
      toast.error('Unable to approve trips. Try again.');
      setApproveStatus('error');
    }
  }, [reloadData, selectedTrips, setSelectedTrips]);

  return {onApprove, approveStatus};
};
