import EventIcon from '@mui/icons-material/EventRounded';
import InputAdornment from '@mui/material/InputAdornment';
import {
  MobileDateTimePicker,
  type MobileDateTimePickerProps,
} from '@mui/x-date-pickers/MobileDateTimePicker';
import {useFormikContext} from 'formik';
import {DateTime} from 'luxon';

import {useFieldDisabled} from './hooks/useFieldDisabled';
import {useFieldError} from './hooks/useFieldError';

export type VantageDateTimePickerFieldProps =
  | ({formik: true} & VantageDateTimePickerFieldFormikProps)
  | ({formik?: false} & VantageDateTimePickerFieldBaseProps);

export function VantageDateTimePickerField({
  formik,
  ...props
}: VantageDateTimePickerFieldProps) {
  if (formik === true) return <VantageDateTimePickerFieldFormik {...props} />;

  return <VantageDateTimePickerFieldBase {...props} />;
}

type VantageDateTimePickerFieldFormikProps = Omit<
  VantageDateTimePickerFieldBaseProps,
  'value' | 'defaultValue' | 'onChange'
>;

function VantageDateTimePickerFieldFormik({
  name,
  disabled = false,
  ...props
}: VantageDateTimePickerFieldFormikProps) {
  const fieldDisabled = useFieldDisabled(disabled);
  const fieldError = useFieldError(name);
  const {getFieldMeta, getFieldHelpers, handleBlur} = useFormikContext();
  const {initialValue, value} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  if (initialValue != null && !(initialValue instanceof Date)) {
    throw new Error('Invalid date');
  }

  if (value != null && !(value instanceof Date)) {
    throw new Error('Invalid date');
  }

  const onChange = async (val: DateTime<boolean> | null) => {
    await setValue(val?.set({second: 0, millisecond: 0}).toJSDate());
  };

  return (
    <VantageDateTimePickerFieldBase
      {...props}
      name={name}
      slotProps={{
        ...props.slotProps,
        textField: {
          error: fieldError != null,
          helperText: fieldError,
          onBlur: handleBlur,
          ...props.slotProps?.textField,
        },
      }}
      disabled={fieldDisabled}
      onChange={onChange}
      defaultValue={
        initialValue != null ? DateTime.fromJSDate(initialValue) : null
      }
      value={value != null ? DateTime.fromJSDate(value) : null}
    />
  );
}

interface VantageDateTimePickerFieldBaseProps
  extends MobileDateTimePickerProps<DateTime> {
  name: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
}

export function VantageDateTimePickerFieldBase({
  name,
  size = 'medium',
  fullWidth = true,
  ...props
}: VantageDateTimePickerFieldBaseProps) {
  return (
    <MobileDateTimePicker<DateTime>
      ampm={false}
      {...props}
      reduceAnimations
      name={name}
      slotProps={{
        ...props.slotProps,
        textField: {
          size,
          id: name,
          fullWidth,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <EventIcon />
              </InputAdornment>
            ),
          },
          ...props.slotProps?.textField,
          sx: {
            cursor: 'pointer',
            ...(props.slotProps?.textField != null &&
            'sx' in props.slotProps.textField
              ? props.slotProps?.textField?.sx
              : {}),
          },
        },
      }}
    />
  );
}
