import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import TripDebriefs from './pages/TripDebriefs';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyTripDebrief = lazy(async () => import('./pages/TripDebrief'));

export const tripDebriefRoutes: RouteObject[] = [
  {
    path: '',
    loader: () => redirect(routes.tripDebrief.list),
  },
  {
    path: routes.getRelativePath(
      routes.tripDebrief.list,
      routes.tripDebrief.base,
    ),
    element: <TripDebriefs />,
    loader: () => {
      document.title = 'Trip Debriefing';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Trip Debriefing'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.tripDebrief.masterTripId,
      routes.tripDebrief.base,
    ),
    element: <LazyTripDebrief />,
    loader: () => {
      document.title = 'View Trip Debrief';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {
            label: 'Trip Debriefing',
            path: routes.tripDebrief.list,
          },
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
