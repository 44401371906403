import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';

import {getGlassBackground} from '../../../../theme/getGlassBackground';

export const TripCard = styled(Box)(({theme}) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  flex: 1,
  ...getGlassBackground(theme),
}));
