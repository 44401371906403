import {useState} from 'react';

export const useStepper = <StepValue = string>(steps: readonly StepValue[]) => {
  const [activeStep, setActiveStep] = useState<StepValue>(steps[0]);
  const [completedSteps, setCompletedSteps] = useState<StepValue[]>([]);

  const getAdjacentStep = (step: StepValue, side: 'next' | 'prev') => {
    const indexOfStep = steps.indexOf(step);
    if (side === 'next' && steps.length > indexOfStep + 1) {
      return steps[indexOfStep + 1];
    }
    if (side === 'prev' && indexOfStep > 0) {
      return steps[indexOfStep - 1];
    }
    return null;
  };

  return {
    activeStep,
    activeStepPosition: steps.indexOf(activeStep) + 1,
    isFirstStep: activeStep === steps[0],
    isLastStep: steps.indexOf(activeStep) + 1 === steps.length,
    completedSteps,
    onResetStepper: () => {
      setActiveStep(steps[0]);
      setCompletedSteps([]);
    },
    onNextStep: () => {
      let currentStep: StepValue | null = null;
      let lastStep: StepValue | null = null;
      setActiveStep((activeStepState) => {
        const adjacentStep = getAdjacentStep(activeStepState, 'next');
        currentStep = activeStepState;
        if (
          adjacentStep != null &&
          steps.indexOf(adjacentStep) === steps.length - 1
        ) {
          lastStep = adjacentStep;
        }
        return adjacentStep ?? activeStepState;
      });
      setCompletedSteps((currentCompletedSteps) => [
        ...currentCompletedSteps,
        ...(currentStep != null ? [currentStep] : []),
        ...(lastStep != null ? [lastStep] : []),
      ]);
    },
    onPreviousStep: () => {
      setActiveStep(
        (currentStep) => getAdjacentStep(currentStep, 'prev') ?? currentStep,
      );
    },
    onGoToStep: (targetStep: StepValue) => {
      if (completedSteps.includes(targetStep)) {
        setActiveStep(targetStep);
      }
    },
  };
};

export type UseStepsReturnType<T = string> = ReturnType<typeof useStepper<T>>;
