import {useMemo} from 'react';

import {type Theme, useTheme} from '@mui/material/styles';
import {
  type TwilioLogResponse,
  type Voice,
} from '@onroadvantage/onroadvantage-api';

import {config} from '../../../config';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTable} from '../../common/components/table/VantageTable';

const tableConfig = (theme: Theme): VantageTableConfig<TwilioLogResponse> => ({
  columns: [
    {name: 'status', label: 'Status', type: 'string'},
    {name: 'from', label: 'To Number', type: 'string'},
    {name: 'to', label: 'To Number', type: 'string'},
    {name: 'createdAt', label: 'Created At', type: 'datetime'},
  ],
  fitHeight: true,
  backgroundColor: config.tableTheme.nestedLevels[theme.palette.mode].level2,
});

export function BroadcastsRowDetailRowDetail({row}: {row: Voice}) {
  const theme = useTheme();
  const memoizedConfig = useMemo(() => tableConfig(theme), [theme]);
  return (
    <VantageTable
      config={memoizedConfig}
      data={row.voiceCallLogs ?? []}
      rowId="id"
    />
  );
}
