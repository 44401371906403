import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {ProgressTypeProviderFormatter} from './ProgressTypeProviderFormatter';

export default function ProgressTypeProvider(props: DataTypeProviderProps) {
  return (
    <DataTypeProvider
      formatterComponent={ProgressTypeProviderFormatter}
      {...props}
    />
  );
}
