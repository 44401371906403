import Typography from '@mui/material/Typography';

import {VantageAutocompleteFieldWithListing} from '../../../common/components/form/VantageAutocompleteFieldWithListing';

export function ReportScheduleFormRecipients() {
  return (
    <>
      <Typography color="text.primary" mb={0} pb={0}>
        Recipients
      </Typography>
      <VantageAutocompleteFieldWithListing
        name="contactGroupsTo"
        models="ContactGroup"
        label="TO: Audience Group(s)"
        placeholder="Search and select for audience group(s)"
        multiple
        formik
      />
      <VantageAutocompleteFieldWithListing
        name="contactGroupsCC"
        models="ContactGroup"
        label="CC: Audience Group(s)"
        placeholder="Search and select for audience group(s)"
        multiple
        formik
      />
      <VantageAutocompleteFieldWithListing
        name="contactGroupsBCC"
        models="ContactGroup"
        label="BCC: Audience Group(s)"
        placeholder="Search and select for audience group(s)"
        multiple
        formik
      />
      <VantageAutocompleteFieldWithListing
        name="contactsTo"
        models="Contact"
        label="TO: Audience(s)"
        placeholder="Search and select for audience(s)"
        multiple
        formik
      />
      <VantageAutocompleteFieldWithListing
        name="contactsCC"
        models="Contact"
        label="CC: Audience(s)"
        placeholder="Search and select for audience(s)"
        multiple
        formik
      />
      <VantageAutocompleteFieldWithListing
        name="contactsBCC"
        models="Contact"
        label="BCC: Audience(s)"
        placeholder="Search and select for audience(s)"
        multiple
        formik
      />
    </>
  );
}
