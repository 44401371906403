import {TableSelection} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

/**
 * TableSelection's typing for some reason doesn't have the Row option, although it should exist and doing this does
 * give the desired result. Which is why the `// @ts-ignore`s was needed
 * TODO: Investigate why the TableSelection has TableSelection.RowProps but not TableSelection.Row
 */
// @ts-expect-error TODO -> This does exist? Probably needs an upgrade
export const VantageTableSelectionRowComponent = styled(TableSelection.Row)(
  () => ({
    background: 'transparent',
    paddingLeft: 0,
    height: 50,
    '&.TableParts-fixedHeader': {
      background: 'transparent',
    },
  }),
);
