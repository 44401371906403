export enum ReportParameterParameterType {
  arrayInt = 'arrint',
  requiredArrayInt = 'arrint_required',
  customList = 'customlist',
  requiredCustomList = 'customlist_required',
  date = 'date',
  dateTime = 'datetime',
  dateRange = 'daterange',
  int = 'int',
  integer = 'integer',
  decimal = 'decimal',
  numeric = 'numeric',
  other = 'other',
}
