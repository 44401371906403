import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import PurchaseOrderAdd from './pages/PurchaseOrderAdd';
import PurchaseOrders from './pages/PurchaseOrders';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyPurchaseOrder = lazy(async () => import('./pages/PurchaseOrder'));

export const purchaseOrderRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.purchaseOrder.list)},
  {
    path: routes.getRelativePath(
      routes.purchaseOrder.list,
      routes.purchaseOrder.base,
    ),
    element: <PurchaseOrders />,
    loader: () => {
      document.title = `Purchase Orders`;
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Purchase Orders'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.purchaseOrder.add,
      routes.purchaseOrder.base,
    ),
    element: <PurchaseOrderAdd />,
    loader: () => {
      document.title = `Add Purchase Orders`;
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Purchase Orders', path: routes.purchaseOrder.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.purchaseOrder.purchaseOrderId,
      routes.purchaseOrder.base,
    ),
    element: <LazyPurchaseOrder />,
    loader: () => {
      document.title = `View Purchase Order`;
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Purchase Orders', path: routes.purchaseOrder.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
