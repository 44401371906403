import type {Dispatch, SetStateAction} from 'react';

import EditIcon from '@mui/icons-material/EditRounded';

import {TableActionButtonBase} from './TableActionButtonBase';
import {isObjectWithKeys} from '../../../helpers/unknownValueTypeChecks';

interface TableActionNavigateProps<TableData extends object> {
  setEditingRowIds: Dispatch<SetStateAction<Array<string | number>>>;
  rowId: keyof TableData;
  row: TableData;
}

export function TableActionInlineEdit<TableData extends object>({
  setEditingRowIds,
  rowId,
  row,
}: TableActionNavigateProps<TableData>) {
  const handleEditRow = () => {
    if (
      isObjectWithKeys(row, rowId) &&
      (typeof row[rowId] === 'number' || typeof row[rowId] === 'string')
    ) {
      setEditingRowIds((prev) =>
        prev.includes(row[rowId] as number | string)
          ? prev.filter((item) => item !== row[rowId])
          : [...prev, row[rowId] as string | number],
      );
    }
  };

  return (
    <TableActionButtonBase data-cy="TableActionsEdit" onClick={handleEditRow}>
      <EditIcon />
    </TableActionButtonBase>
  );
}
