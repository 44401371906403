import type {Dispatch, SetStateAction} from 'react';

import AddIcon from '@mui/icons-material/AddRounded';
import EditIcon from '@mui/icons-material/EditRounded';

import {ActionsTypeProviderEditorSaveAll} from './ActionsTypeProviderEditorSaveAll';
import {TableActionButtonBase} from '../components/TableActionButtonBase';
import {TableActionDownload} from '../components/TableActionDownload';
import {TableActionSelectAll} from '../components/TableActionSelectAll';
import type {
  RowChangesType,
  VantageTableActions,
  VantageTableProps,
} from '../VantageTable';

export interface ActionsTypeProviderEditorHeaderProps<TableData extends object>
  extends Pick<VantageTableProps<TableData>, 'data' | 'config' | 'rowId'> {
  setAddedRows: Dispatch<SetStateAction<Array<Record<string, unknown>>>>;
  editingRowIds: Array<string | number>;
  setEditingRowIds: Dispatch<SetStateAction<Array<string | number>>>;
  actions: VantageTableActions<TableData>;
  rowChanges: RowChangesType<TableData> | undefined;
  setRowChanges: Dispatch<
    SetStateAction<RowChangesType<TableData> | undefined>
  >;
}

export function ActionsTypeProviderEditorHeader<TableData extends object>({
  config,
  setAddedRows,
  editingRowIds,
  setEditingRowIds,
  rowId,
  data,
  actions,
  setRowChanges,
  rowChanges,
}: ActionsTypeProviderEditorHeaderProps<TableData>) {
  if (actions.onInlineEdit != null && editingRowIds.length > 0) {
    return (
      <ActionsTypeProviderEditorSaveAll
        rowChanges={rowChanges}
        onInlineEdit={actions.onInlineEdit}
        setEditingRowIds={setEditingRowIds}
        setRowChanges={setRowChanges}
      />
    );
  }
  return (
    <>
      {actions.selection != null && (
        <TableActionSelectAll
          selection={actions.selection}
          config={config}
          loading={false}
          data={data}
        />
      )}
      {(actions.onAdd != null || actions.onInlineAdd != null) && (
        <TableActionButtonBase
          data-cy="TableActionsAdd"
          onClick={
            actions.onInlineAdd != null
              ? () => {
                  setAddedRows([{}]);
                }
              : actions.onAdd
          }
          loading={false}
        >
          <AddIcon />
        </TableActionButtonBase>
      )}
      {actions.onInlineEdit != null && (
        <TableActionButtonBase
          data-cy="TableActionsEditAll"
          onClick={() => {
            setEditingRowIds(
              data
                .map((item) => item[rowId] as string | number | undefined)
                .filter((id) => id != null),
            );
          }}
          loading={false}
        >
          <EditIcon />
        </TableActionButtonBase>
      )}
      {actions.onDownload != null && (
        <TableActionDownload onDownload={actions.onDownload} data={data} />
      )}
    </>
  );
}
