import {webMasterTripApi} from '../../../api';
import {useTableDownload} from '../../common/hooks/useTableDownload';

export const useDownloadTrips = () => {
  return useTableDownload('Trips', async () => {
    const {items} = await webMasterTripApi.apiWebMasterTripGet({
      perPage: 10000,
    });
    return (
      items?.map((item) => ({
        tripNumber: item.tripNumber,
        driver: item.driverName,
        vehicle: item.vehicleRegistrationNumber,
        fleetNumber: item.fleetNumber,
        collectionLocation: item.upliftNodeName,
        deliveryLocation: item.offloadNodeName,
        contractCode: item.contractCode,
        tripStart: item.tripStart?.toISOString(),
        tripEnd: item.tripEnd?.toISOString(),
        createdAt: item.createdAt?.toISOString(),
        orderProgress: item.orderProgress,
        stopProgress: item.stopProgress,
        taskProgress: item.taskProgress,
        status: item.status,
        orderNumber: item.orderNumber,
      })) ?? []
    );
  });
};
