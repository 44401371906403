import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {LinkTypeProviderFormatter} from './LinkTypeProviderFormatter';

export default function LinkTypeProvider(props: DataTypeProviderProps) {
  return (
    <DataTypeProvider
      formatterComponent={LinkTypeProviderFormatter}
      {...props}
    />
  );
}
