import {ResponseService} from './ResponseService';
import {getAuthHeaderValues} from '../../../api';
import {config} from '../../../config';

const serverUrl = config.serverUri;
const voiceCallsQueryUrl = `${serverUrl}/api/voice`;

const VoiceUtil = {
  createDirectCall: async (body: Record<string, unknown>) => {
    const {name, value} = getAuthHeaderValues();
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set(name, value);
    const response = await fetch(`${voiceCallsQueryUrl}/twilio/direct`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),
      headers,
    });
    return ResponseService.checkStatus(response);
  },
  generateVoiceMessage: async ({
    identifier,
    message,
  }: {
    identifier: string;
    message: string;
  }) => {
    const {name, value} = getAuthHeaderValues();
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set(name, value);
    const body = JSON.stringify({identifier, message});
    const response = await fetch(
      `${serverUrl}/api/voice_message/generate/audio/file`,
      {
        method: 'POST',
        mode: 'cors',
        body,
        headers,
      },
    );
    return ResponseService.checkStatus(response);
  },
};

export default VoiceUtil;
