import {
  type MasterTripPostOrder,
  type MasterTripPostStop,
} from '@onroadvantage/onroadvantage-api';

import {type TripAddStopsStop} from '../hooks/useAddTripStops';

export const mapTripAddStopsToPostStops = (
  stops: TripAddStopsStop[],
): MasterTripPostStop[] => {
  const stopsByNode = new Map<string, MasterTripPostStop[]>();

  stops.forEach((stop) => {
    if (stop.orders != null && stop.orders.length > 0) {
      const order = stop.orders[0];

      const upliftPointNodeId = order.upliftPointNode?.value ?? 0;
      const offloadPointNodeId = order.offloadPointNode?.value ?? 0;
      const loadingKey = `${upliftPointNodeId}LOADING`;
      const offloadingKey = `${upliftPointNodeId}${offloadPointNodeId}OFFLOADING`;
      const masterTripPostOrder: MasterTripPostOrder = {
        orderNumber: order.orderNumber,
        upliftPointNodeId,
        offloadPointNodeId,
        purchaseOrderId:
          order.purchaseOrder?.value != null && order.purchaseOrder.value > 0
            ? order.purchaseOrder.value
            : undefined,
        purchaseOrderNumber: order.purchaseOrder?.label ?? order.orderNumber,
      };

      if (order.id == null) {
        masterTripPostOrder.productId = order.product?.value;
        masterTripPostOrder.quantity = order.quantity;
        masterTripPostOrder.deliveryNoteNumber = order.deliveryNoteNumber;
      }

      const currentStopLoadingStops = stopsByNode.get(loadingKey) ?? [];
      stopsByNode.set(loadingKey, [
        ...currentStopLoadingStops,
        {
          nodeId: upliftPointNodeId,
          orders: [masterTripPostOrder],
          sequence: stop.sequence,
        },
      ]);
      stopsByNode.set(offloadingKey, [
        ...(stopsByNode.get(offloadingKey) ?? []),
        {
          nodeId: offloadPointNodeId,
          orders: [masterTripPostOrder],
          sequence: stop.sequence,
        },
      ]);
    } else {
      const stopKey = `{${stop.sequence}${stop.node.value ?? 0}STOP`;
      stopsByNode.set(stopKey, [
        ...(stopsByNode.get(stopKey) ?? []),
        {
          nodeId: stop.node.value ?? 0,
          orders: [],
          sequence: stop.sequence,
        },
      ]);
    }
  });

  const backToArray: MasterTripPostStop[] = [];

  stopsByNode.forEach((stopsForNode) => {
    backToArray.push(
      ...stopsForNode.map((stopForNode, index) => ({
        ...stopForNode,
        sequence: backToArray.length + index + 1,
      })),
    );
  });

  return backToArray.sort((a, b) => a.sequence - b.sequence);
};
