import {lazy, type ReactNode, Suspense} from 'react';

import Stack from '@mui/material/Stack';

import {AuthCard} from './AuthCard';
import {AuthenticationBackToLogin} from './AuthenticationBackToLogin';
import {AuthenticationLogo} from './AuthenticationLogo';
import {AuthenticationTag} from './AuthenticationTag';

const LazyAppShellAnimatedBackground = lazy(
  async () =>
    import('../../common/components/appShell/AppShellAnimatedBackground'),
);
export function AuthenticationContainer({
  children,
  enableBackToLogin = false,
  tag,
}: {
  children: ReactNode;
  enableBackToLogin?: boolean;
  tag?: string;
}) {
  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <Suspense fallback={null}>
        <LazyAppShellAnimatedBackground />
      </Suspense>
      <AuthCard>
        {enableBackToLogin && <AuthenticationBackToLogin />}
        {tag != null && <AuthenticationTag label={tag} />}
        <AuthenticationLogo />
        <Stack>{children}</Stack>
      </AuthCard>
    </Stack>
  );
}
