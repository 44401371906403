import {orderApi} from '../../../api';
import {useLoad} from '../../common/hooks/useLoad';

export const useLoadOrder = (id: number) => {
  const {item, load, loadStatus, updateState} = useLoad(async () =>
    orderApi.apiOrderOrderIdGet({
      orderId: id,
    }),
  );

  return {
    item,
    id,
    loadStatus,
    updateState,
    reload: async () => {
      await load('reload');
    },
  };
};
