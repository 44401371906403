import {ToastContainer} from 'react-toastify';

import {styled, useTheme} from '@mui/material/styles';

import {config} from './config';

import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled('div')(({theme}) => ({
  '.Toastify__toast-container': {
    padding: 0,
    bottom: 0,
  },
  '.Toastify__toast': {
    backgroundColor: theme.palette.background.default,
    padding: 0,
    minHeight: 'fit-content',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
  },
  '.Toastify__toast-body': {
    padding: theme.spacing(1),
  },
  '.Toastify__toast--success': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.success.main,
    },
    '& > .Toastify__progress-bar': {
      backgroundColor: theme.palette.success.main,
    },
  },
  '.Toastify__toast--error': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.error.main,
    },
    '& > .Toastify__progress-bar': {
      backgroundColor: theme.palette.error.main,
    },
  },
  '.Toastify__toast--warning': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.warning.main,
    },
    '& > .Toastify__progress-bar': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  '.Toastify__toast--info': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.info.main,
    },
    '& > .Toastify__progress-bar': {
      backgroundColor: theme.palette.info.main,
    },
  },
}));

export function Toastify() {
  const theme = useTheme();
  return (
    <StyledToastContainer>
      <ToastContainer
        position="bottom-left"
        autoClose={config.toastyAutoHideDuration}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        limit={3}
        closeButton={false}
        theme={theme.palette.mode}
      />
    </StyledToastContainer>
  );
}
