import {lazy, Suspense} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import AuthRedirect from './pages/AuthRedirect';
import Login from './pages/Login';
import {routes} from '../../routes';
import {PageLoader} from '../common/components/loader/PageLoader';

const LazyForgotPassword = lazy(async () => import('./pages/ForgotPassword'));
const LazyResetPassword = lazy(async () => import('./pages/ResetPassword'));

export const authRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.auth.login)},
  {
    path: routes.getRelativePath(routes.auth.login, routes.auth.base),
    element: (
      <Suspense fallback={<PageLoader />}>
        <Login />
      </Suspense>
    ),
    loader: () => {
      document.title = 'Onroad Vantage Login';
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.auth.redirect, routes.auth.base),
    element: (
      <Suspense fallback={<PageLoader />}>
        <AuthRedirect />
      </Suspense>
    ),
    loader: () => {
      document.title = 'Onroad Vantage Redirecting...';
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.auth.forgotPassword, routes.auth.base),
    element: (
      <Suspense fallback={<PageLoader />}>
        <LazyForgotPassword />
      </Suspense>
    ),
    loader: () => {
      document.title = 'Onroad Vantage Forgot Password';
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.auth.resetPassword, routes.auth.base),
    element: (
      <Suspense fallback={<PageLoader />}>
        <LazyResetPassword />
      </Suspense>
    ),
    loader: () => {
      document.title = 'Onroad Vantage Reset Password';
      return null;
    },
  },
];
