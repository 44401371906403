import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const debriefingMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Trip Debriefing',
    pathname: routes.tripDebrief.list,
    permission: 'tripDebrief:list',
  },
  {
    level: 2,
    label: 'Trip Debrief Configs',
    pathname: routes.tripDebriefConfig.list,
    permission: 'masterTripDebriefConfig:list',
  },
];
