import Stack from '@mui/material/Stack';

import {VantageCard} from '../../common/components/VantageCard';
import {PurchaseOrderAddDetails} from '../components/purchaseOrderAdd/PurchaseOrderAddDetails';

export default function PurchaseOrderAdd() {
  return (
    <VantageCard>
      <Stack>
        <PurchaseOrderAddDetails />
      </Stack>
    </VantageCard>
  );
}
