import {type Dispatch, type SetStateAction, useCallback, useMemo} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useSearchParams} from 'react-router-dom';

import type {Filter} from '@devexpress/dx-react-grid';
import {debounce} from '@mui/material/utils';

import {type VantageTableFiltering} from '../components/table/VantageTable';

interface UseFilteringParams {
  setCurrentPage: Dispatch<SetStateAction<number>> | null;
}

export const useFiltering = ({setCurrentPage}: UseFilteringParams) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    tableFiltering: useMemo(
      () =>
        ({
          filters:
            searchParams
              .get('qryFilters')
              ?.split(',')
              .map((filter) => {
                const [columnName, value] = filter.split(':');
                return {columnName, value};
              }) ?? [],
          onFiltersChange: debounce((newFilters: Filter[]) => {
            unstable_batchedUpdates(() => {
              if (setCurrentPage != null) {
                setCurrentPage(1);
              }
              if (newFilters.length === 0) {
                setSearchParams((curr) => {
                  curr.delete('qryFilters');
                  return curr;
                });
              } else {
                setSearchParams((curr) => {
                  const qryFilters: string[] = [];
                  newFilters.forEach((newFilter) => {
                    const splitColumn = newFilter.columnName.split('.');
                    let {columnName} = newFilter;
                    if (splitColumn.length > 1) {
                      columnName = splitColumn
                        .map((word, index) =>
                          index !== 0
                            ? word[0].toUpperCase() + word.slice(1)
                            : word,
                        )
                        .join('');
                    }

                    qryFilters.push(`${columnName}:${newFilter.value}`);
                  });

                  curr.set('qryFilters', qryFilters.join(','));
                  return curr;
                });
              }
            });
          }, 800),
        }) satisfies VantageTableFiltering,
      [setCurrentPage, searchParams, setSearchParams],
    ),
    getFilter: useCallback(
      (targetColumnName: string) =>
        searchParams
          .get('qryFilters')
          ?.split(',')
          .map((filter) => {
            const [columnName, value] = filter.split(':');
            return {columnName, value};
          })
          .find((f) => f.columnName === targetColumnName)?.value,
      [searchParams],
    ),
  };
};

export type GetFilter = ReturnType<typeof useFiltering>['getFilter'];
export type TableFiltering = ReturnType<typeof useFiltering>['tableFiltering'];
