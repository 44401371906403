import {type SubNode} from '@onroadvantage/onroadvantage-api';

import {nodeApi} from '../../../api';
import {Warning} from '../../common/classes/Warning';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useInlineAdd} from '../../common/hooks/useInlineAdd';

export const useAddSiteSubSite = (
  nodeId: number,
  addSubNode: (subNode: SubNode) => void,
) => {
  return useInlineAdd(
    async (values) => {
      if (
        !isObjectWithKeys(values, 'name') ||
        typeof values.name !== 'string' ||
        values.name === ''
      ) {
        throw new Warning('Name is required');
      }
      if (
        !isObjectWithKeys(values, 'description') ||
        typeof values.description !== 'string' ||
        values.description === ''
      ) {
        throw new Warning('Description is required');
      }
      if (
        !isObjectWithKeys(values, 'taskTemplateName') ||
        !isObjectWithKeys(values.taskTemplateName, 'value') ||
        typeof values.taskTemplateName.value !== 'number'
      ) {
        throw new Warning('Task template is required');
      }

      const response = await nodeApi.apiNodeNodeIdSubNodePost({
        nodeId,
        body: {
          name: values.name,
          description: values.description,
          externalReference:
            isObjectWithKeys(values, 'externalReference') &&
            typeof values.externalReference === 'string'
              ? values.externalReference
              : undefined,
          taskTemplateId: values.taskTemplateName.value,
        },
      });
      addSubNode(response);
    },
    [nodeId, addSubNode],
  );
};
