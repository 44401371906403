import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded';

import {TableActionButtonBase} from './TableActionButtonBase';

interface TableActionNavigateProps<TableData extends object> {
  onNavigate: (row: TableData) => void;
  row: TableData;
}

export function TableActionNavigate<TableData extends object>({
  onNavigate,
  row,
}: TableActionNavigateProps<TableData>) {
  const handleNavigate = () => {
    onNavigate(row);
  };

  return (
    <TableActionButtonBase
      data-cy="TableActionsNavigate"
      onClick={handleNavigate}
    >
      <OpenInNewIcon fontSize="small" />
    </TableActionButtonBase>
  );
}
