import {useMemo} from 'react';

import {useUserStore} from '../../common/stores/userStore';
import {
  type HomeMenuItemCard,
  homeMenuItemCards,
} from '../homeMenuItems/homeMenuItems';

export const useFilteredHomeMenuItems = () => {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const hasOneOfPermissions = useUserStore(
    (state) => state.hasOneOfPermissions,
  );
  const isSuperAdmin = useUserStore((state) => state.isSuperAdmin);

  return useMemo(() => {
    return homeMenuItemCards.reduce((acc: HomeMenuItemCard[], menuItemCard) => {
      const validMenuItems = menuItemCard.menuItems.filter((menuItem) => {
        if (menuItem.permissionCheck === 'superadmin') {
          return isSuperAdmin();
        }
        if (menuItem.permissions == null || menuItem.permissions.length === 0) {
          return true;
        }
        if (menuItem.permissionCheck === 'one-of') {
          return hasOneOfPermissions(...menuItem.permissions);
        }
        return hasPermissions(...menuItem.permissions);
      });

      if (validMenuItems.length > 0) {
        return [...acc, {...menuItemCard, menuItems: validMenuItems}];
      }

      return acc;
    }, []);
  }, [hasOneOfPermissions, hasPermissions, isSuperAdmin]);
};
