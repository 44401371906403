import {toast} from 'react-toastify';

import {type VehicleType} from '@onroadvantage/onroadvantage-api';

import {vehicleTypeApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteVehicleType = ({removeItem}: DeleteParams) => {
  return useDelete<VehicleType>(async ({id}) => {
    if (id == null) {
      toast('Vehicle type ID not found', {type: 'warning'});
      return;
    }
    await vehicleTypeApi.apiVehicleTypeVehicleTypeIdDelete({
      vehicleTypeId: id,
    });
    removeItem(id);
  });
};
