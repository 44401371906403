import NextWeekIcon from '@mui/icons-material/NextWeekRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const debriefingHomeMenuItems: HomeMenuItemCard = {
  title: 'Debriefing',
  icon: <NextWeekIcon color="action" />,
  menuItems: [
    {
      label: 'Trip Debriefing',
      path: routes.tripDebrief.list,
      permissions: ['tripDebrief:list'],
    },
    {
      label: 'Trip Debrief Config',
      path: routes.tripDebriefConfig.list,
      permissions: ['masterTripDebriefConfig:list'],
    },
  ],
};
