import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const executionMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Orders',
    pathname: routes.order.list,
    permission: 'order:list',
  },
  {
    level: 2,
    label: 'Purchase Orders',
    pathname: routes.purchaseOrder.list,
    permission: 'purchaseOrder:list',
  },
  {
    level: 2,
    label: 'Tasks',
    pathname: routes.task.list,
    permission: 'task:list',
  },
  {
    level: 2,
    label: 'Trips',
    pathname: routes.trip.list,
    permission: 'trip:list',
  },
  {
    level: 2,
    label: 'Workflows',
    children: [
      {
        level: 3,
        label: 'Driver Workflows',
        pathname: routes.driverWorkflow.list,
        permission: 'workflow:list',
      },
      {
        level: 3,
        label: 'Vehicle Workflows',
        pathname: routes.vehicleWorkflow.list,
        permission: 'workflow:list',
      },
      {
        level: 3,
        label: 'Order Workflows',
        pathname: routes.orderWorkflow.list,
        permission: 'workflow:list',
      },
      {
        level: 3,
        label: 'OrderLine Workflows',
        pathname: routes.orderLineWorkflow.list,
        permission: 'workflow:list',
      },
    ],
  },
];
