import {type RefObject, useMemo} from 'react';

import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import type {FormikProps} from 'formik';
import {object, string} from 'yup';

import {ClearEventsPaneSearch} from './ClearEventsPaneSearch';
import {SubmitEventsPaneSearch} from './SubmitEventsPaneSearch';
import {getNestedBorderRadius} from '../../../../theme/getNestedBorderRadius';
import {
  type OnFormSubmit,
  VantageForm,
} from '../../../common/components/form/VantageForm';
import {VantageTextField} from '../../../common/components/form/VantageTextField';

export interface EventsPaneSearchFormValues {
  search: string;
}

const validationSchema = object({
  search: string().required('Required'),
});

export interface EventsPaneSearchFormProps {
  onSearch: OnFormSubmit<EventsPaneSearchFormValues>;
  onClearSearch: () => void;
  searchFormInnerRef: RefObject<FormikProps<EventsPaneSearchFormValues>>;
}

export function EventsPaneSearchForm({
  onSearch,
  onClearSearch,
  searchFormInnerRef,
}: EventsPaneSearchFormProps) {
  const theme = useTheme();
  const InputProps = useMemo(
    () => ({
      style: {borderRadius: getNestedBorderRadius(theme, 1)},
    }),
    [theme],
  );

  return (
    <VantageForm<EventsPaneSearchFormValues>
      initialValues={{search: ''}}
      onSubmit={onSearch}
      validationSchema={validationSchema}
      formInnerRef={searchFormInnerRef}
    >
      <Stack spacing={1.5}>
        <VantageTextField
          InputProps={InputProps}
          name="search"
          label="Search"
          size="small"
          formik
        />
        <Stack direction="row" spacing={1}>
          <SubmitEventsPaneSearch label="Search" />
          <ClearEventsPaneSearch
            variant="outlined"
            color="inherit"
            onReset={onClearSearch}
            label="Clear Search"
          />
        </Stack>
      </Stack>
    </VantageForm>
  );
}
