import {webNodeApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadSites = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  const {data, loadDataStatus, reloadData, setData} = useLoadList(
    async () =>
      webNodeApi.apiWebNodeGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        telematicsConfigId: getFilter('telematicsConfigId'),
        externalId: getFilter('externalId'),
        type: getFilter('type'),
        name: getFilter('name'),
        externalReference: getFilter('externalReference'),
        nodeType: getFilter('nodeType'),
      }),
    updatePagination,
    [getFilter, loadRequestPagination, loadRequestSorting],
  );

  return {
    data,
    loadDataStatus,
    reloadData,
    removeItem: (id: number | string) => {
      setData((prev) => prev.filter((item) => item.id !== id));
    },
  };
};
