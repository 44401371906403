import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {LottieAnimation} from './LottieAnimation';
import vantageLoadingAnimation from '../../../../assets/loader.json';
import {useThemeStore} from '../../stores/themeStore';

export interface LoaderProps {
  size?: number;
  label?: string | null;
  loading?: boolean;
}
export function Loader({size = 50, label = null, loading = true}: LoaderProps) {
  if (!loading) {
    return null;
  }

  return (
    <Stack
      flex={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <LoaderSpinner size={size} />
      {label != null && <Typography py={1}>{label}</Typography>}
    </Stack>
  );
}

function LoaderSpinner({size}: {size: number}) {
  const whiteLabelledThemeOptions = useThemeStore(
    (state) => state.whiteLabelledThemeOptions,
  );

  if (whiteLabelledThemeOptions?.primaryColour != null) {
    return <CircularProgress size={size} />;
  }

  return (
    <LottieAnimation
      name="Vantage Loader"
      animationData={vantageLoadingAnimation}
      width={size}
      height={size}
      loop
      autoplay
    />
  );
}
