import {useCallback} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';
import Checkbox from '@mui/material/Checkbox';

import {checkIsFieldDisabled} from '../helpers/checkIsFieldDisabled';
import type {VantageTableColumn} from '../hooks/useVantageTableConfig';

interface ValueEditorTextFieldProps<TableData extends object>
  extends DataTypeProvider.ValueEditorProps {
  rowId: keyof TableData;
  columnConfig: VantageTableColumn<TableData>;
}

export function ValueEditorCheckbox<TableData extends object>({
  rowId,
  row,
  columnConfig,
  onValueChange,
  onKeyDown,
  value,
  onBlur,
  onFocus,
  autoFocus,
  ...props
}: ValueEditorTextFieldProps<TableData>) {
  if (columnConfig == null || columnConfig?.type !== 'boolean') {
    throw new Error(
      `ValueEditorCheckbox can only be used with columns of type 'boolean'`,
    );
  }

  const disabled =
    checkIsFieldDisabled(row, rowId, columnConfig) || props.disabled;

  const name = columnConfig.name as string;
  const id = `${name}${row[rowId]?.toString() ?? ''}`;
  let defaultValue = value;

  if (columnConfig.getValue != null) {
    defaultValue = columnConfig.getValue(row as TableData);
  }

  const handleChange = useCallback(
    (_event: unknown, checked: boolean) => {
      onValueChange(checked);
    },
    [onValueChange],
  );

  if (disabled) {
    return defaultValue === true ? 'Yes' : 'No';
  }

  return (
    <Checkbox
      id={id}
      name-={name}
      disabled={disabled}
      onChange={handleChange}
      defaultChecked={defaultValue}
      onBlur={onBlur}
      onFocus={onFocus}
      autoFocus={autoFocus}
    />
  );
}
