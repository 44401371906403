import Typography from '@mui/material/Typography';

import {ReportParameterField} from '../../components/parameters/ReportParameterField';
import {useReportContext} from '../../contexts/ReportContext';

export function ReportScheduleFormParameters() {
  const {report} = useReportContext();

  if (
    report?.reportParameters == null ||
    report.reportParameters.length === 0
  ) {
    return <Typography>No report parameters</Typography>;
  }

  return (
    <>
      <Typography color="text.primary">Parameters</Typography>
      {report?.reportParameters?.map((parameter, index) => (
        <ReportParameterField
          key={parameter.id}
          parameter={parameter}
          index={index}
        />
      ))}
    </>
  );
}
