import {type KeyboardEvent, type ReactNode, useCallback, useState} from 'react';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityIcon from '@mui/icons-material/VisibilityRounded';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import {useTheme} from '@mui/material/styles';
import TextField, {type TextFieldProps} from '@mui/material/TextField';
import {useFormikContext} from 'formik';

import {useFieldDisabled} from './hooks/useFieldDisabled';
import {useFieldError} from './hooks/useFieldError';

export type VantageTextFieldProps =
  | ({formik: true} & VantageFieldStringFormikProps)
  | ({formik?: false} & VantageFieldStringBaseProps);

export function VantageTextField({formik, ...props}: VantageTextFieldProps) {
  if (formik === true) {
    return <VantageTextFieldFormik {...props} />;
  }
  return <VantageTextFieldBase {...props} />;
}

type VantageFieldStringFormikProps = Omit<VantageFieldStringBaseProps, 'value'>;

function VantageTextFieldFormik({
  name,
  disabled = false,
  ...props
}: VantageFieldStringFormikProps) {
  const fieldDisabled = useFieldDisabled(disabled);
  const fieldError = useFieldError(name);
  const {getFieldMeta, handleChange, handleBlur} = useFormikContext();
  const {value} = getFieldMeta(name);

  return (
    <VantageTextFieldBase
      error={fieldError != null}
      helperText={fieldError}
      {...props}
      name={name}
      disabled={fieldDisabled}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value ?? ''}
    />
  );
}

interface VantageFieldStringBaseProps
  extends Omit<TextFieldProps, 'name' | 'type'> {
  name: string;
  endAdornment?: ReactNode;
  type?: 'string' | 'number' | 'passwordStatic' | 'password'; // -> passwordStatic cannot be toggled, whereas password can
  onEnterKeyPress?: (event: KeyboardEvent<HTMLDivElement>) => void;
}

function VantageTextFieldBase({
  name,
  endAdornment: propEndAdornment = null,
  type = 'string',
  onEnterKeyPress,
  onKeyDown,
  ...props
}: VantageFieldStringBaseProps) {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  let fieldType = 'string';
  if (type === 'number') {
    fieldType = 'number';
  }
  if (type === 'passwordStatic') {
    fieldType = 'password';
  }
  if (type === 'password') {
    fieldType = showPassword ? 'text' : 'password';
  }

  let endAdornment;
  if (type === 'password') {
    endAdornment = (
      <InputAdornment position="end">
        <IconButton
          name="Password visibility toggle button"
          aria-label="Password visibility toggle button"
          disabled={props.disabled}
          onClick={() => {
            setShowPassword((prevState) => !prevState);
          }}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    );
  }

  if (propEndAdornment != null) {
    endAdornment = (
      <InputAdornment position="end">{propEndAdornment}</InputAdornment>
    );
  }

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key.toLowerCase() === 'enter' && onEnterKeyPress != null) {
        onEnterKeyPress(event);
      }
      if (onKeyDown != null) {
        onKeyDown(event);
      }
    },
    [onEnterKeyPress, onKeyDown],
  );

  return (
    <TextField
      fullWidth
      id={name}
      {...props}
      onKeyDown={handleKeyDown}
      type={fieldType}
      name={name}
      InputProps={{
        endAdornment,
        style: {
          borderRadius:
            props.multiline === true ? theme.shape.borderRadius : undefined,
        },
        ...props.InputProps,
      }}
    />
  );
}
