import {create} from 'zustand';

export interface Breadcrumb {
  label: string;
  path?: string | null;
}

interface BreadcrumbStoreState {
  breadcrumbs: Breadcrumb[];
}

interface BreadcrumbStoreActions {
  reset: () => void;
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
}

const authStoreInitialState: BreadcrumbStoreState = {
  breadcrumbs: [],
};

export const useBreadcrumbStore = create<
  BreadcrumbStoreState & BreadcrumbStoreActions
>((setState) => ({
  ...authStoreInitialState,
  reset: () => {
    setState(authStoreInitialState);
  },
  setBreadcrumbs: (breadcrumbs) => {
    setState({breadcrumbs});
  },
}));
