import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export function EventsPaneSkeleton() {
  return (
    <Stack flex={1} spacing={1} p={1}>
      <EventsPaneSkeletonCard />
    </Stack>
  );
}

function EventsPaneSkeletonCard() {
  return (
    <Stack
      borderRadius="16px"
      overflow="hidden"
      bgcolor="background.paper"
      p={1}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Skeleton animation="wave" variant="text" width={100} height={30} />
        <Stack>
          <Skeleton animation="wave" variant="text" width={50} height={15} />
          <Skeleton animation="wave" variant="text" width={50} height={15} />
        </Stack>
      </Stack>
      <Divider />
      <Skeleton animation="wave" variant="text" height={30} />
      <Skeleton animation="wave" variant="text" height={30} />
      <Skeleton animation="wave" variant="text" height={30} />
      <Divider sx={{mt: 0.5, mb: 1}} />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1}>
          <Skeleton
            animation="pulse"
            variant="rounded"
            width={80}
            height={30}
          />
          <Skeleton
            animation="pulse"
            variant="rounded"
            width={80}
            height={30}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Skeleton
            animation="pulse"
            variant="circular"
            width={30}
            height={30}
          />
          <Skeleton
            animation="pulse"
            variant="circular"
            width={30}
            height={30}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
