import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const analysisMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Dashboards',
    pathname: routes.dashboard.list,
    permission: 'dashboard:list',
  },
  {
    level: 2,
    label: 'Reports',
    pathname: routes.report.list,
    permission: 'report:list',
  },
];
