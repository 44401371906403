import {useState, type MouseEvent, useMemo, type ChangeEvent} from 'react';

import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import {styled, useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {debounce} from '@mui/material/utils';
import type {ApiReasonCodeGetRequest} from '@onroadvantage/onroadvantage-api';
import {useFormikContext} from 'formik';

import {useLoadReasonCodes} from './hooks/useLoadReasonCodes';
import {getNestedBorderRadius} from '../../../theme/getNestedBorderRadius';
import {type AutocompleteOption} from '../../common/components/form/VantageAutocompleteField';
import {VantageTextField} from '../../common/components/form/VantageTextField';

interface ActivityReasonCodeMenuOptionsProps {
  requestParameters: ApiReasonCodeGetRequest;
}

export function ActivityReasonCodeMenuOptions({
  requestParameters,
}: ActivityReasonCodeMenuOptionsProps) {
  const theme = useTheme();
  const {reasonCodeOptions, loadStatus} = useLoadReasonCodes(requestParameters);
  const {setFieldValue} = useFormikContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [queryFilter, setQueryFilter] = useState<string | null>(null);

  const filteredOptions = useMemo(
    () =>
      reasonCodeOptions.filter((option) => {
        return (
          queryFilter == null ||
          queryFilter.length === 0 ||
          option.label?.toLowerCase().includes(queryFilter.toLowerCase())
        );
      }),
    [queryFilter, reasonCodeOptions],
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectReasonCode = (option: AutocompleteOption) => async () => {
    await setFieldValue('comment', option.label);
    await setFieldValue('reasonCodeId', option.value);
    setAnchorEl(null);
  };

  const id = open ? 'activity-reason-code-popover' : undefined;

  return (
    <>
      <Button
        variant="outlined"
        color="inherit"
        fullWidth
        style={{borderRadius: getNestedBorderRadius(theme, 0.5)}}
        onClick={handleClick}
      >
        Reason code
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack p={1}>
          <VantageTextField
            InputProps={{
              sx: {
                borderRadius: getNestedBorderRadius(theme, 1),
              },
            }}
            sx={{
              minWidth: 300,
            }}
            name="search"
            size="small"
            label="Search"
            onChange={debounce((event: ChangeEvent<HTMLInputElement>) => {
              setQueryFilter(event.target.value);
            }, 500)}
          />
        </Stack>
        <Stack maxHeight="500px" overflow="auto">
          {loadStatus === 'loading' && (
            <Typography
              p={1}
              textAlign="center"
              variant="body1"
              color="text.secondary"
            >
              Loading Reason Codes...
            </Typography>
          )}
          {loadStatus === 'success' && filteredOptions.length === 0 && (
            <Typography
              p={1}
              textAlign="center"
              variant="body1"
              color="text.secondary"
            >
              No Reason Codes
            </Typography>
          )}
          {loadStatus === 'error' && (
            <Typography p={1} textAlign="center" variant="body1" color="error">
              Failed to load Reason Codes
            </Typography>
          )}
          {filteredOptions.map((option) => (
            <Option key={option.value} onClick={handleSelectReasonCode(option)}>
              <Typography variant="body1">{option.label}</Typography>
            </Option>
          ))}
        </Stack>
      </Popover>
    </>
  );
}

const Option = styled(ButtonBase)(({theme}) => ({
  padding: theme.spacing(1),
  textAlign: 'start',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));
