import {memo} from 'react';
import {useLocation} from 'react-router-dom';

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {TopBarBreadcrumbs} from './TopBarBreadcrumbs';
import {TopBarDateRange} from './TopBarDateRange';
import {TopBarEvents} from './TopBarEvents';
import {getGlassBackground} from '../../../../theme/getGlassBackground';

export const TopBar = memo(function TopBar() {
  const {pathname} = useLocation();

  const onDuplicateTab = () => {
    window.open(pathname);
  };

  return (
    <TopAppBar color="inherit" position="sticky">
      <Stack direction="row" spacing={1} px={1.5} py={0.5} alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1} flex={1}>
          <TopBarBreadcrumbs />
          <IconButton size="small" onClick={onDuplicateTab}>
            <ContentCopyRoundedIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <TopBarDateRange />
        <TopBarEvents />
      </Stack>
    </TopAppBar>
  );
});

const TopAppBar = styled(AppBar)(({theme}) => ({
  zIndex: 502,
  ...getGlassBackground(theme),
}));
