import {memo, useMemo} from 'react';

import Stack from '@mui/material/Stack';
import {type Theme, useTheme} from '@mui/material/styles';
import type {
  WebMasterTripDetailSingle,
  WebMasterTripOptimised,
} from '@onroadvantage/onroadvantage-api';

import {config} from '../../../config';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {MemoizedVantageTable} from '../../common/components/table/VantageTable';
import {useLoadTripsDetail} from '../hooks/useLoadTripsDetail';

const tableConfig = (
  theme: Theme,
): VantageTableConfig<WebMasterTripDetailSingle> => ({
  columns: [
    {name: 'orderNumbers', label: 'Order Number', type: 'string'},
    {name: 'sequence', label: 'Stop Sequence', type: 'number'},
    {name: 'upliftNodeNames', label: 'Loading Location', type: 'string'},
    {name: 'offloadNodeNames', label: 'Offloading Location', type: 'string'},
  ],
  fitHeight: true,
  backgroundColor: config.tableTheme.nestedLevels[theme.palette.mode].level1,
});

export const TripsDetail = memo(function TripsDetail({
  row,
}: {
  row: WebMasterTripOptimised;
}) {
  if (row.id == null) {
    throw new Error('Trip ID not found');
  }

  const theme = useTheme();
  const {item, loadStatus} = useLoadTripsDetail(row.id);

  const memoizedConfig = useMemo(() => tableConfig(theme), [theme]);

  return (
    <Stack position="relative">
      <OverlayLoader
        loading={loadStatus === 'idle'}
        label="Preparing content..."
      />
      <OverlayLoader
        loading={loadStatus === 'loading'}
        label="Loading trip details..."
      />
      <MemoizedVantageTable
        config={memoizedConfig}
        data={item?.items ?? []}
        rowId="sequence"
      />
    </Stack>
  );
});
