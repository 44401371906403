import Button from '@mui/material/Button';
import {type InferType, object} from 'yup';

import {
  multipleListing,
  number,
} from '../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {
  type OnFormSubmit,
  VantageForm,
} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../common/components/form/VantageFormFieldsRow';
import {VantageFormReset} from '../../common/components/form/VantageFormReset';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';

export const eventBulkClosingSearchFormSchema = object({
  eventTypes: multipleListing,
  contracts: multipleListing,
  drivers: multipleListing,
  vehicles: multipleListing,
  vehicleGroups: multipleListing,
  dateRange: number
    .min(0, 'Min value 0.')
    .max(7, '7 days is the max.')
    .required('Required'),
});
export type EventBulkClosingSearchFormValues = InferType<
  typeof eventBulkClosingSearchFormSchema
>;

interface EventBulkClosingSearchFormProps {
  initialValues: EventBulkClosingSearchFormValues;
  onSearch: OnFormSubmit<EventBulkClosingSearchFormValues>;
  onReset: () => void;
}

export function EventBulkClosingSearchForm({
  initialValues,
  onSearch,
  onReset,
}: EventBulkClosingSearchFormProps) {
  return (
    <VantageForm<EventBulkClosingSearchFormValues>
      initialValues={initialValues}
      onSubmit={onSearch}
      validationSchema={eventBulkClosingSearchFormSchema}
    >
      <VantageFormContent>
        <VantageFormFieldsRow>
          <VantageAutocompleteFieldWithListing
            name="vehicles"
            models="Vehicle"
            label="Vehicles"
            placeholder="Search and select vehicles"
            formik
            multiple
          />
          <VantageAutocompleteFieldWithListing
            name="vehicleGroups"
            models="VehicleGroup"
            label="Vehicle Groups"
            placeholder="Search and select vehicle groups"
            formik
            multiple
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageAutocompleteFieldWithListing
            name="drivers"
            models="Driver"
            label="Drivers"
            placeholder="Search and select drivers"
            formik
            multiple
          />
          <VantageAutocompleteFieldWithListing
            name="contracts"
            models="Contract"
            label="Contracts"
            placeholder="Search and select a contracts"
            formik
            multiple
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageAutocompleteFieldWithListing
            name="eventTypes"
            models="WebEventType"
            label="Event Types"
            placeholder="Search and select event types"
            formik
            multiple
          />
          <VantageTextField
            name="dateRange"
            label="Date range (days)"
            placeholder="Enter the number days"
            type="number"
            formik
          />
        </VantageFormFieldsRow>
      </VantageFormContent>
      <VantageFormActions>
        <Button variant="contained" disabled>
          Back
        </Button>
        <VantageFormSubmit label="Search events" disableIsDirty />
        <VantageFormReset
          label="Reset form"
          color="inherit"
          onReset={onReset}
        />
      </VantageFormActions>
    </VantageForm>
  );
}
