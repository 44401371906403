import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {config} from '../../../../config';
import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';

interface AppShellContainerProps {
  disableGutters?: boolean;
  scrollable?: boolean;
  centered?: boolean;
}

export const AppShellContainer = styled(Stack, {
  shouldForwardProp: addShouldNotForwardProps(
    'disableGutters',
    'scrollable',
    'centered',
  ),
})<AppShellContainerProps>(({theme, disableGutters, centered = false}) => ({
  flex: 1,
  padding:
    disableGutters === true ? 0 : theme.spacing(config.theme.gutterSpacing),
  justifyContent: centered ? 'center' : 'flex-start',
}));
