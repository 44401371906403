import {memo, type ReactElement} from 'react';

import HistoryIcon from '@mui/icons-material/HistoryRounded';
import InboxIcon from '@mui/icons-material/InboxRounded';
import Divider from '@mui/material/Divider';

import {VantageTab} from '../../../common/components/tabs/VantageTab';
import {VantageTabs} from '../../../common/components/tabs/VantageTabs';

const eventsPaneTabs = ['Inbox', 'History'] as const;
export type EventsPaneTab = (typeof eventsPaneTabs)[number];

const EventsPaneTabIcon: {[key in EventsPaneTab]: ReactElement} = {
  Inbox: <InboxIcon />,
  History: <HistoryIcon />,
};

interface EventsPaneTabsProps {
  activeTab: EventsPaneTab;
  onTabChange: (newTab: EventsPaneTab) => void;
}

export const EventsPaneTabs = memo(function EventsPaneTabs(
  props: EventsPaneTabsProps,
) {
  return (
    <VantageTabs {...props} variant="fullWidth">
      <Divider sx={{my: 0.5}} orientation="vertical" flexItem />
      {eventsPaneTabs.map((tab) => (
        <VantageTab
          key={tab}
          value={tab}
          label={tab}
          size="default"
          icon={EventsPaneTabIcon[tab]}
          iconPosition="start"
        />
      ))}
    </VantageTabs>
  );
});
