import {useCallback, useEffect, useRef} from 'react';

interface UseCommandPromptListenersParams {
  onClose: () => void;
  isOpen: boolean;
}
export const useCommandPromptListeners = ({
  onClose,
  isOpen,
}: UseCommandPromptListenersParams) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        boxRef.current != null &&
        event.target instanceof Node &&
        !boxRef.current.contains(event.target)
      ) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleKeyDown, handleClickOutside]);

  return {inputRef, boxRef};
};
