import {create} from 'zustand';
import {persist} from 'zustand/middleware';

export type AuthStatus =
  | 'idle'
  | 'authenticated'
  | 'authenticating'
  | 'unauthenticated';

interface AuthStoreState {
  authStatus: AuthStatus;
  token: string | null;
  ssoToken: string | null;
}

interface AuthStoreActions {
  reset: () => void;
  setToken: (token: string | null) => void;
  setSsoToken: (ssoToken: string | null) => void;
  setAuthStatus: (authStatus: AuthStatus) => void;
}

const authStoreInitialState: AuthStoreState = {
  authStatus: 'idle',
  token: null,
  ssoToken: null,
};

export const useAuthStore = create<AuthStoreState & AuthStoreActions>()(
  persist(
    (setState) => ({
      ...authStoreInitialState,
      reset: () => {
        setState(authStoreInitialState);
      },
      setToken: (token) => {
        setState({token, ssoToken: null});
      },
      setSsoToken: (ssoToken) => {
        setState({ssoToken, token: null});
      },
      setAuthStatus: (authStatus) => {
        setState({authStatus});
      },
    }),
    {
      name: 'auth',
      partialize: (state) => ({
        token: state.token,
        ssoToken: state.ssoToken,
      }),
    },
  ),
);
