import DataObjectIcon from '@mui/icons-material/DataObjectRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const documentationHomeMenuItems: HomeMenuItemCard = {
  title: 'Documentation',
  icon: <DataObjectIcon color="action" />,
  menuItems: [
    {
      label: 'Client API Docs',
      path: routes.documentation.apiDocs.client,
      permissions: ['clientAPIDocs:view'],
    },
    {
      label: 'Developer API Docs',
      path: routes.documentation.apiDocs.developer,
      permissions: ['developerAPIDocs:view'],
    },
    {
      label: 'Functional Docs',
      path: routes.documentation.functional,
      permissions: ['functionalDocs:view'],
    },
    {
      label: 'Integration API Docs',
      path: routes.documentation.apiDocs.integration,
      permissions: ['integrationAPIDocs:view'],
    },
    {
      label: 'Tracking API Docs',
      path: routes.documentation.apiDocs.tracking,
      permissions: ['trackingAPIDocs:view'],
    },
  ],
};
