import Refresh from '@mui/icons-material/RefreshRounded';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {isObjectWithKeys} from '../modules/common/helpers/unknownValueTypeChecks';

export function ErrorBoundaryFallback({
  error,
  resetError,
}: {
  error: unknown;
  resetError: () => void;
}) {
  return (
    <Stack
      flex={1}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      bgcolor="background.default"
      color="text.primary"
    >
      <Stack>
        <Typography variant="h1">Sorry,</Typography>
        <Typography variant="h2">
          a{' '}
          {isObjectWithKeys(error, 'name') && typeof error.name === 'string'
            ? error.name
            : 'unknown'}{' '}
          error occurred
        </Typography>
      </Stack>
      {isObjectWithKeys(error, 'message') &&
        typeof error.message === 'string' && (
          <Stack my={4} p={4} bgcolor="background.paper" borderRadius="16px">
            <p>{error.message}</p>
          </Stack>
        )}
      <Button size="large" startIcon={<Refresh />} onClick={resetError}>
        Retry
      </Button>
    </Stack>
  );
}
