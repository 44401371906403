import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyTripDebriefConfigs = lazy(
  async () => import('./pages/TripDebriefConfigs'),
);
const LazyTripDebriefConfigAdd = lazy(
  async () => import('./pages/TripDebriefConfigAdd'),
);
const LazyTripDebriefConfig = lazy(
  async () => import('./pages/TripDebriefConfig'),
);

export const tripDebriefConfigRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.tripDebriefConfig.list)},
  {
    path: routes.getRelativePath(
      routes.tripDebriefConfig.list,
      routes.tripDebriefConfig.base,
    ),
    element: <LazyTripDebriefConfigs />,
    loader: () => {
      document.title = 'Trip Debrief Configs';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Trip Debrief Configs'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.tripDebriefConfig.add,
      routes.tripDebriefConfig.base,
    ),
    element: <LazyTripDebriefConfigAdd />,
    loader: () => {
      document.title = 'Add Trip Debrief Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Trip Debrief Configs', path: routes.tripDebriefConfig.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.tripDebriefConfig.masterTripDebriefConfigId,
      routes.tripDebriefConfig.base,
    ),
    element: <LazyTripDebriefConfig />,
    loader: () => {
      document.title = 'View Trip Debrief Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Trip Debrief Configs', path: routes.tripDebriefConfig.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
