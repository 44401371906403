import {useCallback} from 'react';

import DownloadIcon from '@mui/icons-material/DownloadRounded';

import {TableActionButtonBase} from './TableActionButtonBase';
import type {VantageTableProps} from '../VantageTable';

interface TableActionDownloadProps<TableData extends object>
  extends Pick<VantageTableProps<TableData>, 'data'> {
  onDownload: (data: TableData[]) => void;
}

export function TableActionDownload<TableData extends object>({
  onDownload,
  data,
}: TableActionDownloadProps<TableData>) {
  const handleDownload = useCallback(() => {
    onDownload(data);
  }, [data, onDownload]);

  return (
    <TableActionButtonBase
      onClick={handleDownload}
      data-cy="TableActionsDownload"
    >
      <DownloadIcon />
    </TableActionButtonBase>
  );
}
