import {Grid} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

import {addShouldNotForwardProps} from '../../../helpers/addShouldNotForwardProps';

export const VantageTableGridRootComponent = styled(Grid.Root, {
  shouldForwardProp: addShouldNotForwardProps('$background'),
})<{
  $background: string | undefined;
}>(({$background = undefined}) => ({
  flex: 1,
  flexGrow: 1,
  backgroundColor: $background,
}));
