import {type ChangeEvent, lazy, Suspense, useState} from 'react';
import {useAuth} from 'react-oidc-context';
import {useNavigate} from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack, {type StackProps} from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {debounce} from '@mui/material/utils';

import {NavigationLogout} from './NavigationLogout';
import {NavigationMenuItems} from './NavigationMenuItems';
import {NavigationSearch} from './NavigationSearch';
import {NavigationUser} from './NavigationUser';
import LogoSvg from '../../../../assets/onroadVantage.svg';
import {config} from '../../../../config';
import {getGlassBackground} from '../../../../theme/getGlassBackground';
import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';
import {useAuthStore} from '../../stores/authStore';
import {useNavigationStore} from '../../stores/navigationStore';

const LazyWhiteLabelLogo = lazy(async () => import('../WhiteLabelLogo'));

export function Navigation() {
  const navigate = useNavigate();
  const {isLoading} = useAuth();
  const navigationDrawerOpen = useNavigationStore(
    (state) => state.navigationDrawerOpen,
  );
  const toggleNavigationsDrawer = useNavigationStore(
    (state) => state.toggleNavigationsDrawer,
  );
  const authStatus = useAuthStore((state) => state.authStatus);
  const [filter, setFilter] = useState<null | string>(null);

  const toggleExpanded = () => {
    toggleNavigationsDrawer();
  };

  return (
    <Container>
      <Drawer $expanded={navigationDrawerOpen} id="Container">
        <CollapseButton
          variant="contained"
          color="secondary"
          $expanded={navigationDrawerOpen}
          onClick={toggleExpanded}
        >
          <ArrowBackIcon fontSize="inherit" color="inherit" />
        </CollapseButton>
        <Content>
          <Stack pt={0.5} px={0.5}>
            <ButtonBase
              onClick={() => {
                navigate('/');
              }}
              sx={{flexDirection: 'column', borderRadius: 100}}
            >
              <Suspense
                fallback={
                  <img
                    src={LogoSvg}
                    alt="Onroad Vantage"
                    style={{width: config.leftDrawerWidth - 30}}
                  />
                }
              >
                <LazyWhiteLabelLogo width={config.leftDrawerWidth - 30} />
              </Suspense>
            </ButtonBase>
            <Typography
              color="text.secondary"
              variant="caption"
              textAlign="center"
              ml={-1}
              sx={{cursor: 'default'}}
            >
              {config.version.frontendVersion} <strong>-</strong>{' '}
              {config.version.onroadvantageApiPackageVersion}
            </Typography>
          </Stack>
          <Stack flex={1} overflow="hidden">
            <NavigationSearch
              onChange={debounce((event: ChangeEvent<HTMLInputElement>) => {
                setFilter(event.target.value);
              }, 500)}
              disabled={authStatus === 'authenticating' || isLoading}
            />
            {authStatus === 'authenticating' || isLoading ? (
              <Stack flex={1}>
                <Skeleton variant="rectangular" height="100%" />
              </Stack>
            ) : (
              <NavigationMenuItems filter={filter} />
            )}
            <Stack bgcolor="background.default">
              <NavigationUser />
              <NavigationLogout />
            </Stack>
          </Stack>
        </Content>
      </Drawer>
    </Container>
  );
}

const CollapseButton = styled(Button, {
  shouldForwardProp: addShouldNotForwardProps('$expanded'),
})<{$expanded: boolean}>(({theme, $expanded}) => ({
  position: 'absolute',
  zIndex: theme.zIndex.speedDial,
  padding: theme.spacing(0.5),
  minWidth: 'unset',
  top: '35px',
  right: '0',
  borderRadius: '0 50% 50% 0',
  whiteSpace: 'nowrap',
  transform: $expanded ? 'translateX(100%)' : 'translateX(100%)',
  svg: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    transform: $expanded ? 'rotate(0)' : 'rotate(180deg)',
  },
}));

const Container = styled(Paper)(({theme}) => ({
  display: 'flex',
  position: 'relative',
  borderRadius: 0,
  zIndex: 503,
  // zIndex: theme.zIndex.drawer,
  ...getGlassBackground(theme),
}));

const Drawer = styled(Stack, {
  shouldForwardProp: addShouldNotForwardProps('$expanded'),
})<{$expanded: boolean} & StackProps>(({theme, $expanded}) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0 0 8px 0',
  flex: 1,
  overflow: 'hidden',
  height: '100%',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: $expanded ? `${config.leftDrawerWidth}px` : 0,
}));

const Content = styled(Stack)(({theme}) => ({
  height: '100%',
  flex: 1,
  whiteSpace: 'nowrap',
  gap: theme.spacing(0.5),
}));
