import {useEffect, useState} from 'react';

import NotificationsActive from '@mui/icons-material/NotificationsActive';
import NotificationsNone from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import {keyframes, styled} from '@mui/material/styles';

import {useLoadEventsCounts} from '../../hooks/useLoadEventsCounts';
import {useEventStore} from '../../stores/eventStore';

export function TopBarEvents() {
  useLoadEventsCounts();
  const eventsCount = useEventStore((state) => state.eventsCount);
  const toggleEventsDrawer = useEventStore((state) => state.toggleEventsDrawer);
  const [showJiggleAnimation, setShowJiggleAnimation] = useState(false);

  useEffect(
    () =>
      useEventStore.subscribe((state, prevState) => {
        if (
          state.eventsCount.critical !== prevState.eventsCount.critical ||
          state.eventsCount.operational !== prevState.eventsCount.operational
        ) {
          setShowJiggleAnimation(true);
          setTimeout(() => {
            setShowJiggleAnimation(false);
          }, 4000);
        }
      }),
    [],
  );

  return (
    <JigglingElement
      size="small"
      color="default"
      onClick={toggleEventsDrawer}
      className={showJiggleAnimation ? 'jiggle' : undefined}
    >
      <EventBadge
        badgeContent={eventsCount.critical + eventsCount.operational}
        color="error"
      >
        {eventsCount.critical > 0 || eventsCount.operational > 0 ? (
          <NotificationsActive
            color="inherit"
            fontSize="inherit"
            sx={{fontSize: '1.6rem'}}
          />
        ) : (
          <NotificationsNone
            color="inherit"
            fontSize="inherit"
            sx={{fontSize: '1.6rem'}}
          />
        )}
      </EventBadge>
    </JigglingElement>
  );
}

const jiggleAnimation = keyframes`
  0%, 25%, 75%, 100% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  35%, 65% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
`;
const JigglingElement = styled(IconButton)({
  '&.jiggle': {
    animation: `${jiggleAnimation} 1s ease-in-out infinite`,
  },
});

const EventBadge = styled(Badge)({'& .MuiBadge-badge': {lineHeight: '20px'}});
