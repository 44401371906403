import {type ProfileTheme} from '@onroadvantage/onroadvantage-api';
import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';

import {config} from '../../../config';
import {getMetaTagContent} from '../helpers/getMetaTag';

const {VITE_APP_WHITE_LABELLED_THEME} = import.meta.env;

const isWhiteLabelledObject = (value: unknown): value is ProfileTheme => {
  if (value == null || typeof value !== 'object') {
    return false;
  }
  let hasIncorrectType = false;

  if (
    'logoLargeUrl' in value &&
    typeof value.logoLargeUrl !== 'string' &&
    value.logoLargeUrl != null
  ) {
    hasIncorrectType = true;
  }
  if (
    'logoSmallUrl' in value &&
    typeof value.logoSmallUrl !== 'string' &&
    value.logoSmallUrl != null
  ) {
    hasIncorrectType = true;
  }
  if (
    'primaryContrastColour' in value &&
    typeof value.primaryContrastColour !== 'string' &&
    value.primaryContrastColour != null
  ) {
    hasIncorrectType = true;
  }
  if (
    'secondaryColour' in value &&
    typeof value.secondaryColour !== 'string' &&
    value.secondaryColour != null
  ) {
    hasIncorrectType = true;
  }
  if (
    'primaryColour' in value &&
    typeof value.primaryColour !== 'string' &&
    value.primaryColour != null
  ) {
    hasIncorrectType = true;
  }
  if (
    'secondaryContrastColour' in value &&
    typeof value.secondaryContrastColour !== 'string' &&
    value.secondaryContrastColour != null
  ) {
    hasIncorrectType = true;
  }

  return !hasIncorrectType;
};

interface ThemeStoreState {
  themeMode: 'dark' | 'light';
  whiteLabelledThemeOptions: ProfileTheme | null | undefined;
}

interface ThemeStoreActions {
  toggleThemeMode: () => void;
  setWhiteLabelledThemeOptions: (
    profileThemeResponse: ProfileTheme | null | undefined,
  ) => void;
}

const themeStoreInitialState: ThemeStoreState = {
  themeMode: 'light',
  whiteLabelledThemeOptions: null,
};

const storageHandler = (key: string, value: unknown) => {
  switch (key) {
    case 'whiteLabelledThemeOptions': {
      try {
        const whiteLabelledTheme = getMetaTagContent(
          'app:white-labelled-theme',
          VITE_APP_WHITE_LABELLED_THEME,
        );
        const parsedWhiteLabelledTheme = JSON.parse(whiteLabelledTheme);
        if (isWhiteLabelledObject(parsedWhiteLabelledTheme)) {
          return {...(value ?? {}), ...parsedWhiteLabelledTheme};
        }
        return value;
      } catch {
        return value;
      }
    }
    case 'themeMode': {
      if (value === 'dark') {
        document.body.style.backgroundImage = 'url("/backgroundDark.svg")';
      } else {
        document.body.style.backgroundImage = 'url("/backgroundLight.svg")';
      }
      return value;
    }
    default:
      return value;
  }
};

export const useThemeStore = create<ThemeStoreState & ThemeStoreActions>()(
  persist(
    (set) => ({
      ...themeStoreInitialState,
      toggleThemeMode: () => {
        set((currentState) => {
          return {
            themeMode: currentState.themeMode === 'light' ? 'dark' : 'light',
          };
        });
      },
      setWhiteLabelledThemeOptions: (profileTheme) => {
        if (profileTheme == null) {
          return;
        }
        const {primaryColour, secondaryColour, ...otherThemeOptions} =
          profileTheme;
        const whiteLabelledThemeOptions: ProfileTheme = otherThemeOptions;
        if (primaryColour !== config.theme.primaryDefault) {
          whiteLabelledThemeOptions.primaryColour = primaryColour;
        }
        if (secondaryColour !== config.theme.secondaryDefault) {
          whiteLabelledThemeOptions.secondaryColour = secondaryColour;
        }

        set({
          whiteLabelledThemeOptions:
            Object.keys(whiteLabelledThemeOptions).filter(
              (key) =>
                whiteLabelledThemeOptions[key as keyof ProfileTheme] != null,
            ).length > 0
              ? whiteLabelledThemeOptions
              : null,
        });
      },
    }),
    {
      name: 'theme',
      storage: createJSONStorage(() => localStorage, {
        reviver: storageHandler,
        replacer: storageHandler,
      }),
      partialize: (state) => ({
        themeMode: state.themeMode,
        whiteLabelledThemeOptions: state.whiteLabelledThemeOptions,
      }),
    },
  ),
);
