import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import Button from '@mui/material/Button';

import {
  ActionsTypeProviderEditorHeader,
  type ActionsTypeProviderEditorHeaderProps,
} from './ActionsTypeProviderEditorHeader';
import {
  ActionsTypeProviderEditorRow,
  type ActionsTypeProviderEditorRowProps,
} from './ActionsTypeProviderEditorRow';

export type ActionsTypeProviderEditorProps<TableData extends object> =
  ActionsTypeProviderEditorHeaderProps<TableData> &
    ActionsTypeProviderEditorRowProps<TableData>;

export function ActionsTypeProviderEditor<TableData extends object>({
  row,
  config,
  setAddedRows,
  data,
  actions,
  rowId,
  editingRowIds,
  setEditingRowIds,
  onInlineEdit,
  onInlineAdd,
  setRowChanges,
  rowChanges,
  addedRows,
  ...props
}: DataTypeProvider.ValueEditorProps &
  ActionsTypeProviderEditorProps<TableData>) {
  if (row == null) {
    return (
      <ActionsTypeProviderEditorHeader
        config={config}
        setAddedRows={setAddedRows}
        editingRowIds={editingRowIds}
        setEditingRowIds={setEditingRowIds}
        rowChanges={rowChanges}
        setRowChanges={setRowChanges}
        rowId={rowId}
        data={data}
        actions={actions}
      />
    );
  }

  if (actions.onInlineEdit != null) {
    return (
      <Button
        size="small"
        variant="outlined"
        color="inherit"
        onClick={() => {
          setEditingRowIds((prev) => prev.filter((r) => r !== row[rowId]));
          setRowChanges((prev) =>
            prev == null
              ? undefined
              : Object.fromEntries(
                  Object.entries(prev).filter(([key]) => key !== row[rowId]),
                ),
          );
        }}
      >
        Cancel
      </Button>
    );
  }

  return (
    <ActionsTypeProviderEditorRow
      {...props}
      row={row}
      rowId={rowId}
      setEditingRowIds={setEditingRowIds}
      setAddedRows={setAddedRows}
      onInlineAdd={onInlineAdd}
      onInlineEdit={onInlineEdit}
      setRowChanges={setRowChanges}
      rowChanges={rowChanges}
      addedRows={addedRows}
    />
  );
}
