import {TableFixedColumns} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

import {addShouldNotForwardProps} from '../../../helpers/addShouldNotForwardProps';

export const VantageTableFixedCellComponent = styled(TableFixedColumns.Cell, {
  shouldForwardProp: addShouldNotForwardProps('$background'),
})<{
  $background: string | undefined;
}>(({theme, $background = undefined}) => ({
  background: $background != null ? `${$background} !important` : undefined,
  paddingLeft: `${theme.spacing(1)} !important`,
}));
