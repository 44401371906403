import {purchaseOrderApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadPurchaseOrders = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: Pick<
  UseLoadParams,
  | 'getFilter'
  | 'updatePagination'
  | 'loadRequestPagination'
  | 'loadRequestSorting'
>) => {
  return useLoadList(
    async () =>
      purchaseOrderApi.apiPurchaseOrderGet({
        ...loadRequestPagination,
        ...loadRequestSorting,

        customerName: getFilter('customer'),
        contractName: getFilter('contract'),
        purchaseOrderNumber: getFilter('purchaseOrderNumber'),
        purchaseOrderType: getFilter('purchaseOrderType'),
        referenceNumber: getFilter('referenceNumber'),
        orderNumber: getFilter('orders'),
      }),
    updatePagination,
    [getFilter, loadRequestPagination, loadRequestSorting],
  );
};
