import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';

import {Loader} from './Loader';
import {
  getGlassBackground,
  type GlassMorphismOptions,
} from '../../../../theme/getGlassBackground';
import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';

interface OverlayLoaderProps {
  loading?: boolean;
  label?: string;
  size?: number;
  glassMorphismOptions?: GlassMorphismOptions;
}

export function OverlayLoader({
  loading = true,
  label,
  size,
  glassMorphismOptions,
}: OverlayLoaderProps) {
  if (loading) {
    return (
      <LoadingOverlay
        $glassMorphismOptions={glassMorphismOptions}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Loader label={label} size={size} />
      </LoadingOverlay>
    );
  }
  return null;
}

export const LoadingOverlay = styled(Box, {
  shouldForwardProp: addShouldNotForwardProps('$glassMorphismOptions'),
})<{$glassMorphismOptions: GlassMorphismOptions | undefined}>(
  ({theme, $glassMorphismOptions}) => ({
    position: 'absolute',
    inset: 0,
    zIndex: 501,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
    flex: 1,
    flexGrow: 1,
    ...getGlassBackground(theme, $glassMorphismOptions),
  }),
);
