import {type ReactNode} from 'react';

import IconButton, {type IconButtonProps} from '@mui/material/IconButton';

interface TableActionButtonBaseProps extends IconButtonProps {
  children: ReactNode;
  loading?: boolean;
}

export function TableActionButtonBase({
  disabled,
  onAuxClick,
  onClick,
  children,
  loading,
  ...otherProps
}: TableActionButtonBaseProps) {
  return (
    <IconButton
      {...otherProps}
      size="small"
      disabled={loading === true || disabled}
      onAuxClick={onAuxClick}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
}
