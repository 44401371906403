import {useCallback} from 'react';

import {DataTypeProvider} from '@devexpress/dx-react-grid';

import {
  ActionsTypeProviderEditor,
  type ActionsTypeProviderEditorProps,
} from './ActionsTypeProviderEditor';
import {
  ActionsTypeProviderFormatter,
  type ActionsTypeProviderFormatterProps,
} from './ActionsTypeProviderFormatter';

export default function ActionsTypeProvider<TableData extends object>({
  expandedRowIds,
  editingRowIds,
  setExpandedRowIds,
  setEditingRowIds,
  setAddedRows,
  config,
  data,
  rowId,
  rowDetail,
  actions,
  rowActions,
  onInlineEdit,
  onInlineAdd,
  setRowChanges,
  rowChanges,
  addedRows,
}: ActionsTypeProviderEditorProps<TableData> &
  ActionsTypeProviderFormatterProps<TableData>) {
  const renderEditor = useCallback(
    (props: DataTypeProvider.ValueEditorProps) => (
      <ActionsTypeProviderEditor<TableData>
        {...props}
        config={config}
        setAddedRows={setAddedRows}
        data={data}
        actions={actions}
        rowId={rowId}
        editingRowIds={editingRowIds}
        setEditingRowIds={setEditingRowIds}
        onInlineEdit={onInlineEdit}
        onInlineAdd={onInlineAdd}
        setRowChanges={setRowChanges}
        rowChanges={rowChanges}
        addedRows={addedRows}
      />
    ),
    [
      actions,
      addedRows,
      config,
      data,
      editingRowIds,
      onInlineAdd,
      onInlineEdit,
      rowChanges,
      rowId,
      setAddedRows,
      setEditingRowIds,
      setRowChanges,
    ],
  );

  const renderFormatter = useCallback(
    (props: DataTypeProvider.ValueFormatterProps) => (
      <ActionsTypeProviderFormatter<TableData>
        {...props}
        config={config}
        rowId={rowId}
        rowActions={rowActions}
        rowDetail={rowDetail}
        expandedRowIds={expandedRowIds}
        setExpandedRowIds={setExpandedRowIds}
        setEditingRowIds={setEditingRowIds}
      />
    ),
    [
      config,
      expandedRowIds,
      rowActions,
      rowDetail,
      rowId,
      setEditingRowIds,
      setExpandedRowIds,
    ],
  );

  return (
    <DataTypeProvider
      for={['actions']}
      editorComponent={renderEditor}
      formatterComponent={renderFormatter}
    />
  );
}
