import {type HTMLAttributes, useMemo} from 'react';

import Stack from '@mui/material/Stack';
import {styled, useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import type {ApiReasonCodeGetRequest} from '@onroadvantage/onroadvantage-api';

import {type Activity, ActivityCard} from './ActivityCard';
import {ActivityCommentField} from './ActivityCommentField';
import {ActivityReasonCodeMenuOptions} from './ActivityReasonCodeMenuOptions';
import {getNestedBorderRadius} from '../../../theme/getNestedBorderRadius';
import {
  type OnFormSubmit,
  VantageForm,
} from '../../common/components/form/VantageForm';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {addShouldNotForwardProps} from '../../common/helpers/addShouldNotForwardProps';
import {capitalizeFirstLetter} from '../../common/helpers/capitalizeFirstLetter';

export interface ActivityFormValues {
  comment: string | null | undefined;
  reasonCodeId?: number | null | undefined;
}

export interface ActivitiesProps extends HTMLAttributes<HTMLDivElement> {
  activities: Activity[];
  onSubmitComment: OnFormSubmit<ActivityFormValues>;
  reasonCodeRequestParameters?: ApiReasonCodeGetRequest;
  borderRadius?: true | 'bottom' | 'top';
  commentPlaceholder?: string;
  actionsDisabled?: boolean;
  maxHeight?: number | string;
  activityMaxWidth?: number;
}

export function Activities({
  activities,
  actionsDisabled = false,
  reasonCodeRequestParameters,
  commentPlaceholder = 'Enter your comments here',
  borderRadius = 'bottom',
  onSubmitComment,
  maxHeight = 400,
  activityMaxWidth,
  ...divProps
}: ActivitiesProps) {
  const theme = useTheme();
  /** Reverse the sorting, so that we can reverse the flex direction, which will allow the scrolling to start at the bottom */
  const sortedActivities = useMemo(
    () =>
      activities.sort((a, b) =>
        a.createdAt != null && b.createdAt != null
          ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          : 0,
      ),
    [activities],
  );
  return (
    <Container $borderRadius={borderRadius} {...divProps}>
      {sortedActivities.length > 0 ? (
        <ScrollContainer $maxHeight={maxHeight}>
          {sortedActivities.map((activity) => (
            <ActivityCard
              activity={activity}
              key={activity.id}
              maxWidth={activityMaxWidth}
            />
          ))}
        </ScrollContainer>
      ) : (
        <NoActivitiesContainer>
          <Typography>No activity</Typography>
        </NoActivitiesContainer>
      )}
      <VantageForm<ActivityFormValues>
        initialValues={{comment: ''}}
        onSubmit={onSubmitComment}
      >
        <ActivityCommentField
          actionsDisabled={actionsDisabled}
          commentPlaceholder={commentPlaceholder}
          enableSubmitButton={reasonCodeRequestParameters == null}
        />
        {reasonCodeRequestParameters != null && (
          <Stack direction="row" spacing={1} mt={1}>
            <VantageFormSubmit
              label="Send"
              fullWidth
              style={{borderRadius: getNestedBorderRadius(theme, 0.5)}}
            />
            <ActivityReasonCodeMenuOptions
              requestParameters={reasonCodeRequestParameters}
            />
          </Stack>
        )}
      </VantageForm>
    </Container>
  );
}

const NoActivitiesContainer = styled(Stack)(({theme}) => ({
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: getNestedBorderRadius(theme, 0.5),
}));

const Container = styled('div', {
  shouldForwardProp: addShouldNotForwardProps('$borderRadius'),
})<{$borderRadius: true | 'bottom' | 'top'}>(({theme, $borderRadius}) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.default,

  flexDirection: 'column',
  padding: theme.spacing(1, 0.5, 0.5),
  gap: theme.spacing(1),
  ...($borderRadius === true
    ? {borderRadius: theme.shape.borderRadius}
    : {
        [`border${capitalizeFirstLetter($borderRadius)}LeftRadius`]:
          theme.shape.borderRadius,
        [`border${capitalizeFirstLetter($borderRadius)}RightRadius`]:
          theme.shape.borderRadius,
      }),
  border: `1px solid ${theme.palette.divider}`,
  margin: theme.spacing(-0.5, 1, 0),
}));

const ScrollContainer = styled('div', {
  shouldForwardProp: addShouldNotForwardProps('$maxHeight'),
})<{$maxHeight: string | number}>(({theme, $maxHeight}) => ({
  display: 'flex',
  maxHeight: typeof $maxHeight === 'number' ? `${$maxHeight}px` : $maxHeight,
  flexDirection: 'column-reverse',
  overflowY: 'auto',
  padding: theme.spacing(1, 0.5),
  gap: theme.spacing(1),
  scrollbarWidth: 'none',
  marginTop: 'auto',
}));
