import RssFeedIcon from '@mui/icons-material/RssFeedRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const broadcastingHomeMenuItems: HomeMenuItemCard = {
  title: 'Broadcasting',
  icon: <RssFeedIcon color="action" />,
  menuItems: [
    {
      label: 'Single Message Broadcast',
      path: routes.broadcast.singleMessage,
      permissions: ['broadcast:view'],
    },
    {
      label: 'Vantage Mobile Broadcast',
      path: routes.broadcast.vantageMobile,
      permissions: ['broadcast:view'],
    },
    {
      label: 'Broadcasts',
      path: routes.broadcast.list,
      permissions: ['broadcast:list'],
    },
  ],
};
