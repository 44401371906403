import {useMemo} from 'react';
import {useAuth} from 'react-oidc-context';
import {useLocation, useNavigate} from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {MenuItemButton} from './MenuItemButton';
import {config} from '../../../../config';
import {routes} from '../../../../routes';
import {useAuthStore} from '../../stores/authStore';
import {useUserStore} from '../../stores/userStore';

export function NavigationUser() {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {isLoading} = useAuth();
  const authStatus = useAuthStore((state) => state.authStatus);
  const email = useUserStore((state) => state.email);
  const roles = useUserStore((state) => state.roles);
  const roleNames = useMemo(
    () => roles.map((role) => role.name).join(', '),
    [roles],
  );
  return (
    <Tooltip
      disableHoverListener={authStatus === 'authenticating' || isLoading}
      title={
        <Stack spacing={0.5} divider={<Divider flexItem />}>
          <Typography>{email}</Typography>

          <Typography variant="body2">
            <strong>Roles:</strong> {roleNames}
          </Typography>
        </Stack>
      }
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: 'background.default',
            color: 'text.primary',
            p: 1,
            maxWidth: config.leftDrawerWidth - 25,
          },
        },
        arrow: {sx: {color: 'background.default'}},
      }}
      placement="top"
      arrow
    >
      <MenuItemButton
        $level={1}
        $isActive={pathname === routes.userSettings}
        onClick={() => {
          if (authStatus !== 'authenticating') {
            navigate(routes.userSettings);
          }
        }}
      >
        <AccountCircleIcon />
        {authStatus === 'authenticating' || isLoading ? (
          <Skeleton height={30} width="100%" />
        ) : (
          <Typography
            fontWeight="bold"
            color="action"
            flex={1}
            noWrap
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {email ?? '-'}
          </Typography>
        )}
      </MenuItemButton>
    </Tooltip>
  );
}
