import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {PageLoader} from '../../common/components/loader/PageLoader';
import {VantageTab} from '../../common/components/tabs/VantageTab';
import {VantageTabs} from '../../common/components/tabs/VantageTabs';
import {VantageCard} from '../../common/components/VantageCard';
import {useDocumentTitle} from '../../common/hooks/useDocumentTitle';
import {useTabs} from '../../common/hooks/useTabs';
import {OrderDetails} from '../components/OrderDetails';
import {OrderLines} from '../components/OrderLines';
import {useLoadOrder} from '../hooks/useLoadOrder';

const orderTabs = ['Details', 'Lines'] as const;
type OrderTab = (typeof orderTabs)[number];

export default function Order() {
  const params = useParams();

  if (params.orderId == null) {
    throw new Error('Order ID not found');
  }

  const {loadStatus, updateState, reload, id, item} = useLoadOrder(
    parseInt(params.orderId, 10),
  );
  useDocumentTitle(
    item?.orderNumber != null ? `Order | ${item.orderNumber}` : null,
  );

  const tabs = useTabs<OrderTab>('Details');
  const {activeTab} = tabs;

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case 'Details':
        return <OrderDetails id={id} item={item} updateState={updateState} />;
      case 'Lines':
        if (item?.lines == null) {
          return null;
        }
        return <OrderLines id={id} item={item} reload={reload} />;
      default:
        return null;
    }
  }, [activeTab, id, item, reload, updateState]);

  if (loadStatus === 'idle') {
    return <PageLoader />;
  }

  if (loadStatus === 'loading') {
    return <PageLoader label="Loading Orders..." />;
  }

  return (
    <VantageCard scrollable>
      <OverlayLoader
        loading={loadStatus === 'reloading'}
        label="Reloading Orders..."
      />
      <VantageTabs sx={{zIndex: 2}} {...tabs}>
        {orderTabs.map((tab) => (
          <VantageTab key={tab} label={tab} value={tab} />
        ))}
      </VantageTabs>
      {renderActiveTab}
    </VantageCard>
  );
}
