import {useState} from 'react';
import {toast} from 'react-toastify';

import {criticalEventApi, operationalEventApi} from '../../../api';
import {type EventActivityParams} from '../types/EventActivityParams';

type AssignToMeStatus = 'idle' | 'assigning' | 'success' | 'error';

export const useAssignEventToMe = ({
  updateEventState,
  eventId,
  model,
}: EventActivityParams) => {
  const [assignToMeStatus, setAssignToMeStatus] =
    useState<AssignToMeStatus>('idle');

  const assignEventToMe = async () => {
    setAssignToMeStatus('assigning');
    try {
      if (model === 'OperationalEvent') {
        const response =
          await operationalEventApi.apiOperationalEventOperationalEventIdPost({
            operationalEventId: eventId,
            body: {
              activities: [
                {action: 'AssignmentChange', value: 'Assigned to Me'},
              ],
            },
          });

        updateEventState(response, 'OperationalEvent');
      } else {
        const response =
          await criticalEventApi.apiCriticalEventCriticalEventIdPost({
            criticalEventId: eventId,
            body: {
              activities: [
                {action: 'AssignmentChange', value: 'Assigned to Me'},
              ],
            },
          });
        updateEventState(response, 'CriticalEvent');
      }
      setAssignToMeStatus('success');
    } catch {
      setAssignToMeStatus('error');
      toast.error('Failed to assign event');
    }
  };

  return {assignEventToMe, assignToMeStatus};
};
