import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

interface EventsPanePaginationProps {
  currentPage: number;
  pagesCount: number;
  onCurrentPageChange: (page: number) => Promise<void>;
}

export function EventsPanePagination({
  currentPage,
  onCurrentPageChange,
  pagesCount,
}: EventsPanePaginationProps) {
  if (pagesCount <= 1) {
    return null;
  }

  const handleChange = async (_event: unknown, value: number) => {
    await onCurrentPageChange(value);
  };

  return (
    <Stack direction="row" justifyContent="center" pt={0.5} px={1}>
      <Pagination
        count={pagesCount}
        boundaryCount={1}
        siblingCount={0}
        page={currentPage}
        onChange={handleChange}
      />
    </Stack>
  );
}
