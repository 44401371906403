import {useFormikContext} from 'formik';

import {
  isArrayOfStrings,
  isObject,
} from '../../../helpers/unknownValueTypeChecks';

export const useFieldError = (name: string) => {
  const {getFieldMeta, submitCount} = useFormikContext();
  const {error, touched} = getFieldMeta(name);
  if (isObject(error)) {
    const errors = Object.values(error);
    return errors != null &&
      isArrayOfStrings(errors) &&
      (touched || submitCount > 0)
      ? errors[0]
      : null;
  }
  return error != null && (touched || submitCount > 0) ? error : null;
};
