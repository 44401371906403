import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {EventsPaneEvents} from './EventsPaneEvents';
import {EventsPanePagination} from './EventsPanePagination';
import {EventsPaneProgress} from './EventsPaneProgress';
import {EventsPaneSearch} from './EventsPaneSearch';
import {EventsPaneSearchButtons} from './EventsPaneSearchButtons';
import {EventsPaneTabs} from './EventsPaneTabs';
import {useSearchEvents} from '../../hooks/useSearchEvents';

export function EventsPane() {
  const {
    activeTab,
    onTabChange,
    currentPage,
    pagesCount,
    onCurrentPageChange,
    events,
    loadEventsStatus,
    updateEventState,
    onAdvancedSearch,
    onClearSearchValues,
    onSearch,
    onSearchVehicleHistory,
    progress,
    expandedSearchForm,
    setExpandedSearchForm,
    searchFormInnerRef,
    advancedFormInnerRef,
    reloadEvents,
  } = useSearchEvents();

  const isLoading =
    loadEventsStatus === 'searching' ||
    loadEventsStatus === 'loading' ||
    loadEventsStatus === 'idle';

  return (
    <Container>
      <EventsPaneTabs activeTab={activeTab} onTabChange={onTabChange} />
      <EventsPaneEvents
        isLoading={isLoading}
        events={events}
        activeTab={activeTab}
        updateEventState={updateEventState}
        onSearchVehicleHistory={onSearchVehicleHistory}
        reloadEvents={reloadEvents}
      />
      <EventsPanePagination
        currentPage={currentPage}
        pagesCount={pagesCount}
        onCurrentPageChange={onCurrentPageChange}
      />
      <EventsPaneSearchButtons
        activeTab={activeTab}
        expandedSearchForm={expandedSearchForm}
        setExpandedSearchForm={setExpandedSearchForm}
      />
      <EventsPaneSearch
        expandedSearchForm={expandedSearchForm}
        onClearSearch={onClearSearchValues}
        advancedFormInnerRef={advancedFormInnerRef}
        onAdvancedSearch={onAdvancedSearch}
        onSearch={onSearch}
        searchFormInnerRef={searchFormInnerRef}
      />
      <EventsPaneProgress activeTab={activeTab} progress={progress} />
    </Container>
  );
}

const Container = styled(Stack)({
  position: 'relative',
  flexGrow: 1,
  minHeight: 0,
});
