import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import Site from './pages/Site';
import Sites from './pages/Sites';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazySiteAdd = lazy(async () => import('./pages/SiteAdd'));

export const siteRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.site.list)},
  {
    path: routes.getRelativePath(routes.site.list, routes.site.base),
    element: <Sites />,
    loader: () => {
      document.title = 'Sites';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Sites'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.site.add, routes.site.base),
    element: <LazySiteAdd />,
    loader: () => {
      document.title = 'Add Site';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Sites', path: routes.site.list}, {label: 'Add'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.site.nodeId, routes.site.base),
    element: <Site />,
    loader: () => {
      document.title = 'View Site';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Sites', path: routes.site.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
