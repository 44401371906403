import type {
  CriticalEventDump,
  OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';

import {criticalEventApi, operationalEventApi} from '../../../api';
import {Warning} from '../../common/classes/Warning';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type ActivityFormValues} from '../../shared/activity/Activities';
import {type EventActivityParams} from '../types/EventActivityParams';

export const useAddCommentToEvent = ({
  updateEventState,
  eventId,
  model,
}: EventActivityParams) => {
  return useSubmitForm<ActivityFormValues>(
    async ({comment, reasonCodeId}) => {
      if (comment == null || comment === '') {
        throw new Warning('Comment or Reason Code is required');
      }

      let response: CriticalEventDump | OperationalEventDump;

      if (model === 'OperationalEvent') {
        response =
          await operationalEventApi.apiOperationalEventOperationalEventIdPost({
            body: {
              activities: [
                {
                  action: 'Comment',
                  value: comment,
                  contractOperationalEventTypeReasonCodeId:
                    reasonCodeId ?? undefined,
                },
              ],
            },
            operationalEventId: eventId,
          });
      } else {
        response = await criticalEventApi.apiCriticalEventCriticalEventIdPost({
          body: {
            activities: [
              {
                action: 'Comment',
                value: comment,
                contractCriticalEventTypeReasonCodeId:
                  reasonCodeId ?? undefined,
              },
            ],
          },
          criticalEventId: eventId,
        });
      }
      updateEventState(response, model);
    },
    [],
    {resetFormBehaviour: 'always'},
  );
};
