import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const sysAdminMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Celery Status',
    pathname: routes.celeryStatus,
    permission: 'superadmin',
  },
  {
    level: 2,
    label: 'Permissions',
    pathname: routes.permission.list,
    permission: 'superadmin',
  },
  {
    level: 2,
    label: 'Profiles',
    pathname: routes.profile.list,
    permission: 'superadmin',
  },
  {
    level: 2,
    label: 'Telematics Configs',
    pathname: routes.telematicsConfig.list,
    permission: 'superadmin',
  },
];
