import {type ReactElement} from 'react';

import {
  type CriticalEvent,
  type CriticalEventDump,
  type OperationalEventDump,
} from '@onroadvantage/onroadvantage-api';
import {create} from 'zustand';

import themeList, {type MapTheme} from '../components/map/mapThemes/themeList';
import {type EventModel} from '../types/EventModel';

export const DEFAULT_BASE_LAYER = 'Streets';

export interface OpenSiteDetailsFormDetails {
  lat: number;
  lng: number;
  label: string;
}

export type Event = CriticalEvent | CriticalEventDump | OperationalEventDump;
export type MapEvent = (CriticalEventDump | OperationalEventDump) & {
  model: EventModel;
};

export type BaseLayer = 'Streets' | 'Hybrid';

interface MapDisplayStoreState {
  openEvent: MapEvent | null;
  // openAddress: OpenAddress | null;
  openStop: unknown | null;
  openActualStop: unknown | null;
  openIsochrone: ReactElement | null;
  // geoSelectionItems: MapSelectionItem[] | null;
  siteDetailsFormDetails: OpenSiteDetailsFormDetails | null;
  baseLayer: BaseLayer;
  mapTheme: MapTheme;
}

interface MapDisplayStoreActions {
  reset: () => void;
  resetPeripherals: () => void;
  clearOpenEvent: () => void;
  getTheme: (themeName: BaseLayer) => MapTheme;
  setOpenEvent: (
    event: MapDisplayStoreState['openEvent'],
    setBehaviour?: 'always' | 'toggle',
  ) => void;
  // setOpenAddress: (address: MapDisplayStoreState['openAddress']) => void;
  setOpenStop: (stop: MapDisplayStoreState['openStop']) => void;
  setOpenActualStop: (
    actualStop: MapDisplayStoreState['openActualStop'],
  ) => void;
  setOpenIsochrone: (isochrone: MapDisplayStoreState['openIsochrone']) => void;
  // setGeoSelectionItems: (
  //   geoSelectionItems: MapDisplayStoreState['geoSelectionItems'],
  // ) => void;
  setSiteDetailsFormDetails: (
    siteDetailsFormDetails: MapDisplayStoreState['siteDetailsFormDetails'],
  ) => void;
  setBaseLayer: (baseLayer: BaseLayer) => void;
  clearBaseLayer: () => void;
}

const mapDisplayStoreInitialState: MapDisplayStoreState = {
  siteDetailsFormDetails: null,
  // openAddress: null,
  // openEvent: {
  //   mapType: 'Critical',
  //   latitude: -25.794010953168502,
  //   longitude: 28.274542457165396,
  //   id: 1,
  //   description: 'Critical Event',
  //   driver: undefined,
  //   createdAt: new Date(),
  //   eventDate: new Date(),
  //   // externalDriverId: 1,
  //   // externalVehicleId: 1,
  //   externalEventGroupId: 1,
  //   externalReceivedDate: new Date(),
  //   // externalId: 1,
  //   // externalGpsId: 1,
  //   // odometer: '1',
  //   status: '1',
  //   // vehicleId: 1,
  //   velocity: 1,
  // },
  openEvent: null,
  openStop: null,
  openActualStop: null,
  openIsochrone: null,
  // geoSelectionItems: null,
  baseLayer: DEFAULT_BASE_LAYER,
  mapTheme: themeList[DEFAULT_BASE_LAYER],
};

export const useMapDisplayStore = create<
  MapDisplayStoreState & MapDisplayStoreActions
>((setState, getState) => ({
  ...mapDisplayStoreInitialState,
  reset: () => {
    setState(mapDisplayStoreInitialState);
  },
  resetPeripherals: () => {
    const {openEvent, openIsochrone} = getState();
    if (
      openEvent !== mapDisplayStoreInitialState.openEvent ||
      openIsochrone !== mapDisplayStoreInitialState.openIsochrone
    ) {
      setState(mapDisplayStoreInitialState);
    }
  },
  getTheme: (themeName) => {
    return themeList[themeName];
  },
  setOpenEvent: (event, behaviour = 'always') => {
    setState((state) => ({
      openEvent:
        behaviour === 'toggle' && state.openEvent?.id === event?.id
          ? null
          : event,
    }));
  },
  clearOpenEvent: () => {
    setState({openEvent: null});
  },
  // setOpenAddress: (address) => {
  //   setState({openAddress: address});
  // },
  setOpenStop: (stop) => {
    setState({openStop: stop});
  },
  setOpenActualStop: (actualStop) => {
    setState({openActualStop: actualStop});
  },
  setOpenIsochrone: (isochrone) => {
    setState({openIsochrone: isochrone});
  },
  // setGeoSelectionItems: (geoSelectionItems) => {
  //   setState({geoSelectionItems});
  // },
  setBaseLayer: (baseLayer) => {
    setState({baseLayer, mapTheme: themeList[baseLayer]});
  },
  setSiteDetailsFormDetails: (siteDetailsFormDetails) => {
    setState({siteDetailsFormDetails});
  },
  clearBaseLayer: () => {
    setState({
      baseLayer: DEFAULT_BASE_LAYER,
      mapTheme: themeList[DEFAULT_BASE_LAYER],
    });
  },
}));
