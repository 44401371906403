import {nodeApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export interface LoadListProps extends UseLoadParams {
  nodeId: number;
}

export const useLoadSitePreSites = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
  nodeId,
}: LoadListProps) => {
  const {data, loadDataStatus, reloadData, setData} = useLoadList(
    async () =>
      nodeApi.apiNodeMainNodeIdAssociateNodeGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        mainNodeId: nodeId,
        isPostNode: false,
        taskTemplateNodeType: getFilter('taskTemplateNodeType'),
      }),
    updatePagination,
    [getFilter, loadRequestPagination, loadRequestSorting],
  );

  return {
    data,
    nodeId,
    loadDataStatus,
    reloadData,
    removeItem: (id: number | string) => {
      setData((prev) => prev.filter((item) => item.id !== id));
    },
  };
};
