import {useCallback} from 'react';

import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {BooleanTypeProviderEditor} from './BooleanTypeProviderEditor';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';

interface BooleanTypeProviderProps<TableData extends object> {
  config: VantageTableConfig<TableData>;
  rowId: keyof TableData;
}

export default function BooleanTypeProvider<TableData extends object>({
  config,
  rowId,
  ...props
}: DataTypeProviderProps & BooleanTypeProviderProps<TableData>) {
  const renderEditor = useCallback(
    (editorProps: DataTypeProvider.ValueEditorProps) => (
      <BooleanTypeProviderEditor
        config={config}
        rowId={rowId}
        {...editorProps}
      />
    ),
    [config, rowId],
  );

  const renderFormatter = useCallback(
    ({column, value, row}: DataTypeProvider.ValueFormatterProps) => {
      const columnConfig = config.columns.find((c) => c.name === column.name);
      if (columnConfig?.getValue == null) {
        return value === true ? 'YES' : 'NO';
      }
      return columnConfig.getValue(row as TableData) === true ? 'YES' : 'NO';
    },
    [config.columns],
  );

  return (
    <DataTypeProvider
      formatterComponent={renderFormatter}
      editorComponent={renderEditor}
      {...props}
    />
  );
}
