import {useState} from 'react';

import {type WebEvent} from '@onroadvantage/onroadvantage-api';
import {DateTime} from 'luxon';

import {webEventApi} from '../../../api';
import {Warning} from '../../common/classes/Warning';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {eventTypeModels} from '../../common/types/EventTypeModel';
import {type EventBulkClosingSearchFormValues} from '../components/EventBulkClosingSearchForm';

export const useSearchWebEvents = (onNextStep: () => void) => {
  const [webEvents, setWebEvents] = useState<WebEvent[]>([]);
  const [selectedWebEvents, setSelectedWebEvents] = useState<WebEvent[]>([]);
  const [formState, setFormState] = useState<EventBulkClosingSearchFormValues>({
    eventTypes: [],
    contracts: [],
    drivers: [],
    vehicles: [],
    dateRange: 1,
  });
  const {submitStatus, resetStatus, onSubmit} =
    useSubmitForm<EventBulkClosingSearchFormValues>(
      async ({
        contracts,
        dateRange,
        drivers,
        eventTypes,
        vehicles,
        vehicleGroups,
      }) => {
        const contractIds = new Set<number>();
        const driverIds = new Set<number>();
        const vehicleIds = new Set<number>();
        const vehicleGroupIds = new Set<number>();
        const criticalEventTypeIds = new Set<number>();
        const operationalEventTypeIds = new Set<number>();

        contracts?.forEach((contract) => {
          if (typeof contract.value === 'number') {
            contractIds.add(contract.value);
          }
        });

        drivers?.forEach((driver) => {
          if (typeof driver.value === 'number') {
            driverIds.add(driver.value);
          }
        });

        vehicles?.forEach((vehicle) => {
          if (typeof vehicle.value === 'number') {
            vehicleIds.add(vehicle.value);
          }
        });

        vehicleGroups?.forEach((vehicleGroup) => {
          if (typeof vehicleGroup.value === 'number') {
            vehicleGroupIds.add(vehicleGroup.value);
          }
        });

        eventTypes?.forEach((eventType) => {
          if (typeof eventType.value !== 'number') {
            return;
          }
          if (eventType.metaData === eventTypeModels.CriticalEventType) {
            criticalEventTypeIds.add(eventType.value);
          } else {
            operationalEventTypeIds.add(eventType.value);
          }
        });

        const endDate = DateTime.now().endOf('day');
        const startDate = endDate.minus({days: dateRange}).startOf('day');

        const response = await webEventApi.apiWebEventGet({
          contractIds:
            contractIds.size > 0 ? Array.from(contractIds) : undefined,
          driverIds: driverIds.size > 0 ? Array.from(driverIds) : undefined,
          vehicleIds: vehicleIds.size > 0 ? Array.from(vehicleIds) : undefined,
          criticalEventTypeIds:
            criticalEventTypeIds.size > 0
              ? Array.from(criticalEventTypeIds)
              : undefined,
          operationalEventTypeIds:
            operationalEventTypeIds.size > 0
              ? Array.from(operationalEventTypeIds)
              : undefined,
          endDate: endDate.toJSDate(),
          startDate: startDate.toJSDate(),
          perPage: 100,
        });

        if (response.items == null || response.items.length === 0) {
          throw new Warning('No events found');
        }

        setWebEvents(response.items);
        setSelectedWebEvents(response.items);
        onNextStep();
        setFormState({
          contracts,
          dateRange,
          drivers,
          eventTypes,
          vehicles,
          vehicleGroups,
        });
      },
      [onNextStep],
      {resetFormBehaviour: 'never'},
    );
  return {
    formState,
    searchStatus: submitStatus === 'submitting' ? 'searching' : submitStatus,
    onSearch: onSubmit,
    resetSearch: () => {
      resetStatus();
      setFormState({
        eventTypes: [],
        contracts: [],
        drivers: [],
        vehicles: [],
        dateRange: 1,
      });
    },
    webEvents,
    selectedWebEvents,
    setSelectedWebEvents,
  };
};
