import {
  type CriticalEventDump,
  type OperationalEventDump,
  type WebEvent,
} from '@onroadvantage/onroadvantage-api';

import {type EventModel} from '../../common/types/EventModel';

export const mapEventToWebEvent = (
  {type, trip, activities, ...event}: CriticalEventDump | OperationalEventDump,
  model: EventModel,
): WebEvent => {
  return {
    ...event,
    eventType: type,
    masterTrip: trip,
    activities: activities?.map((activity) => ({
      ...activity,
      createdByEmail: activity.createdBy?.email,
      createdByName: event.createdAt,
    })),
    metaData: {model},
  };
};
