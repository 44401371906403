import Typography from '@mui/material/Typography';

interface NotAuthorizedProps {
  message?: string;
}

export function NotAuthorized({
  message = 'NOT AUTHORIZED',
}: NotAuthorizedProps) {
  return <Typography variant="h2">{message}</Typography>;
}
