import {useCallback, useEffect, useState} from 'react';

import {catchHandler} from '../helpers/catchHandler';
import type {LoadStatus} from '../types/LoadStatus';

export const useLoad = <T>(
  loadHandler: () => Promise<T>,
  deps: unknown[] = [],
) => {
  const [loadStatus, setLoadStatus] = useState<LoadStatus>('idle');
  const [item, setItem] = useState<T | null>(null);

  const load = useCallback(
    async (reason: 'initial' | 'reload') => {
      setLoadStatus(reason === 'initial' ? 'loading' : 'reloading');
      try {
        const response = await loadHandler();
        setItem(response);
        setLoadStatus('success');
      } catch (error) {
        const {type} = catchHandler(error, 'Failed to load item');

        setLoadStatus(type);
      }
    },
    [loadHandler],
  );

  useEffect(() => {
    void load('initial');
    return () => {
      setItem(null);
      setLoadStatus('idle');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    load,
    loadStatus,
    item,
    reload: async () => {
      await load('reload');
    },
    updateState: setItem,
    updatePartialState: (partialState: Partial<T>) => {
      setItem((prevItem) =>
        prevItem == null ? null : {...prevItem, ...partialState},
      );
    },
  };
};
