import {TableHeaderRow} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

export const VantageTableHeaderRowCellComponent = styled(TableHeaderRow.Cell)(
  ({theme}) => ({
    paddingTop: `${theme.spacing(0.5)} !important`,
    paddingBottom: `${theme.spacing(0.5)} !important`,
    fontWeight: 600,
    '&:first-of-type': {
      paddingLeft: `${theme.spacing(1)} !important`,
    },
  }),
);
