import {create} from 'zustand';

import {webEventApi} from '../../../api';
import {config} from '../../../config';

interface EventStoreState {
  eventsDrawerOpen: boolean;
  eventsCountTimeoutRef: number | null;
  eventsCount: {critical: number; operational: number};
}

interface EventStoreActions {
  toggleEventsDrawer: () => void;
  closeEventsDrawer: () => void;
  loadEventsCount: (behaviour?: 'autoRefresh' | 'singleLoad') => Promise<void>;
}

const dateRangeStoreInitialState: EventStoreState = {
  eventsDrawerOpen: false,
  eventsCountTimeoutRef: null,
  eventsCount: {critical: 0, operational: 0},
};

export const useEventStore = create<EventStoreState & EventStoreActions>()(
  (setState, getState) => ({
    ...dateRangeStoreInitialState,
    toggleEventsDrawer: () => {
      setState((state) => ({
        eventsDrawerOpen: !state.eventsDrawerOpen,
      }));
    },
    closeEventsDrawer: () => {
      setState({eventsDrawerOpen: false});
    },
    loadEventsCount: async (behaviour = 'autoRefresh') => {
      const {eventsCountTimeoutRef} = getState();
      try {
        if (eventsCountTimeoutRef != null) {
          clearTimeout(eventsCountTimeoutRef);
        }
        const response = await webEventApi.apiWebEventCountGet();
        setState({
          eventsCount: {
            critical: response.criticalEvent ?? 0,
            operational: response.operationalEvent ?? 0,
          },
        });
      } finally {
        if (behaviour === 'autoRefresh') {
          setState({
            eventsCountTimeoutRef: setTimeout(async () => {
              await getState().loadEventsCount();
            }, config.eventsRefreshTimeout),
          });
        }
      }
    },
  }),
);
