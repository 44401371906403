import {styled} from '@mui/material/styles';
import {type Driver} from '@onroadvantage/onroadvantage-api';
import {QRCodeCanvas} from 'qrcode.react';

import {useDownloadDriverQR} from '../hooks/useDownloadDriverQR';

export function DriversDownloadQRHiddenCanvas({
  driver,
  qrKey,
  toastId,
  onComplete,
}: {
  qrKey: string;
  driver: Driver;
  toastId: string;
  onComplete: (driver: Driver) => void;
}) {
  const qrContainerRef = useDownloadDriverQR({driver, toastId, onComplete});

  return (
    <HiddenContainer ref={qrContainerRef}>
      <QRCodeCanvas value={qrKey} size={256} level="L" />
    </HiddenContainer>
  );
}

const HiddenContainer = styled('div')({
  position: 'absolute',
  inset: 0,
  zIndex: -2,
  visibility: 'hidden',
});
