import {TableEditRow} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

export const VantageTableEditCellComponent = styled(TableEditRow.Cell)(
  ({theme}) => ({
    paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`,
    '&:first-of-type': {
      paddingLeft: `${theme.spacing(1)} !important`,
    },
  }),
);
