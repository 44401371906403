import {type Driver} from '@onroadvantage/onroadvantage-api';

import {type GeneratedDetails} from './useGenerateAudio';
import {broadcastApi} from '../../../api';
import {Warning} from '../../common/classes/Warning';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type BroadcastVantageMobileValues} from '../components/BroadcastVantageMobileForm';

export const useSendVantageMobileBroadcast = (
  generatedDetails: GeneratedDetails | null,
  selectedDrivers: Driver[],
  onResetStepper: () => void,
) => {
  return useSubmitForm<BroadcastVantageMobileValues>(
    async (values) => {
      if (generatedDetails?.identifier == null) {
        throw new Warning('Generate message before sending.');
      }
      if (generatedDetails.message !== values.message) {
        throw new Warning(
          'Message changed. Generate new message before sending.',
        );
      }

      const smartphoneIds: number[] = [];
      selectedDrivers.forEach((driver) => {
        if (driver?.user?.smartphone?.id != null) {
          smartphoneIds.push(driver.user.smartphone.id);
        }
      });
      await broadcastApi.apiBroadcastPost({
        body: {
          ...values,
          smartphoneIds: smartphoneIds ?? [],
          messageUploadIdentifier: generatedDetails.identifier,
        },
      });
      onResetStepper();
    },
    [generatedDetails],
    {resetStatus: true},
  );
};
