import {useMemo} from 'react';

import Stack from '@mui/material/Stack';

import {TripAddStopsChips} from './TripAddStopsChips';
import {TripAddStopsExistingOrders} from './TripAddStopsExistingOrders';
import {TripAddStopsNewOrder} from './TripAddStopsNewOrder';
import {TripAddStopsOverview} from './TripAddStopsOverview';
import {TripAddStopsSite} from './TripAddStopsSite';
import {useAddTripStops} from '../../hooks/useAddTripStops';

export const tripAddStopsOptions = [
  'Stops overview',
  'Add new order',
  'Add existing orders',
  'Add stop',
] as const;

export type TripAddStopsOption = (typeof tripAddStopsOptions)[number];

export function TripAddStops() {
  const {
    activeOption,
    setActiveOption,
    stops,
    addDepotToStops,
    addNewOrderToStops,
    addExistingOrdersToStops,
    onDragEnd,
    onRemoveStop,
  } = useAddTripStops();

  const renderActiveOption = useMemo(() => {
    switch (activeOption) {
      case 'Add stop':
        return <TripAddStopsSite onSubmit={addDepotToStops} />;
      case 'Add new order':
        return <TripAddStopsNewOrder onSubmit={addNewOrderToStops} />;
      case 'Add existing orders':
        return (
          <TripAddStopsExistingOrders onSubmit={addExistingOrdersToStops} />
        );
      case 'Stops overview':
        return (
          <TripAddStopsOverview
            stops={stops}
            onDragEnd={onDragEnd}
            onRemoveStop={onRemoveStop}
          />
        );
      default:
        return null;
    }
  }, [
    activeOption,
    addDepotToStops,
    addNewOrderToStops,
    addExistingOrdersToStops,
    stops,
    onDragEnd,
    onRemoveStop,
  ]);

  return (
    <Stack flex={1}>
      <TripAddStopsChips
        activeOption={activeOption}
        setActiveOption={setActiveOption}
      />
      {renderActiveOption}
    </Stack>
  );
}
