import {memo} from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';

import {type EventsPaneTab} from './EventsPaneTabs';

export const EventsPaneProgress = memo(function EventsPaneProgress({
  activeTab,
  progress,
}: {
  activeTab: EventsPaneTab;
  progress: number;
}) {
  if (activeTab === 'History') {
    return null;
  }

  if (progress > 0) {
    return (
      <Progress color="secondary" variant="determinate" value={progress} />
    );
  }

  return <Progress color="secondary" variant="query" />;
});

const Progress = styled(LinearProgress)({
  height: 8,
});
