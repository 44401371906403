import {useState} from 'react';

import {type UniqueIdentifier} from '@dnd-kit/core';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import DragIndicatorIcon from '@mui/icons-material/DragIndicatorRounded';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {addShouldNotForwardProps} from '../../../common/helpers/addShouldNotForwardProps';
import type {TripAddStopsStop} from '../../hooks/useAddTripStops';

interface TripAddStopsOverviewDraggableStopProps {
  id: UniqueIdentifier;
  stop: TripAddStopsStop;
  onRemoveStop: (id: UniqueIdentifier) => void;
}

export function TripAddStopsOverviewDraggableStop({
  id,
  onRemoveStop,
  stop,
}: TripAddStopsOverviewDraggableStopProps) {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id});
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const [deleting, setDeleting] = useState(false);

  return (
    <DraggableStopBanner
      $deleting={deleting}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Stack direction="row" alignItems="center">
        {deleting ? (
          <>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveStop(id);
                setDeleting(false);
              }}
              color="error"
            >
              <CheckIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setDeleting(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              size="small"
              {...listeners}
              sx={{cursor: 'move'}}
              disableTouchRipple
            >
              <DragIndicatorIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setDeleting(true);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </>
        )}
      </Stack>
      <Divider orientation="vertical" />
      <Stack
        px={1}
        flex={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {stop.orders != null && stop.orders.length > 0 ? (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}
              divider={<ArrowForwardIcon fontSize="small" />}
            >
              <Typography variant="body2">
                {stop.orders[0].upliftPointNode?.label}
              </Typography>
              <Typography variant="body2">
                {stop.orders[0].offloadPointNode?.label}
              </Typography>
            </Stack>
            <Typography color="text.secondary">
              {stop.orders[0].orderNumber}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2">{stop.node.label}</Typography>
            <Typography color="text.secondary">Stop</Typography>
          </>
        )}
      </Stack>
    </DraggableStopBanner>
  );
}

const DraggableStopBanner = styled('div', {
  shouldForwardProp: addShouldNotForwardProps('$deleting'),
})<{$deleting: boolean}>(({theme, $deleting}) => ({
  backgroundColor: `${$deleting ? 'rgba(248,229,229,0.3)' : theme.palette.background.default}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));
