import {useNavigate, useSearchParams} from 'react-router-dom';

import {authorizationApi} from '../../../api';
import {routes} from '../../../routes';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {useAuthStore} from '../../common/stores/authStore';
import {useUserStore} from '../../common/stores/userStore';
import {type LoginFormValues} from '../components/LoginForm';

export const useLogin = () => {
  const navigate = useNavigate();
  const setToken = useAuthStore((state) => state.setToken);
  const setUser = useUserStore((state) => state.setUser);
  const [searchParams] = useSearchParams();

  return useSubmitForm<LoginFormValues>(
    async (values) => {
      const cleanedValues = {
        ...values,
        email: values.email.toLowerCase().trim(),
      };
      const response = await authorizationApi.apiAuthLoginPost({
        body: cleanedValues,
      });
      setToken(response.authToken);
      setUser({email: cleanedValues.email, roles: response.roles});
      const redirectUrl = searchParams.get('redirect');
      if (redirectUrl != null && !redirectUrl.includes(routes.auth.base)) {
        setTimeout(() => {
          navigate(redirectUrl, {replace: true});
        }, 500);
      } else {
        setTimeout(() => {
          navigate(routes.home, {replace: true});
        }, 500);
      }
    },
    [searchParams, navigate],
  );
};
