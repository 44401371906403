import {useMemo, useState} from 'react';

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import {VantageCard} from '../../common/components/VantageCard';
import {useStepper} from '../../common/hooks/useStepper';
import type {OrderAddFormValues} from '../components/OrderAddForm';
import {type OrderLineAdd, OrderLinesAdd} from '../components/OrderLinesAdd';
import {OrdersStep} from '../components/OrdersStep';
import type {PlanningOrderAddFormValues} from '../components/PlanningOrderAddForm';
import {PurchaseOrderAddForm} from '../components/PurchaseOrderAddForm';
import {
  OrderStepperContext,
  type OrderStepperContextProps,
} from '../contexts/OrderStepperContext';

const orderAddSteps = [
  'Details',
  'Order lines',
  'Purchase order details',
] as const;
type OrderAddStep = (typeof orderAddSteps)[number];

export default function OrderAdd() {
  const {
    activeStep,
    activeStepPosition,
    completedSteps,
    onGoToStep,
    isFirstStep,
    onNextStep,
    onPreviousStep,
    onResetStepper,
  } = useStepper<OrderAddStep>(orderAddSteps);
  const [planningOrderFormValues, setPlanningOrderFormValues] =
    useState<PlanningOrderAddFormValues | null>(null);
  const [orderFormValues, setOrderFormValues] =
    useState<OrderAddFormValues | null>(null);
  const [orderLines, setOrderLines] = useState<OrderLineAdd[]>([]);

  const handlePlanningOrderFormSubmit = (
    values: PlanningOrderAddFormValues,
  ) => {
    setPlanningOrderFormValues(values);
    onNextStep();
  };

  const handleOrderFormSubmit = (values: OrderAddFormValues) => {
    setOrderFormValues(values);
    onNextStep();
  };

  const handleOrderLinesSubmit = (lines: OrderLineAdd[]) => {
    setOrderLines(lines);
    onNextStep();
  };

  const stepperValue = useMemo<OrderStepperContextProps>(
    () => ({onNextStep, onPreviousStep, onResetStepper}),
    [onNextStep, onPreviousStep, onResetStepper],
  );

  return (
    <VantageCard stretch={isFirstStep} scrollable>
      <Stepper
        activeStep={activeStepPosition}
        sx={{
          p: 1,
          bgcolor: 'background.default',
          position: 'sticky',
          top: 0,
          zIndex: 100,
        }}
      >
        {orderAddSteps.map((step) => (
          <Step
            key={step}
            completed={completedSteps.includes(step)}
            active={activeStep === step}
            onClick={() => {
              onGoToStep(step);
            }}
            sx={{
              cursor: completedSteps.includes(step) ? 'pointer' : 'default',
            }}
          >
            <StepLabel color="primary">{step as string}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Divider />
      <OrderStepperContext.Provider value={stepperValue}>
        <Collapse
          in={activeStep === 'Details'}
          mountOnEnter
          unmountOnExit={false}
        >
          <OrdersStep
            onOrderSubmit={handleOrderFormSubmit}
            onPlanningOrderSubmit={handlePlanningOrderFormSubmit}
          />
        </Collapse>
        <Collapse
          in={activeStep === 'Order lines'}
          mountOnEnter
          unmountOnExit={false}
        >
          <OrderLinesAdd
            onPreviousStep={onPreviousStep}
            onSubmitOrderLines={handleOrderLinesSubmit}
          />
        </Collapse>
        <Collapse
          in={activeStep === 'Purchase order details'}
          mountOnEnter
          unmountOnExit={false}
        >
          <PurchaseOrderAddForm
            onPreviousStep={onPreviousStep}
            orderFormValues={orderFormValues}
            planningOrderFormValues={planningOrderFormValues}
            orderLines={orderLines}
          />
        </Collapse>
      </OrderStepperContext.Provider>
    </VantageCard>
  );
}
