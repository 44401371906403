import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyProfiles = lazy(async () => import('./pages/Profiles'));

export const profileRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.profile.list)},
  {
    path: routes.getRelativePath(routes.profile.list, routes.profile.base),
    element: <LazyProfiles />,
    loader: () => {
      document.title = 'Profiles';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Profiles'}]});
      return null;
    },
  },
];
