import {useNavigate} from 'react-router-dom';

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ButtonBase from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';

import {routes} from '../../../routes';

export function AuthenticationBackToLogin() {
  const navigate = useNavigate();

  const onGoBackToLogin = () => {
    navigate(routes.auth.login);
  };

  return (
    <BackToLogin onClick={onGoBackToLogin}>
      <ArrowBackRounded fontSize="small" /> Back to login
    </BackToLogin>
  );
}

const BackToLogin = styled(ButtonBase)(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(0.5),
  gap: theme.spacing(0.5),
  borderRadius: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
