import {useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {ValueEditorStringFilter} from '../components/ValueEditorStringFilter';
import {ValueEditorTextField} from '../components/ValueEditorTextField';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';

export interface StringTypeProviderEditorProps<TableData extends object> {
  rowId: keyof TableData;
  loading?: boolean;
  config: VantageTableConfig<TableData>;
}

export function StringTypeProviderEditor<TableData extends object>({
  rowId,
  config,
  ...props
}: DataTypeProvider.ValueEditorProps &
  StringTypeProviderEditorProps<TableData>) {
  const {row, column} = props;
  const columnConfig = useMemo(
    () => config.columns.find((c) => c.name === column.name),
    [column.name, config.columns],
  );

  if (columnConfig == null) {
    throw new Error('Column config not found');
  }

  if (row == null) {
    return <ValueEditorStringFilter columnConfig={columnConfig} {...props} />;
  }

  return (
    <ValueEditorTextField
      {...props}
      columnConfig={columnConfig}
      rowId={rowId}
    />
  );
}
