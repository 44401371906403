import Stack from '@mui/material/Stack';

import {HomeCard} from './HomeCard';
import {useFilteredHomeMenuItems} from '../hooks/useFilteredHomeMenuItems';

export function HomeMenuItemCards() {
  const filteredHomeMenuItems = useFilteredHomeMenuItems();
  return (
    <Stack direction="row" flexWrap="wrap" spacing={2} useFlexGap>
      {filteredHomeMenuItems.map((homeMenuItem) => (
        <HomeCard key={homeMenuItem.title} {...homeMenuItem} />
      ))}
    </Stack>
  );
}
