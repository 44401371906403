import Stack, {type StackProps} from '@mui/material/Stack';

interface VantageFormActionsProps extends StackProps {
  sticky?: boolean;
}

export function VantageFormActions({
  children,
  sticky = false,
  ...props
}: VantageFormActionsProps) {
  return (
    <Stack
      position={sticky ? 'sticky' : 'relative'}
      bgcolor="background.default"
      zIndex={sticky ? 1 : undefined}
      bottom={0}
      direction={{xs: 'column', sm: 'row'}}
      spacing={1}
      p={1}
      alignSelf="stretch"
      alignItems="center"
      {...props}
    >
      {children}
    </Stack>
  );
}
