import {useCallback, useMemo, useRef} from 'react';

import SyncIcon from '@mui/icons-material/SyncRounded';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import {type InferType, object, string} from 'yup';

import {cellphone} from '../../common/components/form/validationSchemas';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormReset} from '../../common/components/form/VantageFormReset';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../common/components/form/VantageTextField';
import {VantageCard} from '../../common/components/VantageCard';
import {useGenerateAudio} from '../hooks/useGenerateAudio';
import {useSendSingleMessageBroadcast} from '../hooks/useSendSingleMessageBroadcast';

const broadcastSingleMessageSchema = object({
  toNumber: cellphone.required('Required'),
  message: string().required('Required'),
});
export type BroadcastSingleMessageValues = InferType<
  typeof broadcastSingleMessageSchema
>;

export default function BroadcastSingleMessage() {
  const messageInputRef = useRef<HTMLInputElement>(null);
  const {
    generatedDetails,
    generatedUrlStatus,
    generateAudioFile,
    onResetGeneratedUrl,
  } = useGenerateAudio(messageInputRef);
  const {onSubmit, submitStatus} =
    useSendSingleMessageBroadcast(generatedDetails);

  let submitLabel = 'Send broadcast';

  if (submitStatus === 'success') {
    submitLabel = 'Sent broadcast';
  }
  if (submitStatus === 'error' || submitStatus === 'warning') {
    submitLabel = "Didn't broadcast";
  }

  const renderPlayerContent = useMemo(() => {
    switch (generatedUrlStatus) {
      case 'generating':
        return (
          <Skeleton
            height={54}
            width={300}
            variant="rectangular"
            style={{borderRadius: '8rem'}}
          />
        );
      case 'success':
        if (generatedDetails?.url == null) {
          return <Typography>Failed to generate message</Typography>;
        }
        return (
          <audio controls>
            <source src={generatedDetails.url ?? ''} type="audio/mp3" />
            <track kind="captions" />
          </audio>
        );
      default:
        return null;
    }
  }, [generatedUrlStatus, generatedDetails?.url]);

  const handleGenerateAudioFile = useCallback(async () => {
    await generateAudioFile();
  }, [generateAudioFile]);

  return (
    <VantageCard>
      <VantageForm
        initialValues={{message: '', toNumber: ''}}
        onSubmit={onSubmit}
        validationSchema={broadcastSingleMessageSchema}
      >
        <VantageFormContent>
          <VantageTextField name="toNumber" label="Number" formik />
          <VantageTextField
            name="message"
            label="Message"
            inputRef={messageInputRef}
            formik
          />
          <Collapse
            in={generatedUrlStatus !== 'idle'}
            mountOnEnter
            unmountOnExit
          >
            {renderPlayerContent}
          </Collapse>
        </VantageFormContent>
        <VantageFormActions>
          <VantageFormSubmit
            label={submitLabel}
            loadingLabel="Sending broadcast..."
          />
          <VantageFormReset onReset={onResetGeneratedUrl} />
          <Button
            startIcon={<SyncIcon />}
            color="inherit"
            variant="outlined"
            onClick={handleGenerateAudioFile}
          >
            Generate message
          </Button>
        </VantageFormActions>
      </VantageForm>
    </VantageCard>
  );
}
