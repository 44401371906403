import {type Order, type OrderUpdate} from '@onroadvantage/onroadvantage-api';

import {orderApi} from '../../../api';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type EditParams} from '../../common/types/EditParams';
import {type OrderFormValues} from '../components/OrderForm';

export const useEditOrder = ({id: orderId, updateState}: EditParams<Order>) => {
  return useSubmitForm<OrderFormValues>(async (values) => {
    const newValues: OrderUpdate = {
      orderNumber: values.orderNumber,
      actualDispatch: values.actualDispatch ?? new Date(),
      customerReferenceNumber: values.customerReferenceNumber,
      deliveryNoteNumber: values.deliveryNoteNumber,
      deliverByDateTime: values.deliverByDateTime ?? new Date(),
      depotDeparture: values.depotDeparture ?? new Date(),
      status: values.status,
      primeMover: values.primeMover,
      contractId: values.contract?.value ?? 1,
      customerId: values.customer?.value,
      offloadPointId: values.offloadPoint?.value ?? 1,
      transporterId: values.transporter?.value,
      upliftPointId: values.upliftPoint?.value ?? 1,
    };

    const response = await orderApi.apiOrderOrderIdPatch({
      orderId,
      body: newValues,
    });
    updateState(response);
  });
};
