import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const documentationMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'API Docs',
    permission: 'clientAPIDocs:view',
    children: [
      {
        level: 3,
        label: 'Client API Docs',
        pathname: routes.documentation.apiDocs.client,
        permission: 'clientAPIDocs:view',
      },
      {
        level: 3,
        label: 'Developer API Docs',
        pathname: routes.documentation.apiDocs.developer,
        permission: 'developerAPIDocs:view',
      },
      {
        level: 3,
        label: 'Integration API Docs',
        pathname: routes.documentation.apiDocs.integration,
        permission: 'integrationAPIDocs:view',
      },
      {
        level: 3,
        label: 'Tracking API Docs',
        pathname: routes.documentation.apiDocs.tracking,
        permission: 'trackingAPIDocs:view',
      },
    ],
  },
  {
    level: 2,
    label: 'Functional Docs',
    pathname: routes.documentation.functional,
    permission: 'functionalDocs:view',
  },
  {level: 2, label: 'Vantage SOPs', pathname: routes.documentation.sops},
  {level: 2, label: "What's New?", pathname: routes.documentation.new},
];
