import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import Vehicles from './pages/Vehicles';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyVehicleAdd = lazy(async () => import('./pages/VehicleAdd'));
const LazyVehicle = lazy(async () => import('./pages/Vehicle'));

export const vehicleRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.vehicle.list)},
  {
    path: routes.getRelativePath(routes.vehicle.list, routes.vehicle.base),
    element: <Vehicles />,
    loader: () => {
      document.title = 'Vehicles';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Vehicles'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.vehicle.add, routes.vehicle.base),
    element: <LazyVehicleAdd />,
    loader: () => {
      document.title = 'Add Vehicle';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Vehicles', path: routes.vehicle.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.vehicle.vehicleId, routes.vehicle.base),
    element: <LazyVehicle />,
    loader: () => {
      document.title = 'View Vehicle';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Vehicles', path: routes.vehicle.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
