import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type Driver} from '@onroadvantage/onroadvantage-api';

import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {DriversDownloadQRHiddenCanvas} from '../components/DriversDownloadQRHiddenCanvas';
import {useDeleteDriver} from '../hooks/useDeleteDriver';
import {useDownloadDrivers} from '../hooks/useDownloadDrivers';
import {useGenerateDriverQRs} from '../hooks/useGenerateDriverQRs';
import {useLoadDrivers} from '../hooks/useLoadDrivers';

const tableConfig: VantageTableConfig<Driver> = {
  columns: [
    {
      name: 'firstname',
      label: 'Firstname',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'lastname',
      label: 'Lastname',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'externalEmployeeNumber',
      label: 'Employee number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'externalName',
      label: 'External name',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'contractCode',
      label: 'Contract code',
      type: 'string',
      enableFilter: true,
      getValue: ({contract}) => contract?.code,
    },
    {
      name: 'contactNumber',
      label: 'Contact number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Driver name:</Typography>
        <Typography>{row.firstname}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Driver employee number:</Typography>
        <Typography>{row.externalEmployeeNumber}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnGrouping: true,
  enableColumnResizing: true,
  enableColumnVisibility: true,
  identifier: 'drivers',
};

export default function Drivers() {
  const navigate = useNavigate();
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, loadDataStatus, removeItem} = useLoadDrivers({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {deleteStatus, onDelete} = useDeleteDriver({removeItem});
  const {generatedDriverQRs, onComplete, onGenerateDriverQR} =
    useGenerateDriverQRs();
  const {onDownload, downloadStatus} = useDownloadDrivers();

  return (
    <VantageTablePaper>
      {generatedDriverQRs.map(({qrKey, driver, toastId}) => (
        <DriversDownloadQRHiddenCanvas
          key={qrKey}
          driver={driver}
          qrKey={qrKey}
          toastId={toastId}
          onComplete={onComplete}
        />
      ))}
      <OverlayLoader
        loading={loadDataStatus === 'idle'}
        label="Perparing content..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'loading'}
        label="Loading drivers..."
      />
      <OverlayLoader
        loading={deleteStatus === 'deleting'}
        label="Deleting driver..."
      />
      <OverlayLoader
        loading={downloadStatus === 'downloading'}
        label="Downloading drivers..."
      />
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        actions={{
          onAdd: hasPermissions('driver:add')
            ? () => {
                navigate(routes.driver.add);
              }
            : undefined,
          onDownload,
        }}
        rowActions={{
          onNavigate: ({id}) => {
            if (id != null) {
              navigate(
                routes.replaceParams(routes.driver.driverId, {
                  driverId: id,
                }),
              );
            }
          },
          onDownloadItem: onGenerateDriverQR,
          onDelete: hasPermissions('driver:edit') ? onDelete : undefined,
        }}
      />
    </VantageTablePaper>
  );
}
