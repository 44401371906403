import Stack, {type StackProps} from '@mui/material/Stack';

export function VantageFormContent({children, ...props}: StackProps) {
  return (
    <Stack
      p={1}
      position="relative"
      spacing={1.5}
      alignSelf="stretch"
      overflow="auto"
      {...props}
    >
      {children}
    </Stack>
  );
}
