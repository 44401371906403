import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useFormikContext} from 'formik';

import {ReportScheduleFormScheduleDateRange} from './ReportScheduleFormScheduleDateRange';
import {VantageBooleanField} from '../../../common/components/form/VantageBooleanField';
import {VantageDateTimePickerField} from '../../../common/components/form/VantageDateTimePickerField';
import {VantageTimePickerField} from '../../../common/components/form/VantageTimePickerField';

export function ReportScheduleFormSchedule() {
  const {getFieldMeta} = useFormikContext();
  const {value} = getFieldMeta('recurring');
  return (
    <>
      <Typography>Schedule</Typography>
      <VantageBooleanField name="recurring" label="Recurring" formik />
      <Collapse in={value !== true} mountOnEnter unmountOnExit>
        <VantageDateTimePickerField
          name="dateTime"
          label="Schedule Date Time"
          formik
        />
      </Collapse>
      <Collapse in={value === true} mountOnEnter unmountOnExit>
        <Stack spacing={1.5}>
          <VantageTimePickerField name="time" label="Schedule Time" formik />
          <Stack
            direction={{xs: 'column', md: 'row'}}
            justifyContent={{xs: 'flex-start', md: 'space-between'}}
            alignItems="stretch"
            spacing={4}
          >
            <Stack
              direction={{xs: 'row', md: 'column'}}
              spacing={1}
              flexWrap="wrap"
              justifyContent="space-between"
              useFlexGap
            >
              <VantageBooleanField
                name="sunday"
                label="Sun"
                type="checkbox"
                formik
              />
              <VantageBooleanField
                name="monday"
                label="Mon"
                type="checkbox"
                formik
              />
              <VantageBooleanField
                name="tuesday"
                label="Tue"
                type="checkbox"
                formik
              />
              <VantageBooleanField
                name="wednesday"
                label="Wed"
                type="checkbox"
                formik
              />
              <VantageBooleanField
                name="thursday"
                label="Thu"
                type="checkbox"
                formik
              />
              <VantageBooleanField
                name="friday"
                label="Fri"
                type="checkbox"
                formik
              />
              <VantageBooleanField
                name="saturday"
                label="Sat"
                type="checkbox"
                formik
              />
            </Stack>
            <ReportScheduleFormScheduleDateRange />
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
}
