import {type AuthProviderProps} from 'react-oidc-context';

import {WebStorageStateStore} from 'oidc-client-ts';

import {getMetaTagContent} from './modules/common/helpers/getMetaTag';
import {routes} from './routes';
import packageJson from '../package.json';

const {
  VITE_APP_FRESHDESK_SUPPORT_URL,
  VITE_APP_SERVER_URI,
  VITE_APP_SERVER_360_URI,
  VITE_APP_GOOGLE_GEOCODING_KEY,
  VITE_APP_ROUTING_ENGINE_URL,
  VITE_APP_PROMPT_URL,
  VITE_APP_PROMPT_KEY,
  VITE_APP_STREETS_TILING_ENGINE_URL,
  VITE_APP_HYBRID_TILING_URI,
  VITE_APP_VALHALLA_ENGINE_URL,
  VITE_APP_SSO_CLIENT_ID,
  VITE_APP_SSO_REALM,
  VITE_APP_SSO_URI,
  VITE_APP_SENTRY_AUTH_TOKEN,
  VITE_APP_SENTRY_DSN,
} = import.meta.env;

export const config = {
  toastyAutoHideDuration: 3500, // 3.5 seconds
  eventsRefreshTimeout: 30000, // 30 seconds
  eventFilterDuration: 4, // hours
  leftDrawerWidth: 255,
  rightDrawerWidth: 360,
  tableTheme: {
    nestedLevels: {
      light: {level1: '#eee', level2: '#e3e3e3'},
      dark: {level1: '#242424', level2: '#323232'},
    },
  },
  theme: {
    gutterSpacing: 1,
    primaryDefault: '#F26722',
    secondaryDefault: '#4D4D4D',
  },
  freshdeskSupportUrl: getMetaTagContent(
    'app:freshdesk-support-url',
    VITE_APP_FRESHDESK_SUPPORT_URL,
  ),
  serverUri: getMetaTagContent(
    'app:server-uri',
    VITE_APP_SERVER_URI,
    'http://localhost:5000',
  ),
  server360Uri: getMetaTagContent(
    'app:server-360-uri',
    VITE_APP_SERVER_360_URI,
    'http://localhost:5000',
  ),
  sentry: {
    apiKey: getMetaTagContent(
      'app:sentry-auth-token',
      VITE_APP_SENTRY_AUTH_TOKEN,
    ),
    dsn: getMetaTagContent('app:sentry-dsn', VITE_APP_SENTRY_DSN),
  },
  prompt: {
    uri: getMetaTagContent('app:prompt-url', VITE_APP_PROMPT_URL),
    key: getMetaTagContent('app:prompt-key', VITE_APP_PROMPT_KEY),
  },
  mapResources: {
    streetsTilingUrl: getMetaTagContent(
      'app:streets-tiling-engine-url',
      VITE_APP_STREETS_TILING_ENGINE_URL,
    ),
    hybridTilingUrl: getMetaTagContent(
      'app:hybrid-tiling-engine-url',
      VITE_APP_HYBRID_TILING_URI,
    ),
    valhallaEngineUrl: getMetaTagContent(
      'app:valhalla-engine-url',
      VITE_APP_VALHALLA_ENGINE_URL,
    ),
    googleGeocodingKey: getMetaTagContent(
      'app:google-geocoding-key',
      VITE_APP_GOOGLE_GEOCODING_KEY,
    ),
    routingEngineUrl: getMetaTagContent(
      'app:routing-engine-url',
      VITE_APP_ROUTING_ENGINE_URL,
    ),
  },
  get sso() {
    const uri = getMetaTagContent('app:sso-uri', VITE_APP_SSO_URI);
    const realm = getMetaTagContent('app:sso-realm', VITE_APP_SSO_REALM);
    const clientId = getMetaTagContent(
      'app:sso-client-id',
      VITE_APP_SSO_CLIENT_ID,
    );

    const oidcConfig: AuthProviderProps = {
      client_id: clientId,
      authority: `${uri}/auth/realms/${realm}`,
      redirect_uri: `${window.location.origin}${routes.auth.redirect}`,
      scope: 'openid profile email',
      revokeTokensOnSignout: true,
      userStore: new WebStorageStateStore({store: window.localStorage}), // Use LocalStorage here
      onSigninCallback: () => {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname,
        );
      },
    };

    return {
      uri,
      realm,
      clientId,
      oidcConfig,
      ssoLogoutNavigators: ['signoutRedirect', 'signoutPopup', 'signoutSilent'],
      ssoLoginNavigators: [
        'signinRedirect',
        'signinResourceOwnerCredentials',
        'signinPopup',
        'signinSilent',
      ],
    };
  },
  version: {
    frontendVersion: getMetaTagContent('app:version', '0.0.0'),
    onroadvantageApiPackageVersion: (
      packageJson.dependencies['@onroadvantage/onroadvantage-api'] ?? '0.0.0'
    ).replace('^', ''),
  },
} as const;
