import {useState} from 'react';
import {useParams} from 'react-router-dom';

import QrCodeIcon from '@mui/icons-material/QrCode2';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type SubNode} from '@onroadvantage/onroadvantage-api';
import QRCode from 'qrcode.react';

import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useAddSiteSubSite} from '../hooks/useAddSiteSubSite';
import {useDeleteSiteSubSite} from '../hooks/useDeleteSiteSubSite';
import {useEditSiteSubSite} from '../hooks/useEditSiteSubSite';
import {useLoadSiteSubSites} from '../hooks/useLoadSiteSubSites';

const config: VantageTableConfig<SubNode> = {
  columns: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      enableFilter: true,
      enableEdit: true,
      enableAdd: true,
      enableSort: true,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'string',
      enableFilter: true,
      enableEdit: true,
      enableAdd: true,
      enableSort: true,
    },
    {
      name: 'externalReference',
      label: 'External Reference',
      type: 'string',
      enableEdit: true,
      enableAdd: true,
      enableFilter: false,
    },
    {
      name: 'taskTemplateName',
      label: 'Task Template',
      models: 'TaskTemplate',
      type: 'autocomplete',
      enableEdit: true,
      enableAdd: true,
      enableFilter: false,
      getDisplayValue: ({taskTemplate}) => taskTemplate?.name,
      getValue: ({taskTemplate}) => ({
        value: taskTemplate?.id,
        label: taskTemplate?.name,
      }),
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Description:</Typography>
        <Typography>{row.description}</Typography>
      </Stack>
    </Stack>
  ),
  enableRowDownload: (row) => row.uuid != null,
  downloadIcon: <QrCodeIcon />,
};

export function SiteSubSites() {
  const params = useParams();

  if (params.nodeId == null) {
    throw new Error('nodeId is required');
  }

  const nodeId = parseInt(params.nodeId, 10);

  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {loadDataStatus, data, setData, removeItem} = useLoadSiteSubSites({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
    nodeId,
  });
  const {deleteStatus, onDelete} = useDeleteSiteSubSite({removeItem, nodeId});
  const {onInlineAdd, addStatus} = useAddSiteSubSite(nodeId, (newSubNode) => {
    setData((prevState) => [...prevState, newSubNode]);
  });
  const {onInlineEdit, updateStatus} = useEditSiteSubSite(
    nodeId,
    (updatedSubNode) => {
      setData((prevState) =>
        prevState.map((item) =>
          item.id === updatedSubNode.id ? updatedSubNode : item,
        ),
      );
    },
  );
  const [selectedSubNodeToDownload, setSelectedSubNodeToDownload] =
    useState<SubNode | null>(null);

  return (
    <Stack position="relative" minHeight="0" overflow="auto">
      <OverlayLoader
        loading={loadDataStatus === 'idle'}
        label="Preparing content..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'loading'}
        label="Loading sub sites..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'reloading'}
        label="Reloading sub sites..."
      />
      <OverlayLoader
        loading={deleteStatus === 'deleting'}
        label="Deleting sub site..."
      />
      <OverlayLoader
        loading={addStatus === 'adding'}
        label="Adding sub site..."
      />
      <OverlayLoader
        loading={updateStatus === 'submitting'}
        label="Updating sub site..."
      />
      <Dialog
        open={selectedSubNodeToDownload !== null}
        onClose={() => {
          setSelectedSubNodeToDownload(null);
        }}
      >
        <Stack p={2} spacing={1}>
          <Typography fontWeight={600}>
            QR code for {selectedSubNodeToDownload?.name}
          </Typography>
          <QRCode
            value={selectedSubNodeToDownload?.uuid ?? ''}
            size={256}
            level="L"
          />
        </Stack>
        <Button
          variant="outlined"
          color="inherit"
          sx={{mb: 1, mx: 2}}
          onClick={() => {
            setSelectedSubNodeToDownload(null);
          }}
        >
          Close
        </Button>
      </Dialog>
      <VantageTable
        config={config}
        data={data}
        rowId="id"
        actions={{
          onInlineAdd: hasPermissions('site:edit') ? onInlineAdd : undefined,
        }}
        rowActions={{
          onInlineEdit: hasPermissions('site:edit') ? onInlineEdit : undefined,
          onDelete: hasPermissions('site:edit') ? onDelete : undefined,
          onDownloadItem: async (row) => {
            setSelectedSubNodeToDownload(row);
            return 'success';
          },
        }}
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
      />
    </Stack>
  );
}
