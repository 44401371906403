import {useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {DateTypeFormatter} from './DateTypeProvider';
import {VantageDatePickerField} from '../../form/VantageDatePickerField';
import {ValueEditorStringFilter} from '../components/ValueEditorStringFilter';
import {checkIsFieldDisabled} from '../helpers/checkIsFieldDisabled';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';

export interface DateTypeProviderEditorProps<TableData extends object> {
  loading?: boolean;
  rowId: keyof TableData;
  config: VantageTableConfig<TableData>;
}

export function DateTypeProviderEditor<TableData extends object>({
  rowId,
  config,
  ...props
}: DataTypeProvider.ValueEditorProps & DateTypeProviderEditorProps<TableData>) {
  const {row, column, value, onValueChange} = props;
  const columnConfig = useMemo(
    () => config.columns.find((c) => c.name === column.name),
    [column.name, config.columns],
  );

  if (columnConfig == null) {
    throw new Error('Column config not found');
  }

  if (row == null) {
    return <ValueEditorStringFilter columnConfig={columnConfig} {...props} />;
  }

  const disabled =
    checkIsFieldDisabled(row, rowId, columnConfig) || props.disabled;

  if (disabled) {
    let fieldValue = value;
    if (columnConfig?.getValue != null) {
      fieldValue = columnConfig.getValue(row as TableData);
    }
    return <DateTypeFormatter column={column} value={fieldValue} />;
  }
  return (
    <VantageDatePickerField
      name={`${column.name}-${rowId.toString()}`}
      size="small"
      defaultValue={value}
      onChange={(newDate) => {
        onValueChange(newDate);
      }}
    />
  );
}
