import {array, object, string, number as yupNumber} from 'yup';

export const NUMBER_ERROR_MESSAGE = 'Not a valid number input.';

export const cellphone = string().matches(/^[+][0-9]*$/, {
  message: 'Requires a phone number with an international dialing code.',
  excludeEmptyString: true,
});
export const hexColor = string().matches(
  /^#([0-9a-f]{3}){1,2}$/i,
  'Invalid hex color. Should follow the format #fff or #fefefe',
);
export const email = string().email('Enter a valid email');
export const number = yupNumber().typeError(NUMBER_ERROR_MESSAGE);
export const listing = object({
  label: string(),
  metaData: string(),
  value: number,
});
export const requiredListing = object({
  label: string(),
  metaData: string(),
  value: number.required('Required').min(1, 'Required'),
}).required('Required');
export const multipleListing = array(requiredListing);
export const requiredMultipleListing = array(requiredListing).min(
  1,
  'At least 1 selection required',
);
