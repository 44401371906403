import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {type TripAddStopsStop} from './useAddTripStops';
import {tripApi} from '../../../api';
import {routes} from '../../../routes';
import {type AddStatus} from '../../common/types/AddStatus';
import {type TripAddDetailsValues} from '../components/tripAdd/TripAddDetails';
import {mapTripAddStopsToPostStops} from '../helpers/mapTripAddStopsToPostStops';

export const useAddTrip = (
  tripDetails: TripAddDetailsValues,
  stops: TripAddStopsStop[],
) => {
  const navigate = useNavigate();
  const [addStatus, setAddStatus] = useState<AddStatus>('idle');

  const addTrip = async () => {
    try {
      setAddStatus('adding');
      const contractId = tripDetails.contract.value;
      const vehicleId = tripDetails.vehicle.value;
      const driverId = tripDetails.driver.value;
      const {tripStart} = tripDetails;
      const response = await tripApi.apiMasterTripPost({
        body: {
          tripStart,
          contractId,
          vehicleId,
          driverId,
          sendNotification: true,
          stops: mapTripAddStopsToPostStops(stops),
        },
      });
      setAddStatus('success');
      navigate(
        routes.replaceParams(routes.trip.masterTripId, {
          masterTripId: response.id,
        }),
      );
    } catch {
      setAddStatus('error');
      toast.error('Failed to create trip');
    }
  };

  return {addTrip, addStatus};
};
