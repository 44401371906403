import type {DataTypeProvider} from '@devexpress/dx-react-grid';
import TextField from '@mui/material/TextField';

import {onDateTypeProviderKeyDown} from '../helpers/onDateTypeProviderKeyDown';
import {type VantageTableColumn} from '../hooks/useVantageTableConfig';

interface ValueEditorTextFieldFilterProps<TableData extends object> {
  columnConfig: VantageTableColumn<TableData>;
}

export function ValueEditorStringFilter<TableData extends object>({
  value,
  columnConfig,
  onKeyDown,
  onValueChange,
  onBlur,
  onFocus,
  autoFocus,
  disabled,
}: Omit<DataTypeProvider.ValueEditorProps, 'column'> &
  ValueEditorTextFieldFilterProps<TableData>) {
  return (
    <TextField
      autoCorrect="off"
      autoCapitalize="off"
      autoSave="off"
      autoComplete="off"
      id={columnConfig.name as string}
      name={columnConfig.name as string}
      type="string"
      size="small"
      placeholder="Filter..."
      variant="standard"
      value={value ?? ''}
      sx={{mr: 1, my: 0.5, ml: 0.5}}
      disabled={columnConfig.enableFilter !== true || disabled}
      onKeyDown={onDateTypeProviderKeyDown(onKeyDown)}
      onChange={(event) => {
        onValueChange(event.target.value);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      autoFocus={autoFocus}
      fullWidth
    />
  );
}
