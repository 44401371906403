import {styled} from '@mui/material/styles';

import {getNestedBorderRadius} from '../../../../theme/getNestedBorderRadius';
import {addShouldNotForwardProps} from '../../../common/helpers/addShouldNotForwardProps';

export const TripCardAvatarBox = styled('div', {
  shouldForwardProp: addShouldNotForwardProps('transparent'),
})<{transparent?: boolean}>(({theme, transparent = false}) => ({
  display: 'grid',
  placeContent: 'center',
  backgroundColor: transparent
    ? 'transparent'
    : theme.palette.background.default,
  borderRadius: getNestedBorderRadius(theme, 1),
  padding: theme.spacing(0.7),
}));
