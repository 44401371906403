import {useMemo} from 'react';

import {type Order} from '@onroadvantage/onroadvantage-api';

import {OrderForm, type OrderFormValues} from './OrderForm';
import {type EditProps} from '../../common/types/EditProps';
import {useEditOrder} from '../hooks/useEditOrder';

export function OrderDetails({item, ...editParams}: EditProps<Order>) {
  const {onSubmit} = useEditOrder(editParams);

  const initialValues = useMemo<OrderFormValues>(
    () => ({
      orderNumber: item?.orderNumber ?? '',
      customer: {
        value: item?.customerId ?? 0,
        label: item?.customer?.name,
      },
      primeMover: item?.primeMover ?? '',
      upliftPoint: {
        value: item?.upliftPointId ?? 0,
        label: item?.upliftPoint.name,
      },
      offloadPoint: {
        value: item?.offloadPointId ?? 0,
        label: item?.offloadPoint.name,
      },
      transporter: {
        value: item?.transporterId ?? 0,
        label: item?.transporter?.name,
      },
      status: item?.status,
      depotDeparture: item?.depotDeparture ?? new Date(),
      actualDispatch: item?.actualDispatch ?? new Date(),
      deliverByDateTime: item?.deliverByDateTime ?? new Date(),
      contract: {value: item?.contractId ?? 0, label: item?.contract?.name},
      deliveryNoteNumber: item?.deliveryNoteNumber ?? '',
      customerReferenceNumber: item?.customerReferenceNumber ?? '',
    }),
    [item],
  );

  return (
    <OrderForm
      permission="order:edit"
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
}
