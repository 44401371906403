import {useEffect} from 'react';

import {useAuthStore} from '../stores/authStore';
import {useEventStore} from '../stores/eventStore';

export const useLoadEventsCounts = () => {
  const authStatus = useAuthStore((state) => state.authStatus);
  const eventsCountTimeoutRef = useEventStore(
    (state) => state.eventsCountTimeoutRef,
  );
  const loadEventsCount = useEventStore((state) => state.loadEventsCount);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      void loadEventsCount('autoRefresh');
    } else if (eventsCountTimeoutRef != null) {
      clearTimeout(eventsCountTimeoutRef);
    }
    return () => {
      if (eventsCountTimeoutRef != null) {
        clearTimeout(eventsCountTimeoutRef);
      }
    };
    // eslint-disable-next-line
  }, [authStatus]);
};
