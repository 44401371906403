import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {type InferType, object} from 'yup';

import {TripAddStopsTripDetails} from './TripAddStopsTripDetails';
import {requiredListing} from '../../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageForm} from '../../../common/components/form/VantageForm';
import {VantageFormActions} from '../../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../../common/components/form/VantageFormContent';
import {VantageFormSubmit} from '../../../common/components/form/VantageFormSubmit';
import {useTripAddDetailsContext} from '../../contexts/TripAddDetailsContext';

const tripAddStopsDepotValidationSchema = object({
  node: requiredListing.required('Required'),
});
export type TripAddStopsDepotValues = InferType<
  typeof tripAddStopsDepotValidationSchema
>;

interface TripAddStopsDepotProps {
  onSubmit: (values: TripAddStopsDepotValues) => void;
}

export function TripAddStopsSite({onSubmit}: TripAddStopsDepotProps) {
  const {onPreviousStep} = useTripAddDetailsContext();
  return (
    <VantageForm
      permission="trip:add"
      initialValues={{node: {value: 0}}}
      onSubmit={onSubmit}
      validationSchema={tripAddStopsDepotValidationSchema}
    >
      <Stack flex={1} alignItems="flex-start" direction="row">
        <VantageFormContent flex={1}>
          <VantageAutocompleteFieldWithListing
            name="node"
            models="Node"
            label="Site"
            placeholder="Enter and select the trip site"
            formik
          />
        </VantageFormContent>
        <TripAddStopsTripDetails />
      </Stack>
      <VantageFormActions>
        <Button variant="outlined" color="inherit" onClick={onPreviousStep}>
          Back
        </Button>
        <VantageFormSubmit label="Add Stop" />
      </VantageFormActions>
    </VantageForm>
  );
}
