import {webMasterTripApi} from '../../../api';
import {useLoad} from '../../common/hooks/useLoad';

export const useLoadTripsDetail = (id: number) => {
  const {item, updateState, loadStatus} = useLoad(async () =>
    webMasterTripApi.apiWebMasterTripMasterTripIdDetailGet({
      masterTripId: id,
    }),
  );
  return {item, id, loadStatus, updateState};
};
