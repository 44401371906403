import {useMemo} from 'react';

import {type Theme, useTheme} from '@mui/material/styles';
import {type Broadcast, type Voice} from '@onroadvantage/onroadvantage-api';

import {BroadcastsRowDetailRowDetail} from './BroadcastsRowDetailRowDetail';
import {config} from '../../../config';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTable} from '../../common/components/table/VantageTable';

const tableConfig = (theme: Theme): VantageTableConfig<Voice> => ({
  columns: [
    {name: 'toNumber', label: 'To Number', type: 'string'},
    {name: 'scheduledDateTime', label: 'Scheduled Date', type: 'datetime'},
    {name: 'twilioStatus', label: 'Status', type: 'string'},
  ],
  fitHeight: true,
  backgroundColor: config.tableTheme.nestedLevels[theme.palette.mode].level1,
});

export function BroadcastsRowDetail({row}: {row: Broadcast}) {
  const theme = useTheme();
  const memoizedConfig = useMemo(() => tableConfig(theme), [theme]);
  return (
    <VantageTable
      config={memoizedConfig}
      data={row.voiceCalls ?? []}
      rowId="id"
      rowDetail={BroadcastsRowDetailRowDetail}
    />
  );
}
