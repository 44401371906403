interface SplitByCapitalsOptions {
  capabilitiesFirstLetter?: boolean;
  capabilitiesAllLetters?: boolean;
  lowercaseAllLetters?: boolean;
  joinCharacter?: string;
}

export const splitByCapitals = (
  name: string,
  options?: SplitByCapitalsOptions,
) => {
  const splitName = name.split(/(?=[A-Z])/);
  if (options != null) {
    const {
      capabilitiesFirstLetter = false,
      capabilitiesAllLetters = false,
      lowercaseAllLetters = false,
      joinCharacter = ' ',
    } = options;
    let modifiedSplitName = splitName;

    if (lowercaseAllLetters) {
      modifiedSplitName = splitName.map((n) => n.toLowerCase());
    }

    if (capabilitiesFirstLetter) {
      modifiedSplitName = splitName.map(
        (n) => n.charAt(0).toUpperCase() + n.slice(1),
      );
    }

    if (capabilitiesAllLetters) {
      modifiedSplitName = splitName.map((n) => n.toUpperCase());
    }

    return modifiedSplitName.join(joinCharacter);
  }

  return splitName.join(' ');
};
