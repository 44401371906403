import {type ChangeEvent, useCallback} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';
import TextField from '@mui/material/TextField';

import {checkIsFieldDisabled} from '../helpers/checkIsFieldDisabled';
import {onDateTypeProviderKeyDown} from '../helpers/onDateTypeProviderKeyDown';
import type {VantageTableColumn} from '../hooks/useVantageTableConfig';

interface ValueEditorTextFieldProps<TableData extends object>
  extends DataTypeProvider.ValueEditorProps {
  rowId: keyof TableData;
  columnConfig: VantageTableColumn<TableData>;
}

export function ValueEditorTextField<TableData extends object>({
  rowId,
  row,
  columnConfig,
  onValueChange,
  onKeyDown,
  value,
  ...props
}: ValueEditorTextFieldProps<TableData>) {
  if (
    columnConfig == null ||
    (columnConfig?.type !== 'string' && columnConfig?.type !== 'number')
  ) {
    throw new Error(
      `ValueEditorTextField can only be used with columns of type 'string' or 'number'`,
    );
  }

  const disabled =
    checkIsFieldDisabled(row, rowId, columnConfig) || props.disabled;

  const name = columnConfig.name as string;
  const id = `${name}${row[rowId]?.toString() ?? ''}`;
  let defaultValue = value;

  if (columnConfig.getValue != null) {
    defaultValue = columnConfig.getValue(row as TableData);
  }

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (columnConfig.type === 'number') {
        const parsedValue = parseFloat(event.target.value);
        onValueChange(Number.isNaN(parsedValue) ? 0 : parsedValue);
      }
      onValueChange(event.target.value);
    },
    [columnConfig.type, onValueChange],
  );

  if (disabled) {
    return defaultValue;
  }

  return (
    <TextField
      {...props}
      id={id}
      name={columnConfig.name as string}
      onKeyDown={onDateTypeProviderKeyDown(onKeyDown)}
      onChange={handleChange}
      defaultValue={defaultValue}
      sx={{my: -0.5}}
      type={columnConfig.type}
      size="small"
      margin="none"
      variant="outlined"
      fullWidth
    />
  );
}
