import {type SubNode} from '@onroadvantage/onroadvantage-api';

import {nodeApi} from '../../../api';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useInlineEdit} from '../../common/hooks/useInlineEdit';

export const useEditSiteSubSite = (
  nodeId: number,
  updateSubNode: (subNode: SubNode) => void,
) => {
  return useInlineEdit(
    async (subNodeId, newValues) => {
      const response = await nodeApi.apiNodeNodeIdSubNodeSubNodeIdPatch({
        nodeId,
        subNodeId: parseInt(String(subNodeId), 10),
        body: {
          name:
            isObjectWithKeys(newValues, 'name') &&
            typeof newValues.name === 'string'
              ? newValues.name
              : undefined,
          description:
            isObjectWithKeys(newValues, 'description') &&
            typeof newValues.description === 'string'
              ? newValues.description
              : undefined,
          externalReference:
            isObjectWithKeys(newValues, 'externalReference') &&
            typeof newValues.externalReference === 'string'
              ? newValues.externalReference
              : undefined,
          taskTemplateId:
            isObjectWithKeys(newValues, 'taskTemplateName') &&
            isObjectWithKeys(newValues.taskTemplateName, 'value') &&
            typeof newValues.taskTemplateName.value === 'number'
              ? newValues.taskTemplateName.value
              : undefined,
        },
      });
      updateSubNode(response);
    },
    [nodeId, updateSubNode],
  );
};
