import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import {VantageCard} from '../../common/components/VantageCard';
import {useStepper} from '../../common/hooks/useStepper';
import {EventBulkClosingEvents} from '../components/EventBulkClosingEvents';
import {EventBulkClosingSearchForm} from '../components/EventBulkClosingSearchForm';
import {useBulkCloseEvents} from '../hooks/useBulkCloseEvents';
import {useSearchWebEvents} from '../hooks/useSearchWebEvents';

const eventBulkClosingSteps = ['Search', 'Bulk close'] as const;

export default function EventBulkClosing() {
  const {
    onNextStep,
    onPreviousStep,
    completedSteps,
    activeStep,
    activeStepPosition,
    onGoToStep,
    onResetStepper,
  } = useStepper(eventBulkClosingSteps);
  const {
    formState,
    onSearch,
    searchStatus,
    webEvents,
    resetSearch,
    selectedWebEvents,
    setSelectedWebEvents,
  } = useSearchWebEvents(onNextStep);
  const {onSubmit} = useBulkCloseEvents(selectedWebEvents, () => {
    onResetStepper();
    resetSearch();
  });

  return (
    <VantageCard scrollable>
      <Stepper
        activeStep={activeStepPosition}
        sx={{p: 1, bgcolor: 'background.default'}}
      >
        {eventBulkClosingSteps.map((step) => (
          <Step
            key={step}
            completed={completedSteps.includes(step)}
            active={activeStep === step}
            onClick={() => {
              onGoToStep(step);
            }}
            sx={{cursor: completedSteps.includes(step) ? 'pointer' : 'default'}}
          >
            <StepLabel color="primary">{step as string}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Collapse in={searchStatus === 'warning'} mountOnEnter unmountOnExit>
        <Alert severity="warning" sx={{mx: 2, my: 1}}>
          No events for this filter
        </Alert>
      </Collapse>
      {activeStep === 'Search' && (
        <EventBulkClosingSearchForm
          initialValues={formState}
          onSearch={onSearch}
          onReset={resetSearch}
        />
      )}
      {activeStep === 'Bulk close' && (
        <EventBulkClosingEvents
          webEvents={webEvents}
          onCloseEvents={onSubmit}
          onPreviousStep={onPreviousStep}
          selectedWebEvents={selectedWebEvents}
          onSelectedWebEventsChange={setSelectedWebEvents}
        />
      )}
    </VantageCard>
  );
}
