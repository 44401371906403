import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyContracts = lazy(async () => import('./pages/Contracts'));
const LazyContractAdd = lazy(async () => import('./pages/ContractAdd'));
const LazyContract = lazy(async () => import('./pages/Contract'));

export const contractRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.contract.list)},
  {
    path: routes.getRelativePath(routes.contract.list, routes.contract.base),
    element: <LazyContracts />,
    loader: () => {
      document.title = 'Contracts';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Contracts'}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.contract.add, routes.contract.base),
    element: <LazyContractAdd />,
    loader: () => {
      document.title = 'Add Contract';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Contracts', path: routes.contract.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.contract.contractId,
      routes.contract.base,
    ),
    element: <LazyContract />,
    loader: () => {
      document.title = 'View Contract';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Contracts', path: routes.contract.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
