import {type Dispatch, memo, type SetStateAction} from 'react';

import BiotechIcon from '@mui/icons-material/BiotechRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

import {type EventsPaneTab} from './EventsPaneTabs';
import {getNestedBorderRadius} from '../../../../theme/getNestedBorderRadius';
import {type ExpandedSearchForm} from '../../hooks/useSearchEvents';

interface EventsPaneSearchButtonsProps {
  activeTab: EventsPaneTab;
  expandedSearchForm: ExpandedSearchForm;
  setExpandedSearchForm: Dispatch<SetStateAction<ExpandedSearchForm>>;
}

export const EventsPaneSearchButtons = memo(function EventsPaneSearchButtons({
  activeTab,
  expandedSearchForm,
  setExpandedSearchForm,
}: EventsPaneSearchButtonsProps) {
  return (
    <Stack direction="row" spacing={1} p={1}>
      <SearchButton
        disabled={expandedSearchForm === 'advanced'}
        variant="contained"
        onClick={() => {
          setExpandedSearchForm('advanced');
        }}
        startIcon={<BiotechIcon />}
      >
        Advanced Search
      </SearchButton>
      {activeTab === 'Inbox' && (
        <SearchButton
          disabled={expandedSearchForm === 'basic'}
          variant="outlined"
          color="inherit"
          onClick={() => {
            setExpandedSearchForm('basic');
          }}
          startIcon={<SearchIcon />}
        >
          Search
        </SearchButton>
      )}
      <Zoom in={expandedSearchForm !== 'none'}>
        <IconButton
          size="small"
          style={{marginLeft: 'auto'}}
          onClick={() => {
            setExpandedSearchForm('none');
          }}
        >
          <CloseIcon />
        </IconButton>
      </Zoom>
    </Stack>
  );
});

const SearchButton = styled(Button)(({theme}) => ({
  margin: theme.spacing(1),
  borderRadius: getNestedBorderRadius(theme, 1),
}));
