import {createContext, useContext} from 'react';

import {type Report} from '@onroadvantage/onroadvantage-api';

export interface ReportContextProps {
  report: Report | null;
  reportId: number;
  updateState: (report: Report) => void;
}

export const ReportContext = createContext<ReportContextProps | null>(null);

export const useReportContext = () => {
  const context = useContext(ReportContext);
  if (context == null) {
    throw new Error(
      'useReportContext must be used within a ReportContextProvider',
    );
  }
  return context;
};
