import {useCallback, useState} from 'react';

import {type VantageTableOnInlineEdit} from '../components/table/VantageTable';
import {catchHandler} from '../helpers/catchHandler';
import type {SubmitStatus} from '../types/SubmitStatus';

export const useInlineEdit = (
  updateHandler: (
    rowId: number | string | symbol,
    newValues: Record<string, unknown>,
  ) => Promise<void>,
  deps: unknown[] = [],
) => {
  const [updateStatus, setUpdateStatus] = useState<SubmitStatus>('idle');

  const onInlineEdit = useCallback<VantageTableOnInlineEdit>(
    async (rowId, newValues) => {
      setUpdateStatus('submitting');
      try {
        await updateHandler(rowId, newValues);
        setUpdateStatus('success');
        return 'success';
      } catch (error) {
        const {type} = catchHandler(error, 'Failed to update.');

        setUpdateStatus(type);

        return 'catch';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateHandler, ...deps],
  );

  return {updateStatus, onInlineEdit};
};
