import {useCallback} from 'react';

import {
  DataTypeProvider,
  type DataTypeProviderProps,
} from '@devexpress/dx-react-grid';

import {
  DateTypeProviderEditor,
  type DateTypeProviderEditorProps,
} from './DateTypeProviderEditor';
import {DateTimeFormatService} from '../../../services/DateTimeFormatService';

export function DateTypeFormatter({
  value,
}: DataTypeProvider.ValueFormatterProps) {
  if (value == null || !(typeof value === 'string' || value instanceof Date)) {
    return <>-</>;
  }

  return <>{new DateTimeFormatService().formatDate(value)}</>;
}

export default function DateTypeProvider<TableData extends object>({
  config,
  loading,
  rowId,
  ...props
}: DataTypeProviderProps & DateTypeProviderEditorProps<TableData>) {
  const renderEditorComponent = useCallback(
    (editorProps: DataTypeProvider.ValueEditorProps) => (
      <DateTypeProviderEditor
        {...editorProps}
        config={config}
        loading={loading}
        rowId={rowId}
      />
    ),
    [config, loading, rowId],
  );

  return (
    <DataTypeProvider
      formatterComponent={DateTypeFormatter}
      editorComponent={renderEditorComponent}
      {...props}
    />
  );
}
