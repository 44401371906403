import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyOptimisations = lazy(async () => import('./pages/Optimisations'));
const LazyOptimiserAdd = lazy(async () => import('./pages/OptimiserAdd'));
const LazyOptimiser = lazy(async () => import('./pages/Optimiser'));
const LazyOptimiserOutlet = lazy(async () => import('./pages/OptimiserOutlet'));
const LazyOptimiserTrip = lazy(async () => import('./pages/OptimiserTrip'));
const LazyOptimiserTripAdd = lazy(
  async () => import('./pages/OptimiserTripAdd'),
);

export const optimiserRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.optimiser.list)},
  {
    path: routes.getRelativePath(routes.optimiser.list, routes.optimiser.base),
    element: <LazyOptimisations />,
    loader: () => {
      document.title = 'Optimiser';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Optimiser'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.optimiser.add, routes.optimiser.base),
    element: <LazyOptimiserAdd />,
    loader: () => {
      document.title = 'Add Optimiser';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Optimiser', path: routes.optimiser.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.optimiser.optimiserId,
      routes.optimiser.base,
    ),
    element: <LazyOptimiserOutlet />,
    loader: () => {
      document.title = 'View Optimiser';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Optimiser', path: routes.optimiser.list},
          {label: 'View'},
        ],
      });
      return null;
    },
    children: [
      {
        path: '',
        element: <LazyOptimiser />,
        loader: () => {
          document.title = 'View Optimiser';
          useBreadcrumbStore.setState({
            breadcrumbs: [
              {label: 'Optimiser', path: routes.optimiser.list},
              {label: 'View'},
            ],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.optimiser.trip.add,
          routes.optimiser.trip.base,
        ),
        element: <LazyOptimiserTripAdd />,
        loader: ({params}) => {
          if (params.optimiserId != null) {
            document.title = 'View Optimiser';
            useBreadcrumbStore.setState({
              breadcrumbs: [
                {label: 'Optimiser', path: routes.optimiser.list},
                {
                  label: 'View',
                  path: routes.addQueryParams(
                    routes.replaceParams(routes.optimiser.optimiserId, {
                      optimiserId: params.optimiserId,
                    }),
                    {
                      tab: 'Trips',
                    },
                  ),
                },
                {label: 'Trip Add'},
              ],
            });
          }
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.optimiser.trip.planningMasterTripId,
          routes.optimiser.trip.base,
        ),
        element: <LazyOptimiserTrip />,
        loader: ({params}) => {
          if (params.optimiserId != null) {
            useBreadcrumbStore.setState({
              breadcrumbs: [
                {label: 'Optimiser', path: routes.optimiser.list},
                {
                  label: 'View',
                  path: routes.addQueryParams(
                    routes.replaceParams(routes.optimiser.optimiserId, {
                      optimiserId: params.optimiserId,
                    }),
                    {
                      tab: 'Trips',
                    },
                  ),
                },
                {label: 'Trip View'},
              ],
            });
          }
          return null;
        },
      },
    ],
  },
];
