import {array, boolean, date, type InferType, object, string} from 'yup';

import {ReportScheduleFormParameters} from './ReportScheduleFormParameters';
import {ReportScheduleFormRecipients} from './ReportScheduleFormRecipients';
import {ReportScheduleFormSchedule} from './ReportScheduleFormSchedule';
import {multipleListing} from '../../../common/components/form/validationSchemas';
import {VantageForm} from '../../../common/components/form/VantageForm';
import {VantageFormActions} from '../../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../../common/components/form/VantageTextField';
import {type FormProps} from '../../../common/types/FormProps';
import {reportParameterSchema} from '../../schemas/reportParameterSchema';

const validationSchema = object({
  name: string().required('Required.'),
  description: string().required('Required.'),
  parameters: array(reportParameterSchema),
  recurring: boolean().required('Required.'),
  /** Recipients */
  contactGroupsTo: multipleListing,
  contactGroupsCC: multipleListing,
  contactGroupsBCC: multipleListing,
  contactsTo: multipleListing,
  contactsCC: multipleListing,
  contactsBCC: multipleListing,
  /** None Recurring Scheduling */
  dateTime: date().when('recurring', {
    is: false,
    then: (schema) => schema.required('Required.'),
  }),
  /** Recurring Scheduling */
  monday: boolean(),
  tuesday: boolean(),
  wednesday: boolean(),
  thursday: boolean(),
  friday: boolean(),
  saturday: boolean(),
  sunday: boolean(),
  startDate: date().when('recurring', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  endDate: date().when('recurring', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
  time: date().when('recurring', {
    is: true,
    then: (schema) => schema.required('Required.'),
  }),
});

export type ReportScheduleFormValues = InferType<typeof validationSchema>;

export function ReportScheduleForm(props: FormProps<ReportScheduleFormValues>) {
  return (
    <VantageForm {...props} validationSchema={validationSchema}>
      <VantageFormContent>
        <VantageFormFieldsRow>
          <VantageTextField
            name="name"
            label="Name"
            placeholder="Enter the report schedule name"
            formik
          />
          <VantageTextField
            name="description"
            label="Description"
            placeholder="Enter the report schedule description"
            formik
          />
        </VantageFormFieldsRow>
        <ReportScheduleFormParameters />
        <ReportScheduleFormRecipients />
        <ReportScheduleFormSchedule />
      </VantageFormContent>
      <VantageFormActions sticky>
        <VantageFormSubmit />
      </VantageFormActions>
    </VantageForm>
  );
}
