import ButtonBase, {type ButtonBaseProps} from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';

import {type MenuItemLevel} from './menuItems/menuItems';
import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';

interface MenuItemButtonProps extends ButtonBaseProps {
  $level: MenuItemLevel;
  $isActive: boolean;
}

const levelBackgroundColors: Record<
  'light' | 'dark',
  Record<MenuItemLevel, string>
> = {
  light: {
    3: 'rgba(230,230,230,0.3)',
    2: 'rgba(230,230,230,0.2)',
    1: 'unset',
  },
  dark: {
    3: 'rgba(3,3,3,0.3)',
    2: 'rgba(3,3,3,0.2)',
    1: 'unset',
  },
};

export const MenuItemButton = styled(ButtonBase, {
  shouldForwardProp: addShouldNotForwardProps('$level', '$isActive'),
})<MenuItemButtonProps>(({theme, $level, $isActive}) => ({
  padding: theme.spacing(1),
  textAlign: 'start',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  paddingLeft: theme.spacing($level > 1 ? $level * 2 : 1),
  fontSize: '0.928571rem',
  color: $isActive ? theme.palette.primary.main : theme.palette.action.active,
  lineHeight: 1.5,
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  backgroundColor: levelBackgroundColors[theme.palette.mode][$level],
}));
