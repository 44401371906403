import SearchIcon from '@mui/icons-material/SearchRounded';

import {
  VantageTextField,
  type VantageTextFieldProps,
} from '../form/VantageTextField';

export function NavigationSearch(props: Omit<VantageTextFieldProps, 'name'>) {
  return (
    <VantageTextField
      name="menuSearch"
      sx={{mb: 1, mx: 1}}
      size="small"
      placeholder="Search"
      {...props}
      endAdornment={<SearchIcon />}
      fullWidth={false}
    />
  );
}
