import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import MasterRoutes from './pages/MasterRoutes';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyMasterRouteAdd = lazy(async () => import('./pages/MasterRouteAdd'));
const LazyMasterRouteOutlet = lazy(
  async () => import('./pages/MasterRouteOutlet'),
);
const LazyMasterRouteLegAdd = lazy(
  async () => import('./pages/MasterRouteLegAdd'),
);
const LazyMasterRouteLeg = lazy(async () => import('./pages/MasterRouteLeg'));
const LazyMasterRoute = lazy(async () => import('./pages/MasterRoute'));

export const masterRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.masterRoute.list)},
  {
    path: routes.getRelativePath(
      routes.masterRoute.list,
      routes.masterRoute.base,
    ),
    element: <MasterRoutes />,
    loader: () => {
      document.title = 'Master Routes';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Master Routes'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.masterRoute.add,
      routes.masterRoute.base,
    ),
    element: <LazyMasterRouteAdd />,
    loader: () => {
      document.title = 'Add Master Route';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Master Routes', path: routes.masterRoute.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.masterRoute.masterRouteId,
      routes.masterRoute.base,
    ),
    element: <LazyMasterRouteOutlet />,
    children: [
      {
        path: '',
        element: <LazyMasterRoute />,
        loader: () => {
          document.title = 'View Master Route';
          useBreadcrumbStore.setState({
            breadcrumbs: [
              {label: 'Master Routes', path: routes.masterRoute.list},
              {label: 'View'},
            ],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.masterRoute.leg.add,
          routes.masterRoute.masterRouteId,
        ),
        element: <LazyMasterRouteLegAdd />,
        loader: ({params}) => {
          let masterRouteId: number | null = null;
          if (
            params.masterRouteId != null &&
            !Number.isNaN(parseInt(params.masterRouteId, 10))
          ) {
            masterRouteId = parseInt(params.masterRouteId, 10);
          }
          document.title = 'Add Master Route Leg';
          useBreadcrumbStore.setState({
            breadcrumbs: [
              {label: 'Master Routes', path: routes.masterRoute.list},
              {
                label: 'View',
                path:
                  masterRouteId != null
                    ? routes.addQueryParams(
                        routes.replaceParams(routes.masterRoute.masterRouteId, {
                          masterRouteId,
                        }),
                        {tab: 'Legs'},
                      )
                    : undefined,
              },
              {label: 'Add Leg'},
            ],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.masterRoute.leg.masterRouteLegId,
          routes.masterRoute.masterRouteId,
        ),
        element: <LazyMasterRouteLeg />,
        loader: ({params}) => {
          let masterRouteId: number | null = null;
          if (
            params.masterRouteId != null &&
            !Number.isNaN(parseInt(params.masterRouteId, 10))
          ) {
            masterRouteId = parseInt(params.masterRouteId, 10);
          }
          document.title = 'View Master Route Leg';
          useBreadcrumbStore.setState({
            breadcrumbs: [
              {label: 'Master Routes', path: routes.masterRoute.list},
              {
                label: 'View',
                path:
                  masterRouteId != null
                    ? routes.addQueryParams(
                        routes.replaceParams(routes.masterRoute.masterRouteId, {
                          masterRouteId,
                        }),
                        {tab: 'Legs'},
                      )
                    : undefined,
              },
              {label: 'Leg'},
            ],
          });
          return null;
        },
      },
    ],
  },
];
