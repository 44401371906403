import {useSearchParams} from 'react-router-dom';

import NewVantageScreen from './NewVantageScreen';
import {AuthenticationContainer} from '../components/AuthenticationContainer';
import {AuthStateHandler} from '../components/AuthStateHandler';
import {LoginForm} from '../components/LoginForm';
import {useLoadUserMe} from '../hooks/useLoadUserMe';

export default function Login() {
  useLoadUserMe();
  const [searchParams] = useSearchParams();

  if (searchParams.get('reason') === 'newVantage') {
    return <NewVantageScreen />;
  }

  return (
    <AuthStateHandler>
      <AuthenticationContainer>
        <LoginForm />
      </AuthenticationContainer>
    </AuthStateHandler>
  );
}
