import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyProducts = lazy(async () => import('./pages/Products'));
const LazyProductAdd = lazy(async () => import('./pages/ProductAdd'));
const LazyProduct = lazy(async () => import('./pages/Product'));

export const productRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.product.list)},
  {
    path: routes.getRelativePath(routes.product.list, routes.product.base),
    element: <LazyProducts />,
    loader: () => {
      document.title = 'Products';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Products'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.product.add, routes.product.base),
    element: <LazyProductAdd />,
    loader: () => {
      document.title = 'Add Product';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Products', path: routes.product.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.product.productId, routes.product.base),
    element: <LazyProduct />,
    loader: () => {
      document.title = 'View Product';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Products', path: routes.product.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
