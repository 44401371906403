import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyWorkflowConfigs = lazy(async () => import('./pages/WorkflowConfigs'));
const LazyWorkflowConfigAdd = lazy(
  async () => import('./pages/WorkflowConfigAdd'),
);
const LazyWorkflowConfig = lazy(async () => import('./pages/WorkflowConfig'));

export const workflowConfigRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.workflowConfig.list)},
  {
    path: routes.getRelativePath(
      routes.workflowConfig.list,
      routes.workflowConfig.base,
    ),
    element: <LazyWorkflowConfigs />,
    loader: () => {
      document.title = 'Workflow Configs';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Workflow Configs'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.workflowConfig.add,
      routes.workflowConfig.base,
    ),
    element: <LazyWorkflowConfigAdd />,
    loader: () => {
      document.title = 'Add Workflow Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Workflow Configs', path: routes.workflowConfig.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.workflowConfig.workflowConfigId,
      routes.workflowConfig.base,
    ),
    element: <LazyWorkflowConfig />,
    loader: () => {
      document.title = 'View Workflow Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Workflow Configs', path: routes.workflowConfig.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
