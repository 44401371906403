import {type ReactNode} from 'react';

import Card, {type CardProps} from '@mui/material/Card';
import {styled} from '@mui/material/styles';

import {config} from '../../../config';
import {addShouldNotForwardProps} from '../helpers/addShouldNotForwardProps';

interface VantageCardProps extends CardProps {
  children: ReactNode;
  stretch?: boolean;
  disableGutters?: boolean;
  scrollable?: boolean;
}

export function VantageCard({
  stretch = false,
  disableGutters = false,
  scrollable = false,
  children,
  ...props
}: VantageCardProps) {
  return (
    <StyledCard
      $stretch={stretch}
      $disableGutters={disableGutters}
      $scrollable={scrollable}
      {...props}
    >
      {children}
    </StyledCard>
  );
}

const StyledCard = styled(Card, {
  shouldForwardProp: addShouldNotForwardProps(
    '$stretch',
    '$disableGutters',
    '$scrollable',
  ),
})<{$stretch: boolean; $disableGutters: boolean; $scrollable: boolean}>(
  ({theme, $stretch, $disableGutters, $scrollable}) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    ...($stretch && {flex: 1}),
    ...(!$disableGutters && {
      margin: theme.spacing(config.theme.gutterSpacing),
    }),
    ...($scrollable && {overflowY: 'auto'}),
  }),
);
