import SettingsIcon from '@mui/icons-material/SettingsRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const settingsHomeMenuItems: HomeMenuItemCard = {
  title: 'Settings',
  icon: <SettingsIcon color="action" />,
  menuItems: [
    {
      label: 'Permissions',
      path: routes.permission.list,
      permissions: ['permission:list'],
    },
    {
      label: 'Profiles',
      path: routes.profile.list,
      permissions: ['profile:list'],
    },
    {
      label: 'Roles',
      path: routes.role.list,
      permissions: ['role:list'],
    },
    {
      label: 'Task Templates',
      path: routes.taskTemplate.list,
      permissions: ['taskTemplate:list'],
    },
    {
      label: 'Users',
      path: routes.role.list,
      permissions: ['user:list'],
    },
  ],
};
