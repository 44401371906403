import {createContext, useContext} from 'react';

export interface OrderStepperContextProps {
  onNextStep: () => void;
  onPreviousStep: () => void;
  onResetStepper: () => void;
}

export const OrderStepperContext =
  createContext<OrderStepperContextProps | null>(null);

export const useOrderStepperContext = () => {
  const context = useContext(OrderStepperContext);
  if (context == null) {
    throw new Error(
      'useOrderStepperContext must be used within a OrderStepperProvider',
    );
  }
  return context;
};
