import {lazy, useState} from 'react';

import Stack from '@mui/material/Stack';

import {VantageTab} from '../../common/components/tabs/VantageTab';
import {VantageTabs} from '../../common/components/tabs/VantageTabs';

const LazyDailyVehicleWorkflows = lazy(
  async () => import('../pages/DailyVehicleWorkflows'),
);
const LazyAdhocVehicleWorkflows = lazy(
  async () => import('../pages/AdhocVehicleWorkflows'),
);
const LazyYardflowVehicleWorkflows = lazy(
  async () => import('../pages/YardflowVehicleWorkflows'),
);

const vehicleWorkflowTabs = ['Daily', 'Adhoc', 'Yardflow'] as const;
type VehicleWorkflowViewTab = (typeof vehicleWorkflowTabs)[number];

export function VehicleWorkflowTabs() {
  const [activeTab, setActiveTab] = useState<VehicleWorkflowViewTab>('Daily');
  return (
    <Stack height="100%">
      <VantageTabs activeTab={activeTab} onTabChange={setActiveTab}>
        {vehicleWorkflowTabs.map((tab) => (
          <VantageTab key={tab} value={tab} label={tab} />
        ))}
      </VantageTabs>
      {activeTab === 'Daily' && <LazyDailyVehicleWorkflows />}
      {activeTab === 'Adhoc' && <LazyAdhocVehicleWorkflows />}
      {activeTab === 'Yardflow' && <LazyYardflowVehicleWorkflows />}
    </Stack>
  );
}
