import {useCallback, useMemo} from 'react';

import {
  type MenuItem,
  menuItems,
} from '../components/navigation/menuItems/menuItems';
import {useUserStore} from '../stores/userStore';

export const useFilteredMenuItems = (filter: string | null) => {
  const hasPermissions = useUserStore((state) => state.hasPermissions);

  const permissionFilterMenuItem = useCallback(
    (items: MenuItem[]) =>
      items.reduce((acc: MenuItem[], item) => {
        if (
          (item.children == null || item.children.length === 0) &&
          ((item.permission != null && hasPermissions(item.permission)) ||
            item.permission == null)
        ) {
          acc.push({...item});
        } else if (item.children != null && item.children.length > 0) {
          const filteredChildren = permissionFilterMenuItem(item.children);
          if (filteredChildren.length > 0) {
            acc.push({...item, children: filteredChildren});
          }
        }
        return acc;
      }, []),
    [hasPermissions],
  );

  const filterMenuItems = useCallback(
    (items: MenuItem[], newFilter: string) =>
      items.reduce((acc: MenuItem[], item) => {
        const matchesFilter = item.label
          .toLowerCase()
          .includes(newFilter.toLowerCase());
        if (matchesFilter) {
          // Push the item without children to the top level
          acc.push({...item, children: undefined});
        }
        if (item.children != null) {
          // Flatten the children into the top level
          const filteredChildren = filterMenuItems(item.children, newFilter);
          acc.push(...filteredChildren);
        }
        return acc;
      }, []),
    [],
  );

  return useMemo(() => {
    const filteredByPermissions = permissionFilterMenuItem(menuItems);

    if (filter == null || filter === '') {
      return filteredByPermissions;
    }

    return filterMenuItems(filteredByPermissions, filter);
  }, [filter, filterMenuItems, permissionFilterMenuItem]);
};
