import {
  type Listing,
  type OrderLineOrderPost,
  type PlanningOrderPost,
} from '@onroadvantage/onroadvantage-api';

import {orderApi} from '../../../api';
import {routes} from '../../../routes';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type OrderAddFormValues} from '../components/OrderAddForm';
import {type OrderLineAdd} from '../components/OrderLinesAdd';
import {type PlanningOrderAddFormValues} from '../components/PlanningOrderAddForm';
import {type PurchaseOrderAddFormValues} from '../components/PurchaseOrderAddForm';

export interface UseAddPurchaseOrderParams {
  orderFormValues: OrderAddFormValues | null;
  planningOrderFormValues: PlanningOrderAddFormValues | null;
  orderLines: OrderLineAdd[] | [];
}

const transformOrderLine = (orderLine: OrderLineAdd): OrderLineOrderPost => {
  return {
    productId: orderLine.product.value,
    quantity: orderLine.quantity,
  };
};

const createOrderRequestBody = (
  orderFormValues: OrderAddFormValues,
  orderLines: OrderLineAdd[] | [],
  purchaseOrder: string | Listing,
) => {
  let purchaseOrderNumber: string | undefined;
  let purchaseOrderId: number | undefined;

  if (typeof purchaseOrder === 'string') {
    purchaseOrderNumber = purchaseOrder;
  } else {
    purchaseOrderNumber = purchaseOrder.label;
    purchaseOrderId = purchaseOrder.value;
  }

  // Transform order lines
  const transformedOrderLines = orderLines.map(transformOrderLine);
  return {
    orderNumber: orderFormValues.orderNumber,
    customerReferenceNumber: orderFormValues.customerReferenceNumber,
    deliverByDatetime: orderFormValues.deliverByDateTime,
    contractId: orderFormValues.contract?.value,
    customerId: orderFormValues.customer?.value,
    deliveryNoteNumber: orderFormValues.deliveryNoteNumber,
    offloadPointNodeId: orderFormValues.offloadPoint?.value,
    upliftPointNodeId: orderFormValues.upliftPoint?.value,
    transporterId: orderFormValues.transporter?.value,
    orderLines: transformedOrderLines,
    purchaseOrderNumber,
    purchaseOrderId,
  };
};

const createPlanningOrderRequestBody = (
  planningOrderFormValues: PlanningOrderAddFormValues,
): PlanningOrderPost => {
  const skillLabels = planningOrderFormValues.skill.map((skill) => skill.label);
  const skillString = skillLabels.join(', ');

  return {
    upliftPointDuration: planningOrderFormValues.upliftPointDuration,
    upliftPointTimeWindows: planningOrderFormValues.upliftPointTimeWindows,
    offloadPointDuration: planningOrderFormValues.offloadPointDuration,
    offloadPointTimeWindows: planningOrderFormValues.offloadPointTimeWindows,
    skill: skillString,
    priority: planningOrderFormValues.priority,
    capacityDimension1: planningOrderFormValues.capacityDimension1,
    capacityDimension1Unit: planningOrderFormValues.capacityDimension1Unit,
    capacityDimension2: planningOrderFormValues.capacityDimension2 ?? undefined,
    capacityDimension2Unit:
      planningOrderFormValues.capacityDimension2Unit ?? undefined,
    capacityDimension3: planningOrderFormValues.capacityDimension3 ?? undefined,
    capacityDimension3Unit:
      planningOrderFormValues.capacityDimension3Unit ?? undefined,
    capacityDimension4: planningOrderFormValues.capacityDimension4 ?? undefined,
    capacityDimension4Unit:
      planningOrderFormValues.capacityDimension4Unit ?? undefined,
  };
};

export const useAddPurchaseOrder = ({
  orderFormValues,
  planningOrderFormValues,
  orderLines,
}: UseAddPurchaseOrderParams) => {
  return useSubmitForm<PurchaseOrderAddFormValues>(
    async (values) => {
      if (
        orderFormValues != null &&
        values.purchaseOrder != null &&
        orderLines.length > 0 &&
        planningOrderFormValues == null
      ) {
        const orderRequestBody = createOrderRequestBody(
          orderFormValues,
          orderLines,
          values.purchaseOrder,
        );

        await orderApi.apiOrderPost({body: orderRequestBody});
      }

      if (
        orderFormValues != null &&
        values.existingPurchaseOrders != null &&
        orderLines.length > 0 &&
        planningOrderFormValues == null
      ) {
        const orderRequestBody = createOrderRequestBody(
          orderFormValues,
          orderLines,
          values.existingPurchaseOrders,
        );

        await orderApi.apiOrderPost({body: orderRequestBody});
      }

      if (
        planningOrderFormValues != null &&
        values.purchaseOrder != null &&
        orderLines.length > 0 &&
        orderFormValues == null
      ) {
        const planningOrderRequestBody = createPlanningOrderRequestBody(
          planningOrderFormValues,
        );

        // Transform order lines
        const transformedOrderLines = orderLines.map(transformOrderLine);

        await orderApi.apiOrderPost({
          body: {
            contractId: planningOrderFormValues.contractCode.value,
            orderNumber: planningOrderFormValues.shipmentNumber,
            orderLines: transformedOrderLines,
            planningOrder: planningOrderRequestBody,
            purchaseOrderNumber: values.purchaseOrder,
            upliftPointNodeId:
              planningOrderFormValues.collectionExternalReference.value,
            offloadPointNodeId:
              planningOrderFormValues.deliveryExternalReference.value,
          },
        });
      }

      if (
        planningOrderFormValues != null &&
        values.existingPurchaseOrders != null &&
        orderLines.length > 0 &&
        orderFormValues == null
      ) {
        const planningOrderRequestBody = createPlanningOrderRequestBody(
          planningOrderFormValues,
        );

        // Transform order lines
        const transformedOrderLines = orderLines.map(transformOrderLine);

        await orderApi.apiOrderPost({
          body: {
            contractId: planningOrderFormValues.contractCode.value,
            orderNumber: planningOrderFormValues.shipmentNumber,
            orderLines: transformedOrderLines,
            planningOrder: planningOrderRequestBody,
            purchaseOrderId: values.existingPurchaseOrders.value,
            purchaseOrderNumber: values.existingPurchaseOrders.label,
            upliftPointNodeId:
              planningOrderFormValues.collectionExternalReference.value,
            offloadPointNodeId:
              planningOrderFormValues.deliveryExternalReference.value,
          },
        });
      }
    },
    [orderFormValues, planningOrderFormValues, orderLines],
    {redirectRoute: routes.order.list},
  );
};
