const VantageStreetsTheme = {
  nearbyNodesGeofences: {
    color: 'green',
    fillColor: 'green',
    fillOpacity: 0.2,
  },

  plannedTripGeofences: {
    color: 'green',
    fillColor: 'green',
    fillOpacity: 0.2,
  },

  plannedSequenceLine: {
    color: 'black',
  },

  actualSequenceLine: {
    color: 'black',
  },

  telematicsRoute: {
    color: 'red',
  },

  getFeatureStyle: {
    dangerZone: {
      color: 'red',
      fillColor: 'red',
      opacity: 0.7,
    },
  },

  actualStopMarker: {
    scheduledStop: {
      iconSize: [10, 10],
      fillColor: 'green',
      color: 'green',
    },
    knownUnscheduledStop: {
      iconSize: [10, 10],
      fillColor: 'orange',
      color: 'orange',
    },
    illegalStop: {
      iconSize: [12, 12],
      fillColor: 'red',
      color: 'red',
    },
    other: {
      iconSize: [10, 10],
      fillColor: 'grey',
      color: 'grey',
    },
  },

  geoSelectionMarker: {
    iconSize: [25, 25],
    fillColor: 'blue',
    color: 'blue',
  },
  icons: {
    depot: 'black',
    collection: 'black',
    delivery: 'black',
    resource: 'black',
    border: 'black',
    warning: 'black',
    personPin: 'black',
    info: 'black',
    plannedResource: 'black',
    rest: 'black',
  },
};

export {VantageStreetsTheme};
