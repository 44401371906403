import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';

import {type Driver} from '@onroadvantage/onroadvantage-api';

import {getAuthHeaderValues} from '../../../api';
import {config} from '../../../config';

interface GeneratedDriverQR {
  driver: Driver;
  qrKey: string;
  toastId: string;
}

export const useGenerateDriverQRs = () => {
  const [generatedDriverQRs, setGeneratedDriverQRs] = useState<
    GeneratedDriverQR[]
  >([]);

  const onComplete = useCallback((driver: Driver) => {
    setGeneratedDriverQRs((prevState) =>
      prevState.filter(
        ({driver: generatedDriver}) => generatedDriver.id !== driver.id,
      ),
    );
  }, []);

  const onGenerateDriverQR = useCallback(async (driver: Driver) => {
    if (driver.userId == null) {
      return 'catch';
    }
    const headerValues = getAuthHeaderValues();
    if (headerValues.value == null) {
      toast('Unauthorised', {type: 'error'});
      return 'catch';
    }
    const toastId = `${driver.firstname}QR`;
    if (toast.isActive(toastId)) {
      toast.info(`Already downloading QR for ${driver.firstname}`);
      return 'catch';
    }
    toast.loading(`Downloading QR for ${driver.firstname}`, {toastId});
    try {
      const headers = new Headers();
      headers.set('Content-Type', 'application/json');
      headers.set(headerValues.name, headerValues.value);
      headers.set('Authorization', headerValues.value);

      const response = await fetch(
        `${config.serverUri}/api/user/qr/${driver.userId}`,
        {
          method: 'GET',
          mode: 'cors',
          headers,
        },
      );
      const data = await response.json();
      if (data.key == null) {
        toast.update(toastId, {
          type: 'error',
          render: `Failed to download QR for ${driver.firstname}`,
          isLoading: false,
          autoClose: config.toastyAutoHideDuration,
        });
        return 'catch';
      }
      setGeneratedDriverQRs((prev) => [
        ...prev,
        {driver, qrKey: data.key, toastId},
      ]);
      return 'success';
    } catch (error) {
      toast.update(toastId, {
        type: 'error',
        render: `Failed to download QR for ${driver.firstname}`,
        isLoading: false,

        autoClose: config.toastyAutoHideDuration,
      });
      return 'catch';
    }
  }, []);

  return {generatedDriverQRs, onGenerateDriverQR, onComplete};
};
