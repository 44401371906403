import {VantageCard} from '../../../common/components/VantageCard';
import {useReportContext} from '../../contexts/ReportContext';
import {getReportParameter} from '../../helpers/getReportParameter';
import {ReportScheduleForm} from '../components/ReportScheduleForm';
import {useAddReportSchedule} from '../hooks/useAddReportSchedule';

export default function ReportScheduleAdd() {
  const {reportId, report} = useReportContext();
  const {onSubmit} = useAddReportSchedule(reportId);
  return (
    <VantageCard scrollable>
      <ReportScheduleForm
        initialValues={{
          name: '',
          description: '',
          recurring: false,
          parameters: report?.reportParameters?.map(getReportParameter),
          dateTime: new Date(),
          time: new Date(),
          startDate: new Date(),
          endDate: new Date(),
        }}
        onSubmit={onSubmit}
        permission="reportSchedule:add"
      />
    </VantageCard>
  );
}
