import {vehicleApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadVehicles = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  return useLoadList(
    async () =>
      vehicleApi.apiVehicleGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        registrationNumber: getFilter('registrationNumber'),
        contractCode: getFilter('contractCode'),
        description: getFilter('description'),
        fleetNumber: getFilter('fleetNumber'),
        mixOrganisationGroupName: getFilter('mixOrganisationGroupName'),
        providerName: getFilter('providerName'),
        vehicleType: getFilter('vehicleType'),
        vinNumber: getFilter('vinNumber'),
        voicekitNumber: getFilter('voicekitNumber'),
      }),
    updatePagination,
    [getFilter, loadRequestPagination, loadRequestSorting],
  );
};
