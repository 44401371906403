import {VantageSelectField} from '../../../common/components/form/VantageSelectField';

export const ReportParameterDurationByLabel = {
  Today: 'today',
  Yesterday: 'yesterday',
  'Last 24 hours': 'last_24_hours',
  'Last 7 Days': 'last_7_days',
  'Last 7 Days (include today)': 'last_7_days_include_today',
  'Week to Date': 'week_to_date',
  'Week to Date (include today)': 'week_to_date_include_today',
  'Previous week': 'previous_week',
  'Month to Date': 'month_to_date',
  'Month to Date (include today)': 'month_to_date_include_today',
} as const;

interface ReportParameterDateRangeProps {
  name: string;
  label?: string;
}

export function ReportParameterDateRange({
  name,
  label,
}: ReportParameterDateRangeProps) {
  return (
    <VantageSelectField
      name={name}
      label={label}
      options={Object.keys(ReportParameterDurationByLabel)}
      fullWidth
      formik
    />
  );
}
