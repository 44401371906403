import EmojiObjectsIcon from '@mui/icons-material/EmojiObjectsRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const vantageLabsHomeMenuItems: HomeMenuItemCard = {
  title: 'Vantage Labs',
  icon: <EmojiObjectsIcon color="action" />,
  menuItems: [
    {
      label: 'Document Template',
      path: routes.documentTemplate.list,
      permissions: ['documentTemplate:list'],
    },
    {
      label: 'Notification Config',
      path: routes.notificationConfig.list,
      permissions: ['notificationConfig:list'],
    },
    {
      label: 'Template Editor',
      path: routes.experimental,
      permissionCheck: 'superadmin',
    },
  ],
};
