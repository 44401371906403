import type {Order} from '@onroadvantage/onroadvantage-api';

import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {type TripAddStopsOrder} from '../hooks/useAddTripStops';

const isMetaDataOrderSchema = (metaData: unknown): metaData is Order => {
  if (metaData == null || typeof metaData !== 'object') {
    return false;
  }
  if (
    !(
      'orderNumber' in metaData &&
      'offloadPoint' in metaData &&
      'upliftPoint' in metaData
    )
  ) {
    return false;
  }
  return (
    typeof metaData.orderNumber === 'string' &&
    isObjectWithKeys(metaData.offloadPoint, 'id', 'name') &&
    isObjectWithKeys(metaData.upliftPoint, 'id', 'name') &&
    typeof metaData.offloadPoint.id === 'number' &&
    typeof metaData.upliftPoint.id === 'number'
  );
};
export const getOrdersFromMetaData = (
  metaData: string | null | undefined,
): TripAddStopsOrder | null => {
  if (metaData == null) {
    return null;
  }
  try {
    const parsedMetaData = JSON.parse(metaData);
    if (isMetaDataOrderSchema(parsedMetaData)) {
      return {
        id: parsedMetaData.id ?? 0,
        orderNumber: parsedMetaData.orderNumber,
        offloadPointNode: {
          label: parsedMetaData.offloadPoint.name,
          value: parsedMetaData.offloadPoint.id,
        },
        upliftPointNode: {
          label: parsedMetaData.upliftPoint.name,
          value: parsedMetaData.upliftPoint.id,
        },
      };
    }
  } catch {
    return null;
  }
  return null;
};
