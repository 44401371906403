import {type Dispatch, type SetStateAction, useCallback, useState} from 'react';
import {toast} from 'react-toastify';

import {type WebMasterTripDebriefListMasterTrip} from '@onroadvantage/onroadvantage-api';

import {masterTripApi} from '../../../api';
import {type DeleteStatus} from '../../common/types/DeleteStatus';

interface UseBulkDeleteTripsParams {
  selectedTrips: WebMasterTripDebriefListMasterTrip[];
  setSelectedTrips: Dispatch<
    SetStateAction<WebMasterTripDebriefListMasterTrip[]>
  >;
  reloadData: () => Promise<void>;
}

export const useBulkDeleteTrips = ({
  selectedTrips,
  setSelectedTrips,
  reloadData,
}: UseBulkDeleteTripsParams) => {
  const [deleteStatus, setDeleteStatus] = useState<DeleteStatus>('idle');

  const onBulkDelete = useCallback(async () => {
    setDeleteStatus('deleting');
    try {
      await masterTripApi.apiMasterTripBulkDeleteDelete({
        body: {
          masterTripIds: selectedTrips
            .filter(({masterTripId}) => masterTripId != null)
            .map(({masterTripId}) => masterTripId) as number[],
        },
      });
      void reloadData();
      setDeleteStatus('success');
      setSelectedTrips([]);
    } catch (e) {
      toast.error('Unable to bulk delete trips. Try again.');
      setDeleteStatus('error');
    }
  }, [reloadData, selectedTrips, setSelectedTrips]);

  return {onBulkDelete, deleteStatus};
};
