import {useMemo, useState} from 'react';

import Divider from '@mui/material/Divider';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {type Driver} from '@onroadvantage/onroadvantage-api';

import {VantageCard} from '../../common/components/VantageCard';
import {useStepper} from '../../common/hooks/useStepper';
import {BroadcastVantageMobileDrivers} from '../components/BroadcastVantageMobileDrivers';
import {BroadcastVantageMobileForm} from '../components/BroadcastVantageMobileForm';

const broadcastVantageMobileSteps = [
  'Select drivers',
  'Compose message',
] as const;
type BroadcastVantageMobileStep = (typeof broadcastVantageMobileSteps)[number];

export default function BroadcastVantageMobile() {
  const [selectedDrivers, setSelectedDrivers] = useState<Driver[]>([]);
  const {
    activeStep,
    activeStepPosition,
    completedSteps,
    onGoToStep,
    isFirstStep,
    onNextStep,
    onPreviousStep,
    onResetStepper,
  } = useStepper<BroadcastVantageMobileStep>(broadcastVantageMobileSteps);

  const renderActiveStep = useMemo(() => {
    switch (activeStep) {
      case 'Select drivers':
        return (
          <BroadcastVantageMobileDrivers
            selectedDrivers={selectedDrivers}
            setSelectedDrivers={setSelectedDrivers}
            onNextStep={onNextStep}
          />
        );
      case 'Compose message':
        return (
          <BroadcastVantageMobileForm
            onPreviousStep={onPreviousStep}
            onResetStepper={() => {
              onResetStepper();
              setSelectedDrivers([]);
            }}
            selectedDrivers={selectedDrivers}
          />
        );
      default:
        return null;
    }
  }, [activeStep, onNextStep, onPreviousStep, onResetStepper, selectedDrivers]);

  return (
    <VantageCard stretch={isFirstStep}>
      <Stepper
        activeStep={activeStepPosition}
        sx={{p: 1, bgcolor: 'background.default'}}
      >
        {broadcastVantageMobileSteps.map((step) => (
          <Step
            key={step}
            completed={completedSteps.includes(step)}
            active={activeStep === step}
            onClick={() => {
              onGoToStep(step);
            }}
            sx={{cursor: completedSteps.includes(step) ? 'pointer' : 'default'}}
          >
            <StepLabel color="primary">{step as string}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Divider />
      {renderActiveStep}
    </VantageCard>
  );
}
