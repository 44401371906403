import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import DownloadIcon from '@mui/icons-material/DownloadRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {Dashboard} from '@onroadvantage/onroadvantage-api';

import {dashboardApi} from '../../../api';
import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {capitalizeFirstLetter} from '../../common/helpers/capitalizeFirstLetter';
import {splitByCapitals} from '../../common/helpers/splitByCapitals';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useUserStore} from '../../common/stores/userStore';
import {useDeleteDashboard} from '../hooks/useDeleteDashboard';
import {useLoadDashboards} from '../hooks/useLoadDashboards';

const isValidCsvData = (
  data: unknown,
): data is Array<Record<string, string>> => {
  if (!Array.isArray(data)) {
    return false;
  }

  return typeof data[1] === 'object' && data[1] != null;
};

const pad = (value: number) => `0${value}`.slice(-2);
const getTimestamp = (value: number) => {
  const days = Math.floor(value / 86400);
  const hours = Math.floor((value % 86400) / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = value % 60;

  if (days > 1) {
    return `${pad(days)}days ${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
  if (days > 0) {
    return `${pad(days)}day ${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};
const tableConfig: VantageTableConfig<Dashboard> = {
  columns: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'dashboardActions',
      label: 'Dashboard Actions',
      type: 'string',
      getValue: (row) => {
        const onOpenDashboard = () => {
          if (row.type === 'DataStudio') {
            window.open(
              `/dashboard_datastudio?reportId=${row.externalReportId}`,
              '_blank',
            );
          } else {
            window.open(
              `/dashboard_standalone?dashboardId=${row.id}`,
              '_blank',
            );
          }
        };

        const onDownloadDashboard = async () => {
          await toast.promise(
            async () => {
              const data = await dashboardApi.apiDashboardViewGet({
                ...row,
              });
              const csvData = isValidCsvData(data.data) ? data.data : null;
              if (csvData == null) {
                return;
              }

              const titleKeys = Object.keys(csvData[1]).map((key) =>
                splitByCapitals(capitalizeFirstLetter(key)),
              );

              const refinedData: string[][] = [];
              refinedData.push(titleKeys);

              csvData.forEach((item) => {
                refinedData.push(Object.values(item));
              });

              let csvContent = '';

              refinedData.forEach((refinedRow) => {
                csvContent += `${refinedRow.join(',')}\n`;
              });

              const blob = new Blob([csvContent], {
                type: 'text/csv;charset=utf-8,',
              });
              const objUrl = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.setAttribute('href', objUrl);
              link.setAttribute('download', `${row.name}.csv`);
              link.click();
            },
            {
              pending: 'Downloading dashboard...',
              success: 'Dashboard downloaded',
              error: 'Failed to download dashboard',
            },
          );
        };
        return (
          <>
            <IconButton onClick={onOpenDashboard} size="small" sx={{mr: 1}}>
              <FullscreenIcon />
            </IconButton>
            <IconButton
              onClick={onDownloadDashboard}
              size="small"
              disabled={row.type !== 'DataStudio'}
            >
              <DownloadIcon />
            </IconButton>
          </>
        );
      },
    },
    {name: 'category', label: 'Category', type: 'string', enableGrouping: true},
    {name: 'type', label: 'Type', type: 'string', hidden: true},
    {name: 'viewName', label: 'DB View Name', type: 'string', hidden: true},
    {
      name: 'lastRefresh',
      label: 'Last Refresh',
      type: 'datetime',
      hidden: true,
    },
    {
      name: 'refreshFrequency',
      label: 'Refresh Frequency',
      type: 'string',
      getValue: ({refreshFrequency}) => getTimestamp(refreshFrequency),
      hidden: true,
    },
    {
      name: 'filterCriticalEventType',
      label: 'Filter Critical Event Types',
      type: 'boolean',
      hidden: true,
    },
    {
      name: 'filterOperationalEventType',
      label: 'Filter Operational Event Types',
      type: 'boolean',
      hidden: true,
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Dashboard name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Dashboard category:</Typography>
        <Typography>{row.category}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Dashboard type:</Typography>
        <Typography>{row.type}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnVisibility: true,
  enableColumnGrouping: true,
  enableColumnResizing: true,
  identifier: 'dashboards',
};
export default function Dashboards() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, loadDataStatus, removeItem} = useLoadDashboards({
    getFilter,
    loadRequestPagination,
    updatePagination,
  });
  const {deleteStatus, onDelete} = useDeleteDashboard({removeItem});

  return (
    <VantageTablePaper>
      <OverlayLoader
        loading={loadDataStatus === 'idle'}
        label="Preparing content..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'loading'}
        label="Loading dashboards..."
      />
      <OverlayLoader
        loading={deleteStatus === 'deleting'}
        label="Deleting dashboard..."
      />
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        pagination={tablePagination}
        filtering={tableFiltering}
        grouping={[{columnName: 'category'}]}
        actions={
          hasPermissions('dashboard:add')
            ? {
                onAdd: () => {
                  navigate(routes.dashboard.add);
                },
              }
            : {}
        }
        rowActions={{
          onNavigate: (row) => {
            if (row.id != null) {
              navigate(
                routes.replaceParams(routes.dashboard.dashboardId, {
                  dashboardId: row.id,
                }),
              );
            }
          },
          onDelete: hasPermissions('dashboard:delete') ? onDelete : undefined,
        }}
      />
    </VantageTablePaper>
  );
}
