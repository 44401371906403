import {type Theme} from '@mui/material/styles';

export interface GlassMorphismOptions {
  blur?: number;
  transparency?: number;
}

export const getGlassBackground = (
  theme: Theme,
  {transparency, blur}: GlassMorphismOptions = {blur: 11, transparency: 0.4},
): Record<string, string> => {
  if (theme.palette.mode === 'dark') {
    return {
      background: `rgba(21, 21, 21, ${transparency})`,
      backdropFilter: `blur(${blur}px)`,
      webkitBackdropFilter: `blur(${blur}px)`,
    };
  }
  return {
    background: `rgba(255, 255, 255, ${transparency})`,
    backdropFilter: `blur(${blur}px)`,
    webkitBackdropFilter: `blur(${blur}px)`,
  };
};
