import {reportScheduleApi} from '../../../../api';
import {routes} from '../../../../routes';
import {useSubmitForm} from '../../../common/hooks/useSubmitForm';
import {type ReportScheduleFormValues} from '../components/ReportScheduleForm';
import {getReportSchedulePayload} from '../helper/getReportSchedulePayload';

export const useAddReportSchedule = (reportId: number) => {
  return useSubmitForm<ReportScheduleFormValues>(
    async (values) => {
      await reportScheduleApi.apiReportSchedulePost({
        body: getReportSchedulePayload(reportId, values),
      });
    },
    [reportId],
    {
      redirectRoute: routes.addQueryParams(
        routes.replaceParams(routes.report.reportId, {
          reportId,
        }),
        {tab: 'Schedules'},
      ),
    },
  );
};
