import VolumeOffIcon from '@mui/icons-material/VolumeOffRounded';
import Stack from '@mui/material/Stack';
import {styled, useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {getNestedBorderRadius} from '../../../../theme/getNestedBorderRadius';
import {
  type OnFormSubmit,
  VantageForm,
} from '../../../common/components/form/VantageForm';
import {VantageFormSubmit} from '../../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../../common/components/form/VantageTextField';

export interface EventsPaneCardMuteFormValues {
  comment: string | undefined;
  duration: number | undefined;
}

export function EventsPaneCardMuteForm({
  onSubmit,
  actionsDisabled,
}: {
  onSubmit: OnFormSubmit<EventsPaneCardMuteFormValues>;
  actionsDisabled: boolean;
}) {
  const theme = useTheme();
  return (
    <Container>
      <VantageForm<EventsPaneCardMuteFormValues>
        initialValues={{duration: undefined, comment: ''}}
        onSubmit={onSubmit}
      >
        <Stack spacing={1}>
          <Typography>Mute this event type</Typography>
          <VantageTextField
            name="duration"
            label="Duration"
            type="number"
            disabled={actionsDisabled}
            endAdornment={
              <Typography variant="body2" color="text.secondary">
                hours
              </Typography>
            }
            InputProps={{
              style: {borderRadius: getNestedBorderRadius(theme, 1)},
            }}
            multiline
            formik
          />
          <VantageTextField
            name="comment"
            placeholder="Enter your comments here"
            minRows={2}
            InputLabelProps={{shrink: true}}
            disabled={actionsDisabled}
            InputProps={{
              style: {borderRadius: getNestedBorderRadius(theme, 1)},
            }}
            multiline
            formik
          />
          <SubmitMuteEvent label="Mute" startIcon={<VolumeOffIcon />} />
        </Stack>
      </VantageForm>
    </Container>
  );
}

const SubmitMuteEvent = styled(VantageFormSubmit)(({theme}) => ({
  borderRadius: getNestedBorderRadius(theme, 1),
  alignSelf: 'flex-start',
}));

const Container = styled('div')(({theme}) => ({
  display: 'flex',
  background: theme.palette.background.default,
  flexDirection: 'column',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  margin: theme.spacing(-0.5, 1, 0),
}));
