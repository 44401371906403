import {DateTime} from 'luxon';

export const getTimeAgo = (date: Date | undefined | null): string => {
  if (date == null) {
    return 'Unknown';
  }

  const createdAtDiffNow = DateTime.fromJSDate(date)
    .diffNow()
    .shiftTo('days', 'hours', 'minutes');

  const daysAgo = Math.floor(Math.abs(createdAtDiffNow.days));
  const hoursAgo = Math.floor(Math.abs(createdAtDiffNow.hours));
  const minutesAgo = Math.floor(Math.abs(createdAtDiffNow.minutes));

  let timeAgo = 'Unknown';

  if (minutesAgo > 0) {
    timeAgo = `${minutesAgo}min ago`;
  }

  if (hoursAgo > 0) {
    timeAgo = `${hoursAgo}hr ${minutesAgo}min ago`;
  }

  if (daysAgo > 0) {
    timeAgo = `${daysAgo}d ${hoursAgo}hr ${minutesAgo}min ago`;
  }

  if (daysAgo === 0 && hoursAgo === 0 && minutesAgo < 2) {
    timeAgo = `just now`;
  }

  return timeAgo;
};
