import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyCustomers = lazy(async () => import('./pages/Customers'));
const LazyCustomerAdd = lazy(async () => import('./pages/CustomerAdd'));
const LazyCustomer = lazy(async () => import('./pages/Customer'));

export const customerRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.customer.list)},
  {
    path: routes.getRelativePath(routes.customer.list, routes.customer.base),
    element: <LazyCustomers />,
    loader: () => {
      document.title = 'Customers';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Customers'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.customer.add, routes.customer.base),
    element: <LazyCustomerAdd />,
    loader: () => {
      document.title = 'Add Customer';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Customers', path: routes.customer.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.customer.customerId,
      routes.customer.base,
    ),
    element: <LazyCustomer />,
    loader: () => {
      document.title = 'View Customer';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Customers', path: routes.customer.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
