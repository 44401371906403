import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export function AuthenticationTag({label}: {label: string}) {
  return (
    <Tag>
      <Typography variant="body2" fontWeight={600}>
        {label}
      </Typography>
    </Tag>
  );
}

const Tag = styled('span')(({theme}) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.contrastText,
  padding: theme.spacing(0.5),
}));
