import {type SyntheticEvent, useCallback, useState} from 'react';

import {debounce} from '@mui/material/utils';
import {type ApiListingGetRequest} from '@onroadvantage/onroadvantage-api';

import {listingApi} from '../../../api';
import {type AutocompleteOption} from '../components/form/VantageAutocompleteField';
import {catchHandler} from '../helpers/catchHandler';
import {type LoadStatus} from '../types/LoadStatus';

export type UseLoadListingQueryOptions = Pick<
  ApiListingGetRequest,
  | 'contractCode'
  | 'contractId'
  | 'metaData'
  | 'perPage'
  | 'endDate'
  | 'startDate'
> & {enableDateRangeFilter?: boolean};

export const useLoadListing = (
  models: Set<string> | string,
  queryOptions: UseLoadListingQueryOptions = {},
) => {
  const [listing, setListing] = useState<AutocompleteOption[]>([]);
  const [loadListingStatus, setLoadListingStatus] =
    useState<LoadStatus>('idle');

  const loadListing = useCallback(
    async (query?: string) => {
      setLoadListingStatus('loading');
      try {
        if (models instanceof Set) {
          const responses = await Promise.all(
            Array.from(models).map(async (model) =>
              listingApi.apiListingGet({
                ...queryOptions,
                query,
                model,
              }),
            ),
          );
          const combinedListing = responses.flatMap(
            (response) => response.items ?? [],
          );
          setListing(combinedListing);
        } else {
          const response = await listingApi.apiListingGet({
            ...queryOptions,
            query,
            model: models,
          });
          setListing(response.items ?? []);
        }
        setLoadListingStatus('success');
      } catch (error) {
        const {type} = catchHandler(
          error,
          `Failed to load listing: ${Array.from(models).join(', ')}`,
        );

        setLoadListingStatus(type);
      }
    },
    [models, queryOptions],
  );

  return {
    listing,
    loadListing,
    loadListingStatus,
    onDebounceSearch: debounce(
      async (_event: SyntheticEvent, value: string) => {
        await loadListing(value);
      },
      500,
    ),
  };
};
