import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const settingsMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Roles',
    pathname: routes.role.list,
    permission: 'role:list',
  },
  {
    level: 2,
    label: 'Task Templates',
    pathname: routes.taskTemplate.list,
    permission: 'taskTemplate:list',
  },
  {
    level: 2,
    label: 'Users',
    pathname: routes.user.list,
    permission: 'user:list',
  },
];
