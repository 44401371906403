import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../../routes';
import {splitByCapitals} from '../../common/helpers/splitByCapitals';
import {useBreadcrumbStore} from '../../common/stores/breadcrumbsStore';
import {eventTypeModels} from '../../common/types/EventTypeModel';

const LazyContractEventTypeConfigPromptAdd = lazy(
  async () => import('./pages/ContractEventTypeConfigPromptAdd'),
);
const LazyContractEventTypeConfigPrompt = lazy(
  async () => import('./pages/ContractEventTypeConfigPrompt'),
);

export const contractEventTypeConfigPromptRoutes: RouteObject[] = [
  {
    path: '',
    loader: () => redirect(routes.contractEventTypeConfig.prompt.add),
  },
  {
    path: routes.getRelativePath(
      routes.contractEventTypeConfig.prompt.add,
      routes.contractEventTypeConfig.prompt.base,
    ),
    element: <LazyContractEventTypeConfigPromptAdd />,
    loader: ({request, params}) => {
      const url = new URL(request.url);
      const model =
        url.searchParams.get('model') ?? eventTypeModels.CriticalEventType;
      const splitModel = splitByCapitals(model, {lowercaseAllLetters: true});
      if (params.contractEventTypeConfigId != null) {
        useBreadcrumbStore.setState({
          breadcrumbs: [
            {
              label: `Contract ${splitModel}s`,
              path: routes.addQueryParams(routes.contractEventTypeConfig.list, {
                model,
              }),
            },
            {
              label: `View`,
              path: routes.addQueryParams(
                routes.replaceParams(
                  routes.contractEventTypeConfig.contractEventTypeConfigId,
                  {contractEventTypeConfigId: params.contractEventTypeConfigId},
                ),
                {model, tab: 'Prompts'},
              ),
            },
            {label: 'Add Prompt'},
          ],
        });
      }
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.contractEventTypeConfig.prompt.promptId,
      routes.contractEventTypeConfig.prompt.base,
    ),
    element: <LazyContractEventTypeConfigPrompt />,
    loader: ({request, params}) => {
      const url = new URL(request.url);
      const model =
        url.searchParams.get('model') ?? eventTypeModels.CriticalEventType;
      const splitModel = splitByCapitals(model, {lowercaseAllLetters: true});

      if (params.contractEventTypeConfigId != null) {
        useBreadcrumbStore.setState({
          breadcrumbs: [
            {
              label: `Contract ${splitModel}s`,
              path: routes.addQueryParams(routes.contractEventTypeConfig.list, {
                model,
              }),
            },
            {
              label: `View`,
              path: routes.addQueryParams(
                routes.replaceParams(
                  routes.contractEventTypeConfig.contractEventTypeConfigId,
                  {contractEventTypeConfigId: params.contractEventTypeConfigId},
                ),
                {model, tab: 'Prompts'},
              ),
            },
            {label: 'View Prompt'},
          ],
        });
      }
      return null;
    },
  },
];
