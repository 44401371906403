import {type Dispatch, memo, type SetStateAction} from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';

import {MenuItemButton} from './MenuItemButton';
import {type MenuItem} from './menuItems/menuItems';
import {NavigationMenuItemLabel} from './NavigationMenuItemLabel';
import {useMenuItem} from '../../hooks/useMenuItem';

export interface MenuItemProps {
  item: MenuItem;
  parentItem: MenuItem | null;
  expandedGroups: string[];
  setExpandedGroups: Dispatch<SetStateAction<string[]>>;
  filter: string | null;
}

export const NavigationMenuItem = memo(function NavigationMenuItem(
  props: MenuItemProps,
) {
  const {item, filter} = props;
  const theme = useTheme();
  const {isActive, isExpanded, hasChildren, onClick} = useMenuItem(props);

  let background = 'inherit';

  if (item.level === 2) {
    background =
      theme.palette.mode === 'light'
        ? 'rgba(230,230,230, 0.2)'
        : 'rgba(3,3,3, 0.2)';
  }

  return (
    <>
      <MenuItemButton
        key={item.label}
        $level={item.level}
        $isActive={isActive}
        onClick={onClick}
      >
        {item.icon}
        <NavigationMenuItemLabel label={item.label} filter={filter} />
        {hasChildren && (isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </MenuItemButton>
      {hasChildren && (
        <Stack>
          <Collapse in={isExpanded} mountOnEnter>
            <Stack sx={{background}}>
              {item.children?.map((child) => (
                <NavigationMenuItem
                  {...props}
                  key={child.label}
                  item={child}
                  parentItem={item}
                />
              ))}
            </Stack>
          </Collapse>
        </Stack>
      )}
    </>
  );
});
