import {DateTime} from 'luxon';

export const getTimeAgoDisplay = (date: Date | undefined | null): string => {
  if (date == null) {
    return 'Unknown';
  }
  const dateTime = DateTime.fromJSDate(date);

  if (dateTime.hour === 0 && dateTime.minute < 5) {
    return 'just now';
  }

  return DateTime.fromJSDate(date).toFormat('dd/MM HH:mm');
};
