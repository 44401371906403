import {purchaseOrderApi} from '../../../api';
import {routes} from '../../../routes';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type PurchaseOrderAddDetailsValues} from '../components/purchaseOrderAdd/PurchaseOrderAddDetails';

export interface AddPurchaseOrder {
  purchaseOrderNumber: string;
  contractId?: number;
  customerId?: number;
  referenceNumber?: string;
}

export const useAddPurchaseOrder = () => {
  return useSubmitForm<PurchaseOrderAddDetailsValues>(
    async (values) => {
      const {purchaseOrderNumber, referenceNumber, contract, customer} = values;
      await purchaseOrderApi.apiPurchaseOrderPost({
        body: {
          purchaseOrderNumber,
          contractId: contract.value,
          customerId: customer.value,
          referenceNumber,
        },
      });
    },
    [],
    {redirectRoute: routes.purchaseOrder.list},
  );
};
