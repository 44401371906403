import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const planningMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Master Routes',
    pathname: routes.masterRoute.list,
    permission: 'masterRoute:list',
  },
  {
    level: 2,
    label: 'Optimiser',
    pathname: routes.optimiser.list,
    permission: 'planningSolution:list',
  },
  {
    level: 2,
    label: 'Planning Board',
    pathname: routes.planningBoard,
    permission: 'planningBoard:view',
  },
  {
    level: 2,
    label: 'Vehicle Booking Availability',
    pathname: routes.vehicleBookingAvailability,
    permission: 'booking:list',
  },
];
