import {HEREHybridTheme} from './HEREHybridTheme';
import {VantageStreetsTheme} from './VantageStreetsTheme';
import {type BaseLayer} from '../../../stores/mapDisplayStore';

interface IconStyleType {
  iconSize: number[];
  fillColor: string;
  color: string;
}

export interface MapTheme {
  nearbyNodesGeofences: {
    color: string;
    fillColor: string;
    fillOpacity: number;
  };
  plannedTripGeofences: {
    color: string;
    fillColor: string;
    fillOpacity: number;
  };
  plannedSequenceLine: {
    color: string;
  };
  actualSequenceLine: {
    color: string;
  };
  telematicsRoute: {
    color: string;
  };
  getFeatureStyle: {
    dangerZone: {
      color: string;
      fillColor: string;
      opacity: number;
    };
  };
  actualStopMarker: {
    scheduledStop: IconStyleType;
    knownUnscheduledStop: IconStyleType;
    illegalStop: IconStyleType;
  };
  geoSelectionMarker: IconStyleType;
  icons: {
    depot: string;
    collection: string;
    delivery: string;
    resource: string;
    border: string;
    warning: string;
    personPin: string;
    info: string;
    plannedResource: string;
    rest: string;
  };
}

export type MapThemeListType = Record<BaseLayer, MapTheme>;

const themeList: MapThemeListType = {
  Hybrid: HEREHybridTheme,
  Streets: VantageStreetsTheme,
};

export default themeList;
