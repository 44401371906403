import ArrowUpwardIcon from '@mui/icons-material/ArrowUpwardRounded';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, {type IconButtonProps} from '@mui/material/IconButton';
import {styled, useTheme} from '@mui/material/styles';
import {useFormikContext} from 'formik';

import {getNestedBorderRadius} from '../../../theme/getNestedBorderRadius';
import {VantageTextField} from '../../common/components/form/VantageTextField';

interface ActivityCommentFieldProps {
  actionsDisabled: boolean;
  commentPlaceholder: string;
  enableSubmitButton?: boolean;
}

export function ActivityCommentField({
  actionsDisabled,
  commentPlaceholder,
  enableSubmitButton = false,
}: ActivityCommentFieldProps) {
  const theme = useTheme();
  const {submitForm} = useFormikContext();
  return (
    <VantageTextField
      name="comment"
      placeholder={commentPlaceholder}
      minRows={2}
      InputLabelProps={{shrink: true}}
      disabled={actionsDisabled}
      onKeyDown={async (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
          e.preventDefault();
          await submitForm();
        }
      }}
      InputProps={{style: {borderRadius: getNestedBorderRadius(theme, 0.5)}}}
      endAdornment={enableSubmitButton ? <SubmitComment /> : null}
      multiline
      formik
    />
  );
}

function SubmitComment(props: IconButtonProps) {
  const {isSubmitting} = useFormikContext();

  if (isSubmitting) {
    return <CircularProgress size={20} />;
  }

  return (
    <StyledSubmitComment size="small" type="submit" {...props}>
      <ArrowUpwardIcon fontSize="small" />
    </StyledSubmitComment>
  );
}

const StyledSubmitComment = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
  color: '#fff',
}));
