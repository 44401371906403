import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {Loader, type LoaderProps} from './Loader';
import {getGlassBackground} from '../../../../theme/getGlassBackground';

export function PageLoader(props: LoaderProps) {
  return (
    <PageLoaderContainer>
      <Loader size={69} label="Loading content..." {...props} />
    </PageLoaderContainer>
  );
}

const PageLoaderContainer = styled(Stack)(({theme}) => ({
  flex: 1,
  borderRadius: theme.shape.borderRadius,
  ...getGlassBackground(theme),
}));
