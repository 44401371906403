import {type HTMLAttributes, memo, useEffect, useRef} from 'react';

import lottie, {
  type AnimationConfigWithData,
  type AnimationItem,
  type BMCompleteLoopEvent,
  type SVGRendererConfig,
} from 'lottie-web/build/player/lottie_light';

interface LottieAnimationProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  animationData: object;
  autoplay?: boolean;
  loop?: boolean;
  rendererSettings?: SVGRendererConfig;
  animationConfig?: Omit<AnimationConfigWithData, 'animationData'>;
  width?: string | number;
  height?: string | number;
  onLoopComplete?: (event: BMCompleteLoopEvent) => void;
}

export const LottieAnimation = memo(function LottieAnimation({
  name,
  animationData,
  rendererSettings,
  autoplay = true,
  loop = true,
  animationConfig,
  width,
  height,
  onLoopComplete,
  ...divProps
}: LottieAnimationProps) {
  const elementRef = useRef<HTMLDivElement>(null);
  const lottieRef = useRef<AnimationItem>();

  useEffect(() => {
    if (elementRef.current != null) {
      lottieRef.current = lottie.loadAnimation({
        container: elementRef.current,
        animationData,
        autoplay,
        loop,
        renderer: 'svg',
        rendererSettings,
        name,
        ...animationConfig,
      });
    }
    return () => {
      lottieRef.current?.destroy();
    };
  }, [animationData, autoplay, animationConfig, loop, name, rendererSettings]);

  useEffect(() => {
    if (onLoopComplete != null) {
      lottieRef.current?.addEventListener('loopComplete', onLoopComplete);
    }
  }, [onLoopComplete]);

  return (
    <div
      {...divProps}
      style={{width, height, ...divProps.style}}
      ref={elementRef}
    />
  );
});
