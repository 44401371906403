import type {ReactElement} from 'react';

import LibraryAddIcon from '@mui/icons-material/LibraryAddRounded';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksRounded';
import SortIcon from '@mui/icons-material/SortRounded';
import WarehouseIcon from '@mui/icons-material/WarehouseRounded';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import {type TripAddStopsOption, tripAddStopsOptions} from './TripAddStops';

const tripAddStopsOptionLabels: {[key in TripAddStopsOption]: ReactElement} = {
  'Stops overview': <SortIcon fontSize="small" />,
  'Add new order': <LibraryAddIcon fontSize="small" />,
  'Add existing orders': <LibraryBooksIcon fontSize="small" />,
  'Add depot': <WarehouseIcon fontSize="small" />,
};
interface TripAddStopsChipsProps {
  activeOption: TripAddStopsOption;
  setActiveOption: (option: TripAddStopsOption) => void;
}

export function TripAddStopsChips({
  activeOption,
  setActiveOption,
}: TripAddStopsChipsProps) {
  return (
    <Stack direction="row" p={1} spacing={1}>
      {tripAddStopsOptions.map((option) => (
        <Chip
          key={option}
          icon={tripAddStopsOptionLabels[option]}
          color={option === activeOption ? 'primary' : 'default'}
          variant={option === activeOption ? 'outlined' : 'outlined'}
          label={option}
          onClick={() => {
            setActiveOption(option);
          }}
        />
      ))}
    </Stack>
  );
}
