import {useEffect} from 'react';
import {useMap} from 'react-leaflet';

import {useFormikContext} from 'formik';
import {Layer} from 'leaflet';

import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';

const editButtons = ['dragMode', 'editMode', 'removalMode'];
export function SiteMapDrawing() {
  const map = useMap();
  const {getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value: geofencesGeoJson} = getFieldMeta<Layer | null>(
    'geofencesGeoJson',
  );
  const {setValue: setGeofencesGeoJson} = getFieldHelpers<Layer | null>(
    'geofencesGeoJson',
  );
  useEffect(() => {
    if (map == null) {
      return () => {};
    }

    map.pm.addControls({
      position: 'topright',
      drawPolyline: true,
      drawRectangle: true,
      drawPolygon: true,
      drawCircle: true,
      editMode: true,
      removalMode: true,
      dragMode: false,
      customControls: true,
      drawControls: true,
      editControls: true,
      snappable: true,
      drawMarker: false,
      cutPolygon: false,
      rotateMode: false,
      oneBlock: false,
      drawText: false,
      pinningOption: false,
      snappingOption: false,
      drawCircleMarker: false,
      allowSelfIntersection: false,
    });

    return () => {
      map.pm.removeControls();
    };
  }, [map]);

  useEffect(() => {
    if (geofencesGeoJson == null) {
      return () => {};
    }
    const handleEdit = (e: unknown) => {
      if (isObjectWithKeys(e, 'layer') && e.layer instanceof Layer) {
        void setGeofencesGeoJson(e.layer);
      }
    };
    const handleDragEnd = (e: unknown) => {
      if (isObjectWithKeys(e, 'layer') && e.layer instanceof Layer) {
        void setGeofencesGeoJson(e.layer);
      }
    };
    const handleRemove = () => {
      void setGeofencesGeoJson(null);
    };
    geofencesGeoJson.addTo(map);
    geofencesGeoJson.on('pm:update', handleEdit);
    geofencesGeoJson.on('pm:dragend', handleDragEnd);
    geofencesGeoJson.on('pm:remove', handleRemove);
    return () => {
      geofencesGeoJson.off('pm:update', handleEdit);
      geofencesGeoJson.off('pm:dragend', handleDragEnd);
      geofencesGeoJson.off('pm:remove', handleRemove);
      geofencesGeoJson.removeFrom(map);
    };
  }, [geofencesGeoJson, map, setGeofencesGeoJson]);

  useEffect(() => {
    const handleCreateLayer = (e: unknown) => {
      if (isObjectWithKeys(e, 'marker') && e.marker instanceof Layer) {
        const {marker} = e;

        map.removeLayer(marker);

        void setGeofencesGeoJson(marker);
      }
    };

    const handleButtonClick = (e: {btnName: string}) => {
      if (!editButtons.includes(e.btnName)) {
        geofencesGeoJson?.removeFrom(map);
        void setGeofencesGeoJson(null);
      }
    };

    map.on('pm:create', handleCreateLayer);
    map.on('pm:buttonclick', handleButtonClick);
    return () => {
      map.off('pm:create', handleCreateLayer);
      map.off('pm:buttonclick', handleButtonClick);
    };
  }, [geofencesGeoJson, map, setGeofencesGeoJson]);
  return null;
}
