import {vehicleApi} from '../../../api';
import {useTableDownload} from '../../common/hooks/useTableDownload';

export const useDownloadVehicles = () => {
  return useTableDownload('Vehicles', async () => {
    const {items} = await vehicleApi.apiVehicleGet({
      perPage: 10000,
    });
    return (
      items?.map((item) => ({
        vehicleRegistration: item.registrationNumber ?? '-',
        description: item.description ?? '-',
        fleetNumber: item.fleetNumber ?? '-',
        lastOdometer: item.lastOdometer ?? '-',
        contractCode: item.contract?.code ?? '-',
        vehicleType: item.type?.name ?? '-',
        eventDate: item.lastSeen?.eventDate?.toISOString() ?? '-',
        config: item.telematicsConfigId ?? '-',
      })) ?? []
    );
  });
};
