import {lazy} from 'react';
import {Outlet, redirect, type RouteObject} from 'react-router-dom';

import Reports from './pages/Reports';
import {reportScheduleRoutes} from './reportSchedule/reportScheduleRoutes';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyReportAdd = lazy(async () => import('./pages/ReportAdd'));
const LazyReportOutlet = lazy(async () => import('./pages/ReportOutlet'));
const LazyReport = lazy(async () => import('./pages/Report'));

export const reportRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.report.list)},
  {
    path: routes.getRelativePath(routes.report.list, routes.report.base),
    element: <Reports />,
    loader: () => {
      document.title = 'Reports';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Reports'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.report.add, routes.report.base),
    element: <LazyReportAdd />,
    loader: () => {
      document.title = 'Add Report';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Reports', path: routes.report.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.report.reportId, routes.report.base),
    element: (
      <LazyReportOutlet>
        <Outlet />
      </LazyReportOutlet>
    ),

    children: [
      {
        path: '',
        element: <LazyReport />,
        loader: () => {
          document.title = 'View Report';
          useBreadcrumbStore.setState({
            breadcrumbs: [
              {label: 'Reports', path: routes.report.list},
              {label: 'View'},
            ],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.report.schedule.base,
          routes.report.reportId,
        ),
        element: <Outlet />,
        children: reportScheduleRoutes,
      },
    ],
  },
];
