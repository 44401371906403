import {webMasterTripDebriefApi} from '../../../api';
import {useTableDownload} from '../../common/hooks/useTableDownload';
import {useDateRangeStore} from '../../common/stores/dateRangeStore';

export const useDownloadTripDebriefs = () => {
  const startDate = useDateRangeStore((state) => state.startDate);
  const endDate = useDateRangeStore((state) => state.endDate);
  return useTableDownload(
    'TripDebriefing',
    async () => {
      const {items} = await webMasterTripDebriefApi.apiWebMasterTripDebriefGet({
        perPage: 10000,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
      return (
        items?.map((item) => ({
          tripNumber: item.tripNumber,
          contractCode: item.contractCode,
          vehicle: item.vehicleRegistrationNumber,
          driver: item.driverName,
          status: item.status,
          viewed: item.debriefed === true ? 'Yes' : 'No',
          debriefed:
            item.masterTripDebriefApprovedForBilling === true ? 'Yes' : 'No',
          tripDistance:
            item.tripDistance != null
              ? Math.round(item.tripDistance * 10) / 10
              : 'N/A',
          debriefedKilometers: Math.round(
            (item.masterTripDebriefManualEndOdometer ?? 0) -
              (item.masterTripDebriefManualStartOdometer ?? 0),
          ),
        })) ?? []
      );
    },
    [startDate, endDate],
  );
};
