import {type ApiDriverGetRequest} from '@onroadvantage/onroadvantage-api';

import {driverApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadDrivers = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
  ...apiDriverRequest
}: UseLoadParams & ApiDriverGetRequest) => {
  const {data, loadDataStatus, setData} = useLoadList(
    async () =>
      driverApi.apiDriverGet({
        ...loadRequestPagination,
        ...loadRequestSorting,
        firstname: getFilter('firstname'),
        lastname: getFilter('lastname'),
        contactNumber: getFilter('contactNumber'),
        contractCode: getFilter('contractCode'),
        externalEmployeeNumber: getFilter('externalEmployeeNumber'),
        externalExtendedId: getFilter('externalExtendedId'),
        externalName: getFilter('externalName'),
        externalSiteId: getFilter('externalSiteId'),
        licenseExpiry: getFilter('licenseExpiry'),
        licenseType: getFilter('licenseType'),
        telematicsConfigId: getFilter('telematicsConfigId'),
        externalGroupId: getFilter('externalGroupId'),
        externalId: getFilter('externalId'),
        contractIds: getFilter('contractIds'),
        hasSmartphone: getFilter('hasSmartphone'),
        ...apiDriverRequest,
      }),
    updatePagination,
    [getFilter, loadRequestPagination, loadRequestSorting],
  );

  return {
    data,
    loadDataStatus,
    removeItem: (id: number | string) => {
      setData((prevData) => prevData.filter((item) => item.id !== id));
    },
  };
};
