import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyNotificationConfigs = lazy(
  async () => import('./pages/NotificationConfigs'),
);
const LazyNotificationConfigAdd = lazy(
  async () => import('./pages/NotificationConfigAdd'),
);
const LazyNotificationConfig = lazy(
  async () => import('./pages/NotificationConfig'),
);

export const notificationConfigRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.notificationConfig.list)},
  {
    path: routes.getRelativePath(
      routes.notificationConfig.list,
      routes.notificationConfig.base,
    ),
    element: <LazyNotificationConfigs />,
    loader: () => {
      document.title = 'Notification Configs';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Notification Configs'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.notificationConfig.add,
      routes.notificationConfig.base,
    ),
    element: <LazyNotificationConfigAdd />,
    loader: () => {
      document.title = 'Add Notification Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {
            label: 'Notification Configs',
            path: routes.notificationConfig.list,
          },
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.notificationConfig.notificationConfigId,
      routes.notificationConfig.base,
    ),
    element: <LazyNotificationConfig />,
    loader: () => {
      document.title = 'View Notification Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Notification Configs', path: routes.notificationConfig.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
