import {array, boolean, object, string} from 'yup';

import {reportParameterValueSchema} from './reportParameterValueSchema';
import {number} from '../../common/components/form/validationSchemas';

export const reportParameterSchema = object({
  description: string(),
  reportParameterId: number,
  reportScheduleId: number,
  listName: string(),
  listValues: array(string()),
  name: string(),
  type: string().required('Required'),
  required: boolean().required('Required'),
  value: reportParameterValueSchema,
});
