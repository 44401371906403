import {type ReactNode} from 'react';

import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import {useTableToolbarInfoStyles} from './TableToolbarInfo.style';

interface TableToolbarInfoProps {
  toolbarInfo: ReactNode;
}

export function TableToolbarInfo({toolbarInfo}: TableToolbarInfoProps) {
  const classes = useTableToolbarInfoStyles();
  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="TableToolbarInfo">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <Tooltip
          placement="bottom"
          classes={{tooltip: classes.tooltip}}
          TransitionComponent={Zoom}
          title={toolbarInfo}
        >
          <InfoOutlinedIcon className={classes.icon} color="action" />
        </Tooltip>
      </Template>
    </Plugin>
  );
}
