import {redirect, type RouteObject} from 'react-router-dom';

import Order from './pages/Order';
import OrderAdd from './pages/OrderAdd';
import Orders from './pages/Orders';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

export const orderRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.order.list)},
  {
    path: routes.getRelativePath(routes.order.list, routes.order.base),
    element: <Orders />,
    loader: () => {
      document.title = 'Orders';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Orders'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.order.add, routes.order.base),
    element: <OrderAdd />,
    loader: () => {
      document.title = 'Add Order';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Orders', path: routes.order.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.order.orderId, routes.order.base),
    element: <Order />,
    loader: () => {
      document.title = 'View Order';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Orders', path: routes.order.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
