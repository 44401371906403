import {purchaseOrderApi} from '../../../api';
import {useTableDownload} from '../../common/hooks/useTableDownload';
import {
  calculateOrdersCompletedPercentage,
  calculateQuantityCompletedPercentage,
} from '../helper/getPercentages';

export const useDownloadPurchaseOrders = () => {
  return useTableDownload('PurchaseOrders', async () => {
    const {items} = await purchaseOrderApi.apiPurchaseOrderGet({});
    return (
      items?.map((item) => ({
        purchaseOrderNumber: item.purchaseOrderNumber,
        orders: item.orders,
        customers: item.customer,
        totalQuantity: item.totalQuantity,
        actualQuantityCompleted: item.actualQuantityCompleted,
        ordersCompleted: calculateOrdersCompletedPercentage({
          totalOrders: item.totalOrders,
          ordersCompleted: item.ordersCompleted,
        }),
        quantityCompleted: calculateQuantityCompletedPercentage({
          totalQuantity: item.totalQuantity,
          actualQuantityCompleted: item.actualQuantityCompleted,
        }),
      })) ?? []
    );
  });
};
