const mainColor = '#F57C00';

const HEREHybridTheme = {
  nearbyNodesGeofences: {
    color: mainColor,
    fillColor: mainColor,
    fillOpacity: 0.5,
  },

  plannedTripGeofences: {
    color: mainColor,
    fillColor: mainColor,
    fillOpacity: 0.5,
  },

  plannedSequenceLine: {
    color: 'black',
  },

  actualSequenceLine: {
    color: 'black',
  },

  telematicsRoute: {
    color: 'red',
  },

  getFeatureStyle: {
    dangerZone: {
      color: 'red',
      fillColor: 'red',
      opacity: 0.7,
    },
  },

  actualStopMarker: {
    scheduledStop: {
      iconSize: [10, 10],
      fillColor: 'green',
      color: 'green',
    },
    knownUnscheduledStop: {
      iconSize: [10, 10],
      fillColor: 'orange',
      color: 'orange',
    },
    illegalStop: {
      iconSize: [12, 12],
      fillColor: 'red',
      color: 'red',
    },
  },

  geoSelectionMarker: {
    iconSize: [25, 25],
    fillColor: 'blue',
    color: 'blue',
  },
  icons: {
    depot: mainColor,
    collection: mainColor,
    delivery: mainColor,
    resource: mainColor,
    border: mainColor,
    warning: mainColor,
    personPin: mainColor,
    info: mainColor,
    plannedResource: mainColor,
    rest: mainColor,
  },
};

export {HEREHybridTheme};
