import {type Dispatch, type SetStateAction, useMemo, useState} from 'react';

import type {Sorting} from '@devexpress/dx-react-grid';
import {debounce} from '@mui/material/utils';

import {type VantageTableSorting} from '../components/table/VantageTable';
import {getStringFromSorting} from '../helpers/getStringFromSorting';

interface UseSortingParams {
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const useSorting = ({setCurrentPage}: UseSortingParams) => {
  const [sorting, setSorting] = useState<Sorting[]>([]);

  return {
    tableSorting: useMemo(
      () =>
        ({
          sorting,
          onSortingChange: debounce((newSorting) => {
            setCurrentPage(1);
            setSorting(newSorting);
          }, 500),
        }) satisfies VantageTableSorting,
      [setCurrentPage, sorting],
    ),
    loadRequestSorting: useMemo(
      () => ({
        orderBy: getStringFromSorting(sorting) ?? undefined,
      }),
      [sorting],
    ),
  };
};

export type LoadRequestSorting = ReturnType<
  typeof useSorting
>['loadRequestSorting'];
