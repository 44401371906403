import {styled} from '@mui/material/styles';

import {getGlassBackground} from '../../../theme/getGlassBackground';

export const AuthCard = styled('div')(({theme}) => ({
  padding: theme.spacing(2),
  maxWidth: 450,
  zIndex: 2,
  borderRadius: theme.shape.borderRadius,
  ...getGlassBackground(theme, {blur: 10, transparency: 0.2}),
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 4px 30px rgba(255, 255, 255, 0.1)'
      : '0 4px 30px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  minHeight: 0,
}));
