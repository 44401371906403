import {type MouseEvent, type DragEvent, useCallback} from 'react';

import {Table} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

import {addShouldNotForwardProps} from '../../../helpers/addShouldNotForwardProps';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';
import {type VantageTableRowActions} from '../VantageTable';

export interface VantageTableRowProps<TableData extends object> {
  onDragStart: VantageTableRowActions<TableData>['onDragStart'];
  onRightClick: VantageTableRowActions<TableData>['onRightClick'];
  config: VantageTableConfig<TableData>;
}

export function VantageTableRow<TableData extends object>({
  children,
  row,
  onDragStart,
  onRightClick,
  config,
  ...props
}: Table.DataRowProps & VantageTableRowProps<TableData>) {
  const handleRightClick = useCallback(
    (event: MouseEvent<HTMLTableRowElement>) => {
      if (onRightClick != null) {
        event.preventDefault();
        onRightClick(event, row as TableData);
      }
    },
    [onRightClick, row],
  );

  const handleDragStart = useCallback(
    (
      event: DragEvent<HTMLTableRowElement> & {target: {id: string | number}},
    ) => {
      if (onDragStart != null) {
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.effectAllowed = 'move';
        onDragStart(event, row as TableData);
      }
    },
    [onDragStart, row],
  );

  return (
    <VantageTableRowComponent
      {...props}
      rightClick={onRightClick != null}
      onContextMenu={handleRightClick}
      onDragStart={handleDragStart}
      draggable={onDragStart != null}
      row={row}
    >
      {children}
    </VantageTableRowComponent>
  );
}

const VantageTableRowComponent = styled(Table.Row, {
  shouldForwardProp: addShouldNotForwardProps('rightClick'),
})<{rightClick?: boolean}>(({rightClick}) => ({
  ...(rightClick === true ? {cursor: 'context-menu'} : {}),
}));
