import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const vantageLabsMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Document Templates',
    pathname: routes.documentTemplate.list,
    permission: 'documentTemplate:list',
  },
  {
    level: 2,
    label: 'Notification Configs',
    pathname: routes.notificationConfig.list,
    permission: 'notificationConfig:list',
  },
  // TODO
  // {
  //   level: 2,
  //   label: 'Template Editor',
  //   pathname: routes.templateEditor.list,
  //   permission: 'templateEditor:list',
  // },
];
