import {driverApi} from '../../../api';
import {useTableDownload} from '../../common/hooks/useTableDownload';

export const useDownloadDrivers = () => {
  return useTableDownload('Drivers', async () => {
    const {items} = await driverApi.apiDriverGet({
      perPage: 10000,
    });
    return (
      items?.map((item) => ({
        firstName: item.firstname ?? '-',
        lastName: item.lastname ?? '-',
        employeeNumber: item.externalEmployeeNumber ?? '-',
        externalName: item.externalName ?? '-',
        contractCode: item.contract?.code ?? '-',
        contactNumber: item.contactNumber ?? '-',
        licenseType: item.licenseType ?? '-',
        licenseExpiry: item.licenseExpiry?.toISOString() ?? '-',
        config: item.telematicsConfigId ?? '-',
        externalId: item.externalId ?? '-',
      })) ?? []
    );
  });
};
