import {createContext, useContext} from 'react';

import type {TripAddDetailsValues} from '../components/tripAdd/TripAddDetails';

interface TripAddDetailsContextProps {
  tripDetails: TripAddDetailsValues;
  onPreviousStep: () => void;
}

export const TripAddDetailsContext =
  createContext<TripAddDetailsContextProps | null>(null);

export const useTripAddDetailsContext = () => {
  const context = useContext(TripAddDetailsContext);
  if (context == null) {
    throw new Error('TripAddDetailsContext not found');
  }
  return context;
};
