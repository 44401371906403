import EventIcon from '@mui/icons-material/EventRounded';
import InputAdornment from '@mui/material/InputAdornment';
import {
  MobileDatePicker,
  type MobileDatePickerProps,
} from '@mui/x-date-pickers/MobileDatePicker';
import {useFormikContext} from 'formik';
import {DateTime} from 'luxon';

import {useFieldDisabled} from './hooks/useFieldDisabled';
import {useFieldError} from './hooks/useFieldError';

export type VantageDatePickerFieldProps =
  | ({formik: true} & VantageDatePickerFieldFormikProps)
  | ({formik?: false} & VantageDatePickerFieldBaseProps);

export function VantageDatePickerField({
  formik,
  ...props
}: VantageDatePickerFieldProps) {
  if (formik === true) return <VantageDatePickerFieldFormik {...props} />;

  return <VantageDatePickerFieldBase {...props} />;
}

type VantageDatePickerFieldFormikProps = Omit<
  VantageDatePickerFieldBaseProps,
  'value' | 'defaultValue' | 'onChange'
>;

function VantageDatePickerFieldFormik({
  name,
  disabled = false,
  ...props
}: VantageDatePickerFieldFormikProps) {
  const fieldDisabled = useFieldDisabled(disabled);
  const fieldError = useFieldError(name);
  const {getFieldMeta, getFieldHelpers, handleBlur} = useFormikContext();
  const {initialValue, value} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  if (initialValue != null && !(initialValue instanceof Date)) {
    throw new Error('Invalid date');
  }

  if (value != null && !(value instanceof Date)) {
    throw new Error('Invalid date');
  }

  const onChange = async (val: DateTime<boolean> | null) => {
    await setValue(val?.toJSDate());
  };

  return (
    <VantageDatePickerFieldBase
      {...props}
      name={name}
      slotProps={{
        ...props.slotProps,
        textField: {
          error: fieldError != null,
          helperText: fieldError,
          onBlur: handleBlur,
          ...props.slotProps?.textField,
        },
      }}
      disabled={fieldDisabled}
      onChange={onChange}
      defaultValue={
        initialValue != null ? DateTime.fromJSDate(initialValue) : null
      }
      value={value != null ? DateTime.fromJSDate(value) : null}
    />
  );
}

interface VantageDatePickerFieldBaseProps
  extends MobileDatePickerProps<DateTime> {
  name: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
}

export function VantageDatePickerFieldBase({
  name,
  size = 'medium',
  fullWidth = true,
  ...props
}: VantageDatePickerFieldBaseProps) {
  return (
    <MobileDatePicker
      {...props}
      name={name}
      slotProps={{
        ...props.slotProps,
        textField: {
          sx: {cursor: 'pointer'},
          id: name,
          fullWidth,
          size,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <EventIcon />
              </InputAdornment>
            ),
          },
          ...props.slotProps?.textField,
        },
      }}
    />
  );
}
