import {type WebEvent} from '@onroadvantage/onroadvantage-api';

import {
  criticalEventApi,
  mutedCriticalEventTypeApi,
  mutedOperationalEventTypeApi,
  operationalEventApi,
} from '../../../api';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type EventsPaneCardMuteFormValues} from '../components/eventPane/EventsPaneCardMuteForm';
import {type EventActivityParams} from '../types/EventActivityParams';

export const useMuteEvent = ({
  updateEventState,
  eventId,
  event,
  model,
}: EventActivityParams & {
  event: WebEvent;
}) => {
  return useSubmitForm<EventsPaneCardMuteFormValues>(
    async ({duration, comment}) => {
      if (event.eventType?.id == null) {
        throw new Error('No event type found');
      }

      if (event.vehicle?.id == null) {
        throw new Error('Event does not have vehicle');
      }

      if (duration == null) {
        throw new Error('Duration is required');
      }

      if (comment == null) {
        throw new Error('Comment is required');
      }

      if (model === 'OperationalEvent') {
        await mutedOperationalEventTypeApi.apiMutedOperationalEventTypePost({
          body: {
            operationalEventTypeId: event.eventType?.id,
            duration,
            vehicleId: event.vehicle?.id,
          },
        });
        const response =
          await operationalEventApi.apiOperationalEventOperationalEventIdPost({
            body: {
              activities: [
                {action: 'Comment', value: comment},
                {action: 'StatusChange', value: 'MutedClosed'},
              ],
            },
            operationalEventId: eventId,
          });
        updateEventState(response, 'OperationalEvent');
      } else {
        await mutedCriticalEventTypeApi.apiMutedCriticalEventTypePost({
          body: {
            criticalEventTypeId: event.eventType?.id,
            duration,
            vehicleId: event.vehicle?.id,
          },
        });
        const response =
          await criticalEventApi.apiCriticalEventCriticalEventIdPost({
            body: {
              activities: [
                {action: 'Comment', value: comment},
                {action: 'StatusChange', value: 'MutedClosed'},
              ],
            },
            criticalEventId: eventId,
          });
        updateEventState(response, 'CriticalEvent');
      }
    },
  );
};
