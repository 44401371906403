import {useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {ValueEditorAutocompleteWithListing} from '../components/ValueEditorAutocompleteWithListing';
import {ValueEditorAutocompleteWithOptions} from '../components/ValueEditorAutocompleteWithOptions';
import {ValueEditorStringFilter} from '../components/ValueEditorStringFilter';
import {checkIsFieldDisabled} from '../helpers/checkIsFieldDisabled';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';

export interface StringTypeProviderEditorProps<TableData extends object> {
  rowId: keyof TableData;
  loading?: boolean;
  config: VantageTableConfig<TableData>;
}

export function AutocompleteProviderEditor<TableData extends object>({
  rowId,
  config,
  column,
  ...props
}: DataTypeProvider.ValueEditorProps &
  StringTypeProviderEditorProps<TableData>) {
  const {row} = props;
  const columnConfig = useMemo(() => {
    return config.columns.find((c) => c.name === column.name);
  }, [column.name, config.columns]);

  if (columnConfig == null) {
    throw new Error('Column config not found');
  }

  if (row == null) {
    return <ValueEditorStringFilter columnConfig={columnConfig} {...props} />;
  }

  const disabled =
    checkIsFieldDisabled(row, rowId, columnConfig) || props.disabled;
  const {value} = props;

  let fieldValue = value;
  if (columnConfig.getValue != null) {
    fieldValue = columnConfig.getValue(row as TableData);
  }
  if (disabled) {
    if (columnConfig.getValue != null && columnConfig.getDisplayValue == null) {
      fieldValue = columnConfig.getValue(row as TableData);
    }
    if (columnConfig.getDisplayValue != null) {
      fieldValue = columnConfig.getDisplayValue(row as TableData);
    }
    return fieldValue ?? '';
  }

  const name = columnConfig.name as string;
  const id = `${name}${row[rowId]?.toString() ?? ''}`;

  if ('models' in columnConfig) {
    if (columnConfig.type === 'autocompleteMulti') {
      return (
        <ValueEditorAutocompleteWithListing
          {...props}
          columnConfig={columnConfig}
          value={
            typeof fieldValue === 'string'
              ? [{value: 0, label: fieldValue}]
              : (fieldValue ?? [])
          }
          disabled={disabled}
          name={name}
          id={id}
          multiple
        />
      );
    }

    return (
      <ValueEditorAutocompleteWithListing
        {...props}
        columnConfig={columnConfig}
        value={
          typeof fieldValue === 'string'
            ? {value: 0, label: fieldValue}
            : (fieldValue ?? null)
        }
        disabled={disabled}
        name={name}
        id={id}
      />
    );
  }

  if ('options' in columnConfig) {
    if (columnConfig.type === 'autocompleteMulti') {
      return (
        <ValueEditorAutocompleteWithOptions
          {...props}
          columnConfig={columnConfig}
          value={
            typeof value === 'string'
              ? [{value: 0, label: value}]
              : (value ?? [])
          }
          disabled={disabled}
          name={name}
          id={id}
          multiple
        />
      );
    }
    return (
      <ValueEditorAutocompleteWithOptions
        {...props}
        columnConfig={columnConfig}
        value={
          typeof value === 'string' ? {value: 0, label: value} : (value ?? null)
        }
        disabled={disabled}
        name={name}
        id={id}
      />
    );
  }

  // Shouldn't get reached
  return <p>value</p>;
}
