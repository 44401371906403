import ArrowLeftRounded from '@mui/icons-material/ArrowLeftRounded';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {getNestedBorderRadius} from '../../../theme/getNestedBorderRadius';
import {VantageTooltip} from '../../common/components/VantageTooltip';
import {addShouldNotForwardProps} from '../../common/helpers/addShouldNotForwardProps';
import {splitByCapitals} from '../../common/helpers/splitByCapitals';
import {useUserStore} from '../../common/stores/userStore';
import {getTimeAgo} from '../../event/helpers/getTimeAgo';
import {getTimeAgoDisplay} from '../../event/helpers/getTimeAgoDisplay';

export interface Activity {
  action: string;
  createdAt: Date | undefined;
  createdByEmail: string | undefined;
  id: string | number;
  value: string | undefined;
}

interface ActivityCardProps {
  activity: Activity;
  maxWidth: number | undefined;
}

export function ActivityCard({activity, maxWidth = 275}: ActivityCardProps) {
  const email = useUserStore((state) => state.email);
  const createdByMe = activity.createdByEmail === email;
  return (
    <Card key={activity.id} $createdByMe={createdByMe} maxWidth={maxWidth}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        flexWrap="nowrap"
      >
        <Typography variant="caption" color="text.secondary">
          {splitByCapitals(activity.action ?? 'Unknown')}
        </Typography>
        <VantageTooltip title={getTimeAgo(activity.createdAt)}>
          <Typography variant="caption" color="text.secondary">
            {getTimeAgoDisplay(activity.createdAt)}
          </Typography>
        </VantageTooltip>
      </Stack>
      <Typography variant="body2" whiteSpace="normal">
        {activity.value}
      </Typography>
      {!createdByMe && (
        <Typography variant="caption" color="text.secondary">
          {activity.createdByEmail}
        </Typography>
      )}
      <Arrow fontSize="large" $createdByMe={createdByMe} />
    </Card>
  );
}

const Card = styled(Stack, {
  shouldForwardProp: addShouldNotForwardProps('$createdByMe'),
})<{$createdByMe: boolean}>(({theme, $createdByMe}) => ({
  cursor: 'default',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0.3, 0.6),
  borderRadius: getNestedBorderRadius(theme, 0.5),
  position: 'relative',
  alignSelf: $createdByMe ? 'flex-end' : 'flex-start',
}));

const Arrow = styled(ArrowLeftRounded, {
  shouldForwardProp: addShouldNotForwardProps('$createdByMe'),
})<{$createdByMe: boolean}>(({theme, $createdByMe}) => ({
  color: theme.palette.background.paper,
  position: 'absolute',
  top: -4,
  padding: 0,
  ...($createdByMe ? {right: -18, transform: 'rotate(180deg)'} : {left: -18}),
}));
