import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormalRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShippingRounded';
import TodayIcon from '@mui/icons-material/TodayRounded';
import Stack, {type StackProps} from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {DateUtil} from '../../../common/utils/DateUtil';
import {useTripAddDetailsContext} from '../../contexts/TripAddDetailsContext';

function Row(props: StackProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
      {...props}
    />
  );
}

const Card = styled(Stack)(({theme}) => ({
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.default,
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  gap: theme.spacing(2),
}));

export function TripAddStopsTripDetails() {
  const {tripDetails} = useTripAddDetailsContext();

  return (
    <Card>
      <Row>
        <Row spacing={1}>
          <TodayIcon color="action" />
          <Typography variant="body2" fontWeight={600}>
            Trip Start
          </Typography>
        </Row>
        <Typography variant="body2" color="text.secondary">
          {DateUtil.format(tripDetails.tripStart, 'ShortDateTime')}
        </Typography>
      </Row>
      <Row>
        <Row spacing={1}>
          <ContentPasteRoundedIcon color="action" />
          <Typography variant="body2" fontWeight={600}>
            Contract
          </Typography>
        </Row>
        <Typography variant="body2" color="text.secondary">
          {tripDetails.contract.label}
        </Typography>
      </Row>
      <Row>
        <Row spacing={1}>
          <AirlineSeatReclineNormalIcon color="action" />
          <Typography variant="body2" fontWeight={600}>
            Driver
          </Typography>
        </Row>
        <Typography variant="body2" color="text.secondary">
          {tripDetails.driver.label}
        </Typography>
      </Row>
      <Row>
        <Row spacing={1}>
          <LocalShippingIcon color="action" />
          <Typography variant="body2" fontWeight={600}>
            Vehicle
          </Typography>
        </Row>
        <Typography variant="body2" color="text.secondary">
          {tripDetails.vehicle.label}
        </Typography>
      </Row>
    </Card>
  );
}
