import {type ReactNode} from 'react';

import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';

interface TableToolbarCustomActionProps {
  toolbarCustomAction: ReactNode;
}

export function TableToolbarCustomAction({
  toolbarCustomAction,
}: TableToolbarCustomActionProps) {
  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="TableToolbarCustomAction">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        {toolbarCustomAction}
      </Template>
    </Plugin>
  );
}
