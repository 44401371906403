import {LoadingText} from '../../common/components/loader/LoadingText';
import {useCommandPrompt} from '../hooks/useCommandPrompt';
import {useCommandPromptListeners} from '../hooks/useCommandPromptListeners';
import './commandPrompt.css';

interface CommandPromptProps {
  isOpen: boolean;
  onClose: () => void;
}
export function CommandPrompt({isOpen, onClose}: CommandPromptProps) {
  const {inputRef, boxRef} = useCommandPromptListeners({onClose, isOpen});
  const {
    input,
    suggestions,
    selectedModelOption,
    searchTerm,
    focusedSuggestion,
    loadingListing,
    handleInputChange,
    handleOptionSelect,
    handleRemoveOption,
    handleKeyNavigation,
  } = useCommandPrompt({onClose, inputRef});

  if (!isOpen) return null;

  return (
    <div className="command-prompt-overlay">
      <div className="command-prompt" ref={boxRef}>
        <div className="input-container">
          {selectedModelOption != null && (
            <span className="chip">
              {selectedModelOption.label}
              <button
                type="button"
                className="remove-chip"
                onClick={handleRemoveOption}
              >
                &times;
              </button>
            </span>
          )}
          <input
            ref={inputRef}
            type="text"
            value={selectedModelOption != null ? searchTerm : input}
            onChange={handleInputChange}
            onKeyDown={handleKeyNavigation}
            placeholder={
              selectedModelOption != null
                ? 'Start typing to search...'
                : 'Type # for options or start searching...'
            }
          />
        </div>
        {suggestions.length > 0 && (
          <ul className="suggestions">
            {suggestions.map((suggestion, index) => (
              <li
                role="option"
                aria-selected={index === focusedSuggestion}
                tabIndex={0}
                key={suggestion.key}
                onClick={() => {
                  handleOptionSelect(suggestion);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleOptionSelect(suggestion);
                  }
                }}
                className={index === focusedSuggestion ? 'focused' : ''}
              >
                {suggestion.label}
              </li>
            ))}
          </ul>
        )}
        {loadingListing && (
          <LoadingText textAlign="center" mt={1}>
            Loading Options
          </LoadingText>
        )}
      </div>
    </div>
  );
}
