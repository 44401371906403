import DnsIcon from '@mui/icons-material/DnsRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const masterDataHomeMenuItems: HomeMenuItemCard = {
  title: 'Master Data',
  icon: <DnsIcon color="action" />,
  menuItems: [
    {
      label: 'Audience',
      path: routes.audience.list,
      permissions: ['audience:list'],
    },
    {
      label: 'Contracts',
      path: routes.contract.list,
      permissions: ['contract:list'],
    },
    {
      label: 'Drivers',
      path: routes.driver.list,
      permissions: ['driver:list'],
    },
    {
      label: 'Event Types',
      path: routes.eventType.list,
      permissions: ['criticalEventType:list', 'operationalEventType:list'],
      permissionCheck: 'one-of',
    },
    {
      label: 'Vehicles',
      path: routes.vehicle.list,
      permissions: ['vehicle:list'],
    },
  ],
};
