export const isArray = (value: unknown): value is unknown[] => {
  return Array.isArray(value);
};

export const isArrayOfStrings = (value: unknown): value is string[] => {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every((element) => typeof element === 'string')
  );
};

export const isArrayOfNumbers = (value: unknown): value is number[] => {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every((element) => typeof element === 'number')
  );
};

export const isArrayAndNotEmpty = (value: unknown): value is unknown[] => {
  return Array.isArray(value) && value.length > 0;
};

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' && value != null;
};

export const isObjectWithKeys = <T extends string | number | symbol>(
  value: unknown,
  ...keys: T[]
): value is {[key in T]: unknown} => {
  if (typeof value !== 'object' || value == null) {
    return false;
  }
  return keys.every((key) => key in value);
};

export const isStringAndNotEmpty = (value: unknown): value is string => {
  return typeof value === 'string' && value !== '';
};

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

export const isNumberAndNotZero = (value: unknown): value is number => {
  return typeof value === 'number' && value !== 0;
};
