import {memo, useCallback, useMemo, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import HistoryIcon from '@mui/icons-material/HistoryRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded';
import VisibilityIcon from '@mui/icons-material/VisibilityRounded';
import VolumeOffIcon from '@mui/icons-material/VolumeOffRounded';
import Badge from '@mui/material/Badge';
import MuiButton from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  type WebEvent,
  type WebEventVehicle,
} from '@onroadvantage/onroadvantage-api';

import {EventsPaneCardMuteForm} from './EventsPaneCardMuteForm';
import {routes} from '../../../../routes';
import {getNestedBorderRadius} from '../../../../theme/getNestedBorderRadius';
import {VantageTooltip} from '../../../common/components/VantageTooltip';
import {addShouldNotForwardProps} from '../../../common/helpers/addShouldNotForwardProps';
import {isObjectWithKeys} from '../../../common/helpers/unknownValueTypeChecks';
import {useUserStore} from '../../../common/stores/userStore';
import {type EventModel, eventModels} from '../../../common/types/EventModel';
import {Activities} from '../../../shared/activity/Activities';
import {type Activity} from '../../../shared/activity/ActivityCard';
import {getTimeAgo} from '../../helpers/getTimeAgo';
import {getTimeAgoDisplay} from '../../helpers/getTimeAgoDisplay';
import {useAddCommentToEvent} from '../../hooks/useAddCommentToEvent';
import {useAssignEventToMe} from '../../hooks/useAssignEventToMe';
import {useCloseEvent} from '../../hooks/useCloseEvent';
import {useMuteEvent} from '../../hooks/useMuteEvent';
import {type UpdateEventState} from '../../hooks/useSearchEvents';
import {type EventActivityParams} from '../../types/EventActivityParams';

export const EventsPaneCard = memo(function EventsPaneCard({
  event,
  updateEventState,
  onSearchVehicleHistory,
  reloadEvents,
}: {
  event: WebEvent;
  updateEventState: UpdateEventState;
  onSearchVehicleHistory: (vehicle: WebEventVehicle) => Promise<void>;
  reloadEvents: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {pathname} = useLocation();
  const email = useUserStore((state) => state.email);
  const model = event.metaData?.model ?? 'CriticalEvent';
  const activityParams: EventActivityParams = {
    eventId: event.id ?? 0,
    updateEventState,
    model: model in eventModels ? (model as EventModel) : 'CriticalEvent',
  };
  const {assignEventToMe, assignToMeStatus} =
    useAssignEventToMe(activityParams);
  const {closeEvent, closeEventStatus} = useCloseEvent({
    ...activityParams,
    reloadEvents,
  });
  const {onSubmit: onSubmitComment, submitStatus: addCommentStatus} =
    useAddCommentToEvent(activityParams);
  const {onSubmit: onSubmitMuteEvent, submitStatus: muteEventStatus} =
    useMuteEvent({...activityParams, event});
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [muteEventFormExpanded, setMuteEventFormExpanded] = useState(false);
  const searchParamEvent = searchParams.get('event');
  const searchParamModel = searchParams.get('model');

  let isActive = false;

  if (
    event.metaData?.model != null &&
    searchParamEvent != null &&
    searchParamEvent.includes(event.metaData.model) &&
    parseInt(searchParamEvent.replace(event.metaData.model, ''), 10) ===
      event.id
  ) {
    isActive = true;
  } else if (
    pathname.includes(`${routes.event.base}/`) &&
    pathname.includes(`/${event.id}`) &&
    searchParamModel != null &&
    event.metaData?.model != null &&
    searchParamModel === event.metaData.model
  ) {
    isActive = true;
  }

  const activities = useMemo<Activity[]>(
    () =>
      event.activities?.map((activity, index) => ({
        action: activity.action ?? 'Comment',
        createdAt: activity.createdAt,
        createdByEmail: activity.createdByEmail,
        id: activity.id ?? index,
        value: activity.value,
      })) ?? [],
    [event.activities],
  );

  const assignedEmail = useMemo(
    () =>
      event.activities
        ?.sort((a, b) =>
          a.createdAt != null && b.createdAt != null
            ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            : 0,
        )
        ?.reduce((acc: string | undefined, activity) => {
          if (
            activity.action === 'AssignmentChange' &&
            activity.createdByEmail != null
          ) {
            return activity.createdByEmail;
          }
          return acc;
        }, 'Unassigned'),
    [event.activities],
  );

  const isAssignedToMe = assignedEmail === email;

  const actionsDisabled =
    assignToMeStatus === 'assigning' ||
    addCommentStatus === 'submitting' ||
    closeEventStatus === 'closing' ||
    muteEventStatus === 'submitting';

  const renderMainAction = useMemo(() => {
    if (event.status !== 'Open') {
      return (
        <Button
          size="small"
          variant="contained"
          color="inherit"
          onClick={closeEvent}
          disabled={actionsDisabled}
          startIcon={
            closeEventStatus === 'closing' ? (
              <CircularProgress size={18} />
            ) : null
          }
        >
          {closeEventStatus === 'closing' ? 'Opening' : 'Re-Open'}
        </Button>
      );
    }
    if (isAssignedToMe) {
      return (
        <Button
          size="small"
          variant="contained"
          color="inherit"
          onClick={closeEvent}
          disabled={actionsDisabled}
          startIcon={
            closeEventStatus === 'closing' ? (
              <CircularProgress size={18} />
            ) : null
          }
        >
          {closeEventStatus === 'closing' ? 'Closing' : 'Close'}
        </Button>
      );
    }
    return (
      <Button
        size="small"
        variant="contained"
        color="inherit"
        onClick={assignEventToMe}
        disabled={actionsDisabled}
        startIcon={
          assignToMeStatus === 'assigning' ? (
            <CircularProgress size={18} />
          ) : null
        }
      >
        {assignToMeStatus === 'assigning' ? 'Assigning' : 'Assign'}
      </Button>
    );
  }, [
    actionsDisabled,
    assignEventToMe,
    assignToMeStatus,
    closeEvent,
    closeEventStatus,
    event.status,
    isAssignedToMe,
  ]);

  const handleSearchVehicleHistory = useCallback(async () => {
    if (event.vehicle != null) {
      await onSearchVehicleHistory(event.vehicle);
    }
  }, [event.vehicle, onSearchVehicleHistory]);

  const handleNavigateToEvent = useCallback(() => {
    if (event.id != null) {
      navigate(
        routes.addQueryParams(
          routes.replaceParams(routes.event.eventId, {
            eventId: event.id,
          }),
          {model: event.metaData?.model ?? 'CriticalEvent'},
        ),
      );
    }
  }, [event.id, event.metaData?.model, navigate]);

  const handleNavigateToTrip = useCallback(async () => {
    if (event.masterTrip?.id != null && event.id != null) {
      navigate(
        routes.addQueryParams(
          routes.replaceParams(routes.trip.masterTripId, {
            masterTripId: event.masterTrip.id,
          }),
          {event: `${event.metaData?.model}${event.id}`},
        ),
      );
    }
  }, [event, navigate]);

  const handleNavigateToVehicle = useCallback(async () => {
    if (event.vehicle?.id != null && event.id != null) {
      navigate(
        routes.addQueryParams(
          routes.replaceParams(routes.vehicle.vehicleId, {
            vehicleId: event.vehicle.id,
          }),
          {event: `${event.metaData?.model}${event.id}`},
        ),
      );
    }
  }, [event.id, event.metaData?.model, event.vehicle?.id, navigate]);

  const hasCoordinates = event.latitude != null && event.longitude != null;

  return (
    <Card>
      <Content $isActive={isActive}>
        <StatusSpan />
        <Stack pl={0.3} pr={0} spacing={0.5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            flexWrap="nowrap"
            sx={{cursor: 'default'}}
          >
            <Typography variant="body1" fontWeight={600}>
              {event.eventType?.name}
            </Typography>
            <Stack alignItems="flex-end" textAlign="right">
              <VantageTooltip title={getTimeAgo(event.createdAt)}>
                <Typography variant="caption" color="text.secondary">
                  {getTimeAgoDisplay(event.createdAt)}
                </Typography>
              </VantageTooltip>
              <Typography variant="caption" color="text.secondary">
                {isAssignedToMe ? 'ME' : assignedEmail}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={0.4}>
            <ClickableItem
              disabled={
                actionsDisabled || event.vehicle?.id == null || !hasCoordinates
              }
              onClick={handleNavigateToVehicle}
            >
              <Typography variant="subtitle2" fontWeight={600}>
                Vehicle:
              </Typography>
              {event.vehicle?.id != null && hasCoordinates && (
                <OpenInNewIcon fontSize="inherit" color="action" />
              )}
              <Typography
                variant="subtitle2"
                color="text.secondary"
                style={{marginLeft: 'auto'}}
                textAlign="right"
              >
                {event.vehicle?.registrationNumber != null
                  ? `${event.vehicle.registrationNumber} - ${event.vehicle.fleetNumber}`
                  : '-'}
              </Typography>
            </ClickableItem>
            <ClickableItem
              disabled={
                actionsDisabled ||
                event.masterTrip?.id == null ||
                !hasCoordinates
              }
              onClick={handleNavigateToTrip}
            >
              <Typography variant="subtitle2" fontWeight={600}>
                Trip:
              </Typography>
              {event.masterTrip?.id != null && hasCoordinates && (
                <OpenInNewIcon fontSize="inherit" color="action" />
              )}
              <Typography
                variant="subtitle2"
                color="text.secondary"
                style={{marginLeft: 'auto'}}
                textAlign="right"
              >
                {event.masterTrip?.tripNumber ?? '-'}
              </Typography>
            </ClickableItem>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              flexWrap="nowrap"
              px={0.5}
              sx={{cursor: 'default'}}
            >
              <Typography variant="subtitle2" fontWeight={600}>
                Driver:
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                textAlign="right"
              >
                {event.driver?.firstname ?? '-'}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="nowrap"
            pt={0.5}
          >
            <Stack direction="row" spacing={1}>
              {renderMainAction}
              <Badge color="default" badgeContent={event.activities?.length}>
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setMuteEventFormExpanded(false);
                    setCommentsExpanded((prev) => !prev);
                  }}
                  disabled={actionsDisabled}
                >
                  Activity
                </Button>
              </Badge>
            </Stack>
            <VantageTooltip title="View event">
              <span style={{marginLeft: 'auto'}}>
                <IconButton
                  size="small"
                  color="default"
                  disabled={actionsDisabled || event.vehicle == null}
                  onClick={handleNavigateToEvent}
                >
                  <VisibilityIcon />
                </IconButton>
              </span>
            </VantageTooltip>
            <VantageTooltip title="View vehicle history">
              <span>
                <IconButton
                  size="small"
                  color="default"
                  disabled={actionsDisabled || event.vehicle == null}
                  onClick={handleSearchVehicleHistory}
                >
                  <HistoryIcon />
                </IconButton>
              </span>
            </VantageTooltip>
            <VantageTooltip title="Mute event type">
              <span>
                <IconButton
                  size="small"
                  color="default"
                  disabled={actionsDisabled}
                  onClick={() => {
                    setCommentsExpanded(false);
                    setMuteEventFormExpanded((prev) => !prev);
                  }}
                >
                  {muteEventStatus === 'submitting' ? (
                    <CircularProgress size={16} />
                  ) : (
                    <VolumeOffIcon />
                  )}
                </IconButton>
              </span>
            </VantageTooltip>
          </Stack>
        </Stack>
      </Content>
      <Collapse in={commentsExpanded} mountOnEnter unmountOnExit>
        <Activities
          reasonCodeRequestParameters={useMemo(() => {
            if (event.eventType?.id == null) {
              return undefined;
            }
            let contractId = event.vehicle?.contractId;
            if (
              isObjectWithKeys(event.vehicle, 'contract') &&
              isObjectWithKeys(event.vehicle.contract, 'id') &&
              typeof event.vehicle.contract.id === 'number'
            ) {
              contractId = event.vehicle.contract.id;
            }
            if (model === eventModels.OperationalEvent) {
              return {
                operationalEventTypeId: event.eventType.id,
                contractId,
              };
            }
            return {
              criticalEventTypeId: event.eventType.id,
              contractId,
            };
          }, [event.eventType?.id, event.vehicle, model])}
          activities={activities}
          actionsDisabled={actionsDisabled}
          onSubmitComment={onSubmitComment}
        />
      </Collapse>
      <Collapse in={muteEventFormExpanded} mountOnEnter unmountOnExit>
        <EventsPaneCardMuteForm
          actionsDisabled={actionsDisabled}
          onSubmit={onSubmitMuteEvent}
        />
      </Collapse>
    </Card>
  );
});

const statusSize = 0.7;

const Card = styled('div')(({theme}) => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  overflow: 'visible',
}));

const Content = styled('div', {
  shouldForwardProp: addShouldNotForwardProps('$isActive'),
})<{$isActive: boolean}>(({theme, $isActive}) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  position: 'relative',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: $isActive ? theme.palette.primary.main : theme.palette.divider,
  opacity: $isActive ? 0.7 : 1,
}));

const StatusSpan = styled('span')(({theme}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  width: theme.spacing(statusSize),
  background: theme.palette.secondary.light,
}));

const ClickableItem = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.2, 0.5),
  borderRadius: '100px',
}));

const Button = styled(MuiButton)(({theme}) => ({
  whiteSpace: 'nowrap',
  borderRadius: getNestedBorderRadius(theme, 0.8),
}));
