import {VantageTooltip} from '../components/VantageTooltip';

export function getTooltipTableColumnValues(
  arr: string[] | undefined,
  limit: number = 3,
) {
  const tooltipTitle = arr?.join(', ');
  const content = arr?.slice(0, limit).join(', ') ?? '-';

  if (arr == null || arr.length <= limit) {
    return content;
  }

  return (
    <VantageTooltip title={tooltipTitle}>
      <span>{content}...</span>
    </VantageTooltip>
  );
}
