import {GroupingPanel} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

export const VantageTableGroupingPanelComponent = styled(
  GroupingPanel.Container,
)({
  padding: `0 !important`,
  alignItems: 'center !important',
  marginTop: '0 !important',
});
