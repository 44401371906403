import type {
  ReportSchedulePost,
  ReportSchedulePostContact,
  ReportSchedulePostContactGroup,
  ReportSchedulePostParameter,
} from '@onroadvantage/onroadvantage-api';
import {DateTime} from 'luxon';

import type {ReportScheduleFormValues} from '../components/ReportScheduleForm';

export const getReportSchedulePayload = (
  reportId: number,
  values: ReportScheduleFormValues,
  reportScheduleId?: number,
) => {
  const contractGroups = new Map<
    string /** contactGroupId|recipientType  */,
    ReportSchedulePostContactGroup
  >();
  const contracts = new Map<
    string /** contactId|recipientType  */,
    ReportSchedulePostContact
  >();
  const parameters = new Map<
    number /** reportParameterId */,
    ReportSchedulePostParameter
  >();

  values.contactGroupsTo?.forEach(({value}) => {
    contractGroups.set(`${value}|TO`, {
      contactGroupId: value,
      recipientType: 'to',
    });
  });
  values.contactGroupsCC?.forEach(({value}) => {
    contractGroups.set(`${value}|CC`, {
      contactGroupId: value,
      recipientType: 'cc',
    });
  });
  values.contactGroupsBCC?.forEach(({value}) => {
    contractGroups.set(`${value}|BCC`, {
      contactGroupId: value,
      recipientType: 'bcc',
    });
  });

  values.contactsTo?.forEach(({value}) => {
    contracts.set(`${value}|TO`, {
      contactId: value,
      recipientType: 'to',
    });
  });
  values.contactsCC?.forEach(({value}) => {
    contracts.set(`${value}|CC`, {
      contactId: value,
      recipientType: 'cc',
    });
  });
  values.contactsBCC?.forEach(({value}) => {
    contracts.set(`${value}|BCC`, {
      contactId: value,
      recipientType: 'bcc',
    });
  });

  values.parameters?.forEach((parameter) => {
    if (parameter.reportParameterId == null) {
      return;
    }

    parameters.set(parameter.reportParameterId, {
      reportParameterId: parameter.reportParameterId,
      reportScheduleId: parameter.reportScheduleId ?? reportScheduleId,
      value: parameter.value as object,
    });
  });

  const body: ReportSchedulePost = {
    reportId,
    name: values.name,
    description: values.description,
    contactGroups: Array.from(contractGroups.values()),
    contacts: Array.from(contracts.values()),
    parameters: Array.from(parameters.values()),
  };

  if (values.recurring) {
    const dateTime =
      values.time != null ? DateTime.fromJSDate(values.time) : DateTime.now();
    const utcDateTime = dateTime.toUTC();
    body.startDate = values.startDate;
    body.endDate = values.endDate;
    body.time = `${utcDateTime.toFormat('HH:mm:ss')}`;
    body.type = 'recurring';
    body.monday = values.monday ?? false;
    body.tuesday = values.tuesday ?? false;
    body.wednesday = values.wednesday ?? false;
    body.thursday = values.thursday ?? false;
    body.friday = values.friday ?? false;
    body.saturday = values.saturday ?? false;
    body.sunday = values.sunday ?? false;
  } else {
    const dateTime =
      values.dateTime != null
        ? DateTime.fromJSDate(values.dateTime).toUTC()
        : DateTime.utc();
    body.date = new Date(dateTime.toFormat('yyyy-MM-dd'));
    body.time = `${dateTime.toFormat('HH:mm:ss')}`;
    body.type = 'once';
  }
  return body;
};
