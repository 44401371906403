import {routes} from '../../../routes';

export default [
  // Most Used
  {label: 'Trips', model: 'MasterTrip', pathname: routes.trip.base},
  {label: 'Orders', model: 'Order', pathname: routes.order.base},
  {label: 'Sites', model: 'Node', pathname: routes.site.base},
  // Other
  {label: 'Audience', model: 'Contact', pathname: routes.audience.base},
  {
    label: 'Audience Groups',
    model: 'ContactGroup',
    pathname: routes.audienceGroup.base,
  },
  {label: 'Contracts', model: 'Contract', pathname: routes.contract.base},
  {
    label: 'Contract Groups',
    model: 'ContractGroup',
    pathname: routes.contractGroup.base,
  },
  {label: 'Customers', model: 'Customer', pathname: routes.customer.base},
  {label: 'Dashboards', model: 'Dashboard', pathname: routes.dashboard.base},
  {label: 'Drivers', model: 'Driver', pathname: routes.driver.base},
  {
    label: 'Driver Groups',
    model: 'DriverGroup',
    pathname: routes.driverGroup.base,
  },
  {label: 'Permissions', model: 'Permission', pathname: routes.permission.base},
  {label: 'Products', model: 'Product', pathname: routes.product.base},
  {
    label: 'Product Groups',
    model: 'ProductGroup',
    pathname: routes.productGroup.base,
  },
  {
    label: 'Purchase Orders',
    model: 'PurchaseOrder',
    pathname: routes.purchaseOrder.base,
  },
  {label: 'Reports', model: 'Report', pathname: routes.report.base},
  {label: 'Roles', model: 'Role', pathname: routes.role.base},
  {
    label: 'Speed Tables',
    model: 'SpeedTable',
    pathname: routes.speedTable.base,
  },
  {label: 'Site Types', model: 'NodeType', pathname: routes.siteType.base},
  {
    label: 'Transporters',
    model: 'Transporter',
    pathname: routes.transporter.base,
  },
  {label: 'Users', model: 'User', pathname: routes.user.base},
  {label: 'Vehicles', model: 'Vehicle', pathname: routes.vehicle.base},
  {
    label: 'Vehicle Groups',
    model: 'VehicleGroup',
    pathname: routes.vehicleGroup.base,
  },
  {
    label: 'Vehicle Types',
    model: 'VehicleType',
    pathname: routes.vehicleType.base,
  },
  {label: 'Webhooks', model: 'Webhook', pathname: routes.webhook.base},
  {
    label: 'Webhook Auths',
    model: 'WebhookAuth',
    pathname: routes.webhookAuth.base,
  },
  {
    label: 'Workflow Configs',
    model: 'WorkflowConfig',
    pathname: routes.workflowConfig.base,
  },
];
